import React, { Component } from 'react'
import { connect } from 'react-redux'

class Orderlayout extends Component {

  render() {
    return (
      <div className="page-breadcrumb">{this.props.children}</div>
    )
  }
}

const mapState = () => ({})

const mapDispatch = () => {
  return {}
}
export default connect(mapState, mapDispatch)(Orderlayout)
