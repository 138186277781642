import request from '../../utils/request'

export const invenCalendar = {
    state: {
        inventoriesCalendar: [],
        loadingPage: false
    },
    reducers: {
        inventoriesCalendar(state, payload) {
            return {
                ...state,
                inventoriesCalendar: payload
            }
        },
        inventoriesCalendarCheckLoadpage(state, payload) {
            return {
                ...state,
                loadingPage: payload
            }
        },
    },

    effects: (dispatch) => ({
        async onFaceDataCalendar(monthYear) {
            var d = new Date(monthYear)
            var y = d.getFullYear();
            var month = d.getMonth()+1
            let arr = []
            const res = await request.get(`/maid/calendar/inventory/month/${month}/year/${y}`)
            let checkLoad = false
            for (let i = 0; i < res.data.length; i++) {
                let inventoryObj = {
                    date: res.data[i].date
                };
            
                for (let j = 0; j < res.data[i].inventories.length; j++) {
                    let inventory = res.data[i].inventories[j];
                    inventoryObj[`col${j + 1}`] = inventory !== undefined ? `${inventory.value}` : null;
                    inventoryObj[`col${j + 1}percent`] = inventory !== undefined ? `${inventory.percent}` : null;
                }
            
                let index = arr.findIndex(item => item.date === inventoryObj.date);
                if (index === -1) {
                    arr.push(inventoryObj);
                }
            }            

            const cleanData = arr
            dispatch.invenCalendar.inventoriesCalendarCheckLoadpage(checkLoad)
            dispatch.invenCalendar.inventoriesCalendar(cleanData)
        }

    }),
}