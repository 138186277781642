import React, { Component } from 'react'
import { connect } from 'react-redux'
import './orderManage.css'

class approveManagementLayout extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {}
  }

  async componentDidMount() {
    this.props.setPageTitle('Approve Management')
    this.props.showBackIcon(false)
  }
 
  render() {
    return (
      <div>
        <div>

          {this.props.children}
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  orderMode: state.orderManagement.orderMode,
  dataOrderApprove: state.orderManagement.dataOrderApprove,
})

const mapDispatch = (dispatch) => {
  return {
    setCheckTypePath: dispatch.promotion.setCheckTypePath,
    setPageTitle: dispatch.Title.setPageTitle,
    getorDerApprove: dispatch.orderManagement.getOrderApproveList,
    setOrderMode: dispatch.orderManagement.setOrderMode,
    showBackIcon: dispatch.Title.showBackIcon,
  }
}
export default connect(mapState, mapDispatch)(approveManagementLayout)
