import React, { Component } from 'react'
// import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import Button from '../../components/button'
// import TabComponent from './../../components/tabComponent'
// import styled from 'styled-components'
import "./maidManage.css"
// import Rightbar from './rightbar'

// const Overlay = styled.div`
//   content: ' ';
//   z-index: 10;
//   display: block;
//   position: absolute;
//   height: 100%;
//   top: 0;
//   left: 0;
//   right: 0;
//   background: rgba(255, 255, 255, 0.8);
// `

class MemberManagementLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showRightbar: false,
      mode: 'column',
      textSearch: '',
      checktext: ''
    }
  }

  componentDidMount() {
    this.props.setPageTitle(window.location.href.includes("/radius/list") ? "Radius List" : "Maid List")
    this.props.showBackIcon(false)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.setPageTitle(window.location.href.includes("/radius/list") ? "Radius List" : "Maid List")
      this.props.showBackIcon(false)
    }
  }

  render() {


    return (
      <div>
          {this.props.children}
      </div>
    )
  }
}

const mapState = (state) => ({
  maidsLsit: state.maid.maidsLsit,
  maidColumn: state.maid.column,
  customerList: state.customer.customerList,
  customerColumn: state.customer.column,
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    showBackIcon: dispatch.Title.showBackIcon,
  }
}

export default connect(
  mapState,
  mapDispatch
)(withRouter(MemberManagementLayout))

