import React, { Component } from "react"
import styled from "styled-components"
import ErrorBoundary from "../errorBoundary"
import { connect } from "react-redux"
import request from "../../utils/request"

const Container = styled.div`
  width: 150px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;

  .link {
    cursor: pointer;
  }
`

const Item = styled.div`
  padding: 10px 0 10px 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  img {
    width: 20px;
    margin-right: 14px;
  }

  :hover {
    background-color: #f3f3f3;
  }
`
// const RoleMaidClass = [
//   'Approved',
//   'Unapproved',
//   'Banned'
// ]

class EditOrderApprove extends Component {
  componentDidMount() {
    // console.log(this.props)
  }

  async handleClick(text) {
    await request.post(`maid/id/${this.props.IdMember}/update`, { status: text }).then((res) => {
      if (!res.error) {
        this.props.satatusApprove("UpdateStatus")
      }
    })
  }

  render() {
    const { CheckStatus } = this.props
    return (
      <ErrorBoundary>
        <Container>
          {CheckStatus === "PENDING" && (
            <Item onClick={() => this.handleClick("APPROVED")}>APPROVED</Item>
          )}
          {CheckStatus === "PENDING" && (
            <Item onClick={() => this.handleClick("DISAPPROVED")}>DISAPPROVED</Item>
          )}
          {CheckStatus === "APPROVED" && (
            <Item onClick={() => this.handleClick("DISAPPROVED")}>DISAPPROVED</Item>
          )}
          {CheckStatus === "APPROVED" && (
            <Item onClick={() => this.handleClick("BANNED")}>BANNED</Item>
          )}
          {CheckStatus === "APPROVED" && (
            <Item onClick={() => this.handleClick("PUBLISHED")}>PUBLISHED</Item>
          )}
          {CheckStatus === "PUBLISHED" && (
            <Item onClick={() => this.handleClick("APPROVED")}>APPROVED</Item>
          )}
          {CheckStatus === "PUBLISHED" && (
            <Item onClick={() => this.handleClick("BANNED")}>BANNED</Item>
          )}
          {CheckStatus === "PUBLISHED" && (
            <Item onClick={() => this.handleClick("DISAPPROVED")}>DISAPPROVED</Item>
          )}
          {CheckStatus === "DISAPPROVED" && (
            <Item onClick={() => this.handleClick("APPROVED")}>APPROVED</Item>
          )}
          {CheckStatus === "DISAPPROVED" && (
            <Item onClick={() => this.handleClick("BANNED")}>BANNED</Item>
          )}
          {CheckStatus === "BANNED" && (
            <Item onClick={() => this.handleClick("Pending")}>Pending</Item>
          )}
        </Container>
      </ErrorBoundary>
    )
  }
}

const mapState = () => ({})

const mapDispatch = (dispatch) => {
  return {
    satatusApprove: dispatch.orderManagement.satatusApprove,
  }
}

export default connect(mapState, mapDispatch)(EditOrderApprove)
