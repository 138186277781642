import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import Button from "../../components/button"
import ReactTable from "react-table"
import Rightbar from "../../layouts/paymentManagementLayout/rightbar"
import moment from "moment"
import "./orderManagefilterBy.css"
import request from "../../utils/request"
import Pagination from "../../components/paginationTable/pagination"
import swal from "sweetalert"

const Overlay = styled.div`
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
`
const CardTable = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.active};
  background: #fff;
  height: max-content;
  width: 100%;
  position: relative;
  top: -1px;
  padding: 10px;
  align-items: flex-start;
  overflow: auto;
`

const Container = styled.div`
  padding: 20px;
`
const HeaderTable = styled.div`
  text-align: left;
`
// const Footer = styled.div`
//   position: absolute;
//   left: 10px;
//   bottom: 50px;
// `
// const FooterPicContainer = styled.div`
//   display: flex;
//   padding: 0 0 0 30px;
//   img {
//     margin-right: 10px;
//   }
// `

const Card = styled.div`
  float: left;
  width: 23.333%;
  margin-bottom: 1px;
  border: 0;
  margin-right: -15px;
`

class orderManagementFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      column: this.props.columnOrderManagementNamelist,
      showRightbar: false,
      dataProfile: [],
      salary: "",
      start_datetime: "",
      end_datetime: "",
      listWeekPayment: [],
      Tempweek: "",
      loading: false,
      PageSize: 25,
      numberPage: 1,
      totalPages: 0,
      totaldata: 0,
      yearList: [],
      yaerValue: "",
      status: "",
      salaryForsend: "",
      chanel_url: "",
      isMount: false,
    }
  }

  componentDidMount() {
    this.props.setPageTitle("Back")
    this.props.showBackIcon(true)
    this.getDataProfile()
    // this.getDataCalendarTable()
    // this.handleChangeYear();
    this.setDateForBooking()
    this.props.setStartdateOrder("")
    this.props.setEndateOrder("")
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.column !== this.props.columnOrderManagementNamelist) {
      this.setState({ column: this.props.columnOrderManagementNamelist })
    }
  }
  handleChangeStartDate = (e) => {
    this.setState({ start_datetime: e }, () => this.getDataCalendarTable())
  }
  handleChangeEndDate = (e) => {
    this.setState({ end_datetime: e }, () => this.getDataCalendarTable())
  }

  getDefaultOffDays(year) {
    return this.getDefaultOffDays_3(year)
  }
  leapYear(year) {
    return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0
  }
  loopCheckSatSun(year, dayMonth, month) {
    var saturdays = []
    var sundays = []

    for (let i = 1; i <= dayMonth; i++) {
      var date = new Date(year, month, i)
      if (date.getDay() === 6) {
        saturdays.push(moment(date).format("YYYY-MM-DD"))
      } else if (date.getDay() === 0) {
        sundays.push(moment(date).format("YYYY-MM-DD"))
      }
    }
    let payload = {
      sundays,
      saturdays,
    }
    // console.log("sundays",sundays,"saturdays",saturdays);
    return payload
  }
  // getDefaultOffDays_2(year) {
  //   var year2 = parseInt(year) + 1
  //   var saturdays = []
  //   var sundays = []
  //   var days = []
  //   let payload = []

  //   for (let l = 0; l < 12; l++) {
  //     if (l === 0 || l === 2 || l === 4 || l === 6 || l === 7 || l === 9 || l === 11) {
  //       payload = this.loopCheckSatSun(year, 31, l)
  //     } else if (l === 1) {
  //       this.leapYear(year)
  //         ? (payload = this.loopCheckSatSun(year, 29, l))
  //         : (payload = this.loopCheckSatSun(year, 28, l))
  //     } else {
  //       payload = this.loopCheckSatSun(year, 30, l)
  //     }
  //     saturdays = saturdays.concat(payload.saturdays)
  //     sundays = sundays.concat(payload.sundays)
  //   }

  //   saturdays = saturdays.slice(1)
  //   if (year === 2021 || year === "2021") {
  //     saturdays.push("2022-01-01")
  //     sundays.push("2021-12-26")
  //   }

  //   for (let j = 0; j <= sundays.length; j++) {
  //     console.log("sun", sundays[j])

  //     if (sundays[j] !== undefined && saturdays[j] !== undefined) {
  //       days.push(sundays[j] + " - " + saturdays[j])
  //     }
  //   }

  //   return days
  // }
  getDefaultOffDays_3(year) {
    const days = []
    const date = new Date(year, 0, 1)
    while (date.getDay() != 0) {
      date.setDate(date.getDate() + 1)
    }
    const sundays = []
    const saturdays = []

    while (date.getFullYear() === +year) {
      const m = date.getMonth() + 1
      const d = date.getDate()
      const dateFormate = moment(
        year + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d)
      ).format("YYYY-MM-DD")
      sundays.push(dateFormate)
      saturdays.push(moment(dateFormate).add(6, "d").format("YYYY-MM-DD"))
      date.setDate(date.getDate() + 7)
    }

    for (let j = 0; j <= sundays.length; j++) {
      if (sundays[j] !== undefined && saturdays[j] !== undefined) {
        days.push(sundays[j] + " - " + saturdays[j])
      }
    }
    return days
  }
  async handleChangeYear(year, getCalendarTable = true) {
    this._isMounted = true
    if (this._isMounted) {
      var d = new Date()
      var y = d.getFullYear()
      let Tempyaer = 0
      if (year !== undefined) {
        Tempyaer = year
      } else {
        Tempyaer = y
        // let temparray_no_year = this.getDefaultOffDays(Tempyaer);
        // temparray_no_year = temparray_no_year[0].split(" - ");
        // this.state.start_datetime = temparray_no_year[0];
        // this.state.end_datetime = temparray_no_year[1];
      }

      let temparray = []

      if (Tempyaer) {
        temparray = this.getDefaultOffDays(Tempyaer)
      }
      let temparray_3 = temparray[0].split(" - ")

      // let tempParam = {
      //   startDate: temparray_3[0],
      //   endDate: temparray_3[1],
      // };
      this.setState({ listWeekPayment: temparray, yaerValue: year })
      if (!this._isMounted) {
        console.log("get from params")
      } else {
        this.state.start_datetime = temparray_3[0]
        this.state.end_datetime = temparray_3[1]
      }
      
      if(getCalendarTable){
        await this.getDataCalendarTable(this.state.start_datetime, this.state.end_datetime)
      }

      this._isMounted = true
    }
  }
  async SelectDatePyment(el) {
    this.setState({ Tempweek: el })
    let sDate = el.split(" - ")
    let sDate1 = sDate[0]
    let sDate2 = sDate[1]
    await this.getDataCalendarTable(sDate1, sDate2)
    this.setState({ start_datetime: sDate1, end_datetime: sDate2 })
  }

  async setDateForBooking() {
    let yearGet = this.props.match.params.start_datetime.split("-")
    let yearGetEnd = this.props.match.params.end_datetime.split("-")

    const d = new Date()
    const n = d.getDate()
    //yaerList
    var nYear = yearGet[0]
    var nYearEnd = yearGetEnd[0]
    var num = nYear - 5
    this.setState({ yaerValue: nYear })

    this.handleChangeYear(nYear, false)
    if (num) {
      for (var i = num; i <= nYear; i++) {
        this.state.yearList.push(i)
      }
      this.state.yearList.push(parseInt(nYear) + 1)
      if (nYearEnd !== nYear) {
        this.state.yearList.push(nYearEnd)
      }
    }
    // if (n <= 15) {
    //   await this.setState({
    //     start_datetime: new Date(moment().set("date", 1).format()),
    //     end_datetime: new Date(moment().set("date", 15).format()),
    //   });
    // } else if (n >= 16) {
    //   await this.setState({
    //     start_datetime: new Date(moment().set("date", 16).format()),
    //     end_datetime: new Date(moment(d).endOf("month").format()),
    //   });
    // }
    if (this.props.match.params.start_datetime && this.props.match.params.end_datetime) {
      this.setState({
        Tempweek: `${moment(this.props.match.params.start_datetime, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        )} - ${moment(this.props.match.params.end_datetime, "YYYY-MM-DD").format("YYYY-MM-DD")}`,
        start_datetime: this.props.match.params.start_datetime,
        end_datetime: this.props.match.params.end_datetime,
        yaerValue: nYear,
      })
    }

    // this.handleChangeYear(nYear);

    await this.getDataCalendarTable(
      this.props.match.params.start_datetime,
      this.props.match.params.end_datetime
    )
  }
  async getDataProfile() {
    if (this.props.match.params.id !== undefined) {
      const id = this.props.match.params.id
      const resp = await request.get(`maid/id/${id}`)
      this.setState({
        dataProfile: {
          picture: resp.data.user.image_url,
          name: resp.data.user.first_name + " " + resp.data.user.last_name,
          role: resp.data.user.role,
          email: resp.data.user.email,
          phone: resp.data.user.phone,
          address: resp.data.location_name,
        },
        chanel_url: resp.data.user.admin_channel_url,
      })
    }
  }
  async getDataCalendarTable(start_date, end_date) {
    let startDate = ""
    let endDate = ""
    if (start_date && end_date) {
      startDate = moment(start_date, "YYYY-MM-DD").format("YYYY-MM-DD")
      endDate = moment(end_date, "YYYY-MM-DD").format("YYYY-MM-DD")
      this.setState({
        Tempweek: `${moment(start_date, "YYYY-MM-DD").format("YYYY-MM-DD")} - ${moment(
          end_date,
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD")}`,
      })
    } else {
      startDate = moment(this.state.start_datetime).format("YYYY-MM-DD")
      endDate = moment(this.state.end_datetime).format("YYYY-MM-DD")
      this.setState({
        Tempweek: `${moment(this.state.start_datetime, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        )} - ${moment(this.state.end_datetime, "YYYY-MM-DD").format("YYYY-MM-DD")}`,
      })
    }

    this.setState({ loading: true })
    if (startDate && endDate) {
      console.log(
        "🚀 ---------------------------------------------------------------------------------------------------"
      )
      console.log(
        "🚀 ~ file: index.js ~ line 279 ~ orderManagementFilter ~ getDataCalendarTable ~ startDate",
        startDate,
        endDate
      )
      console.log(
        "🚀 ---------------------------------------------------------------------------------------------------"
      )

      if (this.props.match.params.id !== undefined) {
        const id = this.props.match.params.id
        const resp = await request.get(
          `maid/id/${id}/calendar?start_date=${startDate}&end_date=${endDate}&limit=5000`
        )
        let data = resp.data
        let tempDate = []
        if (data) {
          if (data.calendar.length > 0) {
            for (let i = 0; i < data.calendar.length; i++) {
              console.log("moment", new Date(data.calendar[i].start_datetime))

              // data.calendar[i].start_datetime = moment()
              //   .add(0, "hours")
              //   .format("hh:mm A");

              console.log(
                "moment_2 ????????????",
                new Date(moment(data.calendar[i].start_datetime).add(-1, "hours"))
              )
              tempDate.push({
                Date_service:
                  data.calendar.length > 0
                    ? moment(data.calendar[i].start_datetime).format("YYYY/MM/DD")
                    : "-",
                ServiceHrs: data.calendar[i].booking ? data.calendar[i].booking.duration : -1,
                Type_service: data.calendar[i].booking
                  ? data.calendar[i].booking.type === "MULTI_PACKAGE"
                    ? "MP"
                    : data.calendar[i].booking.type === "ONE_TIME_MANUAL_SELECT"
                    ? "OTM"
                    : "OTA"
                  : "-",
                MP_Credits: data.calendar[i].booking ? data.calendar[i].booking.multi_times : -1,
                Payment: data.calendar[i].booking
                  ? this.formatNumber(data.calendar[i].booking.maid_wage)
                  : -1,
                Rating: data.calendar[i].maid_review ? data.calendar[i].maid_review.rating : -1,
                Bonus: data.calendar[i].bonus ? data.calendar[i].bonus : -1,
                Bonus_Type: data.calendar[i].type ? data.calendar[i].type : -1,
              })
            }
          }
          const totalpage = await Math.ceil((data.calendar.length + 1) / 5000)
          this.setState({
            data: tempDate,
            loading: false,
            status: data.status,
            salary: this.formatSalary(data.salary),
            salaryForsend: data.salary,
            income: data.income ? data.income : 0,
            bonus_five_star: data.bonus_five_star > 0 ? data.bonus_five_star : 0,
            bonus_per_month: data.bonus_per_month > 0 ? data.bonus_per_month : 0,
            bonus_total_hours: data.bonus_total_hours > 0 ? data.bonus_total_hours : 0,
            totalPages: totalpage,
          })
        } else {
          this.setState({
            data: [],
            loading: false,
          })
        }
      }
    }
  }
  formatSalary(num) {
    var val = (Number(num) * 100) / 100
    var parts = val.toString().split(".")
    var num1 =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1].substr(0, 2) : ".00")
    return num1
  }
  formatNumber(num) {
    var val = (Number(num) * 100) / 100
    var parts = val.toString().split(".")
    var num1 =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1].substr(0, 2) : ".00")
    return num1
  }
  handleChangeStatus(value) {
    this.setState({ status: value })
    let idUser = ""
    if (this.props.location.state) {
      if (this.props.match.params.id) {
        idUser = this.props.match.params.id
      }
    }
    const objSalary = {
      salary: this.state.salaryForsend,
      income: this.state.income !== undefined ? this.state.income : 0,
      bonus_five_star: this.state.bonus_five_star !== undefined ? this.state.bonus_five_star : 0,
      bonus_per_month: this.state.bonus_per_month !== undefined ? this.state.bonus_per_month : 0,
      bonus_total_hours:
        this.state.bonus_total_hours !== undefined ? this.state.bonus_total_hours : 0,
      start_date: moment(this.state.start_datetime).format("YYYY-MM-DD"),
      end_date: moment(this.state.end_datetime).format("YYYY-MM-DD"),
    }
    if (value === "PAID") {
      if (objSalary) {
        request.post(`maid/id/${idUser}/paid`, objSalary)
      }
    } else if (value === "UNPAID") {
      if (objSalary) {
        request.post(`maid/id/${idUser}/unpaid`, objSalary)
      }
    }
  }
  handleColumn = () => {
    this.setState({ showRightbar: true, mode: "column" })
  }
  handleFilter = () => {
    this.setState({ showRightbar: true, mode: "filter" })
  }
  handleToggle = (key) => {
    this.setState((prevState) => ({ [key]: !prevState[key] }))
    const { history } = this.props
    history.push(this.props.history.location.pathname)
  }
  sendForChat = () => {
    this.props.history.push(
      `/admin/chatbox/${this.props.match.params.id}/MAID/${this.state.chanel_url}`
    )
    this.props.showBackIcon(false)
    this.props.setPageTitle("Chat Box")
  }
  onExportData() {
    swal({
      className: "swal-modal",
      title: "Are you sure?",
      text: `Are you sure export data Payment `,
      buttons: ["Cancel", "Confirm"],
      defeat: true,
    }).then((willDelete) => {
      if (willDelete) {
        let sDate = moment(this.state.start_datetime).format("YYYY-MM-DD")
        let eDate = moment(this.state.end_datetime).format("YYYY-MM-DD")
        let id = this.props.match.params.id
        request
          .get(`maid/id/${id}/calendar/csv?start_date=${sDate}&end_date=${eDate}`)
          .then((res) => {
            window.location.href = res.config.url
            swal("Export Success", {
              icon: "success",
            })
          })
      }
    })
  }

  compareBy(key, order) {
    return function (a, b) {
      if (order) {
        if (a[key] < b[key]) {
          return 1
        }
        if (a[key] > b[key]) {
          return -1
        }
        return 0
      } else {
        if (a[key] > b[key]) {
          return 1
        }
        if (a[key] < b[key]) {
          return -1
        }
        return 0
      }
    }
  }

  togleSorting(newSorted, column, shiftKey) {
    let arrayCopy = [...this.state.data]
    arrayCopy.sort(this.compareBy(column.id, newSorted[0].desc))
    this.setState({ data: arrayCopy })
  }
  render() {
    const allColumns = [
      {
        Header: () => <HeaderTable style={{ paddingLeft: "10px" }}>Date of service</HeaderTable>,
        accessor: "Date_service", // String-based value accessors!
        minWidth: 100,
        paddingLeft: 10,
        Cell: (row) => (
          <div style={{ textAlign: "left", paddingLeft: "10px" }}>
            {row.original.Date_service !== "-" ? row.original.Date_service : "-"}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable>Service (Hrs)</HeaderTable>,
        accessor: "ServiceHrs",
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: "left" }}>
            {row.original.ServiceHrs !== -1 ? row.original.ServiceHrs : "-"}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable>Type of service</HeaderTable>,
        accessor: "Type_service",
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: "left" }}>
            {row.original.Type_service !== "-" ? row.original.Type_service : "-"}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable>MP Credits</HeaderTable>,
        accessor: "MP_Credits",
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: "left" }}>
            {row.original.MP_Credits !== -1 ? row.original.MP_Credits : "-"}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable>Payment</HeaderTable>,
        accessor: "Payment",
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: "left" }}>
            {row.original.Payment !== -1 ? row.original.Payment : "-"}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable>Rating</HeaderTable>,
        accessor: "Rating",
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: "left" }}>
            {row.original.Rating !== -1 ? row.original.Rating : "-"}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable>Bonus</HeaderTable>,
        accessor: "Bonus",
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: "left" }}>
            {row.original.Bonus !== -1 ? row.original.Bonus : "-"}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable>Bonus Type</HeaderTable>,
        accessor: "Bonus_Type",
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: "left" }}>
            {row.original.Bonus_Type !== -1 ? row.original.Bonus_Type : "-"}
          </div>
        ),
      },
    ]

    let columnWillShow = []
    this.state.column.map((colName) => {
      for (var obj of allColumns) {
        colName.isShow && obj.accessor === colName.accessor && columnWillShow.push(obj)
      }
      return {}
    })
    const currentLocation = this.props.history.location.pathname
    const { dataProfile, salary } = this.state
    return (
      <Container>
        <div className="container-fluid">
          {this.state.showRightbar && <Overlay onClick={() => this.handleToggle("showRightbar")} />}
          {/* Row */}
          <div>
            <div className="row ">
              {/* Column */}
              <Card className="col-md-3">
                <div className="card  col-md-12 cradProfilePyment">
                  <div className="card-body">
                    <center className="m-t-30">
                      <img
                        src={`${dataProfile.picture}`}
                        alt="profile"
                        className="rounded-circle"
                        width={100}
                      />
                      <h4 className="card-title m-t-10">{dataProfile.picnameture}</h4>
                      <h6>{dataProfile.name}</h6>
                      {dataProfile.role ? (
                        <Button
                          className="btn"
                          style={{
                            borderRadius: "11px",
                            width: "75px",
                            height: "30px",
                            fontSize: "12px",
                          }}
                          label="Chat"
                          onClick={() => this.sendForChat()}
                        />
                      ) : null}
                    </center>
                    <small className="text-muted">Role </small>
                    <h6>{dataProfile.role}</h6>
                    <small className="text-muted">Email address </small>
                    <h6>{dataProfile.email}</h6>
                    <small className="text-muted p-t-30 db">Phone</small>
                    <h6>{dataProfile.phone}</h6>
                    <small className="text-muted p-t-30 db">Address</small>
                    <h6>{dataProfile.address}</h6>
                    {/* <Footer>
                    <h6>Social Profile: </h6>
                    <FooterPicContainer>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/users/6.jpg`}
                        alt="profile2"
                        className="rounded-circle"
                        width={35}
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/users/7.jpg`}
                        alt="profile3"
                        className="rounded-circle"
                        width={35}
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/users/8.jpg`}
                        alt="profile4"
                        className="rounded-circle"
                        width={35}
                      />
                    </FooterPicContainer>
                  </Footer> */}
                  </div>
                </div>
              </Card>
              <div className="col-md-9">
                <div className="btn-container">
                  <Button
                    className="btn"
                    style={{ borderRadius: "11px" }}
                    label="Column setting"
                    onClick={() => this.handleColumn()}
                  />
                  <Button
                    className="btn"
                    style={{ borderRadius: "11px" }}
                    label="Filter by"
                    onClick={() => this.handleFilter()}
                  />
                  <Button
                    style={{
                      float: "right",
                      fontSize: "11px",
                      width: "125px",
                      height: "40px",
                    }}
                    className="btn"
                    label="Export"
                    onClick={() => this.onExportData()}
                  />
                </div>
                {/* col-xs-12 col-sm-10 col-md-10 col-xl-8 */}
                <div className="col-md-12">
                  <div className="row form-group">
                    <div className="col-md-3">
                      <div className="col-md-12">
                        <label className="stylelabelPaymentProfile">Year</label>
                      </div>
                      <div className="col-md-12">
                        <select
                          className="OrderFilterInputPyment"
                          id="yaerValue"
                          value={this.state.yaerValue}
                          onChange={(e) => this.handleChangeYear(e.target.value)}>
                          {this.state.yearList.length > 0 ? (
                            this.state.yearList.map((el, i) => {
                              return (
                                <option key={i} value={el}>
                                  {el}
                                </option>
                              )
                            })
                          ) : (
                            <option></option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="col-md-12">
                        <label className="stylelabelPaymentProfile">Date</label>
                      </div>
                      <div className="col-md-12">
                        <select
                          className="OrderFilterInputPyment"
                          id="yaerWeek"
                          value={this.state.Tempweek}
                          onChange={(e) => this.SelectDatePyment(e.target.value)}>
                          {this.state.listWeekPayment.length > 0 ? (
                            this.state.listWeekPayment.map((el, i) => {
                              return (
                                <option key={i} value={el}>
                                  {el}
                                </option>
                              )
                            })
                          ) : (
                            <option></option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="col-md-12">
                        <label className="stylelabelPaymentProfile">Status</label>
                      </div>
                      <div className="col-md-12">
                        <select
                          className="OrderFilterInputPyment"
                          name="max_type"
                          value={this.state.status}
                          onChange={(e) => this.handleChangeStatus(e.target.value)}
                          disabled>
                          <option value="PAID">PAID</option>
                          <option value="UNPAID">UNPAID</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="col-md-12">
                        <label className="stylelabelPaymentProfile">Total Salary</label>
                      </div>
                      <div className="col-md-12">
                        <input
                          className="OrderFilterInputPyment"
                          type="text"
                          id="salary"
                          name="salary"
                          value={salary}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <CardTable>
                  <ReactTable
                    data={this.state.data} //data object
                    columns={columnWillShow} //column config object
                    loading={this.state.loading}
                    pageSize={25}
                    page={this.state.page}
                    showPagination={true}
                    PaginationComponent={Pagination}
                    pages={this.state.totalPages}
                    manual
                    onSortedChange={(newSorted, column, shiftKey) =>
                      this.togleSorting(newSorted, column, shiftKey)
                    }
                    onPageChange={(page) => this.setState({ page: page })}
                    getTrProps={(state, rowInfo) => {
                      return {
                        style: {
                          height: 27.5,
                          overflow: "hidden",
                        },
                      }
                    }}
                    className="-striped -highlight paymentDetailtetable"
                  />
                </CardTable>
              </div>
            </div>
          </div>
          <Rightbar data={this.state} currentLocation={currentLocation} />
          {this.props.children}
        </div>
      </Container>
    )
  }
}

const mapState = (state) => ({
  columnOrderManagementNamelist: state.orderManagement.columnOrderManagementNamelist,
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    showBackIcon: dispatch.Title.showBackIcon,
    setStartdateOrder: dispatch.orderManagement.setStartdateOrder,
    setEndateOrder: dispatch.orderManagement.setEndateOrder,
  }
}

export default connect(mapState, mapDispatch)(orderManagementFilter)
