import * as SendBird from "sendbird";
// import store from '../index'
import { findConfig } from "./sbConstants";

const { APP_ID } = findConfig();

export const sbChannel = {
  state: {
    sbChannelList: [],
  },
  reducers: {
    sbSetChannelList(state, payload) {
      return { ...state, sbChannelList: payload };
    },
  },
  effects: (dispatch) => ({
    sbGetGroupChannelList(filter = "") {
      let sb = SendBird.getInstance();
      if (!sb) sb = new SendBird({ appId: APP_ID });
      // store.getState().sendBirdUser.user.userId
      const channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
      channelListQuery.includeEmpty = true;
      channelListQuery.limit = 100; // The value of pagination limit could be set up to 100.
      // channelListQuery.limit = 30 * (!payload || !payload.limit ? 1 : payload.limit)
      channelListQuery.channelNameContainsFilter = filter;

      if (channelListQuery.hasNext) {
        channelListQuery.next((channelList, error) => {
          if (error) {
            return;
          }

          dispatch.sbChannel.sbSetChannelList(channelList);
        });
      }
    },
    async sbGetGroupChannel(channelUrl) {
      const sb = SendBird.getInstance();
      const channel = await sb.GroupChannel.getChannel(channelUrl);
      // TODO:

      return channel;
    },
  }),
};
