import React, { Component } from 'react'
import { connect } from 'react-redux'
// import TabComponent from '../../components/tabComponent'
import PlusIcon from '../../images/plus.svg'
import MoveIcon from '../../images/minus.svg'
import styled, { css } from 'styled-components'
import request from '../../utils/request'
import swal from 'sweetalert';
const MainContainer = styled.div`

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

// height:100vh;
// overflow:auto;
const CardContainer = styled.div`
 
  ${(props) => {
    if (props.ChangeColor) {
      return css`
     background: ${(props) => props.theme.rightCard2};
     `
    } else {
      return css`
     background: ${(props) => props.theme.rightCard};
     `
    }
  }}
  
  border: 1px solid ${(props) => props.theme.active};
  width: 100%;
  height: 44px;
  padding: 10px 20px;

  display: flex;
  justify-content: space-between;
  img {
    cursor: pointer;
  }
`

const Container = styled.div`
  display: flex;
  .hand {
    width: max-content;
    height: 100%;
    cursor: pointer;
  }
`

class BroadcastLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkTypePath: 'Announcement',
      checkActiveBtn: 'Announcement',
      checkClickTemp: '',
      checkClickTempIndex: ''
    }
    this.onDragEnd = this.onDragEnd.bind(this)
    this.handleToggleShow = this.handleToggleShow.bind(this)
  }
  componentDidUpdate(prevProps) {
    if ((prevProps.checkStatustemp.status !== this.props.checkStatustemp.status && this.props.checkStatustemp.status !== '') || (prevProps.checkStatustemp.index !== this.props.checkStatustemp.index && this.props.checkStatustemp.index !== '')) {
      this.setState({
        checkClickTemp: this.props.checkStatustemp.status,
        checkClickTempIndex: this.props.checkStatustemp.index
      })
    }
    if (prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname === '/admin/broadcasting/list/broadcastSent') {
      this.setState({
        checkClickTemp: '',
        checkClickTempIndex: ''
      })
    }

  }
  onChangeTab = (path) => {
    const { history, match } = this.props
    history.push(match.url + path)
  }
  handleToggleShow(value) {
    let newColumn = this.state.column
    const result = this.state.column.map((obj, index) => {
      if (obj.accessor === value) {
        const editObj = { ...obj, isShow: !obj.isShow }
        newColumn[index] = editObj
        return newColumn
      }
      return newColumn
    })
    this.setState({ column: result[0] })
    this.props.setCustomerColumnList(newColumn)
  }

  onDragEnd = () => {
    this.draggedIdx = null
  }

  onChangeTabTemplate = (path) => {
    const { history, match } = this.props
    history.replace({
      pathname: match.url + path,
      state: this.props.broadcastMode
    })
  }

  ChangeAnnounce = (el, i) => {
    this.props.checkStatusTemp({ id: el.id, status: el.name, index: i })
    this.setState({
      checkClickTemp: el.name,
      checkClickTempIndex: i
    })
    const dataObj = {
      id: el.id,
      name: el.name,
      modeSetup: 'Edit',
      temp: 'template',
    }
    // const { history } = this.props
    // history.push(`/admin/broadcasting/announceTemplate`,dataObj)
    this.props.history.replace({
      pathname: `/admin/broadcasting/announceTemplate`,
      state: dataObj
    })

  }

  DeleteAnnounce(el, i) {
    swal({
      className: 'swal-modal',
      title: "Are you sure?",
      text: `You want delete Template`,
      icon: "warning",
      buttons: {
        confirm: `Delete`,
        cancel: `Cancel`,
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(el)

        swal("Deleted Success", {
          icon: "success",
        });
        try {
          request.post(`broadcast/announcement/template/id/${el.id}/delete`).then(res => {
            this.props.getAnoucement()
          })

        } catch (error) {
          console.log(error)
        }
      }
    })
  }

  ChangeTempInApp = (el, i) => {
    this.props.checkStatusTemp({ id: el.id, status: el.name, index: i })
    this.setState({
      checkClickTemp: el.name,
      checkClickTempIndex: i
    })
    const dataObj = {
      id: el.id,
      name: el.name,
      temp: 'template',
      modeSetup: 'Edit',
      BroadcastMode: 'InAppMessage'
    }
    const { history } = this.props
    history.replace({
      pathname: `/admin/broadcasting/broadcastTemplate`,
      state: dataObj
    })
  }

  DeleteInapp(el, i) {
    swal({
      className: 'swal-modal',
      title: "Are you sure?",
      text: `You want delete Template`,
      icon: "warning",
      buttons: {
        confirm: `Delete`,
        cancel: `Cancel`,
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(el)

        swal("Deleted Success", {
          icon: "success",
        });
        try {
          request.post(`/broadcast/inappmessage/template/id/${el.id}/delete`).then(res => {
            this.props.InAppList()
          })

        } catch (error) {
          console.log(error)
        }
      }
    })
  }


  // *** This Layout contain only rightbar ***
  render() {


    return (
      <div>
        <div className="page-breadcrumb" >
          {this.props.children}
        </div>
        <div id="RightBar-Broadcast">
          <MainContainer >
            <ul >
              <h3
                style={{
                  fontSize: '18px',
                  lineHeight: '1.22px',
                  color: '#f0f3f4',
                  padding: '12px',
                }}>
                {this.props.broadcastMode === 'Announcement' && 'Announcement Template'}
                {this.props.broadcastMode === 'InAppMessage' && 'In App Message Template'}
                {this.props.broadcastMode === 'SMS' && 'SMS Template'}
                {this.props.broadcastMode === 'Email' && 'Email Template'}
              </h3>
              <CardContainer>
                <Container>
                  {this.props.broadcastMode === 'Announcement' ? (
                    <label
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.onChangeTabTemplate('/announceTemplate')} >
                      Add new template
                    </label>
                  ) : (
                      <label
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onChangeTabTemplate('/broadcastTemplate')}>
                        Add new template
                      </label>
                    )}
                </Container>
                <Container>
                  <img alt="minus-icon" src={PlusIcon} />
                </Container>
              </CardContainer>
              {/* //////////// Template /////////////////*/}

              {this.props.broadcastMode === 'Announcement' ?
                <div style={{ overflow: 'overlay', height: '85vh' }}>
                  {this.props.dataAnnouncementList.map((el, i) => (
                    <li key={el.id}>
                      {
                        this.state.checkClickTemp === el.name && this.state.checkClickTempIndex === i ?
                          <CardContainer ChangeColor={true}>
                            <Container >
                              <div style={{ cursor: 'pointer' }} onClick={() => this.ChangeAnnounce(el, i)}>{el.name}</div>
                              {/* <img alt="minus-icon" src={MoveIcon} /> */}
                            </Container>
                            <Container>
                              <img alt="minus-icon" src={MoveIcon} onClick={() => this.DeleteAnnounce(el, i)} />
                            </Container>
                          </CardContainer>
                          :
                          <CardContainer>
                            <Container >
                              <div style={{ cursor: 'pointer' }} onClick={() => this.ChangeAnnounce(el, i)}>{el.name}</div>
                              {/* <img alt="minus-icon" src={MoveIcon} /> */}
                            </Container>
                            <Container>
                              <img alt="minus-icon" src={MoveIcon} onClick={() => this.DeleteAnnounce(el, i)} />
                            </Container>
                          </CardContainer>
                      }
                    </li>
                  ))}
                </div>
                :
                this.props.broadcastMode === 'InAppMessage' ?
                  <div style={{ overflow: 'overlay', height: '85vh' }}>
                    {
                      this.props.InAppTemplateList.map((el, i) => (
                        <li key={el.id}>
                          {
                            this.state.checkClickTemp === el.name && this.state.checkClickTempIndex === i ?
                              <CardContainer ChangeColor={true}>
                                <Container >
                                  <div style={{ cursor: 'pointer' }} onClick={() => this.ChangeTempInApp(el, i)}>{el.name}</div>
                                </Container>
                                <Container>
                                  <img alt="minus-icon" src={MoveIcon} onClick={() => this.DeleteInapp(el, i)} />
                                </Container>
                              </CardContainer>
                              :
                              <CardContainer>
                                <Container >
                                  <div style={{ cursor: 'pointer' }} onClick={() => this.ChangeTempInApp(el, i)}>{el.name}</div>
                                </Container>
                                <Container>
                                  <img alt="minus-icon" src={MoveIcon} onClick={() => this.DeleteInapp(el, i)} />
                                </Container>
                              </CardContainer>
                          }
                        </li>
                      ))
                    }
                  </div>
                  :
                  null
              }
            </ul>
          </MainContainer>
        </div>
      </div>
    )
  }
}
const mapState = (state) => ({
  showBtnAdd: state.promotion.showBtnAdd,
  broadcastMode: state.broadcasting.broadcastMode,
  pageTitle: state.Title.pageTitle,
  dataAnnouncementList: state.broadcasting.dataAnnouncementList,
  InAppTemplateList: state.broadcasting.InAppTemplateList,
  checkStatustemp: state.broadcasting.checkStatustemp
})
const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    setCustomerColumnList: dispatch.customer.setCustomerColumnList,
    checkStatusTemp: dispatch.broadcasting.checkStatusTemp,
    getAnoucement: dispatch.broadcasting.getAnoucement,
    InAppList: dispatch.broadcasting.InAppList


  }
}

export default connect(
  mapState,
  mapDispatch
)(BroadcastLayout)
