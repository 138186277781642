import request from '../../utils/request'

export const broadcasting = {
  state: {
    dataAnnouncementList: [],
    InAppTemplateList: [],
    dataAnnouncementsentList: 0,
    dataInAppMessagesentList: 0,
    dataInAppMessageFailedList: 0,
    dataPromotionAC: [],
    dataPromotionEx: [],
    columnBroadcast: [
      { label: '', accessor: 'edit', isShow: true },
      { label: 'Name', accessor: 'name', isShow: true },
      { label: 'Id', accessor: 'id', isShow: true },
      { label: 'Type', accessor: 'type', isShow: true },
      { label: 'Start Date', accessor: 'start_datetime', isShow: true },
      { label: 'End Date', accessor: 'end_datetime', isShow: true },
    ],
    showBtnAdd: true,
    broadcastMode: 'Announcement',
    checkTypebroadcas: '',
    ModeView: '',
    typeImgModal: '',
    textPupupModal: '',
    checkModeForTemp: '',
    checkStatustemp: {},
  },
  reducers: {
    checkStatusTemp(state, payload) {
      return {
        ...state,
        checkStatustemp: payload
      }
    },
    checkStateForTemp(state, payload) {
      return {
        ...state,
        checkModeForTemp: payload
      }
    },
    checkModeModal(state, payload) {
      console.log(payload)
      return {
        ...state,
        ModeView: payload
      }
    },
    checkTypeImage(state, payload) {
      return {
        ...state,
        typeImgModal: payload
      }
    },
    setTextModal(state, payload) {
      return {
        ...state,
        textPupupModal: payload
      }
    },
    checkRigthBarbroadcas(state, payload) {
      return {
        ...state,
        checkTypebroadcas: payload
      }
    },
    getAnnouncement(state, payload) {
      return {
        ...state,
        dataAnnouncementList: payload
      }
    },
    InAppTemplateList(state, payload) {
      return {
        ...state,
        InAppTemplateList: payload
      }
    },
    getAnnouncementsentList(state, payload) {
      return {
        ...state,
        dataAnnouncementsentList: payload
      }
    },
    getInAppMessageSentList(state, payload) {
      return {
        ...state,
        dataInAppMessagesentList: payload
      }
    },
    getInAppMessageFailedList(state, payload) {
      return {
        ...state,
        dataInAppMessageFailedList: payload
      }
    },
    setCheckTypePath(state, payload) {
      return {
        ...state,
        showBtnAdd: payload
      }
    },
    setBroadcastMode(state, payload) {
      return {
        ...state,
        broadcastMode: payload
      }
    },
  },
  effects: (dispatch) => ({
    async getAnoucement() {
      const res = await request.get('/broadcast/announcement/template/list')
      const cleanData = res.data.map((el) => {
        return {
          id: el.id,
          name: el.name
        }
      })
      dispatch.broadcasting.getAnnouncement(cleanData)
    },
    async InAppList() {
      const res = await request.get('/broadcast/inappmessage/template/list')
      const cleanData = res.data.map((el) => {
        return {
          id: el.id,
          name: el.name
        }
      })
      dispatch.broadcasting.InAppTemplateList(cleanData)
    },
    async getAnnouncementSent() {
      const res = await request.get('/broadcast/announcement/sent/list')
      const cleanData = res.data.item_count
      dispatch.broadcasting.getAnnouncementsentList(cleanData)
    },
    async getInAppMessageSent() {
      const res = await request.get('/broadcast/inappmessage/sent/list')
      const cleanData = res.data.item_count
      dispatch.broadcasting.getInAppMessageSentList(cleanData)
    },
    async getInAppMessageFailed() {
      const res = await request.get('/broadcast/inappmessage/failed/list')
      const cleanData = res.data.item_count
      dispatch.broadcasting.getInAppMessageFailedList(cleanData)
    },
  }),
}