import React, { Component } from "react"

// import { SideBarMenu } from './sidebarMenu'
import { connect } from "react-redux"
import ErrorBoundary from "../errorBoundary"
// import { Content } from './style'
import TopBar from "./topbar"
import SideBar from "./sidebar"
// import RightBar from './rightbar'

//<Header title={this.props.pageTitle} />
class Layout extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.sbChannelList !== this.props.sbChannelList &&
      !this.props.location.pathname.includes("chatbox")
    ) {
      this.props.sbGetMessageList(this.props.sbChannelList[0].url)
    }
  }

  async componentDidMount() {
    await this.props.sbConnect()
    await this.props.sbGetGroupChannelList()
  }

  render() {
    // console.log(this.props.children)
    return (
      <ErrorBoundary>
        {/* ===== Header ===== */}
        {this.props.location.pathname !== "/admin/login" && <TopBar title={this.props.pageTitle} />}

        {/* ===== Side Bar ===== */}
        {this.props.location.pathname !== "/admin/login" && <SideBar />}

        {/* ===== Content ===== */}
        {/* <div class="page-wrapper" style={{display: 'block'}}> */}
        {this.props.location.pathname === "/admin/login" ? (
          <div
            style={{
              backgroundColor: "transparent",
              paddingTop: "0 !important",
              marginLeft: "0 !important",
            }}>
            {this.props.children}
          </div>
        ) : (
          <div className="page-wrapper" style={{ display: "block", overflow: "auto" }}>
            {this.props.children}
          </div>
        )}

        {/* <RightBar/> */}
      </ErrorBoundary>
    )
  }
}

const mapState = (state) => {
  return {
    pageTitle: state.Title.pageTitle,
    sbChannelList: state.sbChannel.sbChannelList,
  }
}

const mapDispatch = (dispatch) => {
  return {
    sbConnect: dispatch.sbUser.sbConnect,
    sbGetGroupChannelList: dispatch.sbChannel.sbGetGroupChannelList,
    sbGetMessageList: dispatch.sbMessage.sbGetMessageList,
  }
}

export default connect(mapState, mapDispatch)(Layout)
