export default {
  grey: '#c2c2c2',
  rightCard: '#F0F3F4',
  rightCard2: '#b6edff',
  active: '#324755',
  primary: '#324755',
  secondary: '#6E8CA0',
  text: '#324755',
  textRed: '#CE4751',
  transparent: 'rgba(255, 255, 255, 0)',
  red: '#ff5151',
  // primary: '#FF6B76',
  // primary: '#d58861',
  // secondary: '#ffefed',
  // secondary: '#f3f0fc',
  // primary: '#2e3192',
  // primaryButton: '#f39700',
  
}
