import React, { Component } from "react"
// import styled from 'styled-components'
import { NavLink, withRouter } from "react-router-dom"
import { connect } from "react-redux"

import "../../style/style.custom.css"
import { SideBarMenu as menus } from "./sidebarMenu"
import ProfilePane from "./profile"
import inconUnread from "../../images/iconUnread.png"
import styled from "styled-components"

const SidbarScroll = styled.div`
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 0px;
  }
`

class Sidebar extends Component {
  state = {
    checkUnreadMsg: false,
    href: null,
  }

  onClick(e) {
    e.preventDefault()
    console.log("onclick..")
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sbChannelList !== this.props.sbChannelList) {
      let temp = this.props.sbChannelList.filter((v) => v.unreadMessageCount > 0)
      this.props.checkNewMsg(JSON.parse(JSON.stringify(temp)).length > 0)
    }

    // console.log(prevProps)
    // console.log(this.props)
  }

  componentDidMount() {
    // Try to maintain expand menus on sidebar when perform refresh
    // By check current location path against "menus.link"
    // Because the template is from Bootstarp + jQuery so this will adjust by side
    // console.log(`this.props : ${JSON.stringify(this.props)}`)
    // console.log(`tune : ${JSON.stringify(this.props.history.location.pathname)}`)
    const currentLocation = this.props.history.location.pathname
    menus.map((menu) =>
      menu.link
        ? currentLocation.includes(menu.link) && this.handleToggleActive(menu.label)
        : menu.subMenu.map(
            (subMenu) =>
              currentLocation.includes(subMenu.link) && this.handleToggleActive(menu.label)
          )
    )
  }

  handleToggleActive(name) {
    // Add class to expand sidebar menu
    const targetName = document.getElementsByName(name)
    for (let node of targetName) {
      // console.log(node.tagName)
      node.tagName === "A" && node.classList.add("active")
      node.tagName === "UL" && node.classList.add("in")
    }
    // console.log(name)
    // console.log(targetName)
  }
  hrefURL() {
    const url = window.location.hostname
    const access_token = localStorage.getItem("accessToken")
    const refresh_token = localStorage.getItem("refreshToken")
    switch (url) {
      case "admin.bluuu.co":
        return `https://prod-admin-bluuu-vue.web.app/login?access_token=${access_token}&refresh_token=${refresh_token}`
        break
      case "uat-admin.bluuu.co":
        return `https://uat-admin-bluuu-vue.web.app/login?access_token=${access_token}&refresh_token=${refresh_token}`
        break
      default:
        return `http://localhost:8080/login?access_token=${access_token}&refresh_token=${refresh_token}`
        break
    }
  }

  render() {
    // **** <a href={this.props.match} === {undefined}
    // **** linked to nowhere ... this will fix bug href="#/"
    return (
      <SidbarScroll className="left-sidebar">
        <ProfilePane />
        <div className="scroll-sidebar">
          <nav className="sidebar-nav">
            <ul id="sidebarnav">
              <ul>
                {menus.map((menu) => {
                  if (menu.url) {
                    menu.url_link = this.hrefURL()
                  }
                  // console.log(menu)
                  return (
                    <li className="sidebar-item" key={menu.label}>
                      {menu.link && !menu.url && !menu.subMenu ? (
                        <NavLink className="sidebar-link has-arrow" to={`/admin${menu.link}`}>
                          <i className={menu.icon}></i>
                          <span className="hide-menu">{menu.label.toUpperCase()}</span>
                          {/* { console.log(this.props.channel.unreadMessageCount)} */}
                          {menu.key === "5" && this.props.checkNewMessage ? (
                            // <span style={{ color: 'aqua' }} >  [New]</span>

                            <img
                              style={{ width: "80px", paddingLeft: "20px" }}
                              src={inconUnread}
                              alt="inconUnread"
                            />
                          ) : // inconUnread
                          null}
                        </NavLink>
                      ) : (
                        <a
                          name={menu.label}
                          className="sidebar-link has-arrow waves-effect waves-dark"
                          href={menu.url_link}
                          target="blank"
                          aria-expanded="false">
                          <i className={menu.icon}></i>
                          <span className="hide-menu">{menu.label.toUpperCase()} </span>
                        </a>
                      )}

                      {menu.subMenu ? (
                        <ul
                          name={menu.label}
                          aria-expanded="false"
                          className="collapse first-level">
                          {menu.subMenu.map((subMenu) => {
                            return (
                              <li className="sidebar-item" key={`${subMenu.key}`}>
                                <NavLink className="sidebar-link" to={`/admin${subMenu.link}`}>
                                  <i className="mdi mdi-layers" />
                                  {subMenu.label}
                                </NavLink>
                              </li>
                            )
                          })}
                        </ul>
                      ) : null}
                    </li>
                  )
                })}
              </ul>
            </ul>
          </nav>
        </div>
      </SidbarScroll>
    )
  }
}

const mapState = (state) => {
  return {
    checkNewMessage: state.sbMessage.checkNewMessage,
    sbChannelList: state.sbChannel.sbChannelList,
  }
}
const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    checkNewMsg: dispatch.sbMessage.checkNewMsg,
  }
}
export default connect(mapState, mapDispatch)(withRouter(Sidebar))

// { menu.subMenu && console.log(menu.subMenu.length)}
