import React, { Component } from "react";
import styled from "styled-components";
import ErrorBoundary from "../errorBoundary";
import request from "../../utils/request";
import { connect } from "react-redux";

const Container = styled.div`
  width: 150px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;

  .link {
    cursor: pointer;
  }
`;

const Item = styled.div`
  padding: 10px 0 10px 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  img {
    width: 20px;
    margin-right: 14px;
  }

  :hover {
    background-color: #f3f3f3;
  }
`;
// const RoleMaidClass = [
//   'Success',
//   'In progress',
//   'Refunded',
//   'Cancled'
// ]

class EditOrderBooking extends Component {
  async handleClick(text) {
    await request
      .post(`booking/id/${this.props.BookingId}/refund`)
      .then((res) => {
        if (!res.error) {
          this.props.satatusBooking("UpdateStatus");
        }
      });
  }

  render() {
    // const { data, id, handleOpenModal } = this.props
    const { CheckStatus } = this.props;
    // console.log(`data :${JSON.stringify(this.props)}`)
    // {/* <Item onClick={() => handleOpenModal(id)}> */}
    // const FilterMaidClassMenu = RoleMaidClass.filter((Allrole) => Allrole !== TypeStatus && Allrole === CheckStatus)
    return (
      <ErrorBoundary>
        <Container>
          {(CheckStatus === "Cancelled 50%" ||
            CheckStatus === "Cancelled 5%") && (
            <Item onClick={() => this.handleClick("Refunded")}>Refunded</Item>
          )}
        </Container>
      </ErrorBoundary>
    );
  }
}

const mapState = () => ({});

const mapDispatch = (dispatch) => {
  return {
    satatusBooking: dispatch.orderManagement.satatusBooking,
  };
};

export default connect(mapState, mapDispatch)(EditOrderBooking);
