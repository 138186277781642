import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import Button from "../../components/button"
import ReactTable from "react-table"
import "./orderBookingProfile.css"
import request from "../../utils/request"
import moment from "moment"
import Rightbar from "../../layouts/orderManagementLayout/rightbar"
import Pagination from "../../components/paginationTable/pagination"

const Overlay = styled.div`
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
`

const Container = styled.div`
  padding: 20px;
`
const HeaderTable = styled.div`
  text-align: left;
`
const CardTable = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.active};
  background: #fff;
  height: max-content;
  width: 100%;
  position: relative;
  top: -1px;
  padding: 10px;
  align-items: flex-start;
  overflow: auto;
`

const Card = styled.div`
  float: left;
  width: 33.333%;
  padding: 0.75rem;
  margin-bottom: 2rem;
  border: 0;
`

class ordeBookingPorfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      column: this.props.columnBookingProfile,
      showRightbar: false,
      dataProfile: [],
      maidName: "",
      quotaMaid: "",
      Tempweek: "",
      loading: false,
      PageSize: 21,
      numberPage: 1,
      totalPages: 0,
      totaldata: 0,
      chanel_url: "",
    }
  }

  componentDidMount() {
    this.props.setPageTitle(`Back`)
    this.props.showBackIcon(true)
    this.getDataProfile()
    this.getDataDetailTable()
  }

  async getDataProfile() {
    if (this.props.match.params.id !== undefined) {
      const id = this.props.match.params.id
      try {
        request.get(`/customer/id/${id}`).then((resp) => {
          if (resp.data) {
            this.setState({
              dataProfile: {
                picture: resp.data.image_url,
                name: resp.data.first_name + " " + resp.data.last_name,
                role: resp.data.role,
                email: resp.data.email,
                phone: resp.data.phone,
                userId: resp.data.customer_id,
                defaultlocation1: resp.data.user_locations
                  ? resp.data.user_locations.length > 0
                    ? resp.data.user_locations[0].secondary_text
                    : "-"
                  : "-",
                defaultlocation2: resp.data.user_locations
                  ? resp.data.user_locations.length > 1
                    ? resp.data.user_locations[1].secondary_text
                    : "-"
                  : "-",
                defaultlocation3: resp.data.user_locations
                  ? resp.data.user_locations.length > 2
                    ? resp.data.user_locations[2].secondary_text
                    : "-"
                  : "-",
              },
              chanel_url: resp.data.admin_channel_url,
              quotaMaid: resp.data.change_maid_quota,
            })
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  async getDataDetailTable() {
    this.setState({ loading: true })
    if (this.props.match.params.bokingId !== undefined) {
      const id = this.props.match.params.bokingId
      try {
        request.get(`/booking/id/${id}&limit=5000`).then((resp) => {
          if (resp.data.length > 0) {
            let tempDate = []
            let arr = resp.data
            for (let i = 0; i < arr.length; i++) {
              tempDate.push({
                Maid_change: arr[i].maid_change ? arr[i].maid_change : "-",
                App_ID: arr[i].maid ? arr[i].maid.id : "-",
                Maid_Name: arr[i].maid
                  ? arr[i].maid.user.first_name + "-" + arr[i].maid.user.last_name
                  : "-",
                Maid_Phone: arr[i].maid
                  ? arr[i].maid.user.phone
                    ? arr[i].maid.user.phone
                    : "-"
                  : "-",
                Credit: arr[i].number ? arr[i].number : "-",
                Book_Date: arr[i].start_datetime
                  ? moment(arr[i].start_datetime).format("YYYY/MM/DD")
                  : "-",
                Start_Time: arr[i].start_datetime
                  ? moment(arr[i].start_datetime).utcOffset("+0700").format("HH:mm")
                  : "-",
                Credit_Status: arr[i].status ? arr[i].status : "-",
                Purchase_Date: arr[i].payment
                  ? moment(arr[i].payment.create_datetime).format("YYYY/MM/DD HH:mm")
                  : "-",
                Expiration_Date: arr[i].expired_datetime
                  ? moment(arr[i].expired_datetime).format("YYYY/MM/DD HH:mm")
                  : "-",
              })
            }
            const totalpage = Math.ceil((arr.length + 1) / 5000)
            this.setState({ loading: false, data: tempDate, totalPages: totalpage })
          } else {
            this.setState({ loading: false, data: [], totalPages: 0 })
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  handleColumn = () => {
    this.setState({ showRightbar: true, mode: "column" })
  }
  handleFilter = () => {
    this.setState({ showRightbar: true, mode: "filter" })
  }
  handleToggle = (key) => {
    this.setState((prevState) => ({ [key]: !prevState[key] }))
    const { history } = this.props
    history.push(this.props.history.location.pathname)
  }
  sendForChat = () => {
    this.props.history.push("/admin/chatbox")
    this.props.history.push(
      `/admin/chatbox/${this.props.match.params.id}/CUSTOMER/${this.state.chanel_url}`
    )
    this.props.showBackIcon(false)
    this.props.setPageTitle("Chat Box")
  }

  compareBy(key, order) {
    return function (a, b) {
      if (order) {
        if (a[key] < b[key]) {
          return 1
        }
        if (a[key] > b[key]) {
          return -1
        }
        return 0
      } else {
        if (a[key] > b[key]) {
          return 1
        }
        if (a[key] < b[key]) {
          return -1
        }
        return 0
      }
    }
  }

  togleSorting(newSorted, column, shiftKey) {
    let arrayCopy = [...this.state.data]
    arrayCopy.sort(this.compareBy(column.id, newSorted[0].desc))
    this.setState({ data: arrayCopy })
  }

  render() {
    const allColumns = [
      {
        Header: () => <HeaderTable>Maid Change</HeaderTable>,
        accessor: "Maid_change", // String-based value accessors!
        minWidth: 100,
        Cell: (row) => <div style={{ paddingLeft: "5px" }}>{row.original.Maid_change}</div>,
      },
      {
        Header: () => <HeaderTable>Maid ID</HeaderTable>,
        accessor: "App_ID",
        minWidth: 80,
        Cell: (row) => <div style={{ textAlign: "left" }}>{row.original.App_ID}</div>,
      },
      {
        Header: () => <HeaderTable>Maid Name</HeaderTable>,
        accessor: "Maid_Name",
        minWidth: 100,
      },
      {
        Header: () => <HeaderTable>Maid Phone</HeaderTable>,
        accessor: "Maid_Phone",
        minWidth: 100,
        Cell: (row) => <div style={{ textAlign: "left" }}>{row.original.Maid_Phone}</div>,
      },
      {
        Header: () => <HeaderTable>Credit</HeaderTable>,
        accessor: "Credit",
        minWidth: 80,
        Cell: (row) => <div style={{ textAlign: "left" }}>{row.original.Credit}</div>,
      },
      {
        Header: () => <HeaderTable>Book Date</HeaderTable>,
        accessor: "Book_Date",
        minWidth: 100,
        Cell: (row) => <div style={{ textAlign: "left" }}>{row.original.Book_Date}</div>,
      },
      {
        Header: () => <HeaderTable>Start Time</HeaderTable>,
        accessor: "Start_Time",
        minWidth: 100,
        Cell: (row) => <div style={{ textAlign: "left" }}>{row.original.Start_Time}</div>,
      },
      {
        Header: () => <HeaderTable>Status</HeaderTable>,
        accessor: "Credit_Status",
        minWidth: 80,
        Cell: (row) => <div style={{ textAlign: "left" }}>{row.original.Credit_Status}</div>,
      },
      {
        Header: () => <HeaderTable>Purchase Date</HeaderTable>,
        accessor: "Purchase_Date",
        minWidth: 100,
        Cell: (row) => <div style={{ textAlign: "left" }}>{row.original.Purchase_Date}</div>,
      },
      {
        Header: () => <HeaderTable>Expiration Date</HeaderTable>,
        accessor: "Expiration_Date",
        minWidth: 120,
        Cell: (row) => <div style={{ textAlign: "left" }}>{row.original.Expiration_Date}</div>,
      },
    ]

    let columnWillShow = []
    this.state.column.map((colName) => {
      for (var obj of allColumns) {
        colName.isShow && obj.accessor === colName.accessor && columnWillShow.push(obj)
      }
      return {}
    })

    const { dataProfile } = this.state
    const currentLocation = this.props.history.location.pathname
    return (
      <Container>
        <div className="container-fluid">
          {this.state.showRightbar && <Overlay onClick={() => this.handleToggle("showRightbar")} />}
          {/* Row */}
          <div>
            <div className="row ">
              {/* Column */}
              <Card
                className="col-md-3"
                style={{ width: "25.333%", padding: "0px", marginBottom: "1px" }}>
                <div className="card col-md-12 cradProfileBooking">
                  <div className="card-body">
                    <center className="m-t-30">
                      <img
                        src={`${dataProfile.picture}`}
                        alt="profile"
                        className="rounded-circle"
                        width={100}
                      />
                      <h4 className="card-title m-t-10">{dataProfile.picnameture}</h4>
                      <h6>{dataProfile.name}</h6>
                      {dataProfile.userId ? (
                        <Button
                          className="btn"
                          style={{
                            borderRadius: "11px",
                            width: "75px",
                            height: "30px",
                            fontSize: "12px",
                          }}
                          label="Chat"
                          onClick={() => this.sendForChat()}
                        />
                      ) : null}
                    </center>
                    <div className="row">
                      <label>
                        <strong className="text-muted">User Id : </strong> {dataProfile.userId}
                      </label>
                    </div>
                    <div className="row">
                      <label>
                        <strong className="text-muted">Role : </strong> {dataProfile.role}
                      </label>
                    </div>
                    <div className="row">
                      <label>
                        <strong className="text-muted">FB Email : </strong> {dataProfile.email}
                      </label>
                    </div>
                    <div className="row">
                      <label>
                        <strong className="text-muted">Phone : </strong> {dataProfile.phone}
                      </label>
                    </div>
                    <div className="row">
                      <label>
                        {" "}
                        <strong className="row text-muted p-t-30 db">
                          Default location 1 :
                        </strong>{" "}
                        {dataProfile.defaultlocation1}
                      </label>
                    </div>
                    <div className="row">
                      <label>
                        {" "}
                        <strong className="row text-muted p-t-30 db">
                          Default location 2 :
                        </strong>{" "}
                        {dataProfile.defaultlocation2}
                      </label>
                    </div>
                    <div className="row">
                      <label>
                        {" "}
                        <strong className="row text-muted p-t-30 db">
                          Default location 3 :
                        </strong>{" "}
                        {dataProfile.defaultlocation3}
                      </label>
                    </div>
                  </div>
                </div>
              </Card>
              <div className="col-md-9">
                <div className="btn-container">
                  <Button
                    className="btn"
                    style={{ borderRadius: "11px" }}
                    label="Column setting"
                    onClick={() => this.handleColumn()}
                  />
                  {/* <Button className="btn" style={{ borderRadius: "11px" }} label="Filter by" onClick={() => this.handleFilter()} /> */}
                </div>
                <CardTable>
                  <ReactTable
                    data={this.state.data} //data object
                    columns={columnWillShow} //column config object
                    loading={this.state.loading}
                    pageSize={25}
                    page={this.state.page}
                    showPagination={true}
                    PaginationComponent={Pagination}
                    manual
                    pages={this.state.totalPages}
                    onSortedChange={(newSorted, column, shiftKey) =>
                      this.togleSorting(newSorted, column, shiftKey)
                    }
                    onPageChange={(page) => this.setState({ page: page })}
                    getTrProps={(state, rowInfo) => {
                      return {
                        style: {
                          height: 25,
                          overflow: "hidden",
                        },
                      }
                    }}
                    className="-striped -highlight bookingprofiletable"
                  />
                </CardTable>
              </div>
            </div>
          </div>
          <Rightbar data={this.state} currentLocation={currentLocation} />
          {this.props.children}
        </div>
      </Container>
    )
  }
}

const mapState = (state) => ({
  columnBookingProfile: state.orderManagement.columnBookingProfile,
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    showBackIcon: dispatch.Title.showBackIcon,
    setStartdateOrder: dispatch.orderManagement.setStartdateOrder,
    setEndateOrder: dispatch.orderManagement.setEndateOrder,
  }
}

export default connect(mapState, mapDispatch)(ordeBookingPorfile)
