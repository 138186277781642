import React, { useRef } from "react"
import moment from "moment"
import { USER_ID } from "../../store/models/sbConstants"

import "./chat.css"

const ChatLeft = (props) => {
  const proImg = useRef()
  const scroll = useRef()

  // if (props.channelList.length > 0 && !props.channel) {
  //     props.onClickGroupChannel(props.channelList[0])
  // }

  const onClickGroupChannel = async (channel) => {
    props.onClickGroupChannel(channel)
  }

  // const findUserActive = (members) => {
  //     const user = members.find(u => u.userId !== USER_ID)
  //     const status = !user ? 'ofline' : user.connectionStatus

  //     return `profile-status ${status} pull-right`
  // }

  // const onFilterContact = (e) => {
  //     if (e.key === 'Enter') {
  //         props.filterChannel(filter)
  //         e.preventDefault()
  //     }
  // }

  // const onScrollChannel = (e) => {
  //     const { scrollHeight, scrollTop, clientHeight } = scroll.current
  //     const isBottom = scrollHeight - scrollTop === clientHeight

  //     if (isBottom) {
  //         const limit = Math.round(props.channelList.length / 30) + 1
  //         props.loadChannelList(limit)
  //     }
  // }

  const redNewMsg = (channel) => {
    if (props.channel && props.channel.url !== channel.url && channel.unreadMessageCount > 0) {
      return `(${channel.unreadMessageCount})`
    } else if (
      props.channel &&
      props.channel.url === channel.url &&
      channel.unreadMessageCount > 0
    ) {
      props.onInputFocus()
    } else {
      return channel.unreadMessageCount > 0 ? `(${channel.unreadMessageCount})` : null
    }
  }

  const checkActive = (channel) => {
    if (props.channel && props.channel.url === channel.url) {
      return "active"
    }

    return ""
  }

  const findMember = (channel) => {
    return channel.members.find((u) => u.userId !== USER_ID)
  }

  const renderChannelList = (channelList) => {
    return channelList.map((channel, index) => {
      return (
        <div
          className={`message-item ${checkActive(channel)}`}
          id="chat_user_1"
          style={{ cursor: "pointer" }}
          key={index}
          onClick={(e) => onClickGroupChannel(channel)}>
          <span className="user-img">
            <img
              className="rounded-circle"
              style={{ width: 45, height: 45 }}
              alt="user"
              ref={proImg}
              src={
                !findMember(channel)
                  ? require("../../images/preview-upload.png")
                  : findMember(channel).profile_url
              }
            />
            {/* <span className={findUserActive(channel.members)} /> */}
          </span>
          <div className="mail-contnet">
            <h5 className="message-title">
              {!findMember(channel) ? channel.name : findMember(channel).nickname}
              {/* {props.channel && props.channel.url !== channel.url && channel.unreadMessageCount > 0 ? ` (${redNewMsg(channel)}) ` : null} */}
              {redNewMsg(channel)}
            </h5>
            {!channel.lastMessage ? null : (
              //  the my admin!
              <div>
                <span className="mail-desc">
                  {channel.lastMessage.messageType === "file"
                    ? "Send file."
                    : channel.lastMessage.message}
                </span>
                <span className="time">
                  {moment.unix(channel.lastMessage.createdAt / 1000).format("DD/MM/YYYY hh:mm a")}
                </span>
              </div>
            )}
          </div>
        </div>
      )
    })
  }

  return (
    <div>
      <div className="left-part bg-light fixed-left-part border-top">
        {/* Mobile toggle button */}
        {/* Wit :: Change template from a -> div for fix the warning  */}
        <div
          className="ti-menu ti-close btn btn-success show-left-part d-block d-md-none"
          href="/#"
        />
        {/* Mobile toggle button */}
        <div className="p-15">
          <h4>Chat Sidebar</h4>
        </div>
        <div className="scrollable position-relative" style={{ height: "100%" }}>
          <div className="p-15">
            <h5 className="card-title">Search Contact</h5>
            <form onSubmit={(e) => e.stopPropagation()}>
              <select
                className="form-control mb-3"
                value={props.role}
                onChange={(e) => props.onFilterRole(e.target.value)}>
                <option value="CUSTOMER">Customer</option>
                <option value="MAID">Maid</option>
              </select>
              <input
                className="form-control"
                type="text"
                placeholder="Search contact"
                onChange={(e) => props.filterChannel(e.target.value)}
              />
            </form>
          </div>
          <hr />
          <ul className="mailbox list-style-none">
            <li>
              <div className="message-center chat-scroll" ref={scroll}>
                {props.loading ? (
                  <div className="message-center" style={{ textAlign: "center" }}>
                    <p>loading...</p>
                  </div>
                ) : props.channelList.length > 0 ? (
                  renderChannelList(props.channelList)
                ) : null}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ChatLeft
