import React, { Component } from "react"
import "./style/style.custom.css"

import Routes from "./routes"

import Cookies from "universal-cookie"
const cookies = new Cookies()
cookies.set("root", "face", { path: "/", sameSite: false, secure: true })

class App extends Component {
  render() {
    const checkTimeOut = function () {
      const timeOut = localStorage.getItem("timeOut")
      const callback = window.location.href
      if (!timeOut || new Date().getTime() > parseInt(timeOut)) {
        window.location.replace("/admin/customer-management#/admin/login" + "?callback=" + callback)
      } else {
        window.setTimeout(checkTimeOut, 1000 * 60 * 5)
      }
    }
    checkTimeOut();
    return <Routes />
  }
}

export default App
