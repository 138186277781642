import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import './settingGroup.css'
import 'react-table/react-table.css'
import '../../style/react-table-custom.css'

import TabComponent from '../../components/tabComponent'

const Container = styled.div`
  padding: 0px 0px 0 20px;;
`
class SettingGroupOfUser extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      column: this.props.customerColumn,
      data: [],
      loading: false,
      pages: null,
      resAnnouncement: [],
      FilterbyMode: '',
      nameSettingGroup: ''
    }
  }
  onChangeTab = (path) => {
    const { history, match } = this.props
    history.push(match.url + path)
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  componentDidUpdate(prevProps) {
    if (prevProps.nameSettingGroup !== this.props.nameSettingGroup && this.props.nameSettingGroup === 'AddGroup') {
      this.setState({ nameSettingGroup: '' })
    }
    if (this.props.reloadNameGroup !== undefined && this.props.reloadNameGroup !== '') {
      this.setState({ nameSettingGroup: this.props.reloadNameGroup })
      this.props.setreloadnameSettingGroup('')
    }

  }

  componentDidMount() {
    this.props.setPageTitle('Group Setting')
    // this.props.setModeSettingGroup('setting-group')
    this.props.checkGroupForEdit(false)
    this.props.showBackIcon(true)
    this.props.getSettingMaid()
    this.props.getSettingCustomer()
  }

  handleFilter = () => {
    // this.setState({ showRightbar: true, mode: 'filter' })
    this.props.checkFillterRightBar({ showRightbar: true, mode: 'filter' })
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }


  render() {

    const tabData = [
      {
        label: 'Customer',
        total: this.props.dataCustomerList,
        key: '0',
        path: '/setting-group-customer',
      },
      {
        label: 'Maid',
        total: this.props.dataMaidList,
        key: '1',
        path: '/setting-group-maid',

      },
    ]
    const { nameSettingGroup } = this.state
    return (

      <Container className="col-md-9">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-xl-12 col-md-12">
              <div className="col-lg-12 col-xl-12 col-md-12" style={{ paddingBottom: '25px', paddingLeft: '20px' }}>
                <input style={{ paddingLeft: '20px' }}
                  className='settingGroup-input'
                  value={nameSettingGroup}
                  name="nameSettingGroup"
                  type="text"
                  placeholder="Group A"
                  onChange={(e) => this.setState({ nameSettingGroup: e.target.value }, () => this.props.setnameSettingGroup(this.state.nameSettingGroup))}
                />
              </div>
              <div className="col-lg-12 col-xl-12 col-md-12" style={{ paddingBottom: '25px', display: 'grid' }}>
                <button
                  className={
                    'btn ' +
                    ('btn-button-broadcast')
                  }
                  onClick={() => this.handleFilter()}>
                  Filter by
            </button>
                <span>การสร้างกลุ่มจะไม่สามารถเลือกรายชื่อของ User ต่างกันได้</span>
              </div>
              {
                <TabComponent transparent tabData={tabData} onChangeTab={this.onChangeTab} />
              }

            </div>
          </div>
          {this.props.children}
        </div>
      </Container>
    )
  }
}

const mapState = (state) => ({
  customerColumn: state.broadcasting.columnBroadcast,
  dataCustomerList: state.settingGroup.dataCustomerList,
  dataMaidList: state.settingGroup.dataMaidList,
  modeSettingGroup: state.settingGroup.modeSettingGroup,
  nameSettingGroup: state.settingGroup.nameSettingGroup,
  reloadNameGroup: state.settingGroup.reloadNameGroup,
  dataForEditGroup: state.settingGroup.dataForEditGroup,
  checkDisable: state.settingGroup.checkDisable,
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    showBackIcon: dispatch.Title.showBackIcon,
    getSettingMaid: dispatch.settingGroup.getSettingMaid,
    getSettingCustomer: dispatch.settingGroup.getSettingCustomer,
    setnameSettingGroup: dispatch.settingGroup.setnameSettingGroup,
    setModeSettingGroup: dispatch.settingGroup.setModeSettingGroup,
    checkGroupForEdit: dispatch.settingGroup.checkGroupForEdit,
    checkFillterRightBar: dispatch.settingGroup.checkFillterRightBar,
    setreloadnameSettingGroup: dispatch.settingGroup.setreloadnameSettingGroup,


  }
}

export default connect(
  mapState,
  mapDispatch
)(SettingGroupOfUser)
