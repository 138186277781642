import React, { Component } from 'react'

class DashboardCustomerPage extends Component {
  render() {
    return (
      <div>
        <div>DashboardCustomerPage</div>
      </div>
    )
  }
}

export default DashboardCustomerPage
