import React, { Component } from 'react'
import { connect } from 'react-redux'
import './broadcasTemplate.css'
import Button from './../../components/button'
import { post, get } from '../../utils/service'
import moment from 'moment'
import DatePicker from "react-datepicker";
import Switch from "react-switch";
import styled from 'styled-components'
import Select from 'react-select';

const InpuntHeadTemp = styled.input`
padding: 16px 16px 16px 30px;
  width: 687.3px;
  height: 60px;
  border: solid 1px #324755;
  background-color: #ffffff;
  font-size: 30px;
  margin-top: 20px;
  margin-left: 18px;
::placeholder,
::-webkit-input-placeholder {
   color: #324755;
}
:-ms-input-placeholder {
    color: #324755;
}
`

const InpuntHeadTemp1 = styled.input`
padding: 0px 0px 35px 30px;
width: 687.3px;
height: 70px;
border: solid 1px #324755;
background-color: #ffffff;
margin-bottom: 10px;
margin-left: 18px;
::placeholder,
::-webkit-input-placeholder {
  #324755;
}
:-ms-input-placeholder {
   #324755;
}
`
class BroadcasTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkTypePath: '',
      publish_now: '',
      checked: false,
      templateName: '',
      senderName: '',
      textAnnouncement: [],
      start_datetime: new Date(),
      end_datetime: new Date(),
      currenDate: new Date(),
      colourOptions: [
        { value: 1, label: 'Customer' },
        { value: 2, label: 'Maid' },
      ],
      checkGroup: [],
      testCase: '',
      SetColumsMaild: [],
      SetColumsCustomer: [],
      checkSetText: [],
      userChecklGoback: []
    }
    this.handleChangePublish = this.handleChangePublish.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.state !== undefined) {
      if (prevProps.location.state.id !== this.props.location.state.id && this.props.location.state.id !== '') {
        this.FindById()
        this.props.showBackIcon(true)
      }
    }
  }
  componentDidMount() {
    this.props.setPageTitle('Add new In app message template')
    this.props.showBackIcon(true)
    // this.FindById()
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.mode !== undefined || this.props.location.state.temp !== undefined) {
        this.FindById()
      }
    }

  }
  async FindById() {
    this.state.userChecklGoback.push({
      text: this.props.checkStatustemp.status,
      index: this.props.checkStatustemp.index,
      id: this.props.checkStatustemp.id
    })
    if (this.props.location.state.mode === 'Edit') {
      await get(`admin/broadcast/inappmessage/id/${this.props.location.state.id}`).then(res => {
        this.setState({
          templateName: res.name,
          publish_now: res.publish_now,
          senderName: res.sender,
          checked: res.publish_now,
          setting_group_id: res.setting_group_id,
          textAnnouncement: res.text,
          start_datetime: new Date(moment(res.start_datetime).format()),
          end_datetime: new Date(moment(res.end_datetime).format()),
          // text: "Welcome",
        }, () => this.handleChangeSelected('', this.state.setting_group_id))
      })
    } else if (this.props.location.state.temp === 'template') {
      await get(`admin/broadcast/inappmessage/template/id/${this.props.location.state.id}`).then(res => {
        this.setState({
          // end_datetime: "2019-10-30T06:19:37.000Z"
          templateName: res.name,
          senderName: res.sender,
          text: res.text,
          publish_now: false,
          checked: false,
          setting_group_id: res.setting_group_id,
          textAnnouncement: res.text
          // start_datetime: "2019-10-20T06:19:37.000Z",

        }, () => this.handleChangeSelected('', this.state.setting_group_id))
        this.state.userChecklGoback.forEach(v => {
          if (res.name === v.text && res.id === v.id) {
            this.props.checkStatusTemp({ status: v.text, index: v.index })
          }
        });
      })
    }
  }
  handleChangeSelected = (e, setting_group_id) => {

    if (setting_group_id === 1 || setting_group_id === 2) {
      if (setting_group_id === 1) {
        this.setState({
          selected_users: 'Customer',
          SetColumsCustomer: this.props.Customercolumn.filter(el => el.label !== 'Picture'),
          SetColumsMaild: [],
          setting_group_id: 1
        })
      } else if (setting_group_id === 2) {
        this.setState({
          selected_users: 'Maid',
          SetColumsMaild: this.props.maidcolumn.filter(el => el.label !== 'Picture'),
          SetColumsCustomer: [],
          setting_group_id: 2
        })
      }
    } else if (e !== null) {
      this.setState({ checkGroup: e.label })
      if (e.label === 'Customer') {
        this.setState({
          selected_users: 'Customer',
          SetColumsCustomer: this.props.Customercolumn.filter(el => el.label !== 'Picture'),
          SetColumsMaild: [],
          setting_group_id: 1
        })
        this.setValueSelect()
      } else if (e.label === 'Maid') {
        this.setState({
          selected_users: 'Maid',
          SetColumsMaild: this.props.maidcolumn.filter(el => el.label !== 'Picture'),
          SetColumsCustomer: [],
          setting_group_id: 2
        })
      }
      this.setValueSelect()
    } else {
      this.setState({ checkGroup: '' })
    }

  }
  setTextGroup(el, i) {
    this.setState({ textAnnouncement: this.state.textAnnouncement + "" + el })
  }
  setValueSelect() {
    if (this.state.setting_group_id >= 0) {
      return this.state.setting_group_id - 1
    } else {
      return null
    }
  }
  async handleChangePublish(checked) {

    this.setState({ publish_now: '' })
    this.setState({ checked });
    if (checked === true) {
      await this.setState({ publish_now: checked })
    } else if (checked === false) {
      await this.setState({ publish_now: checked })
    }
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleChangeStartDate = e => {
    this.setState({ start_datetime: e })
  }
  handleChangeEndDate = e => {
    this.setState({ end_datetime: e })
  }
  async onSubmit() {
    let mode = ''
    let idUpdate = ''
    let broadcastMode = ''
    if (this.props.location.state !== undefined) {
      mode = this.props.location.state.mode
      idUpdate = this.props.location.state.id
      broadcastMode = this.props.location.state.BroadcastMode ? this.props.location.state.BroadcastMode : this.props.location.state
    }

    let { objPublish } = this.state
    objPublish = {
      name: this.state.templateName,
      sender: this.state.senderName,
      text: this.state.textAnnouncement,
      publish_now: this.state.publish_now,
      start_datetime: moment(this.state.start_datetime).format('YYYY-MM-DD h:mm:ss'),
      end_datetime: moment(this.state.end_datetime).format('YYYY-MM-DD h:mm:ss'),
      setting_group_id: this.state.setting_group_id

    }
    if (objPublish) {
      const { history } = this.props
      const url = '/admin/broadcasting/list/broadcastSent'
      if (mode === 'Edit') {
        if (broadcastMode === 'InAppMessage') {
          if (this.state.publish_now === true) {
            await post(objPublish, `admin/broadcast/inappmessage/id/${idUpdate}/update`).then(res => {
              if (!res.error) {
                setTimeout(() => { history.push(url) }, 100);
              }
            })
          }
        }
      } else {
        if (broadcastMode === 'InAppMessage') {
          if (this.state.publish_now === true) {
            await post(objPublish, 'admin/broadcast/inappmessage/add').then(res => {
              if (!res.error) {
                setTimeout(() => { history.push(url) }, 100);
              }
            })
          }
        }
      }
    }
  }
  async onSubmitTempalte() {
    let mode = this.props.location.state.modeSetup ? this.props.location.state.modeSetup : this.props.location.state.mode
    let idUpdate = this.props.location.state.id
    let broadcastMode = this.props.location.state.BroadcastMode ? this.props.location.state.BroadcastMode : this.props.location.state
    let { obj } = this.state
    obj = {
      name: this.state.templateName,
      sender: this.state.senderName,
      text: this.state.textAnnouncement,
      setting_group_id: this.state.setting_group_id
    }
    const { history } = this.props
    const url = '/admin/broadcasting/list/broadcastSent'
    if (mode === 'Edit') {
      if (broadcastMode === 'InAppMessage') {
        await post(obj, `admin/broadcast/inappmessage/template/id/${idUpdate}/update`).then(res => {
          if (!res.error) {
            setTimeout(() => { history.push(url) }, 100);
          }
        })
      }
    } else {
      if (broadcastMode === 'InAppMessage') {
        await post(obj, 'admin/broadcast/inappmessage/template/add').then(res => {
          if (!res.error) {
            setTimeout(() => { history.push(url) }, 100);
          }
        })
      }
    }
  }

  render() {
    const { publish_now, templateName, start_datetime, end_datetime, senderName } = this.state
    return (
      <div style={{ justifyContent: "center", display: "flex" }} className="col-sm-6 col-xs-8 col-md-9 col-xl-10">
        <div >
          <div className="Rectangle-710-broadtem" style={{ height: '715.3px' }}>
            <InpuntHeadTemp type="text" name="templateName" value={templateName} placeholder="Template’s name" onChange={this.handleChange} />
            <InpuntHeadTemp1 type="text" name="senderName" value={senderName} placeholder="Sender’s name" onChange={this.handleChange} />
            <div className="row">
              <div className="col-xs-12">
                <div className="col-xs-12">
                  <div className="col-md-12">
                    <div className="row" style={{ paddingBottom: "10px" }}>
                      <div className="col-md-4">
                        <label style={{ paddingLeft: "25px", paddingTop: "10px" }}>Group of Users :</label>
                      </div>
                      <div className="col-md-7">
                        <Select
                          value={this.state.colourOptions[this.setValueSelect()]}
                          name="selected_users"
                          options={this.state.colourOptions}
                          onChange={this.handleChangeSelected}
                          // className="basic-multi-select"
                          className="Rectangle-31-AnnounceTemp"
                          classNamePrefix="select"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* /////////////// */}
                <div className="col-xs-12">
                  {
                    this.state.checkGroup !== ' ' ?
                      <div className="row" style={{ paddingBottom: "10px", paddingLeft: "45px" }}  >
                        {this.state.SetColumsMaild.map((el, i) =>
                          <div className="col-xs-3" style={{ marginRight: "2px" }} key={i}>
                            <input type="button" className="Rectangle-726-broadtem" value={el.label} onClick={() => this.setTextGroup(`{${el.label}}`, i)} />
                          </div>
                        )}
                        {this.state.SetColumsCustomer.map((el, i) =>
                          <div className="col-xs-3" style={{ marginRight: "2px" }} key={i}>
                            <input type="button" className="Rectangle-726-broadtem" value={el.label} onClick={() => this.setTextGroup(`{${el.label}}`, i)} />
                          </div>
                        )}
                      </div>
                      :
                      <div className="row" style={{ paddingBottom: "10px", paddingLeft: "45px" }}  >
                        {this.state.SetColumsMaild.map((el, i) =>
                          <div className="col-xs-3" style={{ marginRight: "2px" }} key={i}>
                            <input type="button" className="Rectangle-726-broadtem" value={el.label} onClick={() => this.setTextGroup(`{${el.label}}`, i)} />
                          </div>
                        )}
                        {this.state.SetColumsCustomer.map((el, i) =>
                          <div className="col-xs-3" style={{ marginRight: "2px" }} key={i}>
                            <input type="button" className="Rectangle-726-broadtem" value={el.label} onClick={() => this.setTextGroup(`{${el.label}}`, i)} />
                          </div>
                        )}
                      </div>
                  }
                </div>
              </div>
            </div>
            {/* <div className="Rectangle-659-broadtem">
                <div className="row" style={{ padding: "20px"}}>
                <div className="col-xs-3" ><label>{this.state.textAnnouncement.map(el=>{return `${el}  `})}</label></div>
                </div>
            </div> */}
            <textarea className="Rectangle-659-broadtem" value={this.state.textAnnouncement} onChange={(e) => this.setState({ textAnnouncement: e.target.value })} />
            <div className="row footer Rectangle-739-broadtem" style={{ marginTop: '10px' }}>
              <div className="col-md-6">
                <span>ฉันต้องการจะตั้งค่าเพื่อทำการประกาศตอนนี้</span>
                <p>Anyone can search and see this name card</p>
              </div>
              <div className="col-md-6">
                <div style={{ float: 'right' }}> <Switch onChange={this.handleChangePublish} value={publish_now} checked={this.state.checked} /> </div>
              </div>
            </div>   {
              this.state.publish_now === true ?
                <div style={{ paddingTop: '10px' }}>
                  <div className="col-md-12">
                    <div className="row" style={{ paddingBottom: "10px" }}>
                      <div className="col-md-4">
                        <label style={{ paddingLeft: "25px", paddingTop: "5px" }}>Start Date To Announce :</label>
                      </div>
                      <div className="col-md-8">
                        <DatePicker className="Rectangle-Datepicker-promotion"
                          value={start_datetime}
                          showTimeSelect
                          name="start_datetime"
                          selected={start_datetime}
                          minDate={this.state.currenDate}
                          onChange={this.handleChangeStartDate}
                          timeFormat="p"
                          timeIntervals={15}
                          dateFormat=" yyyy-MM-dd h:mm aa"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row" style={{ paddingBottom: "10px" }}>
                      <div className="col-md-4">
                        <label style={{ paddingLeft: "25px", paddingTop: "5px" }}>End Date To Announce :</label>
                      </div>
                      <div className="col-md-8">
                        <DatePicker className="Rectangle-Datepicker-promotion"
                          value={end_datetime}
                          showTimeSelect
                          name="end_datetime"
                          selected={end_datetime}
                          minDate={start_datetime}
                          onChange={this.handleChangeEndDate}
                          timeFormat="p"
                          timeIntervals={15}
                          dateFormat=" yyyy-MM-dd h:mm aa"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ paddingBottom: '10px' }}>
                    <div className="row" style={{ paddingBottom: "10px" }}>
                      <div className="col-md-4" />
                      <div className="col-md-8">
                        <Button style={{ width: "228.3px", float: "right", top: "10px", marginRight: "7px" }}
                          focus
                          className="btn"
                          label="Send this Message"
                          onClick={() => this.onSubmit()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className="col-md-12" style={{ paddingBottom: '10px' }}>
                  <div className="row" style={{ paddingBottom: "10px" }}>
                    <div className="col-md-4" />
                    <div className="col-md-8">
                      <Button style={{ width: "228.3px", float: "right", top: "10px", marginRight: "7px" }}
                        focus
                        className="btn"
                        label="Save this Template"
                        onClick={() => this.onSubmitTempalte()}
                      />
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>

      </div>
    )
  }
}

const mapState = (state) => ({
  maidcolumn: state.maid.column,
  Customercolumn: state.customer.column,
  checkStatustemp: state.broadcasting.checkStatustemp
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    showBackIcon: dispatch.Title.showBackIcon,
    checkStatusTemp: dispatch.broadcasting.checkStatusTemp
  }
}

export default connect(
  mapState,
  mapDispatch
)(BroadcasTemplate)

