import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
// import Button from '../../components/button'
import ReactTable from 'react-table'
import moment from 'moment'
import './maidDetail.css'
import DatePicker from 'react-datepicker'
import request from '../../utils/request'
import Button from '../../components/button'
import Pagination from '../../components/paginationTable/pagination'

const Overlay = styled.div`
  content: ' ';
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
`

const CardTable = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.active};
  background: #fff;
  height: max-content;
  width: 100%;
  position: relative;
  top: -1px;
  padding: 10px;
  align-items: flex-start;
  overflow: auto;
`

const Container = styled.div`
  padding: 20px;
  display: inline-block;
  position: relative;
  width: 100%;
`

// const Footer = styled.div`
//   position: absolute;
//   left: 10px;
//   bottom: 50px;
// `
// const FooterPicContainer = styled.div`
//   display: flex;
//   padding: 0 0 0 30px;
//   img {
//     margin-right: 10px;
//   }
// `


const Card = styled.div`
      float: left;
      width: 33.333%;
      padding: .75rem;
      margin-bottom: 2rem;
      border: 0;
      `
const HeaderTable = styled.div`
      text-align:left
      `
// const CellTofixeTable = styled.div`
//       text-align:right;
//       padding-right:5px
//       `

class maidProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      column: this.props.columnMaidProfile,
      showRightbar: false,
      dataProfile: [],
      salary: '',
      start_datetime: new Date(),
      end_datetime: new Date(),
      loading: false,
      PageSize: 21,
      numberPage: 1,
      totalPages: 0,
      totaldata: 0,
      headerCumulative: {},
      chanel_url: '',
    }
  }

  componentDidMount() {
    this.props.setPageTitle('Back')
    this.props.showBackIcon(true)
    this.getDataProfile()
    this.props.setStartdateOrder('')
    this.props.setEndateOrder('')
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.column !== this.props.columnMaidProfile) {
      this.setState({ column: this.props.columnMaidProfile })
    }
  }



  async getDataProfile() {
    if (this.props.match.params.id !== undefined) {
      const id = this.props.match.params.id
      request.get(`maid/id/${id}`).then(resp => {
        this.setState({
          dataProfile: {
            picture: resp.data.user.image_url,
            name: resp.data.user.first_name + ' ' + resp.data.user.last_name,
            role: resp.data.user.role,
            email: resp.data.user.email,
            phone: resp.data.user.phone,
            address: resp.data.location_name
          },
          chanel_url: resp.data.user.admin_channel_url,
          start_datetime: resp.data.start_working_datetime ? new Date(resp.data.start_working_datetime) : new Date(),
          end_datetime: resp.data.latest_working_datetime ? new Date(resp.data.latest_working_datetime) : new Date(),
        }, () => this.getDataMaidListTable())
      })

    }
  }
  handleChangeStartDate = e => {
    this.setState({ start_datetime: e }, () => this.getDataMaidListTable())
  }
  handleChangeEndDate = e => {
    this.setState({ end_datetime: e }, () => this.getDataMaidListTable())
  }

  async getDataMaidListTable() {
    let startDate = await moment(this.state.start_datetime).format('YYYY-MM');
    let endDate = await moment(this.state.end_datetime).format('YYYY-MM')
    this.setState({ loading: true })
    if (startDate && endDate) {
      if (this.props.match.params.id !== undefined) {
        const id = this.props.match.params.id
        const resp = await request.get(`maid/id/${id}/details?start_month=${startDate}&end_month=${endDate}&limit=5000`)
        let tempDate = []
        let respArr = resp.data.filter(v => v.title !== 'Cumulative')
        this.setState({
          headerCumulative: {
            title: resp.data[0].title,
            Work_completed: this.formatNumber(resp.data[0].no_of_work_completed),
            Hrs_completed: this.formatNumber(resp.data[0].no_of_hours_completed),
            salary: this.formatNumber(resp.data[0].salary),
            star_bonus: this.formatNumber(resp.data[0].five_star_bonus),
            Monthly_bonus: this.formatNumber(resp.data[0].monthly_bonus),
            Milestone_bonus: this.formatNumber(resp.data[0].milestone_bonus)
          }
        })
        if (respArr.length) {
          for (let i = 0; i < respArr.length; i++) {
            tempDate.push({
              Mounth: respArr[i].title,
              Work_completed: this.formatNumber(respArr[i].no_of_work_completed),
              Hrs_completed: this.formatNumber(respArr[i].no_of_hours_completed),
              salary: this.formatNumber(respArr[i].salary),
              star_bonus: this.formatNumber(respArr[i].five_star_bonus),
              Monthly_bonus: this.formatNumber(respArr[i].monthly_bonus),
              Milestone_bonus: this.formatNumber(respArr[i].milestone_bonus)
            })

          }
        }
        const totalpage = await Math.ceil((respArr.length + 1) / 5000)
        this.setState({ loading: false, data: tempDate, totalPages: totalpage })
      } else {
        this.setState({ loading: false })
      }
    }
  }
  formatNumber(num) {
    var val = Number(num) * 100 / 100;
    var parts = val.toString().split(".");
    var num1 = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? "." + parts[1].substr(0, 2) : ".00");
    return num1
  }
  handleColumn = () => {
    this.setState({ showRightbar: true, mode: 'column' })
  }
  handleFilter = () => {
    this.setState({ showRightbar: true, mode: 'filter' })
  }
  handleToggle = (key) => {
    this.setState((prevState) => ({ [key]: !prevState[key] }))
    const { history } = this.props
    history.push(this.props.history.location.pathname)
  }
  sendForChat = () => {
    this.props.history.push(`/admin/chatbox/${this.props.match.params.id}/MAID/${this.state.chanel_url}`)
    this.props.showBackIcon(false)
    this.props.setPageTitle('Chat Box')
  }

  compareBy(key, order) {
    return function (a, b) {
      if (order) {
        if (a[key] < b[key]) {
          return 1;
        }
        if (a[key] > b[key]) {
          return -1;
        }
        return 0;
      } else {
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      }
    };
  }

  togleSorting(newSorted, column, shiftKey) {
    let arrayCopy = [...this.state.data];
    arrayCopy.sort(this.compareBy(column.id, newSorted[0].desc));
    this.setState({ data: arrayCopy });
  }

  render() {
    const allColumns = [

      {
        Header: () => <HeaderTable style={{ paddingLeft: '10px' }}>Month</HeaderTable>,
        accessor: 'Mounth',
        minWidth: 60,
        columns: [
          {
            Header: () => (
              <div
                style={{
                  textAlign: "left",
                  paddingLeft: '10px'
                }}
              >{this.state.headerCumulative.title}</div>),
            accessor: 'Mounth',
            Cell: row => <div style={{ paddingLeft: '10px' }}>{row.original.Mounth}</div>,
          }],

      },
      {
        Header: () => <HeaderTable>Work Completed</HeaderTable>,
        accessor: 'Work_completed',
        minWidth: 110,
        columns: [
          {
            Header: () => (
              <div
                style={{
                  textAlign: "left"
                }}
              >{this.state.headerCumulative.Work_completed ?
                this.state.headerCumulative.Work_completed
                : null}</div>),
            accessor: 'Work_completed',
            Cell: row => <div style={{ paddingLeft: '3px' }}>{row.original.Work_completed}</div>,
          }],
      },
      {
        Header: () => <HeaderTable>Hrs Completed</HeaderTable>,
        accessor: 'Hrs_completed',
        minWidth: 100,
        columns: [
          {
            Header: () => (
              <div
                style={{
                  textAlign: "left"
                }}
              >{this.state.headerCumulative.Hrs_completed ?
                this.state.headerCumulative.Hrs_completed
                : null}</div>),
            accessor: 'Hrs_completed',
            Cell: row => <div style={{ paddingLeft: '5px' }}>{row.original.Hrs_completed}</div>,
          }],
      },
      {
        Header: () => <HeaderTable>Salary(THB)</HeaderTable>,
        accessor: 'salary',
        minWidth: 90,
        columns: [
          {
            Header: () => (
              <div
                style={{
                  textAlign: "left"
                }}
              >{this.state.headerCumulative.salary ?
                this.state.headerCumulative.salary
                : null}</div>),
            accessor: 'salary',
            Cell: row => <div style={{ paddingLeft: '7px' }}>{row.original.salary}</div>,
          }],
      },
      {
        Header: () => <HeaderTable>5 Star Bonus</HeaderTable>,
        accessor: 'star_bonus',
        minWidth: 90,
        columns: [
          {
            Header: () => (
              <div
                style={{
                  textAlign: "left"
                }}
              >{this.state.headerCumulative.star_bonus ?
                this.state.headerCumulative.star_bonus
                : null}</div>),
            accessor: 'star_bonus',
            Cell: row => <div className="maidStarTableProfile">{row.original.star_bonus}</div>,
          }],
      },
      {
        Header: () => <HeaderTable>Monthly Bonus</HeaderTable>,
        accessor: 'Monthly_bonus',
        minWidth: 100,
        columns: [
          {
            Header: () => (
              <div
                style={{
                  textAlign: "left"
                }}
              >{this.state.headerCumulative.Monthly_bonus ?
                this.state.headerCumulative.Monthly_bonus
                : null}</div>),
            accessor: 'Monthly_bonus',
            Cell: row => <div  className="maidTrainerTableProfile">{row.original.Monthly_bonus}</div>,
          }],
      },
      {
        Header: () => 'Milestone Bonus',
        accessor: 'Milestone_bonus',
        minWidth: 110,
        columns: [
          {
            Header: () => (
              this.state.headerCumulative.Milestone_bonus ?
                this.state.headerCumulative.Milestone_bonus
                : null
              ),
            accessor: 'Milestone_bonus',
            Cell: row => <div className="maidTrainerTableProfile">{row.original.Milestone_bonus}</div>,
          }],
      },

    ]

    let columnWillShow = []
    this.state.column.map((colName) => {
      for (var obj of allColumns) {
        colName.isShow && obj.accessor === colName.accessor &&
          columnWillShow.push(obj)
      }
      return {}
    })

    const { dataProfile } = this.state
    return (
      <Container>
        <div className="container-fluid">
          {this.state.showRightbar && <Overlay onClick={() => this.handleToggle('showRightbar')} />}
          {/* Row */}
          <div >
            <div className="row ">
              {/* Column */}
              <Card className="col-md-3" style={{ width: '25.333%', padding: '0px', marginBottom: '1px' }}>
                <div className="card col-md-12 cradProfileMiad" >
                  <div className="card-body">
                    <center className="m-t-30">
                      <img
                        src={`${dataProfile.picture}`}
                        alt="profile"
                        className="rounded-circle"
                        width={80}
                      />
                      <h4 className="card-title m-t-10">{dataProfile.picnameture}</h4>
                      <h6>{dataProfile.name}</h6>
                      {dataProfile.role ? <Button className="btn"
                        style={{ borderRadius: "11px", width: '75px', height: '30px', fontSize: '12px' }}
                        label="Chat" onClick={() => this.sendForChat()} />
                        : null}
                    </center>
                    <small className="text-muted">Role </small>
                    <h6>{dataProfile.role}</h6>
                    <small className="text-muted">Email address </small>
                    <h6>{dataProfile.email}</h6>
                    <small className="text-muted p-t-30 db">Phone</small>
                    <h6>{dataProfile.phone}</h6>
                    <small className="text-muted p-t-30 db">Address</small>
                    <h6>{dataProfile.address}</h6>
                  </div>
                </div>
              </Card>
              <div className="col-md-9">
                <div className="btn-container">
                  {/* <Button className="btn" style={{ borderRadius: "11px" }} label="Column setting" onClick={() => this.handleColumn()} />
                  <Button className="btn" style={{ borderRadius: "11px" }} label="Filter by" onClick={() => this.handleFilter()} /> */}
                </div>
                <div className="col-xs-12 col-sm-10 col-md-10 col-xl-8" >
                  <div className="row form-group">
                    <div className="col-md-6">
                      <div className="row form-group">
                        <div className="col-md-12" >
                          <label style={{ fontSize: "18px", fontWeight: "500px" }}>Start Month</label>
                        </div>
                        <div className="col-md-12 Datepicker_react_Popup">
                          <DatePicker className="Datepicker_react"
                            showMonthYearPicker
                            value={this.state.start_datetime}
                            selected={this.state.start_datetime}
                            onChange={(e) => this.handleChangeStartDate(e)}
                            dateFormat="MM/yyyy"
                            popperPlacement='bottom'
                            popperModifiers={{
                              flip: {
                                behavior: ['bottom'] // don't allow it to flip to be above
                              },
                              preventOverflow: {
                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                              },
                              hide: {
                                enabled: false // turn off since needs preventOverflow to be enabled
                              }
                            }}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6" >
                      <div className="row form-group">
                        <div className="col-md-12" >
                          <label style={{ fontSize: "18px", fontWeight: "500px" }}>End Month</label>
                        </div>
                        <div className="col-md-12 Datepicker_react_Popup">
                          <DatePicker className="Datepicker_react"
                            showMonthYearPicker
                            value={this.state.end_datetime}
                            selected={this.state.end_datetime}
                            onChange={(e) => this.handleChangeEndDate(e)}
                            dateFormat="MM/yyyy"
                            popperPlacement='bottom'
                            popperModifiers={{
                              flip: {
                                behavior: ['bottom']
                              },
                              preventOverflow: {
                                enabled: false
                              },
                              hide: {
                                enabled: false
                              }
                            }}

                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <CardTable className="col-md-12">
                  <ReactTable
                    data={this.state.data}
                    columns={columnWillShow}
                    loading={this.state.loading}
                    pageSize={25}
                    page={this.state.page}
                    showPagination={true}
                    PaginationComponent={Pagination}
                    pages={this.state.totalPages}
                    manual
                    onSortedChange={(newSorted, column, shiftKey) => this.togleSorting(newSorted, column, shiftKey)}
                    onPageChange={page => this.setState({ page: page })}
                    getTrProps={(state, rowInfo) => {
                      return {
                        style: {
                          height: 25,
                          overflow: 'hidden'
                        },
                      }
                    }}

                    className="-striped -highlight maidDetailtetable"
                  />
                </CardTable>

              </div>
            </div>
          </div>
          {this.props.children}
        </div>
      </Container>
    )
  }
}

const mapState = (state) => ({
  columnMaidProfile: state.maid.columnMaidProfile,

})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    showBackIcon: dispatch.Title.showBackIcon,
    setStartdateOrder: dispatch.orderManagement.setStartdateOrder,
    setEndateOrder: dispatch.orderManagement.setEndateOrder,
  }
}

export default connect(
  mapState,
  mapDispatch
)(maidProfile)
