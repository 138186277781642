import request from '../../utils/request'

export const promotion = {
  state: {
    dataPromotionInac: 0,
    dataPromotionAC: 0,
    dataPromotionEx: 0,
    updateData: '',
    columnPromotion: [
      { label: '', accessor: 'edit', isShow: true },
      { label: 'Name', accessor: 'name', isShow: true },
      { label: 'Id', accessor: 'id', isShow: true },
      { label: 'Code', accessor: 'code', isShow: true },
      { label: 'Create BY', accessor: 'createBy', isShow: true },
      { label: 'Start Date', accessor: 'start_datetime', isShow: true },
      { label: 'End Date', accessor: 'end_datetime', isShow: true },
    ],
    showBtnAdd: true,
  },
  reducers: {
    checkUpdateData(state, payload) {
      return {
        ...state,
        updateData: payload
      }
    },
    getPromotionInacList(state, payload) {
      return {
        ...state,
        dataPromotionInac: payload
      }
    },
    getPromotionAcList(state, payload) {
      return {
        ...state,
        dataPromotionAC: payload
      }
    },
    getPromotionExpiredList(state, payload) {
      return {
        ...state,
        dataPromotionEx: payload
      }
    },
    setCheckTypePath(state, payload) {
      return {
        ...state,
        showBtnAdd: payload
      }
    },
  },
  effects: (dispatch) => ({
    async getInacList() {
      const res = await request.get('/promotion/list/inactive')
      const cleanData = res.data.item_count
      dispatch.promotion.getPromotionInacList(cleanData)
    },
    async getAcList() {
      const res = await request.get('/promotion/list/active')
      const cleanData = res.data.item_count
      dispatch.promotion.getPromotionAcList(cleanData)

    },
    async getExpiredList() {
      const res = await request.get('/promotion/list/expired')
      const cleanData = res.data.item_count
      dispatch.promotion.getPromotionExpiredList(cleanData)

    },


  }),
}