import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ErrorBoundary from "../../components/errorBoundary"
import CustomModal from "../../components/customModal"
import Button from "../../components/button"
import DatePicker from "react-datepicker"
import { customModalStyle } from "./modalViewstyle"
import "./swal.css"
import swal from "sweetalert"
import request from "../../utils/request"
import moment from "moment"

const Container = styled.div`
  padding: 20px;
`
const Section = styled.div`
  height: 250px;
  overflow: auto;
`

const Header = styled.div`
  text-align: justify;
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 50px;
  // margin-bottom: 40px;
`

class SettingModal extends Component {
  static propTypes = {}

  state = {
    startMonthYear: new Date(),
    endMonthYear: new Date(),
    checkBoxNYList: [],
    yearParam: [],
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sendForDate.sendYear !== this.props.sendForDate.sendYear) {
      this.setState({
        startMonthYear: this.props.sendForDate.sendYear,
        endMonthYear: this.props.sendForDate.sendYear,
      })
    }
  }

  componentDidMount() {
    this.setCheckBoxMyList()
  }
  setCheckBoxMyList() {
    let arr = []
    let tempArr = []
    let sDate = moment(this.state.startMonthYear)
    let eDate = moment(this.state.endMonthYear)
    while (!sDate.isAfter(eDate)) {
      arr.push({ date: sDate.format("YYYY-MM"), label: sDate.format("MM/YYYY") })
      tempArr.push(sDate.format("YYYY-MM"))
      sDate.add(1, "month")
    }
    this.setState({ checkBoxNYList: arr })
    this.setState({ yearParam: tempArr })
    // React.createElement('input',{type: 'checkbox', defaultChecked: true});
    // let tenpCheck = document.querySelector('.checkbox')
    // console.log(tenpCheck)
    // if (tenpCheck) {
    //   document.getElementById(`${tenpCheck.id}`).checked = false
    // }
  }
  closeModal = () => {
    this.props.closeModal(false)
  }
  setStartMonth(e) {
    this.setState({ startMonthYear: e }, () => this.setCheckBoxMyList())
  }
  setEndMonth(e) {
    this.setState({ endMonthYear: e }, () => this.setCheckBoxMyList())
  }
  toggleCheckboxChange(el) {
    let arr = this.state.yearParam
    if (this.state.yearParam.length > 0) {
      let i = arr.indexOf(el)
      if (i < 0) {
        this.state.yearParam.push(el)
      } else {
        arr.splice(i, 1)
        this.setState({ yearParam: arr })
      }
    } else {
      this.state.yearParam.push(el)
    }
  }
  onCallExport = () => {
    if (this.state.yearParam.length > 0) {
      swal({
        className: "swal-modal",
        title: "Are you sure?",
        text: `Are you sure export data Inventories Calendar `,
        buttons: ["Cancel", "Confirm"],
        defeat: true,
      }).then((willDelete) => {
        if (willDelete) {
          const url = window.location.hostname === "admin.bluuu.co"
          ? process.env.REACT_APP_PRODUCTION
          : window.location.hostname === "uat-admin.bluuu.co"
            ? process.env.REACT_APP_UAT
            : process.env.REACT_APP_DEV
          let param = this.state.yearParam.toString()
          const redir = url + "/" +  `maid/calendar/inventory/csv?month=${param}`
          window.open(redir,"blank") 
          swal("Export Success", {
            icon: "success",
          })
        }
      })
    } else {
      swal("Please select month", "", "warning")
    }
  }

  onCallBack = () => {
    this.props.onCallBack()
  }

  render() {
    return (
      <ErrorBoundary>
        <CustomModal isOpen={this.props.isOpen} customStyle={customModalStyle}>
          <Container>
            <Header>Export Inventories Calendar </Header>
            <Section>
              <div className="row col-12" style={{ paddingBottom: "10px" }}>
                <div className="col-6 Datepicker_react_Popup_invenca_export">
                  <label style={{ fontSize: "18px", fontWeight: "500px" }}>Start Month</label>
                  <DatePicker
                    className="Datepicker_react_invenca_export"
                    selected={this.state.startMonthYear}
                    onChange={(e) => this.setStartMonth(e)}
                    showMonthYearPicker
                    maxDate={this.state.endMonthYear}
                    dateFormat="MM/yyyy"
                    popperPlacement="bottom"
                    popperModifiers={{
                      flip: {
                        behavior: ["bottom"],
                      },
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    }}
                  />
                </div>
                <div className="col-6 Datepicker_react_Popup_invenca_export">
                  <label style={{ fontSize: "18px", fontWeight: "500px" }}>End Month</label>
                  <DatePicker
                    className="Datepicker_react_invenca_export"
                    selected={this.state.endMonthYear}
                    onChange={(e) => this.setEndMonth(e)}
                    showMonthYearPicker
                    minDate={this.state.startMonthYear}
                    dateFormat="MM/yyyy"
                    popperPlacement="bottom"
                    popperModifiers={{
                      flip: {
                        behavior: ["bottom"],
                      },
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    }}
                  />
                </div>
              </div>
              <div className="row" style={{ width: "100%" }}>
                <div className="col-sm-12">
                  <form onSubmit={this.handleFormSubmit}>
                    <div className="row col-md-12 checkbox" style={{ justifyContent: "center" }}>
                      {this.state.checkBoxNYList.map((e, index) => {
                        return (
                          <div className="row col-md-3" key={index}>
                            <label>
                              <input
                                id={"check_" + e.date}
                                style={{ marginRight: "10px" }}
                                type="checkbox"
                                title={e.data}
                                className="checkbox"
                                value={e.date}
                                defaultChecked={true}
                                checked={e.data}
                                onChange={(e) => this.toggleCheckboxChange(e.target.value)}
                              />
                              {e.label}
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </form>
                </div>
              </div>
            </Section>
            <Button
              style={{ position: "absolute", bottom: "20px", right: "180px" }}
              onClick={() => this.props.closeModal()}
              label="Cancel"
              light
            />
            <Button
              style={{ position: "absolute", bottom: "20px", right: "20px" }}
              onClick={() => this.onCallExport()}
              type="submit"
              label="Export"
            />
          </Container>
        </CustomModal>
      </ErrorBoundary>
    )
  }
}

SettingModal.propstype = {
  closeModal: PropTypes.func.isRequired,
  onCallBack: PropTypes.func.isRequired,
}

export default SettingModal
