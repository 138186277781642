import React, { Component } from "react"
import { connect } from "react-redux"

import styled from "styled-components"

const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content:center;
  align-items: center;
`

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

class DummyLayout extends Component {
  state = {
    isSettingModalShow: false,
    index: 0,
  }

  componentDidMount() {
    this.props.setPageTitle("Dummy Work")
    this.props.showBackIcon(false)
  }

  render() {
    return <Container>{this.props.children}</Container>
  }
}

const mapState = () => ({})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    showBackIcon: dispatch.Title.showBackIcon,
  }
}
export default connect(mapState, mapDispatch)(DummyLayout)
