import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import ReactTable from "react-table"
import "react-table/react-table.css"
import "../../style/react-table-custom.css"
import Toggle from "react-toggle"
import "./orderPayment.css"
import moment from "moment"
import request from "../../utils/request"
import Pagination from "../../components/paginationTable/pagination"
import Button from "../../components/button"
import TabComponent from "../../components/tabComponent"
import Rightbar from "../../layouts/paymentManagementLayout/rightbar"
import DatePicker from "react-datepicker"
import swal from "sweetalert"

import CustomModal from "../../components/customModal"
import {
  customModalStyle,
  // mobileModalStyle
} from "../../components/loginModal/customActualModal"

const Container = styled.div`
  padding: 20px;
`

const Header = styled.div`
  text-align: justify;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 30px;
  max-width: 350px;
  text-align: center;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.active};
  background: #fff;
  height: max-content;
  width: 100%;
  position: relative;
  top: -1px;
  padding: 10px;
  align-items: flex-start;
  overflow: auto;
`
const HeaderTable = styled.div`
  text-align: left;
`
const Overlay = styled.div`
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
`

class OrderManagementPayment extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      column: this.props.columnOrderManagementPayment,
      data: [],
      tempdata: [],
      checked: false,
      loading: false,
      paramsDate: {},
      totaldata: 0,
      pages: 0,
      tempdate: [],
      showRightbar: false,
      start_datetime: new Date(),
      end_datetime: new Date(),
      listWeekPayment: [],
      weekValue: "",
      yearList: [],
      yaerValue: "",
      textSearchPayment: "",
      checktext: "",
      unpaid : {
        total: 0,
        count: 0,
      },
      paid : {
        total: 0,
        count: 0,
      },
      checkedAllPaid: false,
      confirmSwitchAllPaidModal: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.column !== this.props.columnOrderManagementPayment) {
      this.setState({
        column: this.props.columnOrderManagementPayment,
      })
    }
  }
  componentDidMount() {
    this.props.setOrderMode("Payment")
    this.setDateForFectData()
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  async setDateForFectData() {
    const d = new Date()
    const n = d.getDate()
    // yearList
    let nYear = await d.getFullYear()
    let num = nYear - 5
    this.setState({ yaerValue: nYear })
    if (num) {
      if (this.state.yearList.length <= 5) {
        for (let i = num; i <= nYear; i++) {
          this.state.yearList.push(i)
        }
      }
      this.state.yearList.push(nYear + 1)
    }
    let tempD = await JSON.parse(localStorage.getItem("PaymentDate"))

    // console.log("🚀 ------------------------------------------------------------------------------------------")
    // console.log("🚀 ~ file: index.js ~ line 103 ~ OrderManagementPayment ~ setDateForFectData ~ tempD", tempD)
    // console.log("🚀 ------------------------------------------------------------------------------------------")

    if (tempD) {
      await this.setState({
        start_datetime: new Date(moment(tempD.startDate).format("YYYY-MM-DD")),
        end_datetime: new Date(moment(tempD.endDate).format("YYYY-MM-DD")),
      })
    }
    // else {
    //   if (n <= 15) {
    //     await this.setState({
    //       start_datetime: new Date(moment().set("date", 1).format()),
    //       end_datetime: new Date(moment().set("date", 15).format()),
    //     });
    //   } else if (n >= 16) {
    //     await this.setState({
    //       start_datetime: new Date(moment().set("date", 16).format()),
    //       end_datetime: new Date(moment(d).endOf("month").format()),
    //     });
    //   }
    // }

    this.handleChangeYear(new Date().getFullYear())
    // this.getDataPayment();
  }
  189

  leapYear(year) {
    return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0
  }
  loopCheckSatSun(year, dayMonth, month) {
    var saturdays = []
    var sundays = []

    for (let i = 1; i <= dayMonth; i++) {
      var date = new Date(year, month, i)
      if (date.getDay() === 6) {
        saturdays.push(moment(date).format("YYYY-MM-DD"))
        // if(year === 2021 && month === 11){
        //   saturdays.push(moment(new Date(2022,0,1)).format("YYYY-MM-DD"));
        // }
      } else if (date.getDay() === 0) {
        sundays.push(moment(date).format("YYYY-MM-DD"))
      }
    }
    let payload = {
      sundays,
      saturdays,
    }
    // console.log("sundays",sundays,"saturdays",saturdays);
    return payload
  }

  // getDefaultOffDays_2(year) {
  //   var year2 = parseInt(year) + 1
  //   var saturdays = []
  //   var sundays = []
  //   var days = []
  //   let payload = []

  //   for (let l = 0; l < 12; l++) {
  //     if (l === 0 || l === 2 || l === 4 || l === 6 || l === 7 || l === 9 || l === 11) {
  //       payload = this.loopCheckSatSun(year, 31, l)
  //     } else if (l === 1) {
  //       this.leapYear(year)
  //         ? (payload = this.loopCheckSatSun(year, 29, l))
  //         : (payload = this.loopCheckSatSun(year, 28, l))
  //     } else {
  //       payload = this.loopCheckSatSun(year, 30, l)
  //     }

  //     saturdays = saturdays.concat(payload.saturdays)
  //     sundays = sundays.concat(payload.sundays)
  //   }
  //   if (year === 2021 || year === "2021") {
  //     saturdays.push("2022-01-01")
  //     sundays.push("2021-12-26")
  //   }

  //   saturdays = saturdays.slice(1)

  //   for (let j = 0; j <= sundays.length; j++) {
  //     if (sundays[j] !== undefined && saturdays[j] !== undefined) {
  //       days.push(sundays[j] + " - " + saturdays[j])
  //     }
  //   }

  //   return days
  // }

  getDefaultOffDays_3(year) {
    const days = []
    const date = new Date(year, 0, 1)
    while (date.getDay() != 0) {
      date.setDate(date.getDate() + 1)
    }
    const sundays = []
    const saturdays = []

    while (date.getFullYear() === +year) {
      const m = date.getMonth() + 1
      const d = date.getDate()
      const dateFormate = moment(
        year + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d)
      ).format("YYYY-MM-DD")
      sundays.push(dateFormate)
      saturdays.push(moment(dateFormate).add(6, "d").format("YYYY-MM-DD"))
      date.setDate(date.getDate() + 7)
    }

    for (let j = 0; j <= sundays.length; j++) {
      if (sundays[j] !== undefined && saturdays[j] !== undefined) {
        days.push(sundays[j] + " - " + saturdays[j])
      }
    }
    return days
  }

  getDefaultOffDays(year) {
    return this.getDefaultOffDays_3(year)
  }
  async handleChangeYear(year) {
    if (year) {
      this.setState({ yaerValue: year })
    }

    var d = new Date()
    var y = d.getFullYear()
    let Tempyaer = 0
    if (year !== undefined) {
      Tempyaer = year
    } else {
      Tempyaer = y
      let temparray_no_year = this.getDefaultOffDays(Tempyaer)
      temparray_no_year = temparray_no_year[0].split(" - ")
      this.state.start_datetime = temparray_no_year[0]
      this.state.end_datetime = temparray_no_year[1]
    }

    let temparray = []

    if (Tempyaer) {
      temparray = this.getDefaultOffDays(Tempyaer)
    }
    if (this.state.weekValue) {
      // console.log("yess");
      this.setState({
        listWeekPayment: temparray,
      })
      // console.log("this.state.weekValue", this.state.weekValue);
      // console.log("listWeekPayment", this.state.listWeekPayment);
      let weekValue = temparray[0].split(" - ")
      this.state.start_datetime = weekValue[0]
      this.state.end_datetime = weekValue[1]
    } else {
      let temparray_no_year = this.getDefaultOffDays(Tempyaer)
      console.log()
      temparray_no_year = temparray_no_year[0].split(" - ")
      this.state.start_datetime = temparray_no_year[0]
      this.state.end_datetime = temparray_no_year[1]
      this.setState({
        listWeekPayment: temparray,
        weekValue: `${moment(this.state.start_datetime).format("YYYY-MM-DD")} - ${moment(
          this.state.end_datetime
        ).format("YYYY-MM-DD")}`,
      })
    }
    ///เปลี่ยนปีแล้วส่งไปเลือกวันแล้วค่อยget DataPayment
    this.SelectDatePyment(`${this.state.start_datetime} - ${this.state.end_datetime} `)

    // this.getDataPayment();
  }

  async getDataPayment(page) {
    this._isMounted = true
    if (this._isMounted === true) {
      if (
        this.state.start_datetime !== "" ||
        this.state.end_datetime !== "" ||
        !this.state.textSearchPayment
      ) {
        if (
          this.state.start_datetime !== undefined ||
          this.state.end_datetime !== undefined ||
          this.state.textSearchPayment
        ) {
          // console.log(
          //   "🚀 --------------------------------------------------------------------------------------------------------------------------"
          // );
          // console.log(
          //   "🚀 ~ file: index.js ~ line 205 ~ OrderManagementPayment ~ getDataPayment ~ this.state.end_datetime",
          //   this.state.end_datetime
          // );
          // console.log(
          //   "🚀 --------------------------------------------------------------------------------------------------------------------------"
          // );

          const params = {
            start_date: this.state.start_datetime
              ? moment(this.state.start_datetime).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            end_date: this.state.end_datetime
              ? moment(this.state.end_datetime).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            textSearchPayment:
              this.state.checktext !== "clearSearch" ? this.state.textSearchPayment : " ",
            page: page ? page.page : 1,
          }
          console.log(
            "🚀 ----------------------------------------------------------------------------------------"
          )
          console.log("🚀 ~ Start Date - End Date", params)
          console.log(
            "🚀 ----------------------------------------------------------------------------------------"
          )
          this.setState({ loading: true, data: [] })
          if (params) {
            let limit = 5000
            try {
              // this.props.getOrderPaymentList({
              //   start_date: params.start_date,
              //   end_date: params.end_date,
              //   textSearchPayment: params.textSearchPayment,
              // })
              request
                .get(
                  `maid/payment/list?start_date=${params.start_date}&end_date=${params.end_date}&search=${params.textSearchPayment.trim()}&page=${params.page}&limit=${limit}`
                )
                .then((resp) => {
                  let data = resp.data
                  // console.log(
                  //   "🚀 -------------------------------------------------------------------------------------"
                  // );
                  console.log("🚀 ~ Data ตารางจาก Backend", resp.data)
                  // console.log(
                  //   "🚀 -------------------------------------------------------------------------------------"
                  // );
                  if (data.payment.length > 0) {
                    let dataPayment = []
                    for (let i = 0; i < data.payment.length; i++) {
                      if (data.payment[i].maid.real_maid_id && data.payment[i].salary) {
                        dataPayment.push({
                          picture: data.payment[i].maid.user.image_url,
                          App_ID: data.payment[i].maid.id,
                          Maid_ID: data.payment[i].maid.real_maid_id,
                          Name_maid:
                            data.payment[i].maid.user.first_name +
                            " " +
                            data.payment[i].maid.user.last_name,
                          id: data.payment[i].maid.id,
                          MP_Hours: data.payment[i].multi_package_hours,
                          OT_Hours: data.payment[i].one_time_hours,
                          total_day: data.payment[i].total_day,
                          Salary: data.payment[i].salary || 0,
                          Payment_date: data.payment[i].payment_datetime
                            ? new Date(data.payment[i].payment_datetime)
                            : null,
                          Status: data.payment[i].status,
                          isToggle:
                            data.payment[i].status === "UNPAID"
                              ? false
                              : data.payment[i].status === "PAID"
                              ? true
                              : false,
                          income: data.payment[i].income,
                          bonus_five_star: data.payment[i].bonus_five_star,
                          bonus_per_month: data.payment[i].bonus_per_month,
                          bonus_total_hours: data.payment[i].bonus_total_hours,
                          start_datetime: params.start_date,
                          end_datetime: params.end_date,
                        })
                      }
                    }
                    dataPayment.sort((a, b) => (a.Maid_ID > b.Maid_ID ? 1 : -1))
                    this.setState({
                      data: dataPayment,
                      totaldata: data.item_count,
                      pages: Math.ceil(data.item_count / limit),
                      loading: false,
                      unpaid: data.unpaid,
                      paid: data.paid,
                      checkedAllPaid: data.unpaid.count == 0,
                    })
                  } else {
                    this.setState({
                      data: [],
                      totaldata: 0,
                      pages: 0,
                      loading: false,
                      unpaid: data.unpaid,
                      paid: data.paid,
                      checkedAllPaid: data.unpaid.count == 0,
                    })
                  }
                })
            } catch (error) {
              console.log(error)
            }
          }
        }
      }
    }
  }

  formatSalary(num) {
    var val = (Number(num) * 100) / 100
    var parts = val.toString().split(".")
    var num1 =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1].substr(0, 2) : ".00")
    return num1
  }
  async SelectDatePyment(el) {
    this.setState({ weekValue: el })
    console.log("el", el)
    let temp = el.split(" - ")

    let temp1 = temp[0]

    let temp2 = temp[1]

    let tempParam = {
      startDate: temp1,
      endDate: temp2,
    }
    localStorage.setItem("PaymentDate", JSON.stringify(tempParam))
    this.setState(
      {
        start_datetime: new Date(temp1),
        end_datetime: new Date(temp2),
      },
      () => this.getDataPayment()
    )
  }

  onSenttextSearchPayment = () => {
    if (this.state.textSearchPayment !== "") {
      this.setState({ checktext: this.state.textSearchPayment } , () => this.getDataPayment())  
    } else if (this.state.textSearchPayment === "" && this.state.checktext !== "") {
      this.setState({ checktext: "clearSearch" } , () => this.getDataPayment())
    } else {
    this.getDataPayment()
    }
  }

  handleChangePaymentDate(e, row) {
    const data = this.state.data
    data.forEach((v, i) => {
      if (v.id === row.original.id) {
        v.Payment_date = e
      }
    })
    this.setState({ data })
    const objSalary = {
      salary: row.original.Salary,
      income: row.original.income !== undefined ? row.original.income : 0,
      bonus_five_star:
        row.original.bonus_five_star !== undefined ? row.original.bonus_five_star : 0,
      bonus_per_month:
        row.original.bonus_per_month !== undefined ? row.original.bonus_per_month : 0,
      bonus_total_hours:
        row.original.bonus_total_hours !== undefined ? row.original.bonus_total_hours : 0,
      start_date: this.state.start_datetime,
      end_date: this.state.end_datetime,
      tranfer_datetime: e,
    }
    if (objSalary) {
      request.post(`maid/id/${row.original.id}/paid/date`, objSalary)
    }
  }

  handleChangeStatus(row) {
    if (row.value === "PAID" && row.original.Payment_date) {
      swal({
        className: "swal-modal",
        title: "Are you sure?",
        text: `The affect of change “Paid” to “Unpaid” it’ll delete Payment Date/Time`,
        icon: "warning",
        buttons: {
          cancel: `No`,
          confirm: `Yes`,
        },
      }).then((willDelete) => {
        if (willDelete) {
          swal("deleted Success", {
            icon: "success",
          })
          this.onsendStatusPaidAndUnPaid(row)
        }
      })

      // change swal to confirm alert from browser

      // if (window.confirm("The affect of change “Paid” to “Unpaid” it’ll delete Payment Date/Time")) {
      //   this.onsendStatusPaidAndUnPaid(row)
      // }

    } else {
      this.onsendStatusPaidAndUnPaid(row)
    }
  }

  onsendStatusPaidAndUnPaid(row) {
    let tempIdx = 0
    const data = this.state.data
    data.forEach((v, i) => {
      if (v.id === row.original.id) {
        v.isToggle = !v.isToggle
        v.status = v.isToggle ? "PAID" : "UNPAID"
        v.Status = v.isToggle ? "PAID" : "UNPAID"
        v.Payment_date = ""
        tempIdx = i
      }
    })
    this.setState({
      data,
      checked: this.state.data[tempIdx].isToggle,
    })
    const objSalary = {
      salary: row.original.Salary,
      income: row.original.income !== undefined ? row.original.income : 0,
      bonus_five_star:
        row.original.bonus_five_star !== undefined ? row.original.bonus_five_star : 0,
      bonus_per_month:
        row.original.bonus_per_month !== undefined ? row.original.bonus_per_month : 0,
      bonus_total_hours:
        row.original.bonus_total_hours !== undefined ? row.original.bonus_total_hours : 0,
      start_date: this.state.start_datetime,
      end_date: this.state.end_datetime,
    }
    if (this.state.data[tempIdx].status === "PAID") {
      if (objSalary) {
        request.post(`maid/id/${row.original.id}/paid`, objSalary)
      }
    } else if (this.state.data[tempIdx].status === "UNPAID") {
      if (objSalary) {
        request.post(`maid/id/${row.original.id}/unpaid`, objSalary)
      }
    }
  }

  handleColumn = () => {
    this._isMounted = true
    if (this._isMounted) {
      this.setState({ showRightbar: true, mode: "column" })
    }
  }
  handleFilter = () => {
    this._isMounted = true
    if (this._isMounted) {
      this.setState({ showRightbar: true, mode: "filter" })
    }
  }

  onChangeTab = (path) => {
    const { history, match } = this.props
    const params = {
      start_datetime: this.state.start_datetime,
      end_datetime: this.state.end_datetime,
    }
    history.push(match.url + path, params)
  }

  handleToggle = (key) => {
    this._isMounted = true
    if (this._isMounted) {
      this.setState((prevState) => ({ [key]: !prevState[key] }))
      const { history } = this.props
      history.push(this.props.history.location.pathname)
    }
  }

  handleToggleCheckedAll(value) {
    this.setState({ confirmSwitchAllPaidModal: value })
  }

  async confirmSwitchAllToPaid() {
    try { 
      const data = this.state.data
        .filter((v) => {
          return v.Status === "PAID" && v.Payment_date == ""
        })
        .map((v) => {
          return v.id
        })
      const list = this.state.unpaid.list.filter((v) => !data.includes(v.maid_id))

      if(list.length == 0){
        this.setState({ confirmSwitchAllPaidModal: false })
        return
      }
      
      const res = await request.post("/maid/paid/all", {
        start_date: moment(this.state.start_datetime).format("YYYY-MM-DD"),
        end_date: moment(this.state.end_datetime).format("YYYY-MM-DD"),
        list: list
      })
      if (res) {
        this.getDataPayment()
        this.setState({ confirmSwitchAllPaidModal: false })
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  onExportData() {
    swal({
      className: "swal-modal",
      title: "Are you sure?",
      text: `Are you sure export data Payment `,
      buttons: ["Cancel", "Confirm"],
      defeat: true,
    }).then((willDelete) => {
      if (willDelete) {
        let sDate = moment(this.state.start_datetime).format("YYYY-MM-DD")
        let eDate = moment(this.state.end_datetime).format("YYYY-MM-DD")
        console.log("sDate", sDate, "eDate", eDate)
        request.get(`maid/payment/list/csv?start_date=${sDate}&end_date=${eDate}`).then((res) => {
          window.location.href = res.config.url
          swal("Export Success", {
            icon: "success",
          })
        })
      }
    })
  }
  compareBy(key, order) {
    return function (a, b) {
      // a[key] = a[key] === null || a[key] === undefined ? -Infinity : a[key];
      // b[key] = b[key] === null || b[key] === undefined ? -Infinity : b[key];

      // a[key] = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
      // b[key] = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];

      if (order) {
        if (a[key] < b[key]) {
          return 1
        }
        if (a[key] > b[key]) {
          return -1
        }
        return 0
      } else {
        if (a[key] > b[key]) {
          return 1
        }
        if (a[key] < b[key]) {
          return -1
        }
        return 0
      }
    }
  }

  togleSorting(newSorted, column, shiftKey) {
    let arrayCopy = [...this.state.data]
    arrayCopy.sort(this.compareBy(column.id, newSorted[0].desc))
    this.setState({ data: arrayCopy })
  }

  openProfileMaid(data) {
    const url = `#/admin/payment-management/orderManagementFilter/${data.id}/${data.start_datetime}/${data.end_datetime}`
    // const parmas = data;
    window.open(url)
  }

  render() {
    const allColumns = [
      {
        Header: "",
        accessor: "picture",
        Cell: (row) => (
          <div style={{ width: "100%" }}>
            <img
              src={`${row.original.picture}`}
              alt="profile3"
              className="rounded-circle"
              width={25}
            />
          </div>
        ),
        minWidth: 40,
      },
      {
        Header: () => <HeaderTable style={{ paddingLeft: "10px" }}>Maid ID</HeaderTable>,
        accessor: "Maid_ID",
        Cell: (row) => (
          <div style={{ textAlign: "left", paddingLeft: "10px" }}>
            {row.original.Maid_ID || "-"}
          </div>
        ),
        minWidth: 50,
      },
      {
        Header: () => <HeaderTable style={{ paddingLeft: "10px" }}> Name of maid </HeaderTable>,
        accessor: "Name_maid", // String-based value accessors!
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: "left", paddingLeft: "10px" }}>
            {row.original.Name_maid !== "-" ? row.original.Name_maid : "-"}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable> MP Hours </HeaderTable>,
        accessor: "MP_Hours",
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: "left" }}>
            {row.original.MP_Hours !== "-" ? row.original.MP_Hours : "-"}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable> OT Hours </HeaderTable>,
        accessor: "OT_Hours",
        minWidth: 100,
        Cell: (row) => (
          <div className="paymentSOTHours">
            {row.original.OT_Hours !== "-" ? row.original.OT_Hours : "-"}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable> # of Days </HeaderTable>,
        accessor: "total_day",
        minWidth: 100,
        Cell: (row) => (
          <div className="paymentSOTHours">
            {row.original.total_day !== "-" ? row.original.total_day : "-"}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable> Salary </HeaderTable>,
        accessor: "Salary",
        minWidth: 100,
        Cell: (row) => (
          <div className="paymentSalaryStyle">
            { this.formatSalary(row.original.Salary) }
          </div>
        ),
      },
      {
        Header: () => <HeaderTable> Payment date </HeaderTable>,
        accessor: "Payment_date",
        minWidth: 100,
        Cell: (row) => (
          <div className="ApproveTraining">
            <div style={{ zIndex: 99999 }}>
              {row.original.Status === "PAID" || row.original.status === "PAID" ? (
                <DatePicker
                  className="Rectangle-Datepicker-PymentDate"
                  value={row.original.Payment_date}
                  name="Payment_date"
                  selected={row.original.Payment_date}
                  onChange={(e) => this.handleChangePaymentDate(e, row)}
                  timeIntervals={15}
                  dateFormat=" yyyy-MM-dd "
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              ) : null}
            </div>
          </div>
        ),
      },
      {
        Header: () => <HeaderTable> Status </HeaderTable>,
        accessor: "Status",
        Cell: (row) => (
          <div className="paymentStatus">
            <label>
              <Toggle
                onChange={this.handleChangeStatus.bind(this, row)}
                checked={row.original.isToggle}
                value={row.original.Status}
                icons={{
                  checked: (
                    <div
                      style={{
                        display: "flex",
                        //  justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 12,
                        color: "#FFFFFF",
                        paddingRight: 2,
                      }}>
                      Paid
                    </div>
                  ),
                  unchecked: (
                    <div
                      style={{
                        display: "flex",
                        float: "right",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 12,
                        color: "#324755",
                        paddingRight: 2,
                      }}>
                      Unpaid
                    </div>
                  ),
                }}
              />
            </label>
          </div>
        ),
        minWidth: 100,
      },
    ]

    let columnWillShow = []
    this.state.column.map((colName) => {
      for (var obj of allColumns) {
        colName.isShow && obj.accessor === colName.accessor && columnWillShow.push(obj)
      }
      return {}
    })
    const tabData = [
      {
        label: "Payment",
        total: this.state.totaldata,
        key: "0",
        path: "/Payment",
      },
    ]
    const currentLocation = this.props.history.location.pathname
    return (
      <div>
        {this.state.showRightbar && <Overlay onClick={() => this.handleToggle("showRightbar")} />}
        <div className="col-md-12" style={{ paddingBottom: "10px" }}>
          <div className="row">
            <div className="col-md-3 btn-container" style={{ paddingTop: "40px" }}>
              <Button
                style={{ fontSize: "11px", width: "125px", height: "40px" }}
                className="btn"
                label="Column setting"
                onClick={() => this.handleColumn()}
              />
              <Button
                style={{ fontSize: "11px", width: "125px", height: "40px" }}
                className="btn"
                label="Filter by"
                onClick={() => this.handleFilter()}
              />
              <Button
                style={{
                  float: "right",
                  fontSize: "11px",
                  width: "125px",
                  height: "40px",
                }}
                className="btn"
                label="Export"
                onClick={() => this.onExportData()}
              />
            </div>
            <div className="col-md-3">
              <div className="col-md-12">
                <label style={{ fontSize: "18px", fontWeight: "500px" }}>Year</label>
              </div>
              <div className="col-md-12" style={{ paddingTop: "10px" }}>
                <select
                  className="Rectangle-Datepicker-OrderPymentR"
                  style={{ width: "200px", textAlign: "center" }}
                  id="yaerValue"
                  value={this.state.yaerValue}
                  onChange={(e) => this.handleChangeYear(e.target.value)}>
                  {this.state.yearList.length > 0 ? (
                    this.state.yearList.map((el, i) => {
                      return (
                        <option key={i} value={el}>
                          {el}
                        </option>
                      )
                    })
                  ) : (
                    <option></option>
                  )}
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="col-md-12">
                <label style={{ fontSize: "18px", fontWeight: "500px" }}>Date</label>
              </div>
              <div className="col-md-12 form-group " style={{ paddingTop: "10px" }}>
                <select
                  className="Rectangle-Datepicker-OrderPymentR"
                  id="yaerWeek"
                  style={{ width: "200px" }}
                  value={this.state.weekValue}
                  onChange={(e) => this.SelectDatePyment(e.target.value)}>
                  {this.state.listWeekPayment.length > 0 ? (
                    this.state.listWeekPayment.map((el, i) => {
                      return (
                        <option key={i} value={el}>
                          {el}
                        </option>
                      )
                    })
                  ) : (
                    <option></option>
                  )}
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="col-md-12">
                <label></label>{" "}
              </div>
              <div className="searchPyementRow">
                <input
                  id="searchText"
                  type="text"
                  className="inPut-search-textPayment"
                  placeholder="Search.."
                  name="textSearchPayment"
                  value={this.state.textSearchPayment}
                  onChange={(e) => this.setState({ textSearchPayment: e.target.value })}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      this.onSenttextSearchPayment()
                    }
                  }}
                />
                <button
                  id="searchTextmaid"
                  type="submit"
                  className="search-containe-button"
                  onClick={this.onSenttextSearchPayment}>
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="" style={{ paddingLeft: "25px" }}>
              Total amount: {this.formatSalary(this.state.unpaid.total + this.state.paid.total)}
            </div>
            <div className="" style={{ paddingLeft: "25px" }}>
              Total maid: {this.state.unpaid.count + this.state.paid.count}
            </div>
            <div className="" style={{ paddingLeft: "25px" }}>
              Swich all to paid
              &nbsp;
              <input
                disabled={this.state.loading || this.state.unpaid.count == 0}
                type="checkbox"
                checked={this.state.checkedAllPaid}
                onChange={() => this.handleToggleCheckedAll(true)}
                ></input>
            </div>
          </div>
        </div>
        <TabComponent transparent tabData={tabData} onChangeTab={this.onChangeTab} />
        <Card>
          {this.state.data.length >= 0 ? (
            <div onScroll={this.onScroll} style={{ width: "100%" }}>
              <ReactTable
                data={
                  this.state.tempdate.length > this.state.data.length
                    ? this.state.tempdate
                    : this.state.data
                }
                columns={columnWillShow}
                loading={this.state.loading}
                pageSize={25}
                page={this.state.page}
                showPagination={true}
                PaginationComponent={Pagination}
                pages={this.state.pages}
                manual
                onSortedChange={(newSorted, column, shiftKey) =>
                  this.togleSorting(newSorted, column, shiftKey)
                }
                onPageChange={(page) =>
                  this.setState({ page: page }, () => {
                    this.getDataPayment({ page: page + 1 })
                  })
                }
                getTrGroupProps={(state, rowInfo) => {
                  if (rowInfo !== undefined) {
                    return {
                      onDoubleClick: () => {
                        this.openProfileMaid(rowInfo.original)
                      },
                    }
                  }
                }}
                getTrProps={(state, rowInfo) => {
                  return {
                    style: {
                      height: 27.5,
                      overflow: "hidden",
                    },
                  }
                }}
                className="-striped -highlight paymentLsittetable"
              />
            </div>
          ) : (
            <ReactTable
              data={this.state.data}
              columns={columnWillShow}
              defaultPageSize={25}
              showPagination={false}
              className=""
            />
          )}
        </Card>
        <Rightbar data={this.state} currentLocation={currentLocation} />
        {this.props.children}
        <CustomModal isOpen={this.state.confirmSwitchAllPaidModal} customStyle={customModalStyle}>
            <Container>
              <Header>
                Do you confirm to switch from all unpaid status to paid ?
              </Header>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{ margin: "0 10px" }}
                  onClick={() => this.handleToggleCheckedAll(false)}
                  label="Cancel"
                  light
                />
                <Button
                  style={{ margin: "0 10px" }}
                  onClick={() => this.confirmSwitchAllToPaid()}
                  type="submit"
                  label="Confirm"
                />
              </div>
            </Container>
        </CustomModal>
      </div>
    )
  }
}

const mapState = (state) => ({
  start_dateOrderPayment: state.orderManagement.start_dateOrderPayment,
  end_dateOrderPayment: state.orderManagement.end_dateOrderPayment,
  customers: state.customer.customers,
  columnOrderManagementPayment: state.orderManagement.columnOrderManagementPayment,
  totalpagePaymentlist: state.orderManagement.totalpagePaymentlist,
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    setOrderMode: dispatch.orderManagement.setOrderMode,
    getOrderPaymentList: dispatch.orderManagement.getOrderPaymentList,
  }
}

export default connect(mapState, mapDispatch)(OrderManagementPayment)
