

export const SideBarMenu = [
  // {
  //   key: '1',
  //   label: 'User',
  //   icon: 'icon fas fa-user',
  //   // subMenu: [
  //   //   // {
  //   //   //   key: '1',
  //   //   //   label: 'Admin Management',
  //   //   //   link: '/admin-management',
  //   //   // },
  //   //   {
  //   //     key: '1',
  //   //     label: 'Customer List UI',
  //   //     link: '/customer-management',
  //   //   },
  //   // ],
  // },
  {
    key: "1",
    label: "Customer List",
    icon: "icon fas fa-user",
    subMenu: [
      {
       key: '1',
       label: 'Customer List 1',
       link: '/customer-management',
     },
     {
       key: '2',
       label: 'Customer List 2',
       link: '/customer-management/2',
     },
   ],
  },
  {
    key: "2",
    label: "Maid",
    icon: "icon fas fa-user",
    subMenu: [
      {
        key: "1",
        label: "Maid List",
        link: "/maid-management",
      },
      {
        key: "11",
        label: "Radius List",
        link: "/maid-management/radius",
      },
      {
        key: "12",
        label: "Monthly List",
        link: "/maid-management/monthly"
      },
      {
        key: "13",
        label: "Check who got OTA",
        link: "/maid-management/check-ota"
      },
      {
        key: "2",
        label: "Approve List",
        link: "/Approve-management",
      },
      {
        key: "3",
        label: "Payment List",
        link: "/payment-management",
      },
      {
        key: "4",
        label: "Dummy Work",
        link: "/dummy-management",
      },
    ],
  },
  {
    key: "3",
    label: "Booking",
    icon: "icon fas fa-user",
    subMenu: [
       {
        key: '1',
        label: 'Booking List 1',
        link: '/order-management',
      },
      {
        key: '2',
        label: 'Booking List 2',
        link: '/order-management/list/2',
      },
    ],
  },
  {
    key: "4",
    label: "PAYMENT",
    icon: "icon fas fa-comment-alt",
    link: "/order-management",
    // url:"https://prod-admin-bluuu-vue.web.app"
    url:true
  },
  {
    key: "5",
    label: "Inventories ",
    icon: "icon fas fa-user",
    subMenu: [
      {
        key: "1",
        label: "Calendar Inventory UI",
        link: "/inventories-management",
      },
    ],
  },

  // {
  //   key: '3',
  //   label: 'Order Management',
  //   link: '/order-management',
  // },
  {
    key: "6",
    label: "Chat Box",
    icon: "icon fas fa-comment-alt",
    link: "/chatbox",
  },

  {
    key: "7",
    label: "Content",
    icon: "icon far fa-edit",
    subMenu: [
      // {
      //   key: '1',
      //   label: 'Content',
      //   link: '/content',
      // },
      // {
      //   key: '2',
      //   label: 'Polls & Forms',
      //   link: '/polls-forms',
      // },
      {
        key: "3",
        label: "Broadcasting",
        link: "/broadcasting",
      },
      {
        key: "4",
        label: "Promotion",
        link: "/promotion",
      },
    ],
  },

  {
    key: "8",
    label: "Analysis",
    icon: "icon fas fa-chart-line",
    subMenu: [
      {
        key: "1",
        label: "Dashboard",
        link: "/dashboard",
      },
      // {
      //   key: '2',
      //   label: 'Dashboard_Old',
      //   link: '/dashboard0',
      // },
      // {
      //   key: '2',
      //   label: 'Database',
      //   link: '/database',
      // },
    ],
  },
  {
    key: "9",
    label: "Group setting",
    icon: "icon fas fa-cog",
    subMenu: [
      // {
      //   key: '1',
      //   label: 'Profile Setting',
      //   link: '/profile-setting',
      // },
      // {
      //   key: '2',
      //   label: 'Roles Setting',
      //   link: '/roles-setting',
      // },
      {
        key: "3",
        label: "Group Setting",
        link: "/group-setting",
      },
    ],
  },

  {
    key: "10",
    label: "Log out",
    icon: "icon fas fa-sign-out-alt",
    link: "/login?logout=true",
  },
];
