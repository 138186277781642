import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ErrorBoundary from '../errorBoundary'
import {post} from '../../utils/service'

const Container = styled.div`
  width: 150px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;

  .link {
    cursor: pointer;
  }
`

const Item = styled.div`
  padding: 10px 0 10px 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  img {
    width: 20px;
    margin-right: 14px;
  }

  :hover {
    background-color: #f3f3f3;
  }
`
const RoleMaidClass = [
  'Edit',
  'View',
  // 'Duplicate',
  'Remove'
]

class EditStatusBroadcas extends Component {
    constructor(props){
        super(props);
        this.state={
            checkId : this.props.TypeStatus,
            setModal:'',
            isSettingModalShow: false,
            isExportModalShow: false,
        }
    }
    componentDidMount(){
        // console.log(this.props)
    }

 closeModal = () => {
      this.setState({ isShow: false })
  }

 async handleClick(text) {    
  this.props.checkModeModal(text)
  this.props.checkTypeImage(this.props.Typeview)
  this.props.setTextModal(this.props.dataText)
    if(text === 'Remove'){
      const { props } = this.props
      if(this.props.checkMode==='Announcement'){
        if(this.state.checkId){
          await post({},`admin/broadcast/announcement/id/${this.state.checkId}/delete`).then(res=>{
              if(!res.error){
               props.history.replace({
                 pathname: '/admin/broadcasting/list/broadcastSent',
                 state:"delete"
                  })
              }
            })
        }
      }else if(this.props.checkMode==='InAppMessage'){
        if(this.state.checkId){
          await post({},`admin/broadcast/inappmessage/id/${this.state.checkId}/delete`).then(res=>{
              if(!res.error){
                    props.history.replace({
                    pathname: '/admin/broadcasting/list/broadcastSent',
                    state:"delete"
                 })
              }
            })
        }
      } 
    }else if(text === 'Edit'){
      const { props } = this.props
      let dataTemp= {
              id:this.state.checkId,
              mode: text,
              BroadcastMode:this.props.checkMode
      }
      if(this.props.checkMode==='Announcement'){
        // props.history.push(`/admin/broadcasting/announceTemplate`,dataTemp)
      props.history.replace({
          pathname: '/admin/broadcasting/announceTemplate',
          state: dataTemp
        })
      }else if(this.props.checkMode==='InAppMessage'){
        // props.history.push(`/admin/broadcasting/broadcastTemplate`,dataTemp)
        props.history.replace({
          pathname: '/admin/broadcasting/broadcastTemplate',
          state: dataTemp
        })
      } 
    }
  }

  render() {
    const {TypeStatus } = this.props
    const FilterMaidClassMenu = RoleMaidClass.filter((Allrole) => Allrole !== TypeStatus)
    return (
      <div>
        <div>
        <ErrorBoundary>
        <Container>
          {TypeStatus &&
            FilterMaidClassMenu.map((menu, index) => (
              <Item key={index} onClick={() => this.handleClick(menu)}>
                {menu}
              </Item>
            ))}
         
        </Container>
       
      </ErrorBoundary>
        </div>
        {this.props.children}
     </div>
      

    )
  }
}
const mapState = () => ({})

const mapDispatch = (dispatch) => {
  return {
    checkModeModal: dispatch.broadcasting.checkModeModal,
    checkTypeImage:dispatch.broadcasting.checkTypeImage,
    setTextModal:dispatch.broadcasting.setTextModal
  }
}
export default connect(mapState, mapDispatch)(EditStatusBroadcas)
