import React, { Component } from 'react'
import { connect } from 'react-redux'

class PromotionLayout extends Component {


  render() {


    return (
      <div>
        <div className="page-breadcrumb">
          {this.props.children}
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({})

const mapDispatch = (dispatch) => {
  return {}
}
export default connect(
  mapState,
  mapDispatch
)(PromotionLayout)
