import React, { Component } from 'react'
import { connect } from 'react-redux'

import Button from '../../components/button'
import TabComponent from './../../components/tabComponent'

class PromotionLayout extends Component {
  constructor() {
    super()
    this.state = {
      totalIN: [],
      totalAC: 0,
      totalEX: 0,
    }
  }

  componentDidMount() {
    this.props.setPageTitle('Promotion List')
    this.props.getDataInActive()
    this.props.getDataActive()
    this.props.getDataExActive()
    this.props.showBackIcon(false)
  }

  onChangeTab = (path) => {
    const { history, match } = this.props
    history.push(match.url + path)
  }
  onChangeTabDetail = (path) => {
    const { history } = this.props
    history.push('/admin/promotion' + path)
    this.props.setCheckTypePath(false)
  }
  render() {
    const tabData = [
      {
        label: 'Inactive',
        total: this.props.dataPromotionInac,
        key: '0',
        path: '/promotionInactive',
      },
      {
        label: 'Actived',
        total: this.props.dataPromotionAC,
        key: '1',
        path: '/promotionActived',
      },
      {
        label: 'Expired',
        total: this.props.dataPromotionEx,
        key: '2',
        path: '/promotionExpired',
      },
    ]

    return (
      <div>
        <div>
          <div className="btn-container">
            <Button
              className="btn"
              label="Add new"
              onClick={() => this.onChangeTabDetail('/promotionAdd')}
            />
          </div>
          <TabComponent transparent tabData={tabData} onChangeTab={this.onChangeTab} />
        </div>
        {this.props.children}
      </div>
    )
  }
}

const mapState = (state) => ({
  dataPromotionInac: state.promotion.dataPromotionInac,
  dataPromotionAC: state.promotion.dataPromotionAC,
  dataPromotionEx: state.promotion.dataPromotionEx,
  columnPromotion: state.promotion.columnPromotion,
})

const mapDispatch = (dispatch) => {
  return {
    setCheckTypePath: dispatch.promotion.setCheckTypePath,
    setPageTitle: dispatch.Title.setPageTitle,
    // setinactive: dispatch.promotion.setinactive,
    getDataInActive: dispatch.promotion.getInacList,
    getDataActive: dispatch.promotion.getAcList,
    getDataExActive: dispatch.promotion.getExpiredList,
    showBackIcon: dispatch.Title.showBackIcon,
  }
}
export default connect(mapState, mapDispatch)(PromotionLayout)
