import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ErrorBoundary from '../errorBoundary'
import {post} from '../../utils/service'

const Container = styled.div`
  width: 150px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;

  .link {
    cursor: pointer;
  }
`

const Item = styled.div`
  padding: 10px 0 10px 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  img {
    width: 20px;
    margin-right: 14px;
  }

  :hover {
    background-color: #f3f3f3;
  }
`
const RoleMaidClass = [
  'Edit',
  'Remove'
]

class EditStatusPromoiton extends Component {

componentDidMount(){ 
    // console.log(this.props)
 } 

async  handleClick(text) {
    // console.log(`handleClick : ${text}`)
    if(text === 'Remove'){
      if(this.props.PromotionID){
        this.props.checkUpdateData('Update')
        await post({},`admin/promotion/id/${this.props.PromotionID}/delete`).then(res=>{
            if(!res.error){
            // window.location.reload(true)
            this.props.checkUpdateData('Update')
            }
          })
      }
    }else if(text === 'Edit'){
      const { props } = this.props
      let dataTemp= {
              id:this.props.PromotionID,
              mode: text,
      }
      if(dataTemp){
        // props.history.push(`/admin/promotion/promotionAdd`,dataTemp)
        props.history.replace({
          pathname: '/admin/promotion/promotionAdd',
          state: dataTemp
        })
      }
    }
  }

  render() {
    // const { data, id, handleOpenModal } = this.props
    const {TypeStatus } = this.props
    // console.log(`data :${JSON.stringify(this.props)}`)
    // {/* <Item onClick={() => handleOpenModal(id)}> */}
    const FilterMaidClassMenu = RoleMaidClass.filter((Allrole) => Allrole !== TypeStatus)
    return (
      <ErrorBoundary>
        <Container>
          {TypeStatus &&
            FilterMaidClassMenu.map((menu, index) => (
              <Item key={index} onClick={() => this.handleClick(menu)}>
                {menu}
              </Item>
            ))}
        </Container>
      </ErrorBoundary>
    )
  }
}
const mapState = () => ({})

const mapDispatch = (dispatch) => {
  return {
    checkUpdateData: dispatch.promotion.checkUpdateData,
  }
}
export default connect(mapState, mapDispatch)(EditStatusPromoiton)
