import React, { Component } from 'react'
// import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class MemberManagementLayout extends Component {
  state = {
    showRightbar: false,
    mode: 'column',
  }

  componentDidMount() {
    this.props.setPageTitle(window.location.href.includes("/2/customer2") ? "User List 2" : "User List")
    this.props.showBackIcon(false)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.setPageTitle(window.location.href.includes("/2/customer2") ? "User List 2" : "User List")
      this.props.showBackIcon(false)
    }
  }


  render() {
    

    return (
       <div>
        <div className="page-breadcrumb">{this.props.children}</div>
      </div>
    )
  }
}

const mapState = (state) => ({

})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    showBackIcon: dispatch.Title.showBackIcon,
  }
}

export default connect(
  mapState,
  mapDispatch
)(withRouter(MemberManagementLayout))

