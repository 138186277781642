import React, { Component } from 'react'
import { connect } from 'react-redux'

import Button from './../../components/button'
import TabComponent from './../../components/tabComponent'

const tabData = [
  {
    label: 'Published',
    total: 5,
    key: '0',
  },
  {
    label: 'Draft',
    total: 4,
    key: '1',
  },
]

class ContentManagementLayout extends Component {
  componentDidMount() {
    this.props.setPageTitle('Content List')
  }

  render() {
    return (
      <div>
        <div className="page-breadcrumb">
          <div className="btn-container">
            <Button className="btn" label="Add new content" />
            <Button className="btn" label="Filter by" />
          </div>
          <TabComponent transparent style={{}} tabData={tabData} onChangeTab={null} />
          {this.props.children}
        </div>
      </div>
    )
  }
}

const mapState = () => ({})

const mapDispatch = (dispatch) => {
  return { setPageTitle: dispatch.Title.setPageTitle }
}

export default connect(mapState, mapDispatch)(ContentManagementLayout)
