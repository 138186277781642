import React, { Component, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import ErrorBoundary from "../errorBoundary"
import CustomModal from "../customModal"
import Button from "../../components/button"
import CheckBox from "../../components/checkbox"

import Cookies from "universal-cookie"
import { post } from "../../utils/service"
import request from "../../utils/request"

// import img from '../../../images/popup-submit-288-px.svg'
// import CancelIcon from '../../images/ic-cancelbtn-gray-24-px.svg'
// import { Highlight } from '../../components/styledComponents'
import {
  customModalStyle,
  // mobileModalStyle
} from "./customActualModal"
import moment from "moment"
// import { Desktop, Mobile } from '../../utils/displayResponsive'

// const imgDesktop = {
//   width: '530px',
//   height: '230px',
//   objectFit: 'cover',
// }

const cookies = new Cookies()

const Container = styled.div`
  padding: 20px;
`
const Section = styled.div`
  height: 450px;
  overflow: auto;
`

const Header = styled.div`
  text-align: justify;
  font-size: 30px;
  font-weight: bold;

  // margin-bottom: 40px;
`

const SectionText = styled.h4`
  padding: 10px 20px 0px;
  // width: 100%;
`
// const Text = styled.div`
//   font-size: 14px;
// `

const Row = styled.div`
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  // width: 100%;
  flex: 1;
`

// <Text>{text}</Text> <Text>[ ]</Text>

// const ExportOptions = (index, label, onChange, checked) => {
//   return (
//     <Row>
//       <CheckBox  id={index} label={label} onChange={onChange} checked={checked} />
//     </Row>
//   )
// }

class LoginModal extends Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      showOtp: false,
      email: "",
      password: "",
      otp: "",
    }
  }

  componentDidMount() {
    console.log(this.props)
    let initialState = {}
    this.setState(initialState)
  }
  closeModal = () => {
    this.setState(
      {
        showOtp: false,
        email: "",
        password: "",
        otp: "",
      }
    )
    console.log("closeModal")
    this.props.closeModal(false)
  }

  onSubmit = () => {
    console.log("onSubmit")
  }

  onToggle = (key, index) => {
    console.log(`index: ${JSON.stringify(index)}`)
    this.setState((prevState) => ({ [key]: !prevState[key] }))
  }

  // login = async () => {
  //   console.log("login", this.state.email, this.state.password)
  //   console.log(this.props)

  //   if (!this.props.user_id) {
  //     alert("No User ID")
  //     return
  //   }

  //   let result
  //   try {
  //     result = await post(
  //       {
  //         email: this.state.email,
  //         password: this.state.password,
  //       },
  //       `/authentication/email/login`,
  //       false
  //     )

  //     if (result.error) {
  //       console.log(result);
  //       throw new Error(result)
  //     }
  //     this.setState({ showOtp: true })
  //   } catch (err) {
  //     alert("Incorrect Email or Password")
  //     return
  //   }
  // }

  // confirmOtp = async () => {
  //   if (!this.props.user_id) {
  //     alert("No User ID")
  //     return
  //   }
  //   try {
  //     const result = await request
  //       .post(`customer/id/${this.props.user_id}/oauth/token`, {
  //         email: this.state.email,
  //         otp: this.state.otp,
  //       },
  //       false)
  //       .then((res) => res.data)
  //       .catch((err) => {
  //         console.log(err)
  //         alert("OTP Incorrect or Expired")
  //         return
  //       })

  //     if (result && result.access_token) {
  //       let origin
  //       switch (window.location.hostname) {
  //         case "admin.bluuu.co":
  //           origin = "bluuu.co"
  //           break
  //         case "uat-admin.bluuu.co":
  //           origin = "uat.bluuu.co"
  //           break
  //         case "localhost":
  //           origin = "localhost:8080"
  //           break
  //         default:
  //           alert("Invalid Hostname")
  //           return
  //       }
  //       const admin_token = localStorage.getItem("accessToken")
  //       window.open(
  //         `https://${origin}/en/admin?access_token=${result.access_token}&expires_in=${result.expires_in}&refresh_token=${result.refresh_token}&admin_token=${admin_token}`
  //       )

  //       this.closeModal()
  //       this.setState({ showOtp: false })
  //     }
  //   } catch (err) {
  //     console.log(err)
  //     return
  //   }
  // }

  confirmLogin = async () => {
    if (!this.props.user_id) {
      alert("No User ID")
      return
    }
    try {
      const result = await request
        .post(`customer/id/${this.props.user_id}/oauth/token`, {
          email: this.state.email,
          password: this.state.password,
        },
        false)
        .then((res) => res.data)
        .catch((err) => {
          console.log(err)
          alert("Incorrect Email or Password")
          return
        })

      if (result && result.access_token) {
        let origin
        switch (window.location.hostname) {
          case "admin.bluuu.co":
            origin = "bluuu.co"
            break
          case "uat-admin.bluuu.co":
            origin = "uat.bluuu.co"
            break
          case "localhost":
            origin = "localhost:8080"
            break
          default:
            alert("Invalid Hostname")
            return
        }
        const admin_token = localStorage.getItem("accessToken")
        window.open(
          `https://${origin}/en/admin?access_token=${result.access_token}&expires_in=${result.expires_in}&refresh_token=${result.refresh_token}&admin_token=${admin_token}`
        )

        this.closeModal()
        this.setState({ showOtp: false })
      }
    } catch (err) {
      console.log(err)
      return
    }
  }

  render() {
    // const img = 'https://res.cloudinary.com/teepublic/image/private/s--F0FUtuXp--/t_Preview/b_rgb:191919,c_limit,f_jpg,h_630,q_90,w_630/v1527190543/production/designs/2720834_0.jpg'
    // const { img, name, school, point, description } = this.props.data
    // console.log(`exportModal state: ${JSON.stringify(this.state)}`)
    const showOtp = this.state.showOtp
    return (
      <ErrorBoundary>
        <CustomModal isOpen={this.props.isOpen} customStyle={customModalStyle}>
          {false ? (
            <Container>
              <Header>OTP</Header>
              <input
                className="form-control"
                placeholder="otp"
                value={this.state.otp}
                onChange={(e) => this.setState({ otp: e.target.value })}
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{ margin: "0 10px" }}
                  onClick={() => this.setState({ showOtp: false })}
                  label="Back"
                  light
                />
                <Button
                  style={{ margin: "0 10px" }}
                  onClick={() => this.confirmOtp()}
                  type="submit"
                  label="Confirm"
                />
              </div>
            </Container>
          ) : (
            <Container>
              <Header>Please Login</Header>
              <input
                className="form-control"
                placeholder="email"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <input
                className="form-control"
                type="password"
                placeholder="password"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{ margin: "0 10px" }}
                  onClick={() => this.props.closeModal()}
                  label="Cancel"
                  light
                />
                <Button
                  style={{ margin: "0 10px" }}
                  onClick={() => this.confirmLogin()}
                  type="submit"
                  label="Login"
                />
              </div>
            </Container>
          )}
        </CustomModal>
      </ErrorBoundary>
    )
  }
}

LoginModal.propstype = {
  closeModal: PropTypes.func.isRequired,
}

export default LoginModal
