export const Title = {
  state: {
    isBack: false,
    pageTitle: '',
  },
  reducers: {
    setPageTitle(state, payload) {
      return {
        ...state,
        pageTitle: payload,
      }
    },
    showBackIcon(state, payload) {
      return {
        ...state,
        isBack: payload,
      }
    },
  },
}