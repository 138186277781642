import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Grid from '../../components/grid/loadable'
// import GraphCard from '../../components/graphCard/index'
// import SaleGarp from '../../components/graphCard/saleGrap'
// import SaleGraphPT from '../../components/graphCard/saleGraphPT'
// import SaleGarphUF from '../../components/graphCard/saleGraphUF'
// import AppointmentDT from '../../components/graphCard/appointmentsDT'
import CohortCard from '../../components/graphCohortCard'
import styled from 'styled-components'
// import ovi01_1 from './ovi01Data'
import moment from 'moment'

const DashboardOverallPage = (props) => {
  const Title = styled.h3`
  margin: 10px 0px 20px 10px;
`

  const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  // justify-content: center;
  // align-items:center;
`
  const graph = {
    title1: 'Sales by product category',
    title2: 'Sales by locations',
    title3: 'Sales by product type',
    title4: 'Number of new login users and Number of new service users',
    title5: 'Retention rate by user life cycle (cohort analysis table) by booking appointment data, NOT payment',
    titleUF: 'Users by user funnel',
    titleDT: ' Appointments by days and times',
  }
  ///////////// set data ////////////////////
  const [SaleCategory, setSaleCategory] = useState()
  const [SaleLocation, setSaleLocation] = useState()
  const [SalePStype, setSalePStype] = useState()
  const [SaleUSF, setSaleUSF] = useState()
  const [AppointDT, setAppointDT] = useState()
  //////////// set date //////////////////////
  const [start_datetime, setStart_datetime] = useState()
  const [end_datetime, setEnd_datetime] = useState()
  const [start_datetimeLo, setStart_datetimeLo] = useState()
  const [end_datetimeLo, setEnd_datetimeLo] = useState()
  const [start_datetimePT, setStart_datetimePT] = useState()
  const [end_datetimePT, setEnd_datetimePT] = useState()
  const [start_datetimeUF, setStart_datetimeUF] = useState()
  const [end_datetimeUF, setEnd_datetimeUF] = useState()
  const [start_datetimeDT, setStart_datetimeDT] = useState()
  const [end_datetimeDT, setEnd_datetimeDT] = useState()


  // useEffect(() => {
  //   props.setPageTitle('Dash Board')
  //   let temp = ovi01_1
  //   let temp_date = new Date()
  //   let temp_enDate = new Date()
  //   setStart_datetime(temp_date)
  //   setEnd_datetime(temp_enDate)
  //   setStart_datetimeLo(temp_date)
  //   setEnd_datetimeLo(temp_enDate)
  //   setStart_datetimePT(temp_date)
  //   setEnd_datetimePT(temp_enDate)
  //   setStart_datetimeUF(temp_date)
  //   setEnd_datetimeUF(temp_enDate)
  //   setStart_datetimeDT(temp_date)
  //   setEnd_datetimeDT(temp_enDate)
  //   ////set data/////
  //   setSaleCategory(temp)
  //   setSaleLocation(temp)
  //   setSalePStype(temp)
  //   setSaleUSF(temp)
  //   setAppointDT(temp)
  //   ////////
  // }, []);


  /////////// sale category //////////
  // useEffect(() => {
  //   const setRewardSaleCate = async (SaleCategory) => {
  //     let temp = { SaleCategory }
  //     temp.series = await props.dataSaleProduct
  //     temp.title = null
  //     setSaleCategory(temp)
  //   }
  //   setRewardSaleCate()
  // }, [props.dataSaleProduct])
  ///////// sale Location //////////
  // useEffect(() => {
  //   const setRewardSalePDL = async (SaleLocation) => {
  //     let temp = { SaleLocation }
  //     temp.series = await props.dataSaleProductLo
  //     temp.title = null
  //     setSaleLocation(temp)
  //   }
  //   setRewardSalePDL()
  // }, [props.dataSaleProductLo])

  /////////// sale Produc type //////////
  // useEffect(() => {
  //   const setRewardSalePT = async (SalePStype) => {
  //     let temp = { SalePStype }
  //     temp.series = await props.dataPStyeList
  //     temp.title = null
  //     setSalePStype(temp)
  //   }
  //   setRewardSalePT()
  // }, [props.dataPStyeList])

  /////////// User by user funnel //////////
  // useEffect(() => {
  //   const setRewardSaleUF = async (SalePStype) => {
  //     let temp = { SalePStype }
  //     temp.series = await props.dataUserUFList
  //     temp.title = null
  //     setSaleUSF(temp)
  //   }
  //   setRewardSaleUF()
  // }, [props.dataUserUFList])

  /////////// User by date time //////////
  // useEffect(() => {
  //   const setRewardDT = async (AppointDT) => {
  //     let temp = { AppointDT }
  //     temp.series = await props.dataUserDTList
  //     temp.title = null
  //     setAppointDT(temp)
  //   }
  //   setRewardDT()
  // }, [props.dataUserDTList])



  ////////////////////////////////
  // const setDatetime_GrapSale = async (e) => {
  //   setStart_datetime(e.start_datetime)
  //   if (end_datetime.getTime() < e.start_datetime.getTime()) {
  //     setEnd_datetime(e.start_datetime)
  //   } else {
  //     setEnd_datetime(e.end_datetime)
  //   }

  //   if (start_datetime && end_datetime) {
  //     props.FaceGrapSale({ stateDate: moment(start_datetime).format('YYYY-MM-DD'), endDate: moment(end_datetime).format('YYYY-MM-DD') })
  //   }
  // }

  // const setDatetime_BYLo = async (e) => {
  //   setStart_datetimeLo(e.start_datetime)
  //   if (end_datetime.getTime() < e.start_datetime.getTime()) {
  //     setEnd_datetimeLo(e.start_datetime)
  //   } else {
  //     setEnd_datetimeLo(e.end_datetime)
  //   }
  //   if (start_datetimeLo && end_datetimeLo) {
  //     props.FaceGrapSaleBylocation({ stateDate: moment(start_datetimeLo).format('YYYY-MM-DD'), endDate: moment(end_datetimeLo).format('YYYY-MM-DD') })

  //   }
  // }

  // const setDatetime_BYPT = async (e) => {
  //   setStart_datetimePT(e.start_datetime)
  //   if (end_datetime.getTime() < e.start_datetime.getTime()) {
  //     setEnd_datetimePT(e.start_datetime)
  //   } else {
  //     setEnd_datetimePT(e.end_datetime)
  //   }
  //   props.FaceGraphPStype({ stateDate: moment(start_datetimePT).format('YYYY-MM-DD'), endDate: moment(end_datetimePT).format('YYYY-MM-DD') })

  // }

  // const setDatetime_BYUF = async (e) => {
  //   setStart_datetimeUF(e.start_datetime)
  //   if (end_datetime.getTime() < e.start_datetime.getTime()) {
  //     setEnd_datetimeUF(e.start_datetime)
  //   } else {
  //     setEnd_datetimeUF(e.end_datetime)
  //   }
  //   props.FaceGraphUF({ stateDate: moment(start_datetimeUF).format('YYYY-MM-DD'), endDate: moment(end_datetimeUF).format('YYYY-MM-DD') })
  // }

  // const setStartDate = (e) => {
  //   setStart_datetimeDT(e.start_datetime)
  //   if (end_datetime.getTime() < e.start_datetime.getTime()) {
  //     setEnd_datetimeDT(e.start_datetime)
  //   } else {
  //     setEnd_datetimeDT(e.end_datetime)
  //   }
  //   props.FaceGraphDT({ stateDate: moment(start_datetimeDT).format('YYYY-MM-DD'), endDate: moment(end_datetimeDT).format('YYYY-MM-DD') })

  // }
  ///////////////////////////




  return (
    <div>
      <div className="container-fluid">
        <MainContainer>
          {/* <Title>Bussiness Section</Title>
          <Grid gap="15px" width="450px">
            <GraphCard title={graph.title1} data={SaleCategory} date={{ start_datetime, end_datetime }} setDatetime_GrapSale={setDatetime_GrapSale} />
            <SaleGarp title={graph.title2} data={SaleLocation} date={{ start_datetimeLo, end_datetimeLo }} setDatetime_BYLo={setDatetime_BYLo} />
          </Grid>
          <Grid gap="15px" width="450px">
            <SaleGraphPT title={graph.title3} data={SalePStype} date={{ start_datetimePT, end_datetimePT }} setDatetime_BYPT={setDatetime_BYPT} />
            <SaleGarphUF title={graph.titleUF} data={SaleUSF} date={{ start_datetimeUF, end_datetimeUF }} setDatetime_BYUF={setDatetime_BYUF} />
          </Grid>
          <Grid gap="15px" width="450px">
            <AppointmentDT title={graph.titleDT} data={AppointDT} date={{ start_datetimeDT, end_datetimeDT }} setStartDate={setStartDate} />
          </Grid> */}
          <Title>User Engagement Section</Title>
          <Grid gap="15px" width="900px">
            {/* <GraphCard title={graph.title4} data={ovi0506_1} date={{start_datetime,end_datetime}} setStartDate={setStartDate} setEnDate={setEnDate} /> */}
            <CohortCard title={graph.title5} />
          </Grid>
          {/* <GraphCard title={graph.title5} data={hcData} /> */}
        </MainContainer>
      </div>
      DashboardOverallPage
        </div>
  )
}

const mapState = (state) => {
  return {
    tempSetData: state.dashoard.tempSetData,
    dataSaleProduct: state.dashoard.dataSaleProduct,
    dateforGraph: state.dashoard.dateforGraph,
    dataSaleProductLo: state.dashoard.dataSaleProductLo,
    dataPStyeList: state.dashoard.dataPStyeList,
    dataUserUFList: state.dashoard.dataUserUFList,
    dataUserDTList: state.dashoard.dataUserDTList
  }
}

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    FaceGrapSale: dispatch.dashoard.FaceGrapSale,
    setDateforGraph: dispatch.dashoard.setDateforGraph,
    FaceGrapSaleBylocation: dispatch.dashoard.FaceGrapSaleBylocation,
    FaceGraphPStype: dispatch.dashoard.FaceGraphPStype,
    FaceGraphUF: dispatch.dashoard.FaceGraphUF,
    FaceGraphDT: dispatch.dashoard.FaceGraphDT,
    tempSetDataArr: dispatch.dashoard.tempSetDataArr

  }
}

export default connect(
  mapState,
  mapDispatch
)(DashboardOverallPage)
