import React, { Component } from 'react'
import { connect } from 'react-redux'

import Button from '../../components/button'

import Graph from '../../components/graph'

import Image1 from '../../images/location1.png'
import Image2 from '../../images/location2.png'
import Image3 from '../../images/location3.png'
import Image4 from '../../images/location4.png'

class DashboardPage extends Component {
  componentDidMount() {
    this.props.setPageTitle('Dashboard')
  }

  render() {
    return (
      <div>
        <div className="page-breadcrumb m-b-10">
          <div className="btn-container">
            <Button className="btn" label="Select to Export" />
            <Button className="btn" label="Setting" />
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-md-8 h-100">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center">
                    <div className="align-self-start">
                      <h4 className="card-title">Product Sales</h4>
                      <h5 className="card-subtitle">Overview of Latest Month</h5>
                    </div>
                    <Graph type="bar" width="80%" height="225px" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-4 h-100">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Location</h4>
                  <div className="d-flex flex-md-grow-1 card-location">
                    <div className="d-grid g-col-100 align-self-md-center">
                      <img
                        src={Image1}
                        alt="loc1"
                        className="img-thumbnail d-grid-item"
                        width="100"
                      />
                      <img
                        src={Image2}
                        alt="loc2"
                        className="img-thumbnail d-grid-item"
                        width="100"
                      />
                      <img
                        src={Image3}
                        alt="loc3"
                        className="img-thumbnail d-grid-item"
                        width="100"
                      />
                      <img
                        src={Image4}
                        alt="loc4"
                        className="img-thumbnail d-grid-item"
                        width="100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 ">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Employee</h4>
                  <div className="table-responsive">
                    <table className="table v-middle">
                      <thead>
                        <tr className="bg-light">
                          <th className="border-top-0">Products</th>
                          <th className="border-top-0">License</th>
                          <th className="border-top-0">Support Agent</th>
                          <th className="border-top-0">Technology</th>
                          <th className="border-top-0">Tickets</th>
                          <th className="border-top-0">Sales</th>
                          <th className="border-top-0">Earnings</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="m-r-10">
                                <div className="btn btn-circle btn-danger text-white">EA</div>
                              </div>
                              <div >
                                <h4 className="m-b-0 font-16">Elite Admin</h4>
                              </div>
                            </div>
                          </td>
                          <td>Single Use</td>
                          <td>John Doe</td>
                          <td>
                            <label className="label label-danger">Angular</label>
                          </td>
                          <td>46</td>
                          <td>356</td>
                          <td>
                            <h5 className="m-b-0">$2850.06</h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="m-r-10">
                                <div className="btn btn-circle btn-info text-white">MA</div>
                              </div>
                              <div >
                                <h4 className="m-b-0 font-16">Monster Admin</h4>
                              </div>
                            </div>
                          </td>
                          <td>Single Use</td>
                          <td>Venessa Fern</td>
                          <td>
                            <label className="label label-info">Vue Js</label>
                          </td>
                          <td>46</td>
                          <td>356</td>
                          <td>
                            <h5 className="m-b-0">$2850.06</h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="m-r-10">
                                <div className="btn btn-circle btn-success text-white">MP</div>
                              </div>
                              <div >
                                <h4 className="m-b-0 font-16">Material Pro Admin</h4>
                              </div>
                            </div>
                          </td>
                          <td>Single Use</td>
                          <td>John Doe</td>
                          <td>
                            <label className="label label-success">Bootstrap</label>
                          </td>
                          <td>46</td>
                          <td>356</td>
                          <td>
                            <h5 className="m-b-0">$2850.06</h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="m-r-10">
                                <div className="btn btn-circle btn-purple text-white">AA</div>
                              </div>
                              <div >
                                <h4 className="m-b-0 font-16">Ample Admin</h4>
                              </div>
                            </div>
                          </td>
                          <td>Single Use</td>
                          <td>John Doe</td>
                          <td>
                            <label className="label label-purple">React</label>
                          </td>
                          <td>46</td>
                          <td>356</td>
                          <td>
                            <h5 className="m-b-0">$2850.06</h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = () => ({})

const mapDispatch = (dispatch) => {
  return { setPageTitle: dispatch.Title.setPageTitle }
}

export default connect(
  mapState,
  mapDispatch
)(DashboardPage)
