import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '../../components/button'
import { post, get } from '../../utils/service'
import styled from 'styled-components'
import moment from 'moment'
import Switch from "react-switch";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import imgTypePop from '../../images/Group8451.png'
import imgTypeTop from '../../images/Group8450.png'
import imgTypeButton from '../../images/Group8449.png'

const InpuntHeadTemp = styled.input`
  padding: 16px 16px 16px 30px;
    width: 687.3px;
    height: 60px;
    border: solid 1px #324755;
    background-color: #ffffff;
    font-size: 30px;
    margin-top: 20px;
    margin-left: 18px;
  ::placeholder,
  ::-webkit-input-placeholder {
     color: #324755;
  }
  :-ms-input-placeholder {
      color: #324755;
  }
`

const InpuntHeadTemp1 = styled.input`
  padding: 0px 0px 35px 30px;
  width: 687.3px;
  height: 70px;
  border: solid 1px #324755;
  background-color: #ffffff;
  margin-bottom: 10px;
  margin-left: 18px;
  ::placeholder,
  ::-webkit-input-placeholder {
    #324755;
  }
  :-ms-input-placeholder {
     #324755;
  }
`
class AnnounTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publish_now: '',
      checked: false,
      typeAnnouncement: '',
      templateName: '',
      senderName: '',
      start_datetime: new Date(),
      end_datetime: new Date(),
      currenDate: new Date(),
      textAnnouncement: [],
      colourOptions: [
        { value: 1, label: 'Customer' },
        { value: 2, label: 'Maid' },
      ],
      selected_users: null,
      checkGroup: '',
      SetColumsMaild: [],
      SetColumsCustomer: [],
      setting_group_id: '',
      checkSetText: [],
      isClearable: true,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
      userChecklGoback: []
    }
    this.handleChangePublish = this.handleChangePublish.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.state !== undefined) {
      if (prevProps.location.state.id !== this.props.location.state.id) {
        this.FindById()
        this.props.showBackIcon(true)
      }
    }
  }
  componentDidMount() {
    this.props.setPageTitle('Add new Announcement template')
    this.props.showBackIcon(true)
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.mode !== undefined || this.props.location.state.temp !== undefined) {
        this.FindById()
      }
    }

  }
  async FindById() {
    this.state.userChecklGoback.push({
      text: this.props.checkStatustemp.status,
      index: this.props.checkStatustemp.index,
      id: this.props.checkStatustemp.id
    })
    if (this.props.location.state.mode === 'Edit') {
      await get(`admin/broadcast/announcement/id/${this.props.location.state.id}`).then(res => {
        this.setState({
          //  end_datetime: res.end_datetime,
          //  start_datetime:res.start_datetime,
          templateName: res.name,
          publish_now: res.publish_now,
          senderName: res.sender,
          checked: res.publish_now,
          start_datetime: new Date(moment(res.start_datetime).format()),
          end_datetime: new Date(moment(res.end_datetime).format()),
          text: res.text,
          setting_group_id: res.setting_group_id,
          textAnnouncement: res.text,
          typeAnnouncement: res.type,
        }, () => this.handleChangeSelected('', this.state.setting_group_id))

      })
    } else if (this.props.location.state.temp === 'template') {

      await get(`admin/broadcast/announcement/template/id/${this.props.location.state.id}`).then(res => {
        this.setState({
          templateName: res.name,
          senderName: res.sender,
          text: res.text,
          publish_now: false,
          textAnnouncement: res.text,
          setting_group_id: res.setting_group_id,
          checked: false,
          typeAnnouncement: res.type

        }, () => this.handleChangeSelected('', this.state.setting_group_id))
        //    this.state.userChecklGoback.forEach(v => {
        //     if(res.name === v.text && res.id === v.id){
        //       this.props.checkStatusTemp({status:v.text,index:v.index})
        //     }
        //  });
      })
    }

  }
  handleChangeStartDate = e => {
    this.setState({ start_datetime: e })
  }
  handleChangeEndDate = e => {
    this.setState({ end_datetime: e })
  }

  async handleChangePublish(checked) {
    this.setState({ publish_now: '' })
    this.setState({ checked });
    if (checked !== false) {
      await this.setState({ publish_now: checked })
    }
  }
  setTextGroup(el, i) {
    this.setState({ textAnnouncement: this.state.textAnnouncement + "" + el })
  }


  handleChangeSelected = (e, setting_group_id) => {
    if (e !== null) {
      this.setState({ checkGroup: e.label })
      if (e.label === 'Customer') {
        this.setState({
          selected_users: 'Customer',
          SetColumsCustomer: this.props.Customercolumn.filter(el => el.label !== 'Picture'),
          SetColumsMaild: [],
          setting_group_id: 1
        })
      } else if (e.label === 'Maid') {
        this.setState({
          selected_users: 'Maid',
          SetColumsMaild: this.props.maidcolumn.filter(el => el.label !== 'Picture'),
          SetColumsCustomer: [],
          setting_group_id: 2
        })
      }
    } if (setting_group_id === 1 || setting_group_id === 2) {
      if (setting_group_id === 1) {
        this.setState({
          selected_users: 'Customer',
          SetColumsCustomer: this.props.Customercolumn.filter(el => el.label !== 'Picture'),
          SetColumsMaild: [],
          setting_group_id: 1

        })
        this.setValueSelect()
      } else if (setting_group_id === 2) {
        this.setState({
          selected_users: 'Maid',
          SetColumsMaild: this.props.maidcolumn.filter(el => el.label !== 'Picture'),
          SetColumsCustomer: [],
          setting_group_id: 2
        })
        this.setValueSelect()
      }
    } else {
      this.setState({ checkGroup: '' })
    }

  }
  setValueSelect() {
    if (this.state.setting_group_id >= 0) {
      return this.state.setting_group_id - 1
    } else {
      return null
    }
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  async onSubmit() {
    let mode = ''
    let idUpdate = ''
    if (this.props.location.state !== undefined) {
      mode = this.props.location.state.mode
      idUpdate = this.props.location.state.id
    }
    let { objPublish } = this.state
    objPublish = {
      name: this.state.templateName,
      sender: this.state.senderName,
      text: this.state.textAnnouncement,
      type: this.state.typeAnnouncement,
      publish_now: this.state.publish_now,
      start_datetime: moment(this.state.start_datetime).format('YYYY-MM-DD h:mm:ss'),
      end_datetime: moment(this.state.end_datetime).format('YYYY-MM-DD h:mm:ss'),
      setting_group_id: this.state.setting_group_id
    }
    if (objPublish) {
      const { history } = this.props
      const url = '/admin/broadcasting/list/broadcastSent'
      if (mode === 'Edit') {
        if (this.state.publish_now === true) {
          await post(objPublish, `admin/broadcast/announcement/id/${idUpdate}/update`).then(res => {
            if (!res.error) {
              setTimeout(() => { history.push(url) }, 100);
            }
          })
        }
      } else {
        if (this.state.publish_now === true) {
          await post(objPublish, `admin/broadcast/announcement/add`).then(res => {
            if (!res.error) {
              setTimeout(() => { history.push(url) }, 100);
            }
          })
        }
      }
    }
  }
  async onSubmitTemplate() {
    let mode = ''
    let idUpdate = ''
    if (this.props.location.state !== undefined) {
      mode = this.props.location.state.modeSetup
      idUpdate = this.props.location.state.id
    }
    let { obj, } = this.state
    obj = {
      name: this.state.templateName,
      sender: this.state.senderName,
      text: this.state.textAnnouncement,
      type: this.state.typeAnnouncement,
      setting_group_id: this.state.setting_group_id
    }
    if (obj) {
      const { history } = this.props
      const url = '/admin/broadcasting/list/broadcastSent'
      if (mode === 'Edit') {
        await post(obj, `admin/broadcast/announcement/template/id/${idUpdate}/update`).then(res => {
          if (!res.error) {
            setTimeout(() => { history.push(url) }, 100);
          }
        })
      } else {
        await post(obj, `admin/broadcast/announcement/template/add`).then(res => {
          if (!res.error) {
            setTimeout(() => { history.push(url) }, 100);
          }
        })
      }
    }
  }


  render() {
    const { publish_now, typeAnnouncement, templateName, senderName, start_datetime, end_datetime, colourOptions } = this.state
    return (
      <div style={{ justifyContent: "center", display: "flex" }} className="col-sm-6 col-xs-8 col-md-9 col-xl-10">
        <div  >
          <div className="Rectangle-710-broadtem" >
            <InpuntHeadTemp type="text" name="templateName" value={templateName} placeholder="Template’s name" onChange={this.handleChange} />
            <InpuntHeadTemp1 type="text" name="senderName" value={senderName} placeholder="Sender’s name" onChange={this.handleChange} />
            <div className="row">
              <div className="col-xs-12">
                {/* /////////////// */}
                <div className="col-xs-12">
                  <div className="col-md-12">
                    <div className="row" style={{ paddingBottom: "10px" }}>
                      <div className="col-md-4">
                        <label style={{ paddingLeft: "25px", paddingTop: "10px" }}>Group of Users :</label>
                      </div>
                      <div className="col-md-7" style={{ paddingLeft: "25px" }}>
                        <Select
                          value={colourOptions[this.setValueSelect()]}
                          name="selected_users"
                          options={colourOptions}
                          onChange={this.handleChangeSelected}
                          className="Rectangle-31-AnnounceTemp"
                          classNamePrefix="select"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* /////////////// */}
                <div className="col-xs-12">
                  {

                    this.state.checkGroup !== ' ' ?
                      <div className="row" style={{ paddingBottom: "10px", paddingLeft: "45px" }}  >
                        {this.state.SetColumsMaild.map((el, i) =>
                          <div className="col-xs-3" style={{ marginRight: "2px" }} key={i}>
                            <input type="button" className="Rectangle-726-broadtem" value={el.label} onClick={() => this.setTextGroup(`{${el.label}}`, i)} />
                          </div>
                        )}
                        {this.state.SetColumsCustomer.map((el, i) =>
                          <div className="col-xs-3" style={{ marginRight: "2px" }} key={i}>
                            <input type="button" className="Rectangle-726-broadtem" value={el.label} onClick={() => this.setTextGroup(`{${el.label}}`, i)} />
                          </div>
                        )}
                      </div>
                      :
                      <div className="row" style={{ paddingBottom: "10px", paddingLeft: "45px" }}  >
                        {this.state.SetColumsMaild.map((el, i) =>
                          <div className="col-xs-3" style={{ marginRight: "2px" }} key={i}>
                            <input type="button" className="Rectangle-726-broadtem" value={el.label} onClick={() => this.setTextGroup(el.label, i)} />
                          </div>
                        )}
                        {this.state.SetColumsCustomer.map((el, i) =>
                          <div className="col-xs-3" style={{ marginRight: "2px" }} key={i}>
                            <input type="button" className="Rectangle-726-broadtem" value={el.label} onClick={() => this.setTextGroup(el.label, i)} />
                          </div>
                        )}
                      </div>
                  }
                </div>
              </div>
            </div>
            <textarea className="Rectangle-659-broadtem" value={this.state.textAnnouncement} onChange={(e) => this.setState({ textAnnouncement: e.target.value })} />
            {/* <div className="row" style={{ padding: "20px"}}> */}
            {/* <textarea value={this.state.textAnnouncement} onChange={(e)=>this.setState({textAnnouncement:e.target.value})} /> */}
            {/* <div className="col-xs-3" ></div>
                </div>
            </div> */}
            <div className="row" style={{ padding: "28px", fontSize: "20px", lineHeight: "1.2px" }}>
              <div className="col-xs-12" ><label>Please choose the position to show your announcement</label></div>
            </div>
            <div className="row" style={{ paddingLeft: "18px", fontSize: "14px", lineHeight: "1.2px" }}>
              <div className="col-md-4 radio">
                <label>
                  <input type="radio" name="POPUP" value="POPUP" id="001" checked={typeAnnouncement === 'POPUP'} onChange={(e) => this.setState({ typeAnnouncement: e.target.value })} />
                  Popup announcement
                    </label>
              </div>
              <div className="col-md-4 radio">
                <label>
                  <input style={{ marginLeft: "-7px" }} type="radio" name="TOP" value="TOP" id="002" checked={typeAnnouncement === 'TOP'} onChange={(e) => this.setState({ typeAnnouncement: e.target.value })} />
                  Top announcement
                    </label>
              </div>
              <div className="col-md-4 radio">
                <label>
                  <input style={{ marginLeft: "-14px" }} type="radio" name="BOTTOM" value="BOTTOM" id="003" checked={typeAnnouncement === 'BOTTOM'} onChange={(e) => this.setState({ typeAnnouncement: e.target.value })} />
                  Bottom announcement
                    </label>
              </div>
            </div>
            <div className="col-md-12" style={{ display: "flex", paddingLeft: "17px", paddingBottom: '15px' }}>
              <div className="col-xs-4" style={{ width: "215px" }}>
                <img src={imgTypePop} alt="Popup announcement" />
              </div>
              <div className="col-xs-4" style={{ marginLeft: "20px", width: "215px" }}>
                <img src={imgTypeTop} alt="Top announcement" />
              </div>
              <div className="col-xs-4" style={{ marginLeft: "20px", width: "215px" }}>
                <img src={imgTypeButton} alt="Bottom announcement" />

              </div>
            </div>
            <div className="row footer Rectangle-739-broadtem">
              <div className="col-md-6">
                <span>ฉันต้องการจะตั้งค่าเพื่อทำการประกาศตอนนี้</span>
                <p>Anyone can search and see this name card</p>
              </div>
              <div className="col-md-6">
                <div style={{ float: 'right' }}> <Switch onChange={this.handleChangePublish} value={publish_now} checked={this.state.checked} /> </div>
              </div>
            </div>
            {
              this.state.publish_now === true ?
                <div style={{ paddingTop: '10px' }}>
                  <div className="col-md-12">
                    <div className="row" style={{ paddingBottom: "10px" }}>
                      <div className="col-md-4">
                        <label style={{ paddingLeft: "25px", paddingTop: "5px" }}>Start Date To Announce :</label>
                      </div>
                      <div className="col-md-8">
                        <DatePicker className="Rectangle-Datepicker-promotion"
                          value={start_datetime}
                          showTimeSelect
                          name="start_datetime"
                          selected={start_datetime}
                          minDate={this.state.currenDate}
                          onChange={this.handleChangeStartDate}
                          timeFormat="p"
                          timeIntervals={15}
                          dateFormat=" yyyy-MM-dd h:mm aa"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row" style={{ paddingBottom: "10px" }}>
                      <div className="col-md-4">
                        <label style={{ paddingLeft: "25px", paddingTop: "5px" }}>End Date To Announce :</label>
                      </div>
                      <div className="col-md-8">
                        <DatePicker className="Rectangle-Datepicker-promotion"
                          value={end_datetime}
                          showTimeSelect
                          name="end_datetime"
                          selected={end_datetime}
                          minDate={start_datetime}
                          onChange={this.handleChangeEndDate}
                          timeFormat="p"
                          timeIntervals={15}
                          dateFormat=" yyyy-MM-dd h:mm aa"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ paddingBottom: '10px' }}>
                    <div className="row" style={{ paddingBottom: "10px" }}>
                      <div className="col-md-4" />
                      <div className="col-md-8">
                        <Button style={{ width: "228.3px", float: "right", top: "10px", marginRight: "7px" }}
                          focus
                          className="btn"
                          label="Send this Message"
                          onClick={() => this.onSubmit()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className="col-md-12" style={{ paddingBottom: '10px' }}>
                  <div className="row" style={{ paddingBottom: "10px" }}>
                    <div className="col-md-4" />
                    <div className="col-md-8">
                      <Button style={{ width: "228.3px", float: "right", top: "10px", marginRight: "7px" }}
                        focus
                        className="btn"
                        label="Save this Template"
                        onClick={() => this.onSubmitTemplate()}
                      />
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>

      </div>
    )
  }
}

const mapState = (state) => ({
  maidcolumn: state.maid.column,
  Customercolumn: state.customer.column,
  checkStatustemp: state.broadcasting.checkStatustemp
})
const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    showBackIcon: dispatch.Title.showBackIcon,
    checkStateForTemp: dispatch.broadcasting.checkStateForTemp,
    checkStatusTemp: dispatch.broadcasting.checkStatusTemp
  }

}

export default connect(
  mapState,
  mapDispatch
)(AnnounTemplate)

