import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ErrorBoundary from '../errorBoundary'
import CustomModal from '../customModal'
// import Button from '../../components/button'
import Switch from 'react-switch'
import imgButton from '../../images/01_home__accout_free_.png'
import imgPupup from '../../images/pupupBroadcast.png'
import imgTop from '../../images/pupupBrocastTop.png'
import imgHeaderTop from '../../images/headerBrocast.png'
import {customModalStyle,} from './modalViewstyle'
import './modalOrder.css'

const Container = styled.div`
  padding: 20px;
`
const Section = styled.div`
  overflow: auto;
`

// const Header = styled.div`
//   text-align: justify;
//   font-size: 30px;
//   font-weight: bold;

//   // margin-bottom: 40px;
// `

const SectionText = styled.h4`
  padding: 10px 20px 0px;
  // width: 100%;
`

const Text = styled.div`
  font-size: 14px;
`

const Row = styled.div`
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  // width: 100%;
  flex: 1;
`

const ToggleSwitch = ({ onChange, checked }) => {
  return (
    <Switch
      onChange={onChange}
      checked={checked}
      onColor="#6E8CA0"
      onHandleColor="#324755"
      offColor="#dbdbdb"
      offHandleColor="#f1f1f1"
      handleDiameter={20}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={14}
      width={34}
      className="react-switch"
      id="material-switch"
    />
  )
}

class ModalView extends Component {
  static propTypes = {}

  state = {}

  componentDidMount() {
    // console.log(this.props)
  }

  closeModal = () => {
    console.log('closeModal')
    this.props.closeModal(false)
  }

  onCallBack = () => {
    console.log('onCallBack')
    this.props.onCallBack()
  }

  onToggle = (key) => {
    this.setState((prevState) => ({ [key]: !prevState[key] }))
  }

  setOptions = (text, key, keyState) => {
    return (
      <Row key={key}>
        <Text>{text}</Text>
        <ToggleSwitch onChange={() => this.onToggle(key)} checked={keyState} />
      </Row>
    )
  }
  
  createOptions = (inputArray, section) => {
    let items = []
    inputArray.map((obj) => {
      if (obj.section === section) {
        obj.content.map((objkey) =>
          items.push(this.setOptions(objkey.name, objkey.keyName, this.state[objkey.keyName]))
        )
      }
      return null
    })
    return items
  }

  render() {

    return (
      <ErrorBoundary>
        <CustomModal isOpen={this.props.isOpen}  customStyle={customModalStyle}>
          <Container>
            {/* <Header>Dashboard Setting</Header> */}
            <button  onClick={() => this.props.closeModal()} className="close">
              ×
            </button>
            <Section style={{textAlign:'center'}}>
             <SectionText>Preview Web</SectionText>
             
               {
                   this.props.checkTypeView==='POPUP'?
                   <div className="container">
                     <img  style={{width:"520px"}} src={imgPupup} alt="test" />
                    <div className="centered">{this.props.dataText}</div>
                   </div>
                    :
                   this.props.checkTypeView==='TOP'?
                   <div className="container">
                     <img  style={{width:"520px"}} src={imgTop} alt="test" />
                     <img className="headerTop" src={imgHeaderTop}alt="test" />
                    <div className="bottom-Top">{this.props.dataText}</div>
                   </div>
                   
                   :
                   this.props.checkTypeView==='BOTTOM'?
                   <div className="container">
                     <img  style={{width:"520px"}} src={imgButton} alt="test" />
                    <div className="bottom-left">{this.props.dataText}</div>
                   </div>
                  : null
                   
               } 
            </Section>
            {/* <Button
              style={{ position: 'absolute', bottom: '20px', right: '20px' }}
              onClick={() => this.props.closeModal()}
              label="OK"
            /> */}
          </Container>
        </CustomModal>
      </ErrorBoundary>
    )
  }
}

ModalView.propstype = {
  closeModal: PropTypes.func.isRequired,
  onCallBack: PropTypes.func.isRequired,
}

export default ModalView
