import React, { Component } from 'react'
import styled from 'styled-components'

import Chart from 'react-apexcharts'
// import ApexCharts from "apexcharts";

const Container = styled.div`
  // display:flex;
  flex:1;
  // background: #fff;
  // position: absolute;
  // top: 64px;
  // left: 250px;
  height : 100%;
  width: 100%;

  @media (max-width: 768px) {
    left: 0px;
  }
`
const GraphContainer = styled.div`
  // background: #d3e7ee;
  // position: relative;
  padding: 20px 0 0 0;
  // width: 1fr;
`

class Graph extends Component {
  state = {
    options: {
      chart: {
        id: 'apexchart-example',
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
      },

      colors: ['#fea735', '#0077ff', '#fe7235'],
      // colors: ['#235d3a', '#397d54', '#73c088', '#a8e0b7', '#c8ead1']
      // colors: ['#F44336', '#E91E63', '#9C27B0'],
      // fill: {
      //   colors: ['#F44336', '#E91E63', '#9C27B0'],
      // },
      // dataLabels: {
      //   style: {
      //     colors: ['#F44336', '#E91E63', '#9C27B0']
      //   }
      // }
      // markers: {
      //   colors: ['#F44336', '#E91E63', '#9C27B0'],
      // },
      // grid: {
      //   row: {
      //     colors: ['#F44336', '#E91E63', '#9C27B0']
      //   },
      //   column: {
      //     colors: ['#F44336', '#E91E63', '#9C27B0']
      //   }
      // }
    },
    series: [
      {
        name: 'sales',
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
      },
      {
        name: 'supplies',
        data: [50, 60, 85, 90, 129, 150, 160, 191, 210],
      },
    ],
  }

  render() {
    // const types = [
    //   'line',
    //   'area',
    //   'bar',
    //   'pie',
    //   'donut',
    //   'scatter',
    //   'bubble',
    //   'heatmap',
    //   'radialBar',
    //   'radar',
    //   'histogram',
    //   'candlestick',
    // ]
    const { type, width, height } = this.props
    return (
      <Container>
        <GraphContainer>
          <Chart
            options={this.state.options}
            series={this.state.series}
            type={type}
            width={width}
            height={height}
            colors={this.state.colors}
          />
        </GraphContainer>
      </Container>
    )
  }
}

export default Graph
