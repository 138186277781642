import React from "react"
import styled from "styled-components"
import { GetApiURL } from "../../utils/request"
import swal from "sweetalert2"
import { post } from "../../utils/service"
import "./index.css"
const DummyWorkPage = () => {
  const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `
  const Button = styled.button`
    margin-bottom: 20px;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 10%;
    padding-bottom: 10%;
    border-radius: 10px;
    background: #6e8ca0;
    color: white;
    border: none;
    &:hover {
      background-color: white;
      border: 1px solid black;
      color: black;
    }
  `

  const SendOtHandle = async () => {
    const res = await post({}, "/dummy/onetime/send")
    console.log(": -----------------------")
    console.log("SendOtHandle -> res", res)
    console.log(": -----------------------")
    res.error
      ? swal.fire({
          title: "ERROR!",
          text: `Unable to sent Dummy Work of OT to maids`,
          showCloseButton: true,
          buttons: {
            confirm: `Ok`,
          },
        })
      : swal.fire({
          title: "SUCCESSFUL!",
          text: `All Dummy Work of OT already sent to ${res.no_of_approved_maids} approved maids`,
          showCloseButton: true,
          buttons: {
            confirm: `Ok`,
          },
        })
  }

  const SendMpHandle = async () => {
    const res = await post({}, "/dummy/multipackage/send")
    console.log(": -----------------------")
    console.log("SendMpHandle -> res", res)
    console.log(": -----------------------")
    res.error
      ? swal.fire({
          title: "ERROR!",
          text: `Unable to sent Dummy Work of MP to maids`,
          showCloseButton: true,
          buttons: {
            confirm: `Ok`,
          },
        })
      : swal.fire({
          title: "SUCCESSFUL!",
          text: `All Dummy Work of MP already sent to ${res.no_of_approved_maids} approved maids`,
          showCloseButton: true,
          buttons: {
            confirm: `Ok`,
          },
        })
  }

  const DeleteHandle = async () => {
    const res = await post({}, "/dummy/delete")
    console.log(": -----------------------")
    console.log("DeleteHandle -> res", res)
    console.log(": -----------------------")
    res.error
      ? swal.fire({
          title: "ERROR!",
          text: `Unable to deleted All Dummy Work from maids`,
          showCloseButton: true,
          buttons: {
            confirm: `Ok`,
          },
        })
      : swal.fire({
          title: "DELETED!",
          text: `All Dummy Work already deleted from maids`,
          showCloseButton: true,
          buttons: {
            confirm: `Ok`,
          },
        })
  }

  return (
    <div>
      <ButtonContainer>
        <Button onClick={SendOtHandle}>Send OT to all Approve Maids</Button>
        <Button onClick={SendMpHandle}>Send MP to all Approve Maids</Button>
        <Button onClick={DeleteHandle}>Delete all Dummy Work</Button>
      </ButtonContainer>
    </div>
  )
}

export default DummyWorkPage
