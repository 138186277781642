import React, { Component } from "react"
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { connect } from "react-redux"

// No Dependency Trial
import styled from "styled-components"

import DragIcon from "../../images/drag.svg"
import MinusIcon from "../../images/minus.svg"
import PlusIcon from "../../images/plus.svg"
import BackIcon from "../../images/back.svg"

const MainContainer = styled.div`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

const CardContainer = styled.div`
  background: ${(props) => props.theme.rightCard};
  border: 1px solid ${(props) => props.theme.active};
  width: 100%;
  height: 44px;
  padding: 10px 20px;

  display: flex;
  justify-content: space-between;
  img {
    cursor: pointer;
  }
`

const Container = styled.div`
  display: flex;
  .hand {
    width: max-content;
    height: 100%;
    cursor: pointer;
  }
`
const Text = styled.div`
  margin-left: 15px;
`

class CollumSettingApprove extends Component {
  constructor(props) {
    super(props)
    this.state = {
      column: this.props.customerColumn,
      itemStatus: ["PENDING", "APPROVED", "DISAPPROVED", "BANNED", "PUBLISHED"],
      statusList: this.props.statusApproveFilter,
      statusCheckBox: this.props.statusApproveCheckBox,
    }
    this.onDragEnd = this.onDragEnd.bind(this)
    this.handleToggleShow = this.handleToggleShow.bind(this)
  }

  // state = {
  //   column: this.props.customerColumn,
  // }
  componentDidMount() {
    this.replaceValueAndFilterStatusFilter()
    // this.replaceValueAndFilterRepeatRateListFilter()
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.customerColumn !== this.props.customerColumn) {
    //   // console.log('update',this.props)
    //   this.setState({ column: this.props.customerColumn })
    // }
    if (prevProps.mode !== this.props.mode) {
      if (this.props.mode === "column") {
        this.setState({ column: this.props.customerColumn })
      } else {
        this.setState({
          column: this.props.columnOrderManagementApproveFilter,
          fillter: 0,
          index: null,
        })
      }
    }
  }

  handleToggleShow(value) {
    let newColumn = this.state.column
    const result = this.state.column.map((obj, index) => {
      if (obj.accessor === value) {
        const editObj = { ...obj, isShow: !obj.isShow }

        newColumn[index] = editObj
        return newColumn
      }
      return newColumn
    })
    this.setState({ column: result[0] })
    this.props.setCustomerColumnList(newColumn)
  }

  replaceValueAndFilterStatusFilter = () => {
    if (localStorage.statusApproveCheckBox) {
      let local_checkbox = JSON.parse(localStorage.statusApproveCheckBox)
      this.props.statusApproveCheckBoxDis(local_checkbox)
      this.setState({ statusCheckBox: local_checkbox }, () => {
        let x = this.state.itemStatus
        let tempArr = this.state.statusList

        let arry = x.map((e, i) => {
          if (!this.state.statusCheckBox[i].checked) {
            console.log(
              "eeee : checkbox mounted",
              this.state.statusCheckBox[i].checked,
              x[i],
              tempArr
            )
            const index = tempArr.findIndex((o) => o === e.toLowerCase())
            if (index > -1) {
              tempArr.splice(index, 1)
              this.setState({ statusList: tempArr }, () => this.filterdataList())
              this.props.setStatusApproveFilter(tempArr)
            } else {
              tempArr.push(e.toLowerCase())
              this.setState({ statusList: tempArr }, () => this.filterdataList())
              this.props.setStatusApproveFilter(tempArr)
            }
          }
        })
      })
    }
  }

  // No Dependency Trial
  onDragStart = (e, index) => {
    this.draggedItem = this.state.column[index]
    e.dataTransfer.effectAllowed = "move"
    e.dataTransfer.setData("text/html", e.target.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20)
  }

  onDragOver = (index) => {
    const draggedOverItem = this.state.column[index]

    // if the item is dragged over itself, ignore
    if (this.draggedItem === draggedOverItem) {
      return
    }

    // filter out the currently dragged item
    let items = this.state.column.filter((item) => item !== this.draggedItem)

    // add the dragged item after the dragged over item
    items.splice(index, 0, this.draggedItem)

    this.setState({ column: items })
    this.props.setCustomerColumnList(items)
  }

  onDragEnd = () => {
    this.draggedIdx = null
  }

  onfillter = (index) => {
    var v = document.getElementById(`imgFillterApprove${index}`)
    if (this.state.index !== index) {
      if (this.state.index !== null) {
        var temp = document.getElementById(`imgFillterApprove${this.state.index}`)
        temp.classList.remove("transformIconRigbarFillter")
      }
      this.setState({ fillter: 1, index: index })
      v.classList.add("transformIconRigbarFillter")
    } else {
      this.setState({ fillter: 0, index: null })
      v.classList.remove("transformIconRigbarFillter")
    }
  }

  async StatusFilter(e, i) {
    let arr = this.state.statusCheckBox
    // console.log("arr", arr)
    this.state.statusCheckBox[i].checked = !arr[i].checked
    this.props.statusApproveCheckBoxDis(this.state.statusCheckBox)
    let tempArr = this.state.statusList
    const index = await tempArr.findIndex((o) => o === e.toLowerCase())
    if (index > -1) {
      tempArr.splice(index, 1)
      this.setState({ statusList: tempArr }, () => this.filterdataList())
      this.props.setStatusApproveFilter(tempArr)
    } else {
      await tempArr.push(e.toLowerCase())
      this.setState({ statusList: tempArr }, () => this.filterdataList())
      this.props.setStatusApproveFilter(tempArr)
    }
  }
  async filterdataList() {
    let arr = []
    if (this.state.statusList.length === 0) {
      arr = ["noData"]
    } else {
      arr = await this.props.data.filter((v) =>
        this.state.statusList.includes(v.Status.toLowerCase())
      )
      if (arr.length === 0) {
        arr = ["noData"]
      }
    }
    this.props.data_filter(arr)
  }
  render() {
    return (
      <MainContainer>
        <ul className="rightBarOrderApprove">
          {this.state.column.map((item, idx) => (
            <li key={item.accessor} onDragOver={() => this.onDragOver(idx)}>
              {this.props.mode === "column" && (
                <CardContainer>
                  <Container>
                    <div
                      draggable
                      onDragStart={(e) => this.onDragStart(e, idx)}
                      onDragEnd={this.onDragEnd}>
                      <img alt="drag-icon" src={DragIcon} />
                    </div>
                    <Text>{item.label}</Text>
                  </Container>
                  <Container>
                    {item.isShow ? (
                      <div className="hand" onClick={() => this.handleToggleShow(item.accessor)}>
                        <img alt="minus-icon" src={MinusIcon} />
                      </div>
                    ) : (
                      <div className="hand" onClick={() => this.handleToggleShow(item.accessor)}>
                        <img alt="plus-icon" src={PlusIcon} />
                      </div>
                    )}
                  </Container>
                </CardContainer>
              )}
              {this.props.mode === "filter" && (
                <CardContainer
                  className="row"
                  style={{ height: "auto", marginLeft: "0px" }}
                  key={idx}>
                  <Container className="col-md-10">
                    <Text>{item.label}</Text>
                  </Container>
                  <Container className="col-md-2">
                    <img
                      id={"imgFillterApprove" + idx}
                      alt="back-icon"
                      src={BackIcon}
                      onClick={() => this.onfillter(idx)}
                    />
                  </Container>
                  {this.state.fillter === 1 && idx === this.state.index ? (
                    <div>
                      {this.state.index === 0 ? (
                        <div
                          style={{ display: "flex", flexDirection: "column", paddingLeft: "30px" }}>
                          {this.state.itemStatus.map((e, i) => {
                            return (
                              <div className="custom-control custom-checkbox" key={i}>
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  checked={this.state.statusCheckBox[i].checked}
                                  id={i}
                                  onChange={() => this.StatusFilter(e, i)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={i}
                                  style={{ cursor: "pointer" }}>
                                  {e}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </CardContainer>
              )}
            </li>
          ))}
        </ul>
      </MainContainer>
    )
  }
}

const mapState = (state) => ({
  customerColumn: state.orderManagement.columnOrderManagementApprove,
  columnOrderManagementApproveFilter: state.orderManagement.columnOrderManagementApproveFilter,
  statusApproveCheckBox: state.orderManagement.statusApproveCheckBox,
  statusApproveFilter: state.orderManagement.statusApproveFilter,
})

const mapDispatch = (dispatch) => {
  return {
    setCustomerColumnList: dispatch.orderManagement.setOrderApproveColumnList,
    setStatusApproveFilter: dispatch.orderManagement.setStatusApproveFilter,
    statusApproveCheckBoxDis: dispatch.orderManagement.statusApproveCheckBoxDis,
  }
}

export default connect(mapState, mapDispatch)(CollumSettingApprove)
