import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import './invencalendarLayout.css'
import Button from '../../components/button'
// import './swal.css'
// import swal from 'sweetalert';
import ExportModal from './exportModal'

class InventoriesLayout extends Component {
  state = {
    showRightbar: false,
    mode: 'column',
    monthValue: '',
    sendYear: new Date(),
    isExportModalShow: false
  }

  componentDidMount() {
    this.props.setPageTitle('Inventories Calendar List')
    this.props.showBackIcon(false)
    let d = new Date();
    let m = d.getMonth();
    let month = m + 1
    this.setState({ monthValue: month })
    this.props.onFaceDataCalendar(this.state.sendYear)
    this.props.inventoriesCalendarCheckLoadpage(true)
  }


  onChangeTab = (path) => {
    const { history, match } = this.props
    history.push(match.url + path)
  }

  // async handleChangeYear(value) {
  //   this.setState({ monthValue: value })
  //   this.props.onFaceDataCalendar(value)
  //   this.props.inventoriesCalendarCheckLoadpage(true)
  // }

  setYearForFectData = e => {
    this.setState({ sendYear: e })
    this.props.onFaceDataCalendar(e)
    this.props.inventoriesCalendarCheckLoadpage(true)
  }

  onExportData = () => {
    this.setState({ isExportModalShow: true })
  }
  render() {

    return (
      <div>
        <div className="page-breadcrumb">
          <div className="btn-container">
            <div className="col-md-6 ">
              <DatePicker
                disabled={this.props.loadingPage}
                className="Datepicker_react_invenca"
                selected={this.state.sendYear}
                onChange={e => this.setYearForFectData(e)}
                showMonthYearPicker
                dateFormat="MM/yyyy"
                popperPlacement='bottom'
                popperModifiers={{
                  flip: {
                    behavior: ['bottom']
                  },
                  preventOverflow: {
                    enabled: false
                  },
                  hide: {
                    enabled: false
                  }
                }}
              />
            </div>
            <div className="col-md-6">
              <Button
                style={{ float: 'right' }}
                className="btn"
                label="Export"
                onClick={() => this.onExportData()}
              />
            </div>
          </div>
        </div>
        {this.props.children}
        <ExportModal
          isOpen={this.state.isExportModalShow}
          onCallBack={this.onCallBack}
          sendForDate={this.state}
          closeModal={(data) => {
            this.setState({ isExportModalShow: data })
          }}
        />
      </div>
    )
  }
}

const mapState = (state) => ({
   loadingPage: state.invenCalendar.loadingPage
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    showBackIcon: dispatch.Title.showBackIcon,
    onFaceDataCalendar: dispatch.invenCalendar.onFaceDataCalendar,
    inventoriesCalendarCheckLoadpage: dispatch.invenCalendar.inventoriesCalendarCheckLoadpage
  }
}

export default connect(
  mapState,
  mapDispatch
)(withRouter(InventoriesLayout))

