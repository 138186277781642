import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Back from '../../images/back_reverse.svg'

import '../../style/style.custom.css'

const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const BackIcon = styled.img`
  margin: 0px 10px 8px 0px;
  cursor: pointer;
`

class Header extends Component {
  state = {
    isBack: true,
  }


  onClick = (e) => {
    e.preventDefault()
    // console.log('onclick..')
  }
   handleGoBack() {
    if (this.props.location.pathname.includes('orderProfileBk')) {
      this.props.history.push('/admin/order-management/list/Booking')
    } else if ((this.props.location.pathname.includes('setting-group-customer')) || (this.props.location.pathname.includes('setting-group-maid'))){
      this.props.history.push('/admin/group-setting/setting-group')
    } else if ((this.props.location.pathname.includes('announceTemplate')) || (this.props.location.pathname.includes('broadcastTemplate'))) {
      this.props.history.push('/admin/broadcasting/list/broadcastSent')
    } else if(this.props.location.pathname.includes('promotionAdd')) {
      this.props.history.push('/admin/promotion/list/promotionInactive')
    }else if(this.props.location.pathname.includes('maidProfile')) {
      this.props.history.push('/admin/maid-management/list/maid')
    }else if(this.props.location.pathname.includes('orderManagementFilter')) {
      this.props.history.push('/admin/payment-management/list/Payment')
    }else{
      this.props.history.goBack()
    }

     // this.props.history.goBack()
    this.props.showBackIcon(false)
    this.props.setCheckTypePath(true)
  }

  render() {
    const { title } = this.props

    return (
      <div>
        <header className="topbar" style={{height:'40px'}}>
          <nav className="navbar top-navbar navbar-expand-md navbar-dark"  style={{minHeight:' 40px'}} > 
            <div id="topbar-expand" className="navbar-header">
              {/* This is for the sidebar toggle which is visible on mobile only */}
              <a
                className="nav-toggler waves-effect waves-light d-block d-md-none"
                href={this.props.content}>
                <i className="ti-menu ti-close" />
              </a>
              {/* ============================================================== */}
              {/* Logo */}
              {/* ============================================================== */}
              <LogoContainer>
                <a className="navbar-brand" href="index.html">
                  <span className="logo-text">
                    <div>Bluuu Admin</div>
                  </span>
                </a>
              </LogoContainer>
            </div>
            <div
              className="navbar-collapse collapse"
              id="navbarSupportedContent"
              data-navbarbg="skin6">
              {/* <div className="navbar-collapse collapse" id="navbarSupportedContent" > */}
              {/* ============================================================== */}
              {/* toggle and nav items */}
              {/* ============================================================== */}
              <div className="page-title">
                {
                  this.props.appState.isBack &&
                  <BackIcon onClick={() => this.handleGoBack()} alt="back-icon" src={Back} />

                }
                <h4 className="font-white keep-word">{title}</h4>
              </div>
            </div>
          </nav>
        </header>
      </div>
    )
  }
}

const mapState = (state) => ({
  appState: state.Title,
})

const mapDispatch = (dispatch) => {
  return {
    showBackIcon: dispatch.Title.showBackIcon,
    setCheckTypePath: dispatch.promotion.setCheckTypePath,
  }
}

export default connect(
  mapState,
  mapDispatch
)(withRouter(Header))
