import request from '../../utils/request'

export const dashoard = {
  state: {
    tempSetData: 0,
    dataSaleProduct: [],
    dataSaleProductLo: [],
    dataPStyeList: [],
    dataUserUFList: [],
    dataUserDTList: [],
    dataRetentionList: [],
    dateforGraph: '',
    faceGrapSale_Date: '',
    faceGrapSale_EDate: '',
    faceGrapSalelo_Date: '',
    faceGrapSalelo_EDate: '',
    faceGrapPStype_Date: '',
    faceGrapPStype_EDate: '',
    faceGraphUF_Date: '',
    faceGraphUF_EDate: '',
    faceGraphDT_Date: '',
    faceGraphDT_EDate: '',
  },
  reducers: {
    tempSetDataArr(state, payload) {
      return {
        ...state,
        tempSetData: payload
      }
    },
    setDateforGraph(state, payload) {
      return {
        ...state,
        dateforGraph: payload
      }
    },
    dataSaleProduct(state, payload) {
      return {
        ...state,
        dataSaleProduct: payload
      }
    },
    faceGrapSale_Date(state, payload) {
      return {
        ...state,
        faceGrapSale_Date: payload
      }
    },
    faceGrapSale_EDate(state, payload) {
      return {
        ...state,
        faceGrapSale_EDate: payload
      }
    },
    dataSaleProductLo(state, payload) {
      return {
        ...state,
        dataSaleProductLo: payload
      }
    },
    faceGrapSalelo_Date(state, payload) {
      return {
        ...state,
        faceGrapSalelo_Date: payload
      }
    },
    faceGrapSalelo_EDate(state, payload) {
      return {
        ...state,
        faceGrapSalelo_EDate: payload
      }
    },
    faceGrapPStype_Date(state, payload) {
      return {
        ...state,
        faceGrapPStype_Date: payload
      }
    },
    faceGrapPStype_EDate(state, payload) {
      return {
        ...state,
        faceGrapPStype_EDate: payload
      }
    },
    dataPStye(state, payload) {
      return {
        ...state,
        dataPStyeList: payload
      }
    },
    faceGraphUF_Date(state, payload) {
      return {
        ...state,
        faceGraphUF_Date: payload
      }
    },
    faceGraphUF_EDate(state, payload) {
      return {
        ...state,
        faceGraphUF_EDate: payload
      }
    },
    dataUserUF(state, payload) {
      return {
        ...state,
        dataUserUFList: payload
      }
    },
    faceGraphDT_Date(state, payload) {
      return {
        ...state,
        faceGraphDT_Date: payload
      }
    },
    faceGraphDT_EDate(state, payload) {
      return {
        ...state,
        faceGraphDT_EDate: payload
      }
    },
    dataUserDT(state, payload) {
      return {
        ...state,
        dataUserDTList: payload
      }
    },
    dataRetentionList(state, payload) {
      return {
        ...state,
        dataRetentionList: payload
      }
    },
  },

  effects: (dispatch) => ({
    async FaceGrapSale(date, getState) {
      let arr = []
      if (date !== undefined) {
        if (date.stateDate !== undefined && date.endDate !== undefined) {
          const res = await request.get(`/insight/sales/product/category?start_date=${date.stateDate}&end_date=${date.endDate}`)
          res.data.forEach((el, i) => {
            if (el.sales_values !== undefined) {
              for (let i = 0; i < el.sales_values.length; i++) {
                if (arr.length === 0) {
                  arr.push({
                    name: `${el.sales_values[i].name}`,
                    data: [el.sales_values[i].value]
                  })
                } else {
                  let index = arr.map(function (el) { return el.name; }).indexOf(`${el.sales_values[i].name}`);
                  if (index === -1 && index < 0) {
                    arr.push({
                      name: `${el.sales_values[i].name}`,
                      data: [el.sales_values[i].value]
                    })
                  } else if (index >= 0) {
                    arr[index].data.push(el.sales_values[index].value)
                  }
                }
              }
            }
          });
        }
        const cleanData = arr
        if (getState.dashoard.faceGrapSale_Date !== date.stateDate || getState.dashoard.faceGrapSale_EDate !== date.endDate) {
          dispatch.dashoard.faceGrapSale_Date(date.stateDate)
          dispatch.dashoard.faceGrapSale_EDate(date.endDate)
          dispatch.dashoard.dataSaleProduct(cleanData)
        }
      }

    },
    async  FaceGrapSaleBylocation(datelo, getState) {
      let arr = []
      if (datelo !== undefined) {
        if (datelo.stateDate !== undefined && datelo.endDate !== undefined) {
          const res = await request.get(`/insight/sales/location?start_date=${datelo.stateDate}&end_date=${datelo.endDate}`)
          res.data.forEach((el, i) => {
            if (el.sales_values !== undefined) {
              for (let i = 0; i < el.sales_values.length; i++) {
                if (arr.length === 0) {
                  arr.push({
                    name: `${el.sales_values[i].name}`,
                    data: [el.sales_values[i].value]
                  })
                } else {
                  let index = arr.map(function (el) { return el.name; }).indexOf(`${el.sales_values[i].name}`);
                  if (index === -1 && index < 0) {
                    arr.push({
                      name: `${el.sales_values[i].name}`,
                      data: [el.sales_values[i].value]
                    })
                  } else if (index >= 0) {
                    arr[index].data.push(el.sales_values[index].value)
                  }
                }
              }
            }
          });
        }
        const cleanData = arr
        if (getState.dashoard.faceGrapSalelo_Date !== datelo.stateDate || getState.dashoard.faceGrapSalelo_EDate !== datelo.endDate) {
          dispatch.dashoard.faceGrapSalelo_Date(datelo.stateDate)
          dispatch.dashoard.faceGrapSalelo_EDate(datelo.endDate)
          dispatch.dashoard.dataSaleProductLo(cleanData)
        }
      }

    },
    async FaceGraphPStype(date, getState) {
      let arr = []
      if (date !== undefined) {
        if (date.stateDate !== undefined && date.endDate !== undefined) {
          const res = await request.get(`/insight/sales/product/type?start_date=${date.stateDate}&end_date=${date.endDate}`)
          res.data.forEach((el, i) => {
            if (el.sales_values !== undefined) {
              for (let i = 0; i < el.sales_values.length; i++) {
                if (arr.length === 0) {
                  arr.push({
                    name: `${el.sales_values[i].name}`,
                    data: [el.sales_values[i].value]
                  })
                } else {
                  let index = arr.map(function (el) { return el.name; }).indexOf(`${el.sales_values[i].name}`);
                  if (index === -1 && index < 0) {
                    arr.push({
                      name: `${el.sales_values[i].name}`,
                      data: [el.sales_values[i].value]
                    })
                  } else if (index >= 0) {
                    arr[index].data.push(el.sales_values[index].value)
                  }
                }
              }
            }
          });
        }
      }
      const cleanData = arr
      if (getState.dashoard.faceGrapPStype_Date !== date.stateDate || getState.dashoard.faceGrapPStype_EDate !== date.endDate) {
        dispatch.dashoard.faceGrapPStype_Date(date.stateDate)
        dispatch.dashoard.faceGrapPStype_EDate(date.endDate)
        dispatch.dashoard.dataPStye(cleanData)
      }


    },
    async  FaceGraphUF(date, getState) {
      let arr = []
      if (date !== undefined) {
        if (date.stateDate !== undefined && date.endDate !== undefined) {
          const res = await request.get(`/insight/user/funnel?start_date=${date.stateDate}&end_date=${date.endDate}`)
          res.data.forEach((el, i) => {
            if (el.sales_values !== undefined) {
              for (let i = 0; i < el.sales_values.length; i++) {
                if (arr.length === 0) {
                  arr.push({
                    name: `${el.sales_values[i].name}`,
                    data: [el.sales_values[i].value]
                  })
                } else {
                  let index = arr.map(function (el) { return el.name; }).indexOf(`${el.sales_values[i].name}`);
                  if (index === -1 && index < 0) {
                    arr.push({
                      name: `${el.sales_values[i].name}`,
                      data: [el.sales_values[i].value]
                    })
                  } else if (index >= 0) {
                    arr[index].data.push(el.sales_values[index].value)
                  }
                }
              }
            }
          });
        }
      }
      const cleanData = arr

      if (getState.dashoard.faceGraphUF_Date !== date.stateDate || getState.dashoard.faceGraphUF_EDate !== date.endDate) {
        dispatch.dashoard.faceGraphUF_Date(date.stateDate)
        dispatch.dashoard.faceGraphUF_EDate(date.endDate)
        dispatch.dashoard.dataUserUF(cleanData)
      }

    },
    async  FaceGraphDT(date, getState) {
      let arr = []
      if (date !== undefined) {
        if (date.stateDate !== undefined && date.endDate !== undefined) {
          const res = await request.get(`/insight/appointment/datetime?start_date=${date.stateDate}&end_date=${date.endDate}`)
          res.data.forEach((el, i) => {
            if (el.sales_values !== undefined) {
              for (let i = 0; i < el.sales_values.length; i++) {
                if (arr.length === 0) {
                  arr.push({
                    name: `${el.sales_values[i].name}`,
                    data: [el.sales_values[i].value]
                  })
                } else {
                  let index = arr.map(function (el) { return el.name; }).indexOf(`${el.sales_values[i].name}`);
                  if (index === -1 && index < 0) {
                    arr.push({
                      name: `${el.sales_values[i].name}`,
                      data: [el.sales_values[i].value]
                    })
                  } else if (index >= 0) {
                    arr[index].data.push(el.sales_values[index].value)
                  }
                }
              }
            }
          });
        }
      }
      const cleanData = arr
      if (getState.dashoard.faceGraphDT_Date !== date.stateDate || getState.dashoard.faceGraphDT_EDate !== date.endDate) {
        dispatch.dashoard.faceGraphDT_Date(date.stateDate)
        dispatch.dashoard.faceGraphDT_EDate(date.endDate)
        dispatch.dashoard.dataUserDT(cleanData)
      }

    },
    async FaceserRetention() {
      let arr = []
      const res = await request.get(`/insight/retention`)
      for (let i = 0; i < res.data.length; i++) {
        for (let j = 0; j < res.data[i].retention.length; j++) {
          if (arr.length === 0) {
            arr.push({
              date: `${res.data[i].month}`,
              col1: res.data[i].retention[0] !== undefined ? `${res.data[i].retention[0].percentage} %` : null,
              col2: res.data[i].retention[1] !== undefined ? `${res.data[i].retention[1].percentage} %` : null,
              col3: res.data[i].retention[2] !== undefined ? `${res.data[i].retention[2].percentage} %` : null,
              col4: res.data[i].retention[3] !== undefined ? `${res.data[i].retention[3].percentage} %` : null,
              col5: res.data[i].retention[4] !== undefined ? `${res.data[i].retention[4].percentage} %` : null,
              col6: res.data[i].retention[5] !== undefined ? `${res.data[i].retention[5].percentage} %` : null,
              col7: res.data[i].retention[6] !== undefined ? `${res.data[i].retention[6].percentage} %` : null,
              col8: res.data[i].retention[7] !== undefined ? `${res.data[i].retention[7].percentage} %` : null,
              col9: res.data[i].retention[8] !== undefined ? `${res.data[i].retention[8].percentage} %` : null,
              col10: res.data[i].retention[9] !== undefined ? `${res.data[i].retention[9].percentage} %` : null,
              col11: res.data[i].retention[10] !== undefined ? `${res.data[i].retention[10].percentage} %` : null,
              col12: res.data[i].retention[11] !== undefined ? `${res.data[i].retention[11].percentage} %` : null,
            })
          } else {
            let index = arr.map(function (e) { return e.date; }).indexOf(`${res.data[i].month}`);
            if (index === -1 && index < 0) {
              arr.push({
                date: `${res.data[i].month}`,
                col1: res.data[i].retention[0] !== undefined ? `${res.data[i].retention[0].percentage} %` : null,
                col2: res.data[i].retention[1] !== undefined ? `${res.data[i].retention[1].percentage} %` : null,
                col3: res.data[i].retention[2] !== undefined ? `${res.data[i].retention[2].percentage} %` : null,
                col4: res.data[i].retention[3] !== undefined ? `${res.data[i].retention[3].percentage} %` : null,
                col5: res.data[i].retention[4] !== undefined ? `${res.data[i].retention[4].percentage} %` : null,
                col6: res.data[i].retention[5] !== undefined ? `${res.data[i].retention[5].percentage} %` : null,
                col7: res.data[i].retention[6] !== undefined ? `${res.data[i].retention[6].percentage} %` : null,
                col8: res.data[i].retention[7] !== undefined ? `${res.data[i].retention[7].percentage} %` : null,
                col9: res.data[i].retention[8] !== undefined ? `${res.data[i].retention[8].percentage} %` : null,
                col10: res.data[i].retention[9] !== undefined ? `${res.data[i].retention[9].percentage} %` : null,
                col11: res.data[i].retention[10] !== undefined ? `${res.data[i].retention[10].percentage} %` : null,
                col12: res.data[i].retention[11] !== undefined ? `${res.data[i].retention[11].percentage} %` : null,
              })
            }

          }

        }
      }
      const cleanData = arr
      dispatch.dashoard.dataRetentionList(cleanData)
    }

  }),
}