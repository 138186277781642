import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import ReactTable from "react-table"
import "react-table/react-table.css"
import "../../style/react-table-custom.css"
import EditOrderApprove from "../../components/editOrderApprove/index"
import Tooltip from "../../components/tooltip"
import moment from "moment"
import request from "../../utils/request"
import Pagination from "../../components/paginationTable/pagination"
import Button from "../../components/button"
import TabComponent from "./../../components/tabComponent"
import DatePicker from "react-datepicker"
import Rightbar from "../../layouts/approvemanagementLayout/rightbar"
import "./ApproveList.css"

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.active};
  background: #fff;
  height: max-content;
  width: 100%;
  position: relative;
  top: -1px;
  padding: 10px;
  align-items: flex-start;
  overflow: auto;
`

// const Icon = styled.div`
//   align-self: center;
//   img {
//   }
// `

const Overlay = styled.div`
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
`

const HeaderTable = styled.div`
  text-align: left;
`

class OrderManagementApprove extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      column: this.props.columnOrderManagementApprove,
      data: [],
      start_dateOrder: new Date(),
      end_dateOrder: new Date(),
      error: false,
      paramsDate: {},
      loading: false,
      showRightbar: false,
      totalPages: 0,
      totaldata: 0,
      textSearch: "",
      tempdate: [],
      checktext: "",
      tempDataTable: [],
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.updateSatatusApprove !== undefined || this.props.updateSatatusApprove !== null) {
      if (this.props.updateSatatusApprove !== "") {
        this.props.satatusApprove("")
        this.getDataActive()
        this.setState({ totaldata: 0 })
      }
    }

    if (prevState.column !== this.props.columnOrderManagementApprove) {
      this.setState({ column: this.props.columnOrderManagementApprove })
    }
  }

  async componentDidMount() {
    await this.setDateForFectData()
    this.props.setOrderMode("Approve")
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async setDateForFectData() {
    const d = new Date()
    const n = d.getDate()
    let tempD = await JSON.parse(localStorage.getItem("ApproveDate"))
    if (tempD) {
      this.setState({
        start_datetime: new Date(tempD.startDate),
        end_datetime: new Date(tempD.endDate),
      })
    } else {
      if (n <= 15) {
        this.setState({
          start_datetime: new Date(moment().set("date", 1).format()),
          end_datetime: new Date(moment().set("date", 15).format()),
        })
      } else if (n >= 16) {
        this.setState({
          start_datetime: new Date(moment().set("date", 16).format()),
          end_datetime: new Date(moment(d).endOf("month").format()),
        })
      }
    }

    if (this.state.paramsDate.start_date && this.state.paramsDate.end_date) {
      await this.props.setStartdateOrder("")
      await this.props.setEndateOrder("")
    }
    await this.getDataActive()
  }
  isNull(data) {
    console.log("data null", data)
    return data ? data : "-"
  }

  getloginType(data) {
    if (data === "EMAIL") {
      return "Username"
    } else if (data === "FACEBOOK") {
      return "Facebook"
    } else if (data === "LINE") {
      return "Line"
    } 
    return data
  }

  getDataActive(page) {
    if (
      this.state.start_datetime !== "" ||
      this.state.end_datetime !== "" ||
      !this.state.textSearch
    ) {
      if (
        this.state.start_datetime !== undefined ||
        this.state.end_datetime !== undefined ||
        !this.state.textSearch
      ) {
        const params = {
          start_date: this.state.start_datetime
            ? moment(this.state.start_datetime).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          end_date: this.state.end_datetime
            ? moment(this.state.end_datetime).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          textSearch: this.state.checktext !== "clearSearch" ? this.state.textSearch : "",
          page: page ? page.page : 1,
        }
        this.setState({ loading: true, data: [], tempDataTable: [] })
        if (params) {
          // this.props.getOrderApproveList({
          //   start_date: params.start_date,
          //   end_date: params.end_date,
          //   textSearch: params.textSearch,
          // })
          let limit = 5000
          try {
            request
              .get(
                `maid/list?start_date=${params.start_date}&end_date=${params.end_date}&search=${params.textSearch.trim()}&page=${params.page}&limit=${limit}`
              )
              .then((resp) => {
                let data = resp.data
                let tempData = []
                if (data.maids.length > 0) {
                  for (let i = 0; i < data.maids.length; i++) {
                    // console.log(data.maids[i]);
                    tempData.push({
                      picture: this.isNull(data?.maids[i]?.user?.image_url),
                      App_ID: data.maids[i].id,
                      Maid_ID: data.maids[i].real_maid_id,
                      Name_maid:
                        data?.maids[i]?.user?.first_name || data?.maids[i]?.user?.last_name
                          ? `${data?.maids[i]?.user?.first_name} ${data?.maids[i]?.user?.last_name}`
                          : "-",
                      id: data.maids[i].id,
                      Phone_number: this.isNull(data?.maids[i]?.user?.phone),
                      Register_date: data.maids[i].create_datetime
                        ? moment(data.maids[i].create_datetime).format("YYYY-MM-DD HH:mm")
                        : "-",
                      Training_com_date: this.isNull(data.maids[i].traning_completed_datetime),
                      Status: data.maids[i].status,
                      LoginType:this.getloginType(data?.maids[i]?.user?.provider),
                      BackupLogin:
                        this.isNull(data?.maids[i]?.user?.provider) === "EMAIL" ? "On" : "Off",
                      Recommended: data?.maids[i]?.admin_recommend  ? "On" : "Off",
                    })
                  }

                  const sortedTemplate = tempData.sort(
                    (a, b) => moment(b.Register_date).toDate() - moment(a.Register_date).toDate()
                  )

                  this.setState(
                    { data: sortedTemplate, 
                      loading: false, 
                      totaldata: data.item_count , 
                      totalPages: Math.ceil(data.item_count / limit)
                    },
                    () => this.filterdataList(tempData)
                  )
                } else {
                  this.setState({ data: [], loading: false, totaldata: 0 , totalPages: 0 })
                }
              })
          } catch (error) {
            console.log(error)
          }
        }
      }
    }
  }

  handleToggle = (key) => {
    this._isMounted = true
    if (this._isMounted) {
      this.setState((prevState) => ({ [key]: !prevState[key] }))
      const { history } = this.props
      history.push(this.props.history.location.pathname)
    }
  }

  handleColumn = () => {
    this._isMounted = true
    if (this._isMounted) {
      this.setState({ showRightbar: true, mode: "column" })
    }
  }
  handleFilter = () => {
    this._isMounted = true
    if (this._isMounted) {
      this.setState({ showRightbar: true, mode: "filter" })
    }
  }
  onChangeTab = (path) => {
    const { history, match } = this.props
    const params = {
      start_datetime: this.state.start_datetime,
      end_datetime: this.state.end_datetime,
    }
    history.push(match.url + path, params)
  }
  handleChangeStartDate = (e) => {
    this.setState({ start_datetime: new Date(moment(e).startOf("month").format()) }, () => this.getDataActive())
    let temp = {
      startDate: moment(e).startOf("month").format(),
      endDate: this.state.end_datetime,
    }
    localStorage.setItem("ApproveDate", JSON.stringify(temp))
  }
  handleChangeEndDate = (e) => {
    this.setState({ end_datetime: new Date(moment(e).endOf("month").format()) }, () => this.getDataActive())
    let temp = {
      startDate: this.state.start_datetime,
      endDate: moment(e).endOf("month").format(),
    }
    localStorage.setItem("ApproveDate", JSON.stringify(temp))
  }
  onSentTextSearch = () => {
    if (this.state.textSearch !== "") {
      this.setState({ checktext: this.state.textSearch } , () => this.getDataActive())
    } else if (this.state.textSearch === "" && this.state.checktext !== "") {
      this.setState({ checktext: "clearSearch" } , () => this.getDataActive())
    } else {
    this.getDataActive()
    }
  }
  compareBy(key, order) {
    return function (a, b) {
      // a[key] = a[key] === null || a[key] === undefined ? -Infinity : a[key];
      // b[key] = b[key] === null || b[key] === undefined ? -Infinity : b[key];

      // a[key] = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
      // b[key] = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];

      if (order) {
        if (a[key] < b[key]) {
          return 1
        }
        if (a[key] > b[key]) {
          return -1
        }
        return 0
      } else {
        if (a[key] > b[key]) {
          return 1
        }
        if (a[key] < b[key]) {
          return -1
        }
        return 0
      }
    }
  }

  async togleSorting(newSorted, column, shiftKey) {
    let arrayCopy =
      (await this.state.tempDataTable.length) > 0
        ? [...this.state.tempDataTable]
        : [...this.state.data]
    arrayCopy.sort(this.compareBy(column.id, newSorted[0].desc))
    this.setState({ data: arrayCopy, tempDataTable: arrayCopy })
  }

  async filterdataList(data) {
    let arr = []
    if (this.props.statusApproveFilter.length === 0) {
      arr = ["noData"]
    } else {
      arr = await data.filter((v) =>
        this.props.statusApproveFilter.includes(v.Status.toLowerCase())
      )
      if (arr.length === 0) {
        arr = ["noData"]
      }
    }
    this._on_data_filter(arr)
  }

  _on_data_filter(data) {
    this.setState({ tempDataTable: data })
  }

  async toggleBackup(data) {
    const AppId = data.App_ID
    const toggleTo = data.BackupLogin === "Off" ? true : false
    try {
      await request.post(`maid/id/${AppId}/backup/login/${toggleTo}`)
      this.getDataActive()
    } catch (err) {
      if (err.response) {
        alert(err.response.data.error)
      } else {
        alert(err.message)
      }
    }
  }

  async toggleRecommended(data) {
    const AppId = data.App_ID
    const toggleTo = data.Recommended === "Off" ? true : false
    try {
      await request.post(`maid/id/${AppId}/recommend/${toggleTo}`)
      this.getDataActive()
    } catch (err) {
      if (err.response) {
        alert(err.response.data.error)
      } else {
        alert(err.message)
      }
    }
  }

  render() {
    const allColumns = [
      {
        Header: "",
        accessor: "picture",
        Cell: (row) => (
          <div style={{ width: "100%" }}>
            <img
              src={`${row.original.picture}`}
              alt="profile3"
              className="rounded-circle"
              width={25}
            />
          </div>
        ),
        minWidth: 20,
      },
      {
        Header: () => <HeaderTable>App ID</HeaderTable>,
        accessor: "App_ID", // String-based value accessors!
        minWidth: 30,
        Cell: (row) => (
          <div className="maidAllTable">
            {row.original !== "noData"
              ? row.original.App_ID === -1
                ? "-"
                : row.original.App_ID
              : "-"}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable style={{ paddingLeft: 5 }}>Maid ID</HeaderTable>,
        accessor: "Maid_ID",
        Cell: (row) => (
          <div style={{ textAlign: "left", paddingLeft: 5 }}>{row.original.Maid_ID || "-"}</div>
        ),
        minWidth: 50,
      },
      {
        Header: () => <HeaderTable>Name of maid</HeaderTable>,
        accessor: "Name_maid", // String-based value accessors!
        minWidth: 80,
      },
      {
        Header: () => <HeaderTable>Phone number</HeaderTable>,
        accessor: "Phone_number",
        minWidth: 80,
      },
      {
        Header: () => <HeaderTable>Register date</HeaderTable>,
        accessor: "Register_date",
        minWidth: 80,
      },
      // {
      //   Header: () => <HeaderTable>Training completed date</HeaderTable>,
      //   Cell: (row) => (
      //     <div className="ApproveTraining">
      //       {row.original.Training_com_date !== "-" ? row.original.Training_com_date : "-"}
      //     </div>
      //   ),
      //   accessor: "Training_com_date",
      //   minWidth: 80,
      // },
      {
        Header: () => <HeaderTable>Status</HeaderTable>,
        accessor: "Status",
        Cell: (row) => (
          <div className="ApproveStatus">
            <Tooltip
              placement="bottom"
              trigger="click"
              tooltip={
                <EditOrderApprove
                  IdMember={row.original.id}
                  CheckStatus={row.original.Status}
                  props={this.props}
                />
              }>
              {row.original.Status}
              {/* <Icon ><img style={{width:"5px"}} alt="moreIcon" src={MoreIcon} /></Icon> */}
            </Tooltip>
          </div>
        ),
        minWidth: 100,
      },
      {
        Header: () => <HeaderTable>Login Type</HeaderTable>,
        accessor: "LoginType",
        minWidth: 80,
      },
      {
        Header: () => <HeaderTable>Backup Login</HeaderTable>,
        accessor: "BackupLogin",
        minWidth: 60,
        Cell: (row) => {
          return (
            <div style={{ cursor: "pointer" }} onClick={() => this.toggleBackup(row.original)}>
              <p style={{ textAlign: "center", margin: 0 , borderRadius: "10px" ,
               ...(
                row.original.BackupLogin === "On" ? { 
                  backgroundColor: "#6e8ca0",
                  color: "white",
                } : {
                  backgroundColor: "#ccd1d3",
                  color: "#324755",
                }
              )
            }}>{row.value}</p>
            </div>
          )
        },
      },
      {
        Header: () => <HeaderTable>Recommended</HeaderTable>,
        accessor: "Recommended",
        minWidth: 60,
        Cell: (row) => {
          return (
            <div style={{ cursor: "pointer" }} onClick={() => this.toggleRecommended(row.original)}>
              <p style={{ textAlign: "center", margin: 0 , borderRadius: "10px" , backgroundOpacity: "0.5",
               ...(
                row.original.Recommended === "On" ? { 
                  backgroundColor: "#6e8ca0",
                  color: "white",
                } : {
                  backgroundColor: "#ccd1d3",
                  color: "#324755",
                }
              )              
             }}>{row.value}</p>
            </div>
          )
        },
      },
    ]

    let columnWillShow = []
    this.state.column.map((colName) => {
      for (var obj of allColumns) {
        colName.isShow && obj.accessor === colName.accessor && columnWillShow.push(obj)
      }
      return {}
    })
    const tabData = [
      {
        label: "Approve",
        total: this.state.tempDataTable.length > 0 ? this.state.tempDataTable.length : this.state.totaldata,
        key: "0",
        path: "/Approve",
      },
    ]
    const currentLocation = this.props.history.location.pathname
    return (
      <div>
        {this.state.showRightbar && <Overlay onClick={() => this.handleToggle("showRightbar")} />}
        <div className="col-md-12" style={{ paddingBottom: "10px" }}>
          <div className="row">
            <div className="col-md-3 btn-container" style={{ paddingTop: "25px" }}>
              <Button
                style={{ fontSize: "11px", width: "125px", height: "40px" }}
                className="btn"
                label="Column setting"
                onClick={() => this.handleColumn()}
              />
              <Button
                style={{ fontSize: "11px", width: "125px", height: "40px" }}
                className="btn"
                label="Filter by"
                onClick={() => this.handleFilter()}
              />
            </div>
            <div className="col-md-3">
              <div className="col-md-12">
                <label style={{ fontSize: "16px", fontWeight: "500px" }}>Start Date</label>
              </div>
              <div className="col-md-12" style={{ zIndex: 9 }}>
                <DatePicker
                  showMonthYearPicker
                  className="Rectangle-Datepicker-Arrpove"
                  value={this.state.start_datetime}
                  name="start_datetime"
                  selected={this.state.start_datetime}
                  onChange={this.handleChangeStartDate}
                  timeIntervals={15}
                  dateFormat=" yyyy-MM-dd "
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="col-md-12">
                <label style={{ fontSize: "16px", fontWeight: "500px" }}>End Date</label>
              </div>
              <div className="col-md-12" style={{ zIndex: 9 }}>
                <DatePicker
                  showMonthYearPicker
                  className="Rectangle-Datepicker-Arrpove"
                  value={this.state.end_datetime}
                  name="end_datetime"
                  selected={this.state.end_datetime}
                  onChange={this.handleChangeEndDate}
                  timeIntervals={15}
                  dateFormat=" yyyy-MM-dd "
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="col-md-4">
                <label></label>{" "}
              </div>
              <div className="col-md-8" style={{ display: "flex", flexDirection: "row" }}>
                <input
                  id="searchText"
                  type="text"
                  className="inPut-search-textAppove"
                  placeholder="Search.."
                  name="textSearch"
                  value={this.state.textSearch}
                  onChange={(e) => this.setState({ textSearch: e.target.value })}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      this.onSentTextSearch()
                    }
                  }}
                />
                <button
                  id="searchTextmaid"
                  type="submit"
                  className="search-containe-button"
                  onClick={this.onSentTextSearch}>
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <TabComponent transparent tabData={tabData} onChangeTab={this.onChangeTab} />
        <Card>
          {this.state.data.length >= 0 ? (
            <ReactTable
              data={
                this.state.tempDataTable.length > 0 ? this.state.tempDataTable : this.state.data
              }
              columns={columnWillShow}
              loading={this.state.loading}
              pageSize={25}
              page={this.state.page}
              showPagination={true}
              PaginationComponent={Pagination}
              pages={this.state.totalPages}
              manual
              onSortedChange={(newSorted, column, shiftKey) =>
                this.togleSorting(newSorted, column, shiftKey)
              }
              onPageChange={(page) =>
                this.setState({ page: page }, () => {
                  this.getDataActive({ page: page + 1 })
                })
              }
              getTrProps={(state, rowInfo) => {
                return {
                  style: {
                    height: 27.5,
                    overflow: "hidden",
                  },
                }
              }}
              className="-striped -highlight approveLsittetable"
            />
          ) : null}
        </Card>
        <Rightbar
          data={this.state}
          currentLocation={currentLocation}
          dataFilter={this.state.data}
          data_filter={(data) => this._on_data_filter(data)}
        />
        {this.props.children}
      </div>
    )
  }
}

const mapState = (state) => ({
  start_dateOrder: state.orderManagement.start_dateOrder,
  end_dateOrder: state.orderManagement.end_dateOrder,
  columnOrderManagementApprove: state.orderManagement.columnOrderManagementApprove,
  updateSatatusApprove: state.orderManagement.updateSatatusApprove,
  totalpageApprovelist: state.orderManagement.totalpageApprovelist,
  statusApproveFilter: state.orderManagement.statusApproveFilter,
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    setOrderMode: dispatch.orderManagement.setOrderMode,
    satatusApprove: dispatch.orderManagement.satatusApprove,
    getOrderApproveList: dispatch.orderManagement.getOrderApproveList,
    setStartdateOrder: dispatch.orderManagement.setStartdateOrder,
    setEndateOrder: dispatch.orderManagement.setEndateOrder,
  }
}

export default connect(mapState, mapDispatch)(OrderManagementApprove)
