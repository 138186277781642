import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { get } from '../../utils/service'

import ReactTable from 'react-table'
import moment from 'moment'
import 'react-table/react-table.css'
import '../../style/react-table-custom.css'
import MoreIcon from '../../images/more.svg'

import EditStatusPromoiton from '../../components/editsatusPromotion/index'
import Tooltip from '../../components/tooltip'


const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.active};
  background: #fff;
  height: max-content;
  width: 100%;
  position: relative;
  top: -1px;
  padding: 10px;
  align-items: flex-start;
  overflow: auto;
`
const Icon = styled.div`
  align-self: center;
  img {
  }
`
class PromotionInactive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: this.props.customerColumn,
      data: [],
      PageSize: 11,
      numberPage: 1,
      totalPages: 0,
      totaldata: 0,
      loading: false
    }
  }
  componentDidUpdate() {
    if (this.props.updateData !== undefined || this.props.this.props !== null) {
      if (this.props.updateData !== '' || this.props.updateData === 'Update') {
        this.props.checkUpdateData('')
        this.getDataActive()
        this.props.getInacList()
      }

    }
  }
  async componentDidMount() {
    await this.getDataActive()
  }
  async getDataActive() {
    this.setState({ loading: true })
    const resp = await get('/promotion/list/inactive')
    let testData = []
    if (resp.promotion && resp.promotion.length) {
      for (let i = 0; i < resp.promotion.length; i++) {
        this.setState({ loading: false, totalPages: resp.page_count, totaldata: resp.item_count })
        testData.push({
          name: resp.promotion[i].name,
          id: resp.promotion[i].id,
          code: resp.promotion[i].code,
          createBy: "-",
          start_datetime: moment(resp.promotion[i].start_datetime).format('YYYY-MM-DD'),
          end_datetime: moment(resp.promotion[i].end_datetime).format('YYYY-MM-DD'),
        })
      }
    } else {
      this.setState({ loading: false })
    }

    this.setState({ data: testData })
  }
  onScroll = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight && e.target.scrollTop) {
      if (this.state.totalPages > 1 && this.state.data.length < this.state.totaldata) {
        this.setState({ numberPage: this.state.numberPage + 1, loading: true })
        setTimeout(() => {
          get(`/promotion/list/inactive?page=${this.state.numberPage}&limit=10`).then(resp => {
            if (resp.promotion.length > 0) {
              for (let i = 0; i < resp.promotion.length; i++) {
                this.state.data.push({
                  name: resp.promotion[i].name,
                  id: resp.promotion[i].id,
                  code: resp.promotion[i].code,
                  createBy: "-",
                  start_datetime: moment(resp.promotion[i].start_datetime).format('YYYY-MM-DD'),
                  end_datetime: moment(resp.promotion[i].end_datetime).format('YYYY-MM-DD'),
                })
              }
              this.setState({
                loading: false,
                PageSize: this.state.data.length
              })
            }

          })
        }, 100);
      }
    }
  }



  render() {
    const allColumns = [
      {
        Header: "",
        accessor: "edit",
        Cell: (row) => (
          <div style={{ width: '100%', cursor: 'pointer' }}>
            <Tooltip
              placement="bottom"
              trigger="click"
              tooltip={<EditStatusPromoiton TypeStatus={row.original.code} PromotionID={row.original.id} props={this.props} />}>
              <Icon ><img style={{ width: "5px" }} alt="moreIcon" src={MoreIcon} /></Icon>
            </Tooltip>
          </div>
        ),
        minWidth: 30,
      },
      {
        Header: () => <h6>Production Name</h6>,
        accessor: 'name', // String-based value accessors!
        minWidth: 100,
      },
      {
        Header: () => <h6>Id</h6>,
        accessor: 'id',
        minWidth: 100,
      },
      {
        Header: () => <h6>Code</h6>,
        accessor: 'code',
        minWidth: 100,
      },
      {
        Header: () => <h6>Create By</h6>,
        accessor: 'createBy',
        minWidth: 100,
      },
      {
        Header: () => <h6>Start Date</h6>,
        accessor: 'start_datetime',
        minWidth: 100,
      },
      {
        Header: () => <h6>End Date</h6>,
        accessor: 'end_datetime',
        minWidth: 100,
      },
    ]

    let columnWillShow = []
    this.state.column.map((colName) => {
      for (var obj of allColumns) {
        colName.isShow && obj.accessor === colName.accessor &&
          columnWillShow.push(obj)
      }
      return {}
    })

    return (
      <div>
        <Card>
          {
            this.state.data.length >= 0 ?
              <div onScroll={this.onScroll} style={{ width: '100%' }}>
                <ReactTable
                  data={this.state.data} //data object
                  columns={columnWillShow}  //column config object
                  loading={this.state.loading}
                  pageSize={this.state.PageSize}
                  showPagination={false}
                  style={{
                    height: "400px"
                  }}
                  className="-striped -highlight"
                />
              </div>
              :
              <ReactTable
                data={this.state.data}
                columns={columnWillShow}
                defaultPageSize={5}
                showPagination={false}
                className=""
              />
          }


        </Card>
      </div>
    )
  }
}

const mapState = (state) => ({
  promotionList: state.promotion.promotionList,
  customerColumn: state.promotion.columnPromotion,
  updateData: state.promotion.updateData
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    checkUpdateData: dispatch.promotion.checkUpdateData,
    getInacList: dispatch.promotion.getInacList
  }
}

export default connect(
  mapState,
  mapDispatch
)(PromotionInactive)