import React, { Component } from 'react'
import { connect } from 'react-redux'
import './orderManage.css'

class ManagementLayout extends Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = { }
  }
 
  async componentDidMount() {
    this.props.setPageTitle('Payment Management')
    this.props.showBackIcon(false)
  }
 
  render() {
    return (
      <div>
      
        <div>
          {this.props.children}
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  orderMode: state.orderManagement.orderMode,
  dataOrderPayment: state.orderManagement.dataOrderPayment,
 
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    getOrderPaymentList: dispatch.orderManagement.getOrderPaymentList,
    showBackIcon: dispatch.Title.showBackIcon,
  }
}
export default connect(mapState, mapDispatch)(ManagementLayout)
