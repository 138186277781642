import React from 'react'
// import PropTypes from 'prop-types'
import TooltipTrigger from 'react-popper-tooltip'
import classNames from 'classnames'
import ErrorBoundary from '../errorBoundary'

const Tooltip = ({ children, tooltip, hideArrow, className, ...props }) => (
  <ErrorBoundary>
    <TooltipTrigger
      {...props}
      tooltip={({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) => (
        <div
          {...getTooltipProps({
            ref: tooltipRef,
            className: classNames({ 'tooltip-container': true, [className]: true }),
          })}>
          {!hideArrow && (
            <div
              {...getArrowProps({
                ref: arrowRef,
                className: 'tooltip-arrow',
                'data-placement': placement,
              })}
            />
          )}
          {tooltip}
        </div>
      )}>
      {({ getTriggerProps, triggerRef }) => (
        <span
          {...getTriggerProps({
            ref: triggerRef,
            className: 'trigger',
          })}>
          {children}
        </span>
      )}
    </TooltipTrigger>
  </ErrorBoundary>
)

export default Tooltip
