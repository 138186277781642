import React, { Component } from 'react'
import { connect } from 'react-redux'

class paymentLayout extends Component {
   render() {
    return (
      <div>
        <div className="page-breadcrumb">{this.props.children}</div>
      </div>
    )
  }
}

const mapState = () => ({})

const mapDispatch = () => {
  return {}
}
export default connect(mapState, mapDispatch)(paymentLayout)
