import React, { Component } from "react"
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { connect } from "react-redux"

// No Dependency Trial
import styled from "styled-components"

import DragIcon from "../../images/drag.svg"
import MinusIcon from "../../images/minus.svg"
import PlusIcon from "../../images/plus.svg"
import BackIcon from "../../images/back.svg"

const MainContainer = styled.div`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

const CardContainer = styled.div`
  background: ${(props) => props.theme.rightCard};
  border: 1px solid ${(props) => props.theme.active};
  width: 100%;
  height: 44px;
  padding: 10px 20px;

  display: flex;
  justify-content: space-between;
  img {
    cursor: pointer;
  }
`

const Container = styled.div`
  display: flex;
  .hand {
    width: max-content;
    height: 100%;
    cursor: pointer;
  }
`
const Text = styled.div`
  margin-left: 15px;
`

class DragArea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      column: this.props.maidColumn,
      itemAverage: [
        { id: 1, label: "4.85 and Above" },
        { id: 2, label: "4.70 To 4.85" },
        { id: 3, label: "4.55 To 4.70" },
        { id: 4, label: "4.10 To 4.55" },
        { id: 5, label: "3.95 To 4.10" },
        { id: 6, label: "Below 3.95" },
      ],
      averageList: this.props.averageListFilter,
      averageCheckBox: this.props.averageCheckBox,
      itemRepeatRate: [
        { id: 1, label: "90% and Above" },
        { id: 2, label: "80% To 90%" },
        { id: 3, label: "70% To 80%" },
        { id: 4, label: "60% To 70%" },
        { id: 5, label: "50% To 60%" },
        { id: 6, label: "40% To 50%" },
        { id: 7, label: "30% To 40%" },
        { id: 8, label: "20% To 30%" },
        { id: 9, label: "10% To 20%" },
        { id: 10, label: "Below 10%" },
      ],
      repeatRateList: this.props.repeatRateListFilter,
      repeatRateCheckBox: this.props.repeatRateCheckBox,
      itemPublished: [
        { id: 1, label: "Published Maid Only" },
      ],
      publishedList: this.props.publishedListFilter,
      publishedCheckBox: this.props.publishedCheckBox,
    }
    this.onDragEnd = this.onDragEnd.bind(this)
    this.handleToggleShow = this.handleToggleShow.bind(this)
  }
  componentDidMount() {
    this.replaceValueAndFilterAverageFilter()
    this.replaceValueAndFilterRepeatRateListFilter()
    this.replaceValueAndFilterPublishedFilter()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mode !== this.props.mode) {
      if (this.props.mode === "column") {
        this.setState({ column: this.props.maidColumn })
      } else {
        this.setState({
          column: this.props.columnFilter,
          fillter: 0,
          index: null,
        })
      }
    }
  }

  handleToggleShow(value) {
    let newColumn = this.state.column
    const result = this.state.column.map((obj, index) => {
      if (obj.accessor === value) {
        const editObj = { ...obj, isShow: !obj.isShow }

        newColumn[index] = editObj
        return newColumn
      }
      return newColumn
    })
    this.setState({ column: result[0] })
    this.props.setMaidColumnList(newColumn)
  }

  // No Dependency Trial
  onDragStart = (e, index) => {
    this.draggedItem = this.state.column[index]
    e.dataTransfer.effectAllowed = "move"
    e.dataTransfer.setData("text/html", e.target.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20)
  }

  onDragOver = (index) => {
    const draggedOverItem = this.state.column[index]

    // if the item is dragged over itself, ignore
    if (this.draggedItem === draggedOverItem) {
      return
    }

    // filter out the currently dragged item
    let items = this.state.column.filter((item) => item !== this.draggedItem)

    // add the dragged item after the dragged over item
    items.splice(index, 0, this.draggedItem)

    this.setState({ column: items })
    this.props.setMaidColumnList(items)
  }

  onDragEnd = () => {
    this.draggedIdx = null
  }
  replaceValueAndFilterRepeatRateListFilter = () => {
    if (localStorage.repeatRateCheckBox) {
      let local_repeat_checkbox = JSON.parse(localStorage.repeatRateCheckBox)
      this.props.repeatRateCheckBoxDis(local_repeat_checkbox)
      this.setState({ repeatRateCheckBox: local_repeat_checkbox }, () => {
        let x = this.state.itemRepeatRate
        let tempArr = this.state.repeatRateList
        // let arry =
        x.map((e, i) => {
          if (!this.state.repeatRateCheckBox[i].checked) {
            console.log(
              "eeee : checkbox mounted",
              this.state.repeatRateCheckBox[i].checked,
              x[i],
              tempArr
            )
            const index = tempArr.findIndex((o) => o.id === e.id)
            if (index > -1) {
              tempArr.splice(index, 1)
              this.setState({ repeatRateList: tempArr }, () => this.filterdataList())
              this.props.setRepeatRateListFilter(tempArr)
            } else {
              tempArr.push(e)
              this.setState({ repeatRateList: tempArr }, () => this.filterdataList())
              this.props.setRepeatRateListFilter(tempArr)
            }
          }
        })
      })
    }
  }
  replaceValueAndFilterAverageFilter = () => {
    if (localStorage.averageCheckBox) {
      let local_checkbox = JSON.parse(localStorage.averageCheckBox)
      this.props.averageCheckBoxDis(local_checkbox)
      this.setState({ averageCheckBox: local_checkbox }, () => {
        let x = this.state.itemAverage
        let tempArr = this.state.averageList
        // let arry =
        x.map((e, i) => {
          if (!this.state.averageCheckBox[i].checked) {
            console.log(
              "eeee : checkbox mounted",
              this.state.averageCheckBox[i].checked,
              x[i],
              tempArr
            )
            const index = tempArr.findIndex((o) => o.id === e.id)
            if (index > -1) {
              tempArr.splice(index, 1)
              this.setState({ averageList: tempArr }, () => this.filterdataList())
              this.props.setAverageListFilter(tempArr)
            } else {
              tempArr.push(e)
              this.setState({ averageList: tempArr }, () => this.filterdataList())
              this.props.setAverageListFilter(tempArr)
            }
          }
        })
      })
    }
  }
  replaceValueAndFilterPublishedFilter = () => {
    if (localStorage.publishedCheckBox) {
      let local_checkbox = JSON.parse(localStorage.publishedCheckBox)
      this.props.publishedCheckBoxDis(local_checkbox)
      this.setState({ publishedCheckBox: local_checkbox }, () => {
        let x = this.state.itemPublished
        let tempArr = this.state.publishedList
        // let arry =
        x.map((e, i) => {
          if (!this.state.publishedCheckBox[i].checked) {
            console.log(
              "eeee : checkbox mounted",
              this.state.publishedCheckBox[i].checked,
              x[i],
              tempArr
            )
            const index = tempArr.findIndex((o) => o.id === e.id)
            if (index > -1) {
              tempArr.splice(index, 1)
              console.log(tempArr)
              this.setState({ publishedList: tempArr }, () => this.filterdataList())
              this.props.setPublishedListFilter(tempArr)
            } else {
              tempArr.push(e)
              this.setState({ publishedList: tempArr }, () => this.filterdataList())
              this.props.setPublishedListFilter(tempArr)
            }
          }
        })
      })
    }
  }
  onfillter = (index) => {
    var v = document.getElementById(`imgFillterMaid${index}`)
    if (this.state.index !== index) {
      if (this.state.index !== null) {
        var temp = document.getElementById(`imgFillterMaid${this.state.index}`)
        temp.classList.remove("transformIconRigbarFillter")
      }
      this.setState({ fillter: 1, index: index })
      v.classList.add("transformIconRigbarFillter")
    } else {
      this.setState({ fillter: 0, index: null })
      v.classList.remove("transformIconRigbarFillter")
    }
  }

  async AverageFilter(e, i) {
    let arr = this.state.averageCheckBox
    this.state.averageCheckBox[i].checked = !arr[i].checked
    this.props.averageCheckBoxDis(this.state.averageCheckBox)
    let tempArr = this.state.averageList

    const index = await tempArr.findIndex((o) => o.id === e.id)
    if (index > -1) {
      tempArr.splice(index, 1)
      this.setState({ averageList: tempArr }, () => this.filterdataList())
      this.props.setAverageListFilter(tempArr)
    } else {
      await tempArr.push(e)
      this.setState({ averageList: tempArr }, () => this.filterdataList())
      this.props.setAverageListFilter(tempArr)
    }
  }
  async RepeatRateFilter(e, i) {
    let arr = this.state.repeatRateCheckBox
    this.state.repeatRateCheckBox[i].checked = !arr[i].checked
    this.props.repeatRateCheckBoxDis(this.state.repeatRateCheckBox)
    let tempArr = this.state.repeatRateList
    const index = await tempArr.findIndex((o) => o.id === e.id)
    if (index > -1) {
      tempArr.splice(index, 1)
      this.setState({ repeatRateList: tempArr }, () => this.filterdataList())
      this.props.setRepeatRateListFilter(tempArr)
    } else {
      await tempArr.push(e)
      this.setState({ repeatRateList: tempArr }, () => this.filterdataList())
      this.props.setRepeatRateListFilter(tempArr)
    }
  }
  async PublishedFilter(e, i) {
    let arr = this.state.publishedCheckBox
    this.state.publishedCheckBox[i].checked = !arr[i].checked
    this.props.publishedCheckBoxDis(this.state.publishedCheckBox)
    let tempArr = this.state.publishedList

    const index = await tempArr.findIndex((o) => o.id === e.id)
    if (index > -1) {
      tempArr.splice(index, 1)
      this.setState({ publishedList: tempArr }, () => this.filterdataList())
      this.props.setPublishedListFilter(tempArr)
    } else {
      await tempArr.push(e)
      this.setState({ publishedList: tempArr }, () => this.filterdataList())
      this.props.setPublishedListFilter(tempArr)
    }
  }
  async filterdataList() {
    let arr = []
    if (this.state.repeatRateList.length === 0 || this.state.averageList.length === 0) {
      arr = ["noData"]
    } else {
      arr = await this.props.data.filter(
        (v) =>
          this.checkFilterAverRageList(v.Average_Ratings) &&
          this.checkFilterRepeateRateList(v.Repeat_Rate) &&
          this.checkFilterPublishedList(v.Is_Published)
      )
      if (arr.length === 0) {
        arr = ["noData"]
      }
    }
    console.log("eeee : arr ", arr)
    this.props.data_filter(arr)
  }
  checkFilterAverRageList(averageRatings) {
    if (averageRatings >= 4.85) {
      return this.state.averageList.findIndex((e) => e.id === 1) > -1
    } else if (averageRatings >= 4.7 && averageRatings <= 4.85) {
      return this.state.averageList.findIndex((e) => e.id === 2) > -1
    } else if (averageRatings >= 4.55 && averageRatings <= 4.7) {
      return this.state.averageList.findIndex((e) => e.id === 3) > -1
    } else if (averageRatings >= 4.1 && averageRatings <= 4.55) {
      return this.state.averageList.findIndex((e) => e.id === 4) > -1
    } else if (averageRatings >= 3.95 && averageRatings <= 4.1) {
      return this.state.averageList.findIndex((e) => e.id === 5) > -1
    } else {
      return this.state.averageList.findIndex((e) => e.id === 6) > -1
    }
  }
  checkFilterRepeateRateList(repeatRate) {
    if (repeatRate >= 90) {
      return this.state.repeatRateList.findIndex((e) => e.id === 1) > -1
    } else if (repeatRate >= 80 && repeatRate <= 90) {
      return this.state.repeatRateList.findIndex((e) => e.id === 2) > -1
    } else if (repeatRate >= 70 && repeatRate <= 80) {
      return this.state.repeatRateList.findIndex((e) => e.id === 3) > -1
    } else if (repeatRate >= 60 && repeatRate <= 70) {
      return this.state.repeatRateList.findIndex((e) => e.id === 4) > -1
    } else if (repeatRate >= 50 && repeatRate <= 60) {
      return this.state.repeatRateList.findIndex((e) => e.id === 5) > -1
    } else if (repeatRate >= 40 && repeatRate <= 50) {
      return this.state.repeatRateList.findIndex((e) => e.id === 6) > -1
    } else if (repeatRate >= 30 && repeatRate <= 40) {
      return this.state.repeatRateList.findIndex((e) => e.id === 7) > -1
    } else if (repeatRate >= 20 && repeatRate <= 30) {
      return this.state.repeatRateList.findIndex((e) => e.id === 8) > -1
    } else if (repeatRate >= 10 && repeatRate <= 20) {
      return this.state.repeatRateList.findIndex((e) => e.id === 9) > -1
    } else {
      return this.state.repeatRateList.findIndex((e) => e.id === 10) > -1
    }
  }
  checkFilterPublishedList (is_published) {
    if (is_published) {
      return true
    }
    return this.props.publishedListFilter.findIndex(e => e.id === 1) > -1
  }

  render() {
    // console.log(`DragArea state: ${JSON.stringify(this.state)}`)
    // console.log(`this props : ${JSON.stringify(this.props.maidColumn)}`)
    // console.log(`mode : ${this.props.mode}`)
    return (
      <MainContainer>
        <ul className="rightBarMaid">
          {this.state.column.map((item, idx) => (
            <li key={item.accessor} onDragOver={() => this.onDragOver(idx)} style={{ display: (window.location.href.includes("/monthly/list") && item.accessor != 'Is_Published') ? "none" : "block" }}>
              {this.props.mode === "column" && (
                <CardContainer>
                  <Container>
                    <div
                      draggable
                      onDragStart={(e) => this.onDragStart(e, idx)}
                      onDragEnd={this.onDragEnd}>
                      <img alt="drag-icon" src={DragIcon} />
                    </div>
                    <Text>{item.label}</Text>
                  </Container>
                  <Container>
                    {item.isShow ? (
                      <div className="hand" onClick={() => this.handleToggleShow(item.accessor)}>
                        <img alt="minus-icon" src={MinusIcon} />
                      </div>
                    ) : (
                      <div className="hand" onClick={() => this.handleToggleShow(item.accessor)}>
                        <img alt="plus-icon" src={PlusIcon} />
                      </div>
                    )}
                  </Container>
                </CardContainer>
              )}
              {this.props.mode === "filter" && (
                <CardContainer
                  className="row"
                  style={{ height: "auto", marginLeft: "0px" }}
                  key={idx}>
                  <Container className="col-md-10">
                    <Text>{item.label}</Text>
                  </Container>
                  <Container className="col-md-2">
                    {/* <div className="hand" onClick={() => console.log('trigger filter')}> */}
                    <img
                      id={"imgFillterMaid" + idx}
                      alt="back-icon"
                      src={BackIcon}
                      onClick={() => this.onfillter(idx)}
                    />
                    {/* </div> */}
                  </Container>
                  {this.state.fillter === 1 && idx === this.state.index ? (
                    <div>
                      {this.state.index === 0 ? (
                        <div
                          style={{ display: "flex", flexDirection: "column", paddingLeft: "30px" }}>
                          {this.state.itemAverage.map((e, i) => {
                            return (
                              <div className="custom-control custom-checkbox" key={i}>
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  checked={this.state.averageCheckBox[i].checked}
                                  id={i}
                                  onChange={() => this.AverageFilter(e, i)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={i}
                                  style={{ cursor: "pointer" }}>
                                  {e.label}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      ) : this.state.index === 1 ? (
                        <div
                          style={{ display: "flex", flexDirection: "column", paddingLeft: "30px" }}>
                          {this.state.itemRepeatRate.map((e, i) => {
                            return (
                              <div className="custom-control custom-checkbox" key={i}>
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  checked={this.state.repeatRateCheckBox[i].checked}
                                  id={i}
                                  onChange={() => this.RepeatRateFilter(e, i)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={i}
                                  style={{ cursor: "pointer" }}>
                                  {e.label}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      ) : this.state.index === 2 ? (
                        <div
                          style={{ display: "flex", flexDirection: "column", paddingLeft: "30px" }}>
                          {this.state.itemPublished.map((e, i) => {
                            console.log(i)
                            console.log(this.state.publishedCheckBox)
                            console.log(this.props)
                            return (
                              <div className="custom-control custom-checkbox" key={i}>
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  checked={!this.state.publishedCheckBox[i].checked}
                                  id={i}
                                  onChange={() => this.PublishedFilter(e, i)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={i}
                                  style={{ cursor: "pointer" }}>
                                  {e.label}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </CardContainer>
              )}
            </li>
          ))}
        </ul>
      </MainContainer>
    )
  }
}

const mapState = (state) => ({
  maidColumn: state.maid.column,
  columnFilter: state.maid.columnFilter,
  averageCheckBox: state.maid.averageCheckBox,
  repeatRateCheckBox: state.maid.repeatRateCheckBox,
  averageListFilter: state.maid.averageListFilter,
  repeatRateListFilter: state.maid.repeatRateListFilter,
  publishedListFilter: state.maid.publishedListFilter,
  publishedCheckBox: state.maid.publishedCheckBox,
})

const mapDispatch = (dispatch) => {
  return {
    setMaidColumnList: dispatch.maid.setMaidColumnList,
    toggleShowMaidColumn: dispatch.maid.toggleShowMaidColumn,
    setAverageListFilter: dispatch.maid.setAverageListFilter,
    setRepeatRateListFilter: dispatch.maid.setRepeatRateListFilter,
    averageCheckBoxDis: dispatch.maid.averageCheckBoxDis,
    repeatRateCheckBoxDis: dispatch.maid.repeatRateCheckBoxDis,
    setPublishedListFilter: dispatch.maid.setPublishedListFilter,
    publishedCheckBoxDis: dispatch.maid.publishedCheckBoxDis,
  }
}

export default connect(mapState, mapDispatch)(DragArea)
