import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ModalView from '../../components/editsatatusBroadcas/modalView'
import ModalViewInapp from '../../components/editsatatusBroadcas/modalViewInapp'
import { get } from '../../utils/service'
import moment from 'moment'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import '../../style/react-table-custom.css'
import MoreIcon from '../../images/more.svg'
import EditStatusBroadcas from '../../components/editsatatusBroadcas/index'
import Tooltip from '../../components/tooltip'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex:1;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.active};
  background: #fff;
  height: max-content;
  position:relative;
  top: -1px;
  padding: 20px 20px 30px 50px;
  align-items: flex-start;
`
const Icon = styled.div`
  align-self: center;
  img {
  }
  `

class broadCasSent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: this.props.customerColumn,
      data: [],
      loading: false,
      pages: null,
      isSettingModalShow: false,
      isExportModalShow: false,
      broadcastMode: this.props.broadcastMode,
      resAnnouncement: [],
      PageSize: 11,
      numberPage: 1,
      totalPages: 0,
      totaldata: 0
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.ModeView !== undefined && this.props.ModeView === 'View') {
      if (this.props.ModeView !== '') {
        this.props.checkModeModal()
        if (this.props.broadcastMode === 'Announcement') {
          this.setState({ isSettingModalShow: true })
        } else if (this.props.broadcastMode === 'InAppMessage') {
          this.setState({ isExportModalShow: true })
        }
      }
    }
    if (this.props.ModeView !== undefined && this.props.ModeView === 'Remove') {
      if (this.props.ModeView !== '' && this.props.location.state === "delete") {
        this.props.checkModeModal()
        this.getAnnouncement()
        this.setState({ broadcastMode: this.props.broadcastMode })
      }
    }
    if (prevState.broadcastMode !== this.props.broadcastMode && this.state.broadcastMode !== undefined) {
      this.setState({ broadcastMode: this.props.broadcastMode })
      this.getAnnouncement()
    }
  }

  async componentDidMount() {
    await this.getAnnouncement()
    this.props.InAppList()
    this.props.getAnoucement()
    this.props.setBroadcastMode(`${this.props.broadcastMode}`)

  }
  closeModal() {
    this.setState({ isShow: false })
    this.props.checkModeModal('View')
    this.props.checkTypeImage('')
    this.props.setTextModal('')
  }
  async getAnnouncement() {
    this.props.getAnnouncementSent()
    this.props.getInAppMessageSent()
    this.props.getInAppMessageFailed()
    try {
      let resp = []
      if (this.state.data.length > 0) {
        await this.setState({ data: [], numberPage: 1 })
      }
      if (this.state.data.length === 0) {
        if (this.state.broadcastMode === 'Announcement') {
          resp = await get('admin/broadcast/announcement/sent/list')
          for (let i = 0; i < resp.announcement.length; i++) {
            this.setState({ loading: false, totalPages: resp.page_count, totaldata: resp.item_count })
            this.state.resAnnouncement.push({
              name: resp.announcement[i].name,
              id: resp.announcement[i].id,
              type: resp.announcement[i].type,
              start_datetime: moment(resp.announcement[i].start_datetime).format('YYYY-MM-DD'),
              end_datetime: moment(resp.announcement[i].end_datetime).format('YYYY-MM-DD'),
              text: resp.announcement[i].text
            })
          }
        } else if (this.state.broadcastMode === 'InAppMessage') {
          resp = await get('admin/broadcast/inappmessage/sent/list')
          for (let i = 0; i < resp.in_app_message.length; i++) {
            this.setState({ loading: false, totalPages: resp.page_count, totaldata: resp.item_count })
            this.state.resAnnouncement.push({
              name: resp.in_app_message[i].name,
              id: resp.in_app_message[i].id,
              start_datetime: moment(resp.in_app_message[i].start_datetime).format('YYYY-MM-DD'),
              end_datetime: moment(resp.in_app_message[i].end_datetime).format('YYYY-MM-DD'),
              text: resp.in_app_message[i].text
            })
          }
        }
        if (this.state.broadcastMode === 'Announcement' || this.state.broadcastMode === 'InAppMessage') {
          await this.setState({ data: this.state.resAnnouncement })
          if (this.state.data.length > 0) {
            this.state.resAnnouncement = []
          }
        } else {
          if (this.state.data.length > 0) {
            this.setState({ data: [] })
          }
        }
      }
    } catch (error) {
      console.log(error)
    }

  }
  onScroll = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight && e.target.scrollTop) {
      if (this.state.totalPages > 1 && this.state.data.length < this.state.totaldata) {
        this.setState({ numberPage: this.state.numberPage + 1, loading: true })
        setTimeout(() => {
          if (this.state.broadcastMode === 'Announcement') {
            get(`admin/broadcast/announcement/sent/list?page=${this.state.numberPage}&limit=10`).then(resp => {
              if (resp.announcement.length > 0) {
                for (let i = 0; i < resp.announcement.length; i++) {
                  this.state.data.push({
                    name: resp.announcement[i].name,
                    id: resp.announcement[i].id,
                    type: resp.announcement[i].type,
                    start_datetime: moment(resp.announcement[i].start_datetime).format('YYYY-MM-DD'),
                    end_datetime: moment(resp.announcement[i].end_datetime).format('YYYY-MM-DD'),
                    text: resp.announcement[i].text
                  })
                }
                this.setState({
                  loading: false,
                  PageSize: this.state.data.length
                })
              }
            })
          } else if (this.state.broadcastMode === 'InAppMessage') {
            get(`admin/broadcast/inappmessage/sent/list?page=${this.state.numberPage}&limit=10`).then(resp => {
              if (resp.in_app_message.length > 0) {
                for (let i = 0; i < resp.in_app_message.length; i++) {
                  this.state.data.push({
                    name: resp.in_app_message[i].name,
                    id: resp.in_app_message[i].id,
                    start_datetime: moment(resp.in_app_message[i].start_datetime).format('YYYY-MM-DD'),
                    end_datetime: moment(resp.in_app_message[i].end_datetime).format('YYYY-MM-DD'),
                    text: resp.in_app_message[i].text
                  })
                }
                this.setState({
                  loading: false,
                  PageSize: this.state.data.length
                })
              }
            })
          }


        }, 100);
      }
    }
  }
  render() {
    const allColumns = [
      {
        Header: "",
        accessor: "edit",
        Cell: (row) => (
          <div style={{ width: '100%', cursor: 'pointer' }}>
            <Tooltip
              placement="bottom"
              trigger="click"
              tooltip={<EditStatusBroadcas TypeStatus={row.original.id} checkMode={this.props.broadcastMode} Typeview={row.original.type} dataText={row.original.text} props={this.props} />}>
              <Icon ><img style={{ width: "5px" }} alt="moreIcon" src={MoreIcon} /></Icon>
            </Tooltip>
          </div>
        ),
        minWidth: 30,
      },
      {
        Header: () => <h6>Template Name</h6>,
        accessor: 'name', // String-based value accessors!
        minWidth: 120,
      },
      {
        Header: () => <h6>Id</h6>,
        accessor: 'id',
        minWidth: 80,
      },
      {
        Header: () => <h6>Type</h6>,
        accessor: 'type',
        minWidth: 80
      },
      {
        Header: () => <h6>Start Date</h6>,
        accessor: 'start_datetime',
        minWidth: 100,
      },
      {
        Header: () => <h6>End Date</h6>,
        accessor: 'end_datetime',
        minWidth: 100,
      },
    ]

    let columnWillShow = []
    this.state.column.map((colName) => {
      if (this.state.broadcastMode === 'InAppMessage' && colName.accessor === "type") {
        colName.isShow = false
      }
      for (var obj of allColumns) {
        colName.isShow && obj.accessor === colName.accessor &&
          columnWillShow.push(obj)
      }
      return {}
    })
    return (

      <div >
        <Card className="col-sm-6 col-xs-8 col-md-9 col-xl-10">
          {this.state.data.length >= 0 ?
            <div onScroll={this.onScroll} style={{ width: '100%' }}>
              <ReactTable
                data={this.state.data} //data object
                columns={columnWillShow}  //column config object
                loading={this.state.loading}
                pageSize={this.state.PageSize}
                showPagination={false}
                style={{
                  height: "400px"
                }}
                className="-striped -highlight"

              />
            </div>
            //  <ReactTable
            //   data={this.state.data}
            //   columns={columnWillShow}
            //   showPagination={false}
            //   defaultPageSize={10}
            //   className="-striped -highlight"
            //   />
            :
            null
          }
        </Card>
        <ModalView
          isOpen={this.state.isSettingModalShow}
          checkTypeView={this.props.typeImgModal}
          dataText={this.props.textPupupModal}
          onCallBack={this.onCallBack}
          closeModal={(data) => {
            this.setState({ isSettingModalShow: data })
          }}
        />
        <ModalViewInapp
          isOpen={this.state.isExportModalShow}
          checkTypeView={this.props.typeImgModal}
          onCallBack={this.onCallBack}
          dataText={this.props.textPupupModal}
          closeModal={(data) => {
            this.setState({ isExportModalShow: data })
          }}
        />
      </div>
    )
  }
}


const mapState = (state) => ({
  broadcastMode: state.broadcasting.broadcastMode,
  customerColumn: state.broadcasting.columnBroadcast,
  ModeView: state.broadcasting.ModeView,
  typeImgModal: state.broadcasting.typeImgModal,
  textPupupModal: state.broadcasting.textPupupModal
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    setBroadcastMode: dispatch.broadcasting.setBroadcastMode,
    checkModeModal: dispatch.broadcasting.checkModeModal,
    checkTypeImage: dispatch.broadcasting.checkTypeImage,
    setTextModal: dispatch.broadcasting.setTextModal,
    getAnnouncementSent: dispatch.broadcasting.getAnnouncementSent,
    getInAppMessageSent: dispatch.broadcasting.getInAppMessageSent,
    getInAppMessageFailed: dispatch.broadcasting.getInAppMessageFailed,
    InAppList: dispatch.broadcasting.InAppList,
    getAnoucement: dispatch.broadcasting.getAnoucement
  }
}

export default connect(
  mapState,
  mapDispatch
)(broadCasSent)

