import request from "../../utils/request"

export const orderManagement = {
  state: {
    dataOrderApprove: 0,
    dataOrderPayment: 0,
    dataOrderBooking: 0,
    start_dateOrder: "",
    end_dateOrder: "",
    start_dateOrderPayment: "",
    end_dateOrderPayment: "",
    start_dateOrderBooking: "",
    end_dateOrderBooking: "",
    columnOrderManagementApprove: [
      { label: "Picture", accessor: "picture", isShow: false },
      { label: "App ID", accessor: "App_ID", isShow: true },
      { label: "Maid ID", accessor: "Maid_ID", isShow: true },
      { label: "Name of maid", accessor: "Name_maid", isShow: true },
      { label: "Phone number", accessor: "Phone_number", isShow: true },
      { label: "Register date", accessor: "Register_date", isShow: true },
      {
        label: "Training completed date",
        accessor: "Training_com_date",
        isShow: true,
      },
      { label: "Status", accessor: "Status", isShow: true },
      { label: "LoginType", accessor: "LoginType", isShow: true },
      { label: "BackupLogin", accessor: "BackupLogin", isShow: true },
      { label: "Recommended", accessor: "Recommended", isShow: true },
    ],
    columnOrderManagementApproveFilter: [{ label: "Status", accessor: "Status", isShow: true }],
    columnOrderManagementPayment: [
      { label: "Picture", accessor: "picture", isShow: false },
      // { label: "App ID", accessor: "App_ID", isShow: true },
      { label: "Maid ID", accessor: "Maid_ID", isShow: true },
      { label: "Name of maid", accessor: "Name_maid", isShow: true },
      { label: "MP Hours", accessor: "MP_Hours", isShow: true },
      { label: "OT Hours", accessor: "OT_Hours", isShow: true },
      { label: "total_day", accessor: "total_day", isShow: true },
      { label: "Salary", accessor: "Salary", isShow: true },
      { label: "Payment date", accessor: "Payment_date", isShow: true },
      { label: "Status", accessor: "Status", isShow: true },
    ],
    columnOrderManagementBooking: [
      { label: "Internal ID", accessor: "Internal_ID", isShow: true },
      { label: "Payment date", accessor: "Payment_date", isShow: true },
      { label: "Order ID", accessor: "Order_ID", isShow: true },
      { label: "Channel", accessor: "Payment_Method", isShow: true },
      { label: "Amount", accessor: "Payment_Amount", isShow: true },
      { label: "User ID", accessor: "User_ID", isShow: true },
      { label: "User Name", accessor: "User_Name", isShow: true },
      { label: "User Phone", accessor: "User_Phone", isShow: true },
      { label: "Order Type", accessor: "Order_Type", isShow: true },
      { label: "MP Credits", accessor: "MP_Credits", isShow: true },
      { label: "Status", accessor: "Status", isShow: true },
      { label: "Time to accept", accessor: "Time_to_accept", isShow: true },
      { label: "Maid ID", accessor: "App_ID", isShow: true },
      { label: "Maid Name", accessor: "Name_maid", isShow: true },
      { label: "Maid Phone", accessor: "Maid_Phone", isShow: true },
      { label: "Booking Date", accessor: "Booking_Date", isShow: true },
      { label: "Start Time", accessor: "Start_Time", isShow: true },
      { label: "Copy Info", accessor: "Copy_Info", isShow: true },
      { label: "Location", accessor: "Location", isShow: true },
      { label: "Additional Detail", accessor: "Additional_Detail", isShow: true },
      { label: "Accomodation", accessor: "Accomodation", isShow: true },
      { label: "Language", accessor: "Language", isShow: true },
      { label: "Duration", accessor: "Duration", isShow: true },
      { label: "Services", accessor: "Services", isShow: true },
      { label: "Equipment", accessor: "Equipment", isShow: true },
      { label: "Additional Info", accessor: "Additional_Info", isShow: true },
      { label: "Comments", accessor: "Comments", isShow: true },
      { label: "Create Date", accessor: "Create_Date", isShow: true },
    ],
    columnBookingProfile: [
      { label: "Maid Change", accessor: "Maid_change", isShow: true },
      { label: "Maid ID", accessor: "App_ID", isShow: true },
      { label: "Maid Name", accessor: "Maid_Name", isShow: true },
      { label: "Maid Phone", accessor: "Maid_Phone", isShow: true },
      { label: "Credit", accessor: "Credit", isShow: true },
      { label: "Book Date", accessor: "Book_Date", isShow: true },
      { label: "Start Time", accessor: "Start_Time", isShow: true },
      { label: "Credit Status", accessor: "Credit_Status", isShow: true },
      { label: "Purchase Date", accessor: "Purchase_Date", isShow: true },
      { label: "Expiration Date", accessor: "Expiration_Date", isShow: true },
    ],
    columnOrderManagementNamelist: [
      { label: "Date of service", accessor: "Date_service", isShow: true },
      { label: "Service (Hrs)", accessor: "ServiceHrs", isShow: true },
      { label: "Type of service", accessor: "Type_service", isShow: true },
      { label: "MP Credits", accessor: "MP_Credits", isShow: true },
      { label: "Payment", accessor: "Payment", isShow: true },
      { label: "Rating", accessor: "Rating", isShow: true },
      { label: "Bonus", accessor: "Bonus", isShow: true },
      { label: "Bonus Type", accessor: "Bonus_Type", isShow: true },
    ],
    columnFilterBookingList: [
      { label: "Payment date", accessor: "Payment_date", isShow: true },
      { label: "Order Type", accessor: "Order_Type", isShow: true },
      { label: "Status", accessor: "Status", isShow: true },
      { label: "MP Credits", accessor: "MP_Credits", isShow: true },
      { label: "Duration", accessor: "Duration", isShow: true },
      { label: "Services", accessor: "Services", isShow: true },
      { label: "Language", accessor: "Language", isShow: true },
    ],
    showBtnAdd: true,
    orderMode: "Approve",
    updateSatatusApprove: "",
    updateSatatusBooking: "",
    weekDatePayment: {},
    textSearch: "",
    hisStart_dateApprove: "",
    hisEnd_dateApprove: "",
    textSearchPayment: "",
    hisStart_datePayment: "",
    hisEnd_datePayment: "",
    textSearchBooking: "",
    hisStart_dateBooking: "",
    hisEnd_dateBooking: "",
    totalpageBookinglist: 0,
    totalpageApprovelist: 0,
    totalpagePaymentlist: 0,
    paymentdateListFillter: ["-", "Date"],
    paymentCheckBox: [{ checked: true }, { checked: true }],
    orderTypeListFillter: ["OTA", "OTM", "MP"],
    orderTypeCheckBox: [{ checked: true }, { checked: true }, { checked: true }],
    statusList: [
      "Payment confirmed",
      "Booking confirmed",
      "Booking unsuccessful",
      "Assignment confirmed",
      "Assignment unsuccessful",
      "Update sent",
      "Service completed",
      "Service rated",
      "Cancelled 50%",
      "Cancelled 5%",
      "Refunded 50%",
      "Refunded 5%",
    ],
    statusListFillter: [
      "Payment confirmed",
      "Booking confirmed",
      "Booking unsuccessful",
      "Assignment confirmed",
      "Assignment unsuccessful",
      "Update sent",
      "Service completed",
      "Service rated",
      "Cancelled 50%",
      "Cancelled 5%",
      "Refunded 50%",
      "Refunded 5%",
    ],
    StatusChecBoxBooking: [
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
    ],
    mpCreditsFillter: ["1", "4", "8", "12", "16", "20", "24", "28"],
    mpCreditCheckBox: [
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
    ],
    durationFillter: ["2", "3", "4", "5", "6", "7", "8"],
    durationCheckBox: [
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
    ],
    serviceFillter: ["cleaning", "cleaning and drapery", "drapery"],
    serviceCheckBox: [{ checked: true }, { checked: true }, { checked: true }],
    languageFillter: ["TH", "EN"],
    languageCheckBox: [{ checked: true }, { checked: true }],
    statusApproveFilter: ["pending", "approved", "disapproved", "banned", "published"],
    statusApproveCheckBox: [
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
    ],
  },
  reducers: {
    satatusApprove(state, payload) {
      return {
        ...state,
        updateSatatusApprove: payload,
      }
    },
    satatusBooking(state, payload) {
      return {
        ...state,
        updateSatatusBooking: payload,
      }
    },
    setStartdateOrder(state, payload) {
      return {
        ...state,
        start_dateOrder: payload,
      }
    },
    setEndateOrder(state, payload) {
      return {
        ...state,
        end_dateOrder: payload,
      }
    },
    setHistoryStartdateOrder(state, payload) {
      return {
        ...state,
        hisStart_dateApprove: payload,
      }
    },
    setHistoryEndateOrder(state, payload) {
      return {
        ...state,
        hisEnd_dateApprove: payload,
      }
    },
    setDataSearch(state, payload) {
      return {
        ...state,
        textSearch: payload,
      }
    },
    settotalpageApprovelist(state, payload) {
      return {
        ...state,
        totalpageApprovelist: payload,
      }
    },
    setHistoryStartdatePayment(state, payload) {
      return {
        ...state,
        hisStart_datePayment: payload,
      }
    },
    setHistoryEndatePayment(state, payload) {
      return {
        ...state,
        hisEnd_datePayment: payload,
      }
    },
    setStartdateOrderPayment(state, payload) {
      return {
        ...state,
        start_dateOrderPayment: payload,
      }
    },
    setEndateOrderPayment(state, payload) {
      return {
        ...state,
        end_dateOrderPayment: payload,
      }
    },
    setDataSearchPayment(state, payload) {
      return {
        ...state,
        textSearchPayment: payload,
      }
    },
    settotalpagePaymentlist(state, payload) {
      return {
        ...state,
        totalpagePaymentlist: payload,
      }
    },
    setHistoryStartdateBooking(state, payload) {
      return {
        ...state,
        hisStart_dateBooking: payload,
      }
    },
    setHistoryEndateBooking(state, payload) {
      return {
        ...state,
        hisEnd_dateBooking: payload,
      }
    },
    setStartdateOrderBooking(state, payload) {
      return {
        ...state,
        start_dateOrderBooking: payload,
      }
    },
    setEndateOrderBooking(state, payload) {
      return {
        ...state,
        end_dateOrderBooking: payload,
      }
    },
    setDataSearchBooking(state, payload) {
      return {
        ...state,
        textSearchBooking: payload,
      }
    },
    setPagesCountBooking(state, payload) {
      return {
        ...state,
        totalpageBookinglist: payload,
      }
    },

    setOrderApproveColumnList(state, payload) {
      return { ...state, columnOrderManagementApprove: payload }
    },
    setOrderPaymenColumnList(state, payload) {
      return { ...state, columnOrderManagementPayment: payload }
    },
    setOrderBookingColumnList(state, payload) {
      return { ...state, columnOrderManagementBooking: payload }
    },
    setOrderManagementNameList(state, payload) {
      return { ...state, columnOrderManagementNamelist: payload }
    },
    getorDerApprove(state, payload) {
      return {
        ...state,
        dataOrderApprove: payload,
      }
    },
    getorDerPayment(state, payload) {
      return {
        ...state,
        dataOrderPayment: payload,
      }
    },
    getorDerBooking(state, payload) {
      return {
        ...state,
        dataOrderBooking: payload,
      }
    },
    setCheckTypePath(state, payload) {
      return {
        ...state,
        showBtnAdd: payload,
      }
    },
    setOrderMode(state, payload) {
      return {
        ...state,
        orderMode: payload,
      }
    },

    setPaymentdateListFillter(state, payload) {
      return {
        ...state,
        paymentdateListFillter: payload,
      }
    },
    paymentCheckBoxDis(state, payload) {
      return {
        ...state,
        paymentCheckBox: payload,
      }
    },
    setOrderTypeListFillter(state, payload) {
      return {
        ...state,
        orderTypeListFillter: payload,
      }
    },
    orderTypeCheckBoxDis(state, payload) {
      return {
        ...state,
        orderTypeCheckBox: payload,
      }
    },
    setStatusListShow(state, payload) {
      return {
        ...state,
        statusList: payload,
      }
    },
    setStatusListFillter(state, payload) {
      return {
        ...state,
        statusListFillter: payload,
      }
    },
    StatusChecBoxBookingDis(state, payload) {
      // console.log("payload", payload);
      return {
        ...state,
        StatusChecBoxBooking: payload,
      }
    },
    setMpCreditsFillter(state, payload) {
      return {
        ...state,
        mpCreditsFillter: payload,
      }
    },
    mpCreditCheckBoxDis(state, payload) {
      return {
        ...state,
        mpCreditCheckBox: payload,
      }
    },
    setDurationFillter(state, payload) {
      return {
        ...state,
        durationFillter: payload,
      }
    },
    durationCheckBoxDis(state, payload) {
      return {
        ...state,
        durationCheckBox: payload,
      }
    },
    setServiceFillter(state, payload) {
      return {
        ...state,
        serviceFillter: payload,
      }
    },
    serviceCheckBoxDis(state, payload) {
      return {
        ...state,
        serviceCheckBox: payload,
      }
    },
    setLanguageFillter(state, payload) {
      return {
        ...state,
        languageFillter: payload,
      }
    },
    languageCheckBoxDIs(state, payload) {
      return {
        ...state,
        languageCheckBox: payload,
      }
    },
    setStatusApproveFilter(state, payload) {
      return {
        ...state,
        statusApproveFilter: payload,
      }
    },
    statusApproveCheckBoxDis(state, payload) {
      window.localStorage.setItem("statusApproveCheckBox", JSON.stringify(payload))
      return {
        ...state,
        statusApproveCheckBox: payload,
      }
    },
  },

  effects: (dispatch) => ({
    async getOrderApproveList(set_date) {
      if (set_date !== undefined) {
        if (set_date.start_date !== undefined && set_date.end_date !== undefined) {
          const res = await request.get(
            `/maid/list?start_date=${set_date.start_date}&end_date=${set_date.end_date}&search=${set_date.textSearch}&limit=5000`
          )
          const cleanData = res.data.item_count
          const totalpage = Math.ceil(res.data.item_count / 5000)
          dispatch.orderManagement.getorDerApprove(cleanData)
          dispatch.orderManagement.settotalpageApprovelist(totalpage)
        }
      }
    },
    async getOrderPaymentList(set_date) {
      if (set_date !== undefined) {
        if (set_date.start_date !== undefined && set_date.end_date !== undefined) {
          const res = await request.get(
            `/maid/payment/list?start_date=${set_date.start_date}&end_date=${set_date.end_date}&search=${set_date.textSearchPayment}&limit=5000`
          )
          const totalpage = Math.ceil(res.data.item_count / 5000)
          const cleanData = res.data.item_count
          dispatch.orderManagement.getorDerPayment(cleanData)
          dispatch.orderManagement.settotalpagePaymentlist(totalpage)
        }
      }
    },
    async getOrderBookList(set_date) {
      if (set_date !== undefined) {
        if (set_date.start_date !== undefined && set_date.end_date !== undefined) {
          const res = await request.get(
            `/booking/list?start_date=${set_date.start_date}&end_date=${set_date.end_date}&search=${set_date.textSearchBooking}&limit=5000`
          )
          const totalpage = Math.ceil(res.data.item_count / 5000)
          const cleanData = res.data.item_count
          dispatch.orderManagement.getorDerBooking(cleanData)
          dispatch.orderManagement.setPagesCountBooking(totalpage)
        }
      }
    },
  }),
}
