import React, { Component } from 'react'
import { connect } from 'react-redux'

import Button from './../../components/button'

class AdminManagementPage extends Component {
  componentDidMount() {
    this.props.setPageTitle('Admin List')
  }

  render() {
    return (
      <div>
        <div className="page-breadcrumb">
          <div className="btn-container">
            <Button className="btn" label="Add new admin" />
            <Button className="btn" label="Column setting" />
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">{/* title */}</div>
                <div className="table-responsive">
                  <table className="table v-middle">
                    <thead>
                      <tr className="bg-light">
                        <th className="border-top-0">Products</th>
                        <th className="border-top-0">License</th>
                        <th className="border-top-0">Support Agent</th>
                        <th className="border-top-0">Technology</th>
                        <th className="border-top-0">Tickets</th>
                        <th className="border-top-0">Sales</th>
                        <th className="border-top-0">Earnings</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="m-r-10">
                              <div className="btn btn-circle btn-danger text-white">EA</div>
                            </div>
                            <div>
                              <h4 className="m-b-0 font-16">Elite Admin</h4>
                            </div>
                          </div>
                        </td>
                        <td>Single Use</td>
                        <td>John Doe</td>
                        <td>
                          <label className="label label-danger">Angular</label>
                        </td>
                        <td>46</td>
                        <td>356</td>
                        <td>
                          <h5 className="m-b-0">$2850.06</h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="m-r-10">
                              <div className="btn btn-circle btn-info text-white">MA</div>
                            </div>
                            <div >
                              <h4 className="m-b-0 font-16">Monster Admin</h4>
                            </div>
                          </div>
                        </td>
                        <td>Single Use</td>
                        <td>Venessa Fern</td>
                        <td>
                          <label className="label label-info">Vue Js</label>
                        </td>
                        <td>46</td>
                        <td>356</td>
                        <td>
                          <h5 className="m-b-0">$2850.06</h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="m-r-10">
                              <div className="btn btn-circle btn-success text-white">MP</div>
                            </div>
                            <div >
                              <h4 className="m-b-0 font-16">Material Pro Admin</h4>
                            </div>
                          </div>
                        </td>
                        <td>Single Use</td>
                        <td>John Doe</td>
                        <td>
                          <label className="label label-success">Bootstrap</label>
                        </td>
                        <td>46</td>
                        <td>356</td>
                        <td>
                          <h5 className="m-b-0">$2850.06</h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="m-r-10">
                              <div className="btn btn-circle btn-purple text-white">AA</div>
                            </div>
                            <div >
                              <h4 className="m-b-0 font-16">Ample Admin</h4>
                            </div>
                          </div>
                        </td>
                        <td>Single Use</td>
                        <td>John Doe</td>
                        <td>
                          <label className="label label-purple">React</label>
                        </td>
                        <td>46</td>
                        <td>356</td>
                        <td>
                          <h5 className="m-b-0">$2850.06</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = () => ({})

const mapDispatch = (dispatch) => {
  return { setPageTitle: dispatch.Title.setPageTitle }
}

export default connect(
  mapState,
  mapDispatch
)(AdminManagementPage)
