import React, { Component } from 'react'
import imgProfile from '../../images/bluuu-icon.png'
class ProfilePane extends Component {
  render() {
    return (
      <div className="user-profile dropdown m-t-20">
        <div className="user-content hide-menu m-t-10">
          <a
            href={this.props.content}
            id="Userdd"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true">
            <div className="user-pic">
              <img
                // src={`${process.env.PUBLIC_URL}/assets/images/users/1.jpg`}
                src={imgProfile}
                alt="users"
                className="rounded-circle img-fluid"
              />
            </div>
            <div className="user-profile">
              <h5 className="m-b-10 user-name font-medium">Admin 1</h5>
            </div>
          </a>
          <div className="dropdown-menu animated flipInY" aria-labelledby="Userdd">
            <a className="dropdown-item" href={this.props.content}>
              <i className="ti-user m-r-5 m-l-5"> My Profile</i>
            </a>
            <div className="dropdown-divider" />
            <a className="dropdown-item" href={this.props.content}>
              <i className="ti-settings m-r-5 m-l-5"> Account Setting</i>
            </a>
            <div className="dropdown-divider" />
            <a className="dropdown-item" href={this.props.content}>
              <i className="fa fa-power-off m-r-5 m-l-5"> Logout</i>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default ProfilePane
