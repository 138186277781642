import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from './../../components/button'
import './promotion.css'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import { post } from '../../utils/service'
import Select from 'react-select'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import { get } from '../../utils/service'

const InpuntHeadTemp = styled.input`
  padding: 16px 16px 16px 30px;
  width: 977.4px;
  height: 60px;
  border: solid 1px #324755;
  background-color: #ffffff;
  font-size: 30px;
  margin-top: 20px;
  margin-left: 10px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #324755;
  }
  :-ms-input-placeholder {
    color: #324755;
  }
`

const InpuntHeadTemp1 = styled.input`
  padding: 0px 0px 35px 30px;
  width: 977.4px;
  height: 70px;
  border: solid 1px #324755;
  background-color: #ffffff;
  margin-bottom: 10px;
  margin-left: 10px;
  ::placeholder,
  ::-webkit-input-placeholder {
    #324755;
  }
  :-ms-input-placeholder {
     #324755;
  }
`
class promotionAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colourOptions: [
        { value: '1', label: 'Customer' },
        { value: '2', label: 'Maid' },
      ],
      startDate: new Date(),
      namePromotion: '',
      description: '',
      code: '',
      discount: '',
      discount_type: 'BAHT',
      coupon_type: '',
      selected_users: [],
      start_datetime: new Date(),
      end_datetime: new Date(),
      currenDate:new Date(),
      budget: '',
      max_type: 'BAHT',
      max_user: '',
      tempSelceted:[]
    }
  }

  componentDidMount() {
    this.props.setPageTitle('Add new Promotion')
    this.props.showBackIcon(true)
    this.getDataProfile()
  }
  async getDataProfile() {
    if (this.props.location.state !== undefined) {
      const id = this.props.location.state.id
      const resp = await get(`admin/promotion/id/${id}`)
      this.setState({
        namePromotion: resp.name,
        description: resp.description,
        code: resp.code,
        discount: resp.discount,
        discount_type: resp.discount_type,
        coupon_type: resp.coupon_type,
        selected_users:  resp.selected_users.split(','),
        start_datetime: new Date(resp.start_datetime),
        end_datetime: new Date(resp.end_datetime),
        budget: resp.budget,
        max_type: resp.max_type,
        max_user: resp.max_user,
      })
      this.setValueSelect()
    }
  }
  setValueSelect() {
    let testnum = []
    if (this.state.selected_users.length > 0) {
      testnum = this.state.selected_users.map((el) => el * 1)
      if (testnum.length > 0) {
        const result = testnum.map((el) => {
          if (el - 1 < this.state.colourOptions.length) {
            return this.state.colourOptions[el - 1]
          } else {
            
          }
        })
        this.setState({ ...this.state, selected_users: result })
      }
    } else {
      return null
    }
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleChangeSelected = (e) => {
    this.setState({ selected_users: e})
  }
  handleChangeStartDate = (e) => {
    this.setState({ start_datetime: e })
  }
  handleChangeEndDate = (e) => {
    this.setState({ end_datetime: e })
  }
  async onGenerateCode() {
    const GenCode = Math.random()
      .toString(26)
      .substring(2, 6)
      .toUpperCase()
    this.setState({ code: GenCode })
  }

  async onSubmit() {
    let { obj } = this.state
    obj = {
      name: this.state.namePromotion,
      description: this.state.description,
      code: this.state.code,
      discount: this.state.discount,
      discount_type: this.state.discount_type,
      selected_users: this.state.selected_users.map(el=>el?el.value:null),
      start_datetime: moment(this.state.start_datetime).format('YYYY-MM-DD h:mm:ss'),
      end_datetime: moment(this.state.end_datetime).format('YYYY-MM-DD h:mm:ss'),
      budget: this.state.budget,
      max_type: this.state.max_type,
      max_user: this.state.max_user,
    }
    if (obj) {
      if (this.props.location.state !== undefined) {
        if (this.props.location.state.mode === 'Edit') {
          await post(obj, `admin/promotion/id/${this.props.location.state.id}/update`).then(
            (res) => {
              setTimeout(() => {
                const { history } = this.props
                const url = '/admin/promotion/list/promotionInactive'
                this.props.setCheckTypePath(true)
                history.push(url)
              }, 100)
            }
          )
        }
      } else {
        await post(obj, 'admin/promotion/add').then((res) => {
          setTimeout(() => {
            const { history } = this.props
            const url = '/admin/promotion/list/promotionInactive'
            this.props.setCheckTypePath(true)
            history.push(url)
          }, 100)
        })
      }
    }
  }

  render() {
    const {
      namePromotion,
      description,
      code,
      discount,
      discount_type,
      start_datetime,
      end_datetime,
      budget,
      max_type,
      max_user,
    } = this.state
    return (
      <div>
        <div>
          <div className="Rectangle-710-promotion">
            <div>
              <InpuntHeadTemp
                type="text"
                name="namePromotion"
                value={namePromotion}
                placeholder="Name of Promotion"
                onChange={this.handleChange}></InpuntHeadTemp>
              <InpuntHeadTemp1
                type="text"
                name="description"
                value={description}
                placeholder="Promotion’s description…"
                onChange={this.handleChange}></InpuntHeadTemp1>
            </div>
            <div className="Rectangle-30-promotion">
              <div className="col-md-12" style={{ marginTop: '20px', paddingLeft: '20px' }}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          className="Rectangle-31-promotion"
                          type="text"
                          name="code"
                          value={code}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-md-2" />
                      <div className="col-md-4 gentext-promotion">
                        <Button
                          style={{ width: '200.2px' }}
                          focus
                          className="btn"
                          label="Generate code"
                          onClick={() => this.onGenerateCode()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <label style={{ lineHeight: '40px', fontSize: '20px' }}>Condition</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row" style={{ paddingBottom: '10px' }}>
                      <div className="col-md-2">
                        <label style={{ paddingLeft: '25px' }}>Discount :</label>
                      </div>
                      <div className="col-md-10">
                        <input
                          className="Rectangle-31-promotion"
                          style={{ width: '368px', marginRight: '15px' }}
                          type="text"
                          value={discount}
                          name="discount"
                          onChange={this.handleChange}
                        />

                        <select
                          className="Rectangle-31-promotion"
                          style={{ width: '246px' }}
                          name="discount_type"
                          value={discount_type}
                          onChange={this.handleChange}>
                          <option value="BAHT">BAHT</option>
                          <option value="PERCENT">PERCENT(%)</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* ///////////////////// */}

                  <div className="col-md-12">
                    <div className="row" style={{ paddingBottom: '10px' }}>
                      <div className="col-md-2">
                        <label style={{ paddingLeft: '25px' }}>
                          Group of Users :
                        </label>
                      </div>
                      <div className="col-md-10" style={{ width: '368px' }}  >
                        <Select 
                          key={this.state.selected_users.map(el => `${el}`)}
                          value={this.state.selected_users}
                          isMulti
                          name="selected_users"
                          options={this.state.colourOptions}
                          onChange={this.handleChangeSelected}
                          // className="basic-multi-select"
                          className="Rectangle-31-promotionSelect"
                          classNamePrefix="select"
                        />
                      </div>
                    </div>
                  </div>
                  {/* ///////////////////// */}
                  <div className="col-md-12">
                    <div className="row" style={{ paddingBottom: '10px' }}>
                      <div className="col-md-2">
                        <label style={{ paddingLeft: '25px' }}>Start Dates :</label>
                      </div>
                      <div className="col-md-10">
                        <DatePicker
                          className="Rectangle-Datepicker-promotion"
                          value={start_datetime}
                          minDate = {this.state.currenDate}
                          showTimeSelect
                          name="start_datetime"
                          selected={start_datetime}
                          onChange={this.handleChangeStartDate}
                          timeFormat="p"
                          timeIntervals={15}
                          dateFormat=" dd/MM/yyyy h:mm aa"
                        />
                      </div>
                    </div>
                  </div>
                  {/* ///////////////////// */}
                  <div className="col-md-12">
                    <div className="row" style={{ paddingBottom: '10px' }}>
                      <div className="col-md-2">
                        <label style={{ paddingLeft: '25px' }}>End Dates :</label>
                      </div>
                      <div className="col-md-10">
                        <DatePicker
                          className="Rectangle-Datepicker-promotion"
                          value={end_datetime}
                          name="end_datetime"
                          showTimeSelect
                          selected={end_datetime}
                          minDate = {start_datetime}
                          onChange={this.handleChangeEndDate}
                          timeFormat="p"
                          timeIntervals={15}
                          dateFormat=" dd/MM/yyyy h:mm aa"
                        />
                      </div>
                    </div>
                  </div>
                  {/* ///////////////////// */}
                  <div className="col-md-12">
                    <div className="row" style={{ paddingBottom: '10px' }}>
                      <div className="col-md-3" style={{ marginRight: '-81px' }}>
                        <label style={{ paddingLeft: '25px' }}>Budget (Optional) :</label>
                      </div>
                      <div className="col-md-9">
                        <input
                          className="Rectangle-31-promotion"
                          style={{ width: '368px' }}
                          type="text"
                          value={budget}
                          name="budget"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  {/* ///////////////////// */}
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4" style={{ marginRight: '-50px' }}>
                        <label style={{ paddingLeft: '25px' }}>
                          Maximum times per User (Optional) :
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          className="Rectangle-31-promotion"
                          style={{ width: '256px', marginRight: '15px' }}
                          type="text"
                          name="max_user"
                          value={max_user}
                          onChange={this.handleChange}
                        />
                        <select
                          className="Rectangle-31-promotion"
                          style={{ width: '249px' }}
                          value={max_type}
                          name="max_type"
                          onChange={this.handleChange}>
                          <option value="BAHT">BAHT</option>
                          <option value="NUMBER">NUMBER OF USER</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Button
                style={{ width: '228.3px', float: 'right' }}
                focus
                className="btn"
                label="Save this Promotion"
                onClick={() => this.onSubmit()}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = () => ({})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    showBackIcon: dispatch.Title.showBackIcon,
    setCheckTypePath: dispatch.promotion.setCheckTypePath,
  }
}

export default connect(mapState, mapDispatch)(promotionAdd)
