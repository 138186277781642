export const databaseMaid = {
  state: {
    maids: [
      // {
      // "id": 13,
      // "first_name": "Attawat",
      // "last_name": "Panich",
      // "email": "north.ap@live.com",
      // "phone": null,
      // "role": "MAID",
      // "create_datetime": "2019-06-09T03:32:34.000Z"
      // }
    ],
    column: [
      { label: "Name of Maid", accessor: "name", isShow: true },
      { label: "Training completed date", accessor: "dateTrainComplete", isShow: true },
      { label: "First booking accepted date", accessor: "dateFirstbookingAccept", isShow: true },
      { label: "Last booking accepted date", accessor: "dateLastbookingAccept", isShow: true },
      { label: "# of logins", accessor: "noLogins", isShow: true },
      { label: "Salary", accessor: "salary", isShow: true },
      { label: "Hrs worked", accessor: "hrsWorked", isShow: true },
      { label: "# of users served", accessor: "noUserServed", isShow: true },
      { label: "# of services completed", accessor: "noServiceCompleted", isShow: true },
      { label: "# of ratings received", accessor: "noRatingsReceived", isShow: true },
      { label: "# of users who rated", accessor: "noUsersRated", isShow: true },
      {
        label: "% of ratings received per service",
        accessor: "pctRatingReceivedPerService",
        isShow: true,
      },
      {
        label: "% of ratings received per user",
        accessor: "pctRatingReceivedPerUser",
        isShow: true,
      },
      {
        label: "# of times Five Star Bonus achieved",
        accessor: "noTimesFiveStarBonusAchieved",
        isShow: true,
      },
      {
        label: "# of times Milestone Bonus achieved",
        accessor: "noTimesMilestoneBonusAchieved",
        isShow: true,
      },
      {
        label: "# of times Monthly Bonus achieved",
        accessor: "noTimesMonthlyBonusAchieved",
        isShow: true,
      },
      {
        label: "# of Referral Bonus achieved",
        accessor: "noTimesReferralBonusAchieved",
        isShow: true,
      },
      {
        label: "# of times maid rejected users schedule offer in MP booking",
        accessor: "noTimesRejectedInMPbooking",
        isShow: true,
      },
      {
        label: "# of total available Hrs marked in maid calendar in the next 1 month",
        accessor: "noTotalAvailableHrsNext1mth",
        isShow: true,
      },
      {
        label: "# of total available Hrs marked in maid calendar in the next 2 months",
        accessor: "noTotalAvailableHrsNext2mth",
        isShow: true,
      },
      {
        label: "# of total available Hrs marked in maid calendar in the next 3 months",
        accessor: "noTotalAvailableHrsNext3mth",
        isShow: true,
      },
    ],
  },
  reducers: {
    setMaidColumnList(state, payload) {
      // console.log(`payload: ${JSON.stringify(payload)}`)
      return { ...state, column: payload }
    },
    toggleShowMaidColumn(state, payload) {
      console.log(`payload: ${payload}`)
      console.log(`target: ${JSON.stringify(this.state)}`)
      return { ...state, column: { ...state.column } }
    },
  },
  effects: () => ({}),
}
