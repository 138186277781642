import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import './customerManagement.css'
import DragIcon from '../../images/drag.svg'
import MinusIcon from '../../images/minus.svg'
import PlusIcon from '../../images/plus.svg'
import BackIcon from '../../images/back.svg'

const MainContainer = styled.div`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

const CardContainer = styled.div`
  background: ${(props) => props.theme.rightCard};
  border: 1px solid ${(props) => props.theme.active};
  width: 100%;
  height: 44px;
  padding: 10px 20px;

  display: flex;
  justify-content: space-between;
  img {
    cursor: pointer;
  }
`

const Container = styled.div`
  display: flex;
  .hand {
    width: max-content;
    height: 100%;
    cursor: pointer;
  }
`
const Text = styled.div`
  margin-left: 15px;
`



class DragArea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      column: this.props.customerColumn,
    }
    this.onDragEnd = this.onDragEnd.bind(this)
    this.handleToggleShow = this.handleToggleShow.bind(this)
  }

  // state = {
  //   column: this.props.customerColumn,
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.customerColumn !== this.props.customerColumn) {
      console.log('update')
      this.setState({ column: this.props.customerColumn })
    }
  }

  

  handleToggleShow(value) {
    let newColumn = this.state.column
    const result = this.state.column.map((obj, index) => {
      if (obj.accessor === value) {
        const editObj = { ...obj, isShow: !obj.isShow }

        newColumn[index] = editObj
        return newColumn
      }
      return newColumn
    })
    this.setState({ column: result[0] })
    this.props.setCustomerColumnList(newColumn)
  }

  // No Dependency Trial
  onDragStart = (e, index) => {
    this.draggedItem = this.state.column[index]
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', e.target.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20)
  }

  onDragOver = (index) => {
    const draggedOverItem = this.state.column[index]

    // if the item is dragged over itself, ignore
    if (this.draggedItem === draggedOverItem) {
      return
    }

    // filter out the currently dragged item
    let items = this.state.column.filter((item) => item !== this.draggedItem)

    // add the dragged item after the dragged over item
    items.splice(index, 0, this.draggedItem)

    this.setState({ column: items })
    this.props.setCustomerColumnList(items)
  }

  onDragEnd = () => {
    this.draggedIdx = null
  }

  render() {
    // console.log(`DragArea state: ${JSON.stringify(this.state)}`)
    // console.log(`this props : ${JSON.stringify(this.props.customerColumn)}`)
    return (
      <MainContainer>
        <ul className="rightBarCustomer">
          {this.state.column.map((item, idx) => (
            <li key={item.accessor} onDragOver={() => this.onDragOver(idx)}>
            {this.props.mode === 'column' && (
                <CardContainer>
                  <Container>
                    <div
                      draggable
                      onDragStart={(e) => this.onDragStart(e, idx)}
                      onDragEnd={this.onDragEnd}>
                      <img alt="drag-icon" src={DragIcon} />
                    </div>
                    <Text>{item.label}</Text>
                  </Container>
                  <Container>
                    {item.isShow ? (
                      <div className="hand" onClick={() => this.handleToggleShow(item.accessor)}>
                        <img alt="minus-icon" src={MinusIcon} />
                      </div>
                    ) : (
                      <div className="hand" onClick={() => this.handleToggleShow(item.accessor)}>
                        <img alt="plus-icon" src={PlusIcon} />
                      </div>
                    )}
                  </Container>
                </CardContainer>
              )}
              {this.props.mode === 'filter' && (
                <CardContainer>
                  <Container>
                    <Text>{item.label}</Text>
                  </Container>
                  <Container>
                    <div className="hand" onClick={() => console.log('trigger filter')}>
                      <img alt="back-icon" src={BackIcon} />
                    </div>
                  </Container>
                </CardContainer>
              )}
            </li>
          ))}
        </ul>
      </MainContainer>
    )
  }
}

const mapState = (state) => ({
  maidColumn: state.maid.column,
  customerColumn: state.customer.column,
})

const mapDispatch = (dispatch) => {
  return {
    setMaidColumnList: dispatch.maid.setMaidColumnList,
    setCustomerColumnList: dispatch.customer.setCustomerColumnList,
    toggleShowMaidColumn: dispatch.maid.toggleShowMaidColumn,
    toggleShowCustomerColumn: dispatch.customer.toggleShowCustomerColumn,
  }
}

export default connect(
  mapState,
  mapDispatch
)(DragArea)


// // a little function to help us with reordering the result
// const reorder = (list, startIndex, endIndex) => {
//   // console.log(`list : ${list}`)
//   const result = Array.from(list)
//   const [removed] = result.splice(startIndex, 1)
//   result.splice(endIndex, 0, removed)

//   return result
// }

// onDragEnd(result) {
//   // dropped outside the list
//   // if (!result.destination) {
//   //   return
//   // }
//   // console.log(`result: ${JSON.stringify(result)}`)
//   // console.log(`customer: ${JSON.stringify(this.state.column)}`)
//   const items = reorder(this.state.column, result.source.index, result.destination.index)
//   // console.log(`items: ${JSON.stringify(items)}`)
//   this.props.setCustomerColumnList(items)
//   this.setState({ column: items })
// }

// <DragDropContext onDragEnd={this.onDragEnd}>
//   <Droppable droppableId="droppable">
//     {(provided, snapshot) => (
//       <div {...provided.droppableProps} ref={provided.innerRef}>
//         {this.state.column.map((item, index) => (
//           <Draggable key={item.accessor} draggableId={item.accessor} index={index}>
//             {(provided, snapshot) => (
//               <div
//                 ref={provided.innerRef}
//                 {...provided.draggableProps}
//                 {...provided.dragHandleProps}>
//                 <ItemCard item={item} handleToggleShow={this.handleToggleShow} />
//               </div>
//             )}
//           </Draggable>
//         ))}
//         {provided.placeholder}
//       </div>
//     )}
//   </Droppable>
// </DragDropContext>
