import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { post, get } from '../../utils/service'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import '../../style/react-table-custom.css'
// import CheckBox from '../../components/checkboxSettingGroup'
import swal from 'sweetalert';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex:1;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.active};
  background: #fff;
  height: max-content;
  position:relative;
  top: -1px;
  padding: 20px 20px 30px 50px;
  align-items: flex-start;
`
const CheckedboxContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15vw, 1fr));
  grid-auto-rows: 20px;
  flex: 1;
  width: 85%;
  padding-left: 15px;
`

class settingGroupMaid extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      column: this.props.columnSettingGroup,
      data: [],
      loading: false,
      pages: null,
      isSettingModalShow: false,
      isExportModalShow: false,
      disabled: false,
      broadcastMode: this.props.broadcastMode,
      resAnnouncement: [],
      userId_customer: [],
      PageSize: 11,
      numberPage: 1,
      totalPages: 0,
      totaldata: 0
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataForEditGroup.id !== this.props.dataForEditGroup.id && this.props.dataForEditGroup.id !== undefined) {
      if (this.props.dataForEditGroup.id !== '') {
        this.FindByIdDeatil('', '', this.props.dataForEditGroup.id)
        this.setState({ userId_customer: [] })
      }
    }
  }


  async componentDidMount() {
    this.props.setModeSettingGroup('Maid')
    await this.getAnnouncement()
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.mode === 'Edit' && this.props.location.state.type === 'MAID') {
        this.FindByIdDeatil(this.props.location.state.id, this.props.location.state.mode)
      }
    } else if (this.props.idDetailCustomer !== undefined) {
      if (this.props.idDetailCustomer !== '' && this.props.nameSettingGroup !== 'AddGroup') {
        this.FindByIdDeatil('', '', this.props.idDetailCustomer)
      }
    }
    if (this.props.keepcheckboxMaid !== undefined) {
      let tempData = this.state.data
      this.setState({ userId_customer: this.props.keepcheckboxMaid })
      if (this.props.keepcheckboxMaid !== []) {
        for (let i = 0; i < tempData.length; i++) {
          await this.state.userId_customer.forEach(v => {
            if (tempData[i].id === v) {
              if (tempData[i].checked === false && tempData[i].id === v) {
                tempData[i].checked = true
              }
            }

          })
        }
      }

      this.setState({ data: tempData })
    }
  }


  componentWillUnmount() {
    this._isMounted = false;
  }
  async FindByIdDeatil(id, mode, idDetailCustomer) {
    this.props.setCheckDisableMaid('')
    this._isMounted = true;
    let tempData = this.state.data
    if (this._isMounted) {
      if (id !== undefined && mode === 'Edit') {
        this.props.setIdDetailCustomer(id)
        await get(`admin/setting/group/id/${id}`).then(res => {
          if (this.props.checkDisable !== 'Delete') {
            if (res) {
              for (let i = 0; i < res.setting_group_users.length; i++) {
                tempData.forEach(v => {
                  if (v.id === res.setting_group_users[i].user_id) v.checked = true
                })
                this.setState({ data: tempData })
                this.state.userId_customer.push(res.setting_group_users[i].user_id)
                this.props.checkStateCheckbox('check')
              }
              this.props.setReloadDataForEditGroup({
                id: res.id,
                name: res.name,
                type: res.type,
                mode: 'Edit'
              })
              this.props.setreloadnameSettingGroup(res.name)
            }
            this.props.SetkeepcheckboxMaid(this.state.userId_customer)
            this.props.setCheckDisable('Maid')
          }

        })
      } else if (idDetailCustomer !== undefined && idDetailCustomer !== '') {
        tempData.forEach(v => {
          v.checked = false
        })
        const { history } = this.props
        history.replace({
          pathname: '/admin/group-setting/setting-group-Of-user/setting-group-maid',
          state: { id: idDetailCustomer, mode: 'Edit', type: 'CUSTOMER' }
        })
        this.props.setIdDetailCustomer(idDetailCustomer)
        if (this.props.checkDisable !== 'Delete' || this.props.checkDisable === 'Maid') {
          await get(`admin/setting/group/id/${idDetailCustomer}`).then(res => {
            if (res) {
              if (res.type === 'MAID') {
                for (let i = 0; i < res.setting_group_users.length; i++) {
                  this.state.userId_customer.push(res.setting_group_users[i].user_id)
                  this.props.checkStateCheckbox('check')
                }
                this.props.setReloadDataForEditGroup({
                  id: res.id,
                  name: res.name,
                  type: res.type,
                  mode: 'Edit'
                })
                this.props.setreloadnameSettingGroup(res.name)
                this.props.SetkeepcheckboxMaid(this.state.userId_customer)
                this.props.setCheckDisable('Maid')
              } else {
                this.props.SetkeepcheckboxMaid([])
              }

            }
          })
          for (let i = 0; i < tempData.length; i++) {
            this.state.userId_customer.forEach(v => {
              if (tempData[i].id === v) {
                if (tempData[i].checked === false && tempData[i].id === v) {
                  tempData[i].checked = true
                }
              }
            })
          }
          this.setState({ data: tempData })
        }

      }
    }
    this.props.SetkeepcheckboxCus([])
  }
  Cancel() {
    this.props.setCheckDisable('Delete')
    this.props.setCheckDisableMaid('')
    this.props.SetkeepcheckboxCus([])
    this.props.SetkeepcheckboxMaid([])
    this.setState({ userId_customer: [] })
    this.state.data.forEach(v => {
      v.checked = false
    })
  }
  DeleteListCustomer() {
    this.props.setCheckDisable('Maid')
    this.props.SetkeepcheckboxCus([])
    this.props.setIdDetailCustomer('')
    let temp = []
    temp.push(this.state.userId_customer[this.state.userId_customer.length - 1])
    if (temp.length > 0) {
      this.setState({ userId_customer: temp })
    }
    this.props.SetkeepcheckboxMaid(temp)
  }
  async save() {
    let { obj } = this.state
    obj = {
      name: this.props.nameSettingGroup ? this.props.nameSettingGroup : this.props.dataForEditGroup.name,
      type: "MAID",
      user_ids: this.state.userId_customer
    }
    if (obj) {
      if (this.props.modeUPdate === 'Edit') {
        await post(obj, `admin/setting/group/id/${this.props.IDForUPdate}/update`).then(res => {
          setTimeout(() => {
            const { history } = this.props
            const url = '/admin/group-setting/setting-group'
            history.push(url)
          }, 100)
        })
        this.props.getSettingGroupList()

      } else if (this.props.modeUPdate === 'Add') {
        await post(obj, `admin/setting/group/add`).then(res => {
          setTimeout(() => {
            const { history } = this.props
            const url = '/admin/group-setting/setting-group'
            history.push(url)
          }, 100)
          this.props.getSettingGroupList()
        })
      }
    }
  }
  async getAnnouncement() {
    try {
      let resp = []
      resp = await get('admin/maid/list')
      for (let i = 0; i < resp.maids.length; i++) {
        this.setState({ loading: false, totalPages: resp.page_count, totaldata: resp.item_count })
        this.state.resAnnouncement.push({
          id: resp.maids[i].user.id,
          picture: resp.maids[i].user.image_url,
          name: resp.maids[i].user.first_name + ' ' + resp.maids[i].user.last_name,
          Email: resp.maids[i].user.email,
          Role: resp.maids[i].user.role,
          checked: false
        })
      }
      this.setState({ data: this.state.resAnnouncement })

    } catch (error) {
      console.log(error)
    }
  }

  onScroll = (e) => {
    let temph = 0
    let tempt = 0
    temph = e.target.scrollHeight
    tempt = e.target.scrollTop
    let calHT = temph - tempt
    let tempc = e.target.clientHeight
    if (calHT === tempc) {
      this.onFaceDataOnScroll(calHT, tempc)

    }
  }
  async onFaceDataOnScroll(calHT, tempc) {
    if (calHT === tempc) {
      if (this.state.totalPages > 1 && this.state.data.length < this.state.totaldata) {
        if (this.state.totalPages >= this.state.numberPage) {
          await this.setState({ numberPage: this.state.numberPage + 1, loading: true })
          get(`admin/maid/list?page=${this.state.numberPage}&limit=10`).then(resp => {
            if (resp.maids.length >= 0) {
              for (let i = 0; i < resp.maids.length; i++) {
                this.state.data.push({
                  id: resp.maids[i].user.id,
                  picture: resp.maids[i].user.image_url,
                  name: resp.maids[i].user.first_name + ' ' + resp.maids[i].user.last_name,
                  Email: resp.maids[i].user.email,
                  Role: resp.maids[i].user.role,
                  checked: false
                })
              }
              this.state.data.forEach(v => {
                this.state.userId_customer.forEach(v1 => {
                  if (v.checked === false && v.id === v1) {
                    v.checked = true
                  }
                })
              })
              this.setState({
                loading: false,
                PageSize: this.state.data.length
              })

            }

          })
        }
      }
    }
  }

  async handleChange(row) {
    let tempD = this.state.data
    if (this.props.checkDisable === 'Customer') {
      swal({
        className: 'swal-modal',
        title: "Are you sure?",
        text: `คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการเลือกรายชื่อ user ก่อนหน้านี้ทั้งหมด หากท่านต้องการเลือกรายชื่อ user ที่อยู่ในรายชื่อ Customer ข้อมูลที่ท่านเลือกจากรายชื่อ Maid ทั้งหมดจะถูกยกเลิกคุณต้องการยกเลิกรายชื่อ user ก่อนหน้านี้ทั้งหมดหรือไม่`,
        icon: "warning",
        buttons: {
          cancel: `ไม่ต้องการยกเลิกรายการก่อนหน้า`,
          confirm: `ต้องการยกเลิกรายการก่อนหน้า`,
        },
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            this.DeleteListCustomer()
            swal("deleted Success", {
              icon: "success",
            });

          } else {
            this.props.SetkeepcheckboxMaid([])
            this.props.setCheckDisableMaid('disabled')
            this.setState({ userId_customer: [] })
            tempD.forEach(v => {
              if (v.checked === true) {
                v.checked = false
              }
            })
            this.setState({ data: tempD })
          }
        });
    }

    await tempD.forEach(v => {
      if (v.id === row.original.id) {
        if (v.checked === false) {
          v.checked = true
        } else {
          v.checked = false
        }
      }
    })
    this.setState({ data: tempD })
    if (this.state.userId_customer.indexOf(row.original.id) >= 0) {
      const temp = await this.state.userId_customer.filter(el => el !== row.original.id)
      if (temp.length >= 0) {
        this.state.userId_customer = temp
        this.props.checkStateCheckbox('check')
        if (temp.length > 0) {
          this.props.setCheckDisable('Maid')
        } else {
          this.props.setCheckDisable('')
        }
      }
    } else if (this.state.userId_customer.indexOf(row.original.id) < 0) {
      this.state.userId_customer.push(row.original.id)
      this.props.checkStateCheckbox('check')
      this.props.setCheckDisable('Maid')
    }
    this.props.SetkeepcheckboxMaid(this.state.userId_customer)

  }

  render() {
    const allColumns = [
      {
        Header: "",
        accessor: "check",
        Cell: (row) => (
          <CheckedboxContent>
            {
              this.props.checkDisableMaid === 'disabled' ?
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id={row.original.id} checked={row.original.checked} onChange={this.handleChange.bind(this, row)} disabled />
                  <label className="custom-control-label" htmlFor={row.original.id} style={{ cursor: 'pointer' }} />
                </div>
                : <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id={row.original.id} checked={row.original.checked} onChange={this.handleChange.bind(this, row)} />
                  <label className="custom-control-label" htmlFor={row.original.id} style={{ cursor: 'pointer' }} />
                </div>
            }
          </CheckedboxContent>
        ),
        minWidth: 35,
      },
      {
        Header: "",
        Cell: (row) => (
          <div style={{ width: '100%' }}>
            <img
              src={`${row.original.picture}`}
              alt="profile3"
              className="rounded-circle"
              width={35}
            />
          </div>
        ),
        accessor: 'picture', // String-based value accessors!
        minWidth: 30,
      },
      {
        Header: () => <h6>Name</h6>,
        accessor: 'name', // String-based value accessors!
        minWidth: 80,
      },
      {
        Header: () => <h6>Email</h6>,
        accessor: 'Email',
        minWidth: 100,
      },
      {
        Header: () => <h6>Role</h6>,
        accessor: 'Role',
        minWidth: 80
      },
    ]

    let columnWillShow = []
    this.state.column.map((colName) => {
      if (this.state.broadcastMode === 'InAppMessage' && colName.accessor === "type") {
        colName.isShow = false
      }
      for (var obj of allColumns) {
        colName.isShow && obj.accessor === colName.accessor &&
          columnWillShow.push(obj)
      }
      return {}
    })
    return (

      <div >
        <Card className="col-sm-6 col-xs-8 col-md-9 col-xl-10">
          {this.state.data.length >= 0 ?
            <div onScroll={this.onScroll} style={{ width: '100%' }}>
              <ReactTable
                data={this.state.data} //data object
                columns={columnWillShow}  //column config object
                loading={this.state.loading}
                pageSize={this.state.PageSize}
                showPagination={false}
                style={{
                  height: "400px"
                }}
                className="-striped -highlight"
              />
            </div>
            :
            null
          }
        </Card>
        <div className="col-sm-6 col-xs-8 col-md-9 col-xl-10" style={{ textAlign: 'end', paddingTop: '20px' }}>

          {
            this.props.checkDisable !== '' ?
              <button
                className={
                  'btn ' +
                  (this.state.FilterbyMode === 'Filterby'
                    ? 'active-button-broadcast'
                    : 'btn-button-broadcast')
                }
                onClick={() => this.Cancel()}>
                Cancel
        </button>
              : null
          }
          {
            this.props.keepcheckboxMaid.length > 0 ?
              <button
                className={
                  'btn ' +
                  (this.state.FilterbyMode === 'Filterby'
                    ? 'active-button-broadcast'
                    : 'btn-button-broadcast')
                }
                onClick={() => this.save()}>
                Save this group
        </button>
              : null
          }
        </div>
      </div>
    )
  }
}


const mapState = (state) => ({
  columnSettingGroup: state.settingGroup.columnSettingGroup,
  nameSettingGroup: state.settingGroup.nameSettingGroup,
  modeSettingGroup: state.settingGroup.modeSettingGroup,
  stateCheckbox: state.settingGroup.stateCheckbox,
  idDetailCustomer: state.settingGroup.idDetailCustomer,
  dataForEditGroup: state.settingGroup.dataForEditGroup,
  checkDisable: state.settingGroup.checkDisable,
  keepcheckboxMaid: state.settingGroup.keepcheckboxMaid,
  checkDisableMaid: state.settingGroup.checkDisableMaid,
  modeUPdate: state.settingGroup.modeUPdate,
  IDForUPdate: state.settingGroup.IDForUPdate
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    setModeSettingGroup: dispatch.settingGroup.setModeSettingGroup,
    checkStateCheckbox: dispatch.settingGroup.checkStateCheckbox,
    setIdDetailCustomer: dispatch.settingGroup.setIdDetailCustomer,
    setReloadDataForEditGroup: dispatch.settingGroup.setReloadDataForEditGroup,
    setnameSettingGroup: dispatch.settingGroup.setnameSettingGroup,
    setreloadnameSettingGroup: dispatch.settingGroup.setreloadnameSettingGroup,
    setCheckDisable: dispatch.settingGroup.setCheckDisable,
    SetkeepcheckboxMaid: dispatch.settingGroup.SetkeepcheckboxMaid,
    SetkeepcheckboxCus: dispatch.settingGroup.SetkeepcheckboxCus,
    setCheckDisableMaid: dispatch.settingGroup.setCheckDisableMaid,
    getSettingGroupList: dispatch.settingGroup.getSettingGroupList
  }
}

export default connect(
  mapState,
  mapDispatch
)(settingGroupMaid)

