import React, { Component } from 'react'
import Button from './../../components/button'

import Rightbar from './rightbar'

class DatabaseLayout extends Component {
  state = {
    showRightbar: false,
  }

  handleToggle = (key) => {
    this.setState((prevState) => ({ [key]: !prevState[key] }))
  }

  render() {
    return (
      <div>
        <div className="page-breadcrumb">
          <div className="btn-container">
            <Button className="btn" label="Export to..." />
            <Button className="btn" label="Setting" onClick={() => this.handleToggle('showRightbar')} />
          </div>
          {this.props.children}
        </div>
        <Rightbar data={this.state} />
      </div>
    )
  }
}

export default DatabaseLayout
