import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import ReactTable from "react-table"
import "react-table/react-table.css"
import "../../style/react-table-custom.css"
import moment from "moment"
import request from "../../utils/request"
import Pagination from "../../components/paginationTable/pagination"
import Button from "../../components/button"
import TabComponent from "./../../components/tabComponent"
import Rightbar from "../../layouts/maidManagementLayout/rightbar"
import "./maidTableHeader.css"
import swal from "sweetalert"
import DatePicker from "react-datepicker"

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.active};
  background: #fff;
  height: max-content;
  width: 100%;
  position: relative;
  top: -1px;
  padding: 10px;
  align-items: flex-start;
  overflow: auto;
`
const HeaderTable = styled.div`
  text-align: left;
`
const Overlay = styled.div`
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
`
class MaidMonthlyPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      column: this.props.maidColumn,
      columnMaidProfile: this.props.columnMaidProfile,
      resAnnouncement: [],
      data: [],
      totaldata: 0,
      pages: 0,
      checktext: "",
      textSearch: "",
      tempDataTable: [],
      datetime: new Date(localStorage.getItem("datetime_monthly") || new Date()),
      loading: false,
    }
  }

  componentDidMount() {
    this.props.setPageTitle("Monthly List")
    this.onFeactData()
    // this.props.showBackIcon(true)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.column !== this.props.maidColumn ||
      prevState.columnMaidProfile !== this.props.columnMaidProfile
    ) {
      this.setState({
        column: this.props.maidColumn,
        columnMaidProfile: this.props.columnMaidProfile,
      })
      this.props.setPageTitle("Monthly List")
    }
  }

  formatNumber(num) {
    var val = (Number(num) * 100) / 100
    var parts = val.toString().split(".")
    var num1 =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1].substr(0, 2) : ".00")
    return num1
  }

  async onFeactData(page) {
    let month = await moment(this.state.datetime).format("MM")
    let year = await moment(this.state.datetime).format("YYYY")
    let pageNO = page ? page.page : 1
    let limit = 5000
    
    this.setState({ loading: true, tempDataTable: [] })
    try {
      request
        .get(
          `/maid/monthly?month=${month}&year=${year}&is_published=false&page=${pageNO}&limit=${limit}`
        )
        .then((res) => {
          let resp = res.data
          console.log(`: ------------------------------------------`)
          console.log(`MaidMonthlyPage -> onFeactData -> resp`, resp)
          console.log(`: ------------------------------------------`)

          let tempArr = []
          if (resp.result.length > 0) {
            for (let i = 0; i < resp.result.length; i++) {
              if (resp.result[i].id) {
                let maids = resp.result[i]
                tempArr.push({
                  App_ID: maids.id,
                  Maid_ID: maids.real_maid_id ? parseInt(maids.real_maid_id) : 0,
                  name: maids.first_name + " " + maids.last_name,
                  Average_Ratings: maids.rating ? maids.rating : -1,
                  Repeat_Rate: typeof maids.repeat_rate === "number" ? maids.repeat_rate : -1,
                  status: maids.status === null || !maids.status ? "-" : maids.status,
                  Is_Published: maids.status == "PUBLISHED",
                  // Mounth: month + "/" + year,
                  Work_completed: maids.booking_calendar_completed,
                  Hrs_completed: maids.booking_calendar_hours,
                  salary: maids.income,
                  star_bonus: maids.bonus_five_star,
                  Monthly_bonus: maids.bonus_per_month,
                  Milestone_bonus: maids.bonus_total_hours,
                  maidIdPro: maids.id,
                })
              }
            }

            tempArr.sort((a, b) => b.Maid_ID - a.Maid_ID)

            this.setState(
              {
                data: tempArr,
                loading: false,
                totaldata: resp.result.length,
                pages: Math.ceil(resp.result.length / limit),
              },
              () => this.filterdataList(tempArr)
            )
          } else {
            this.setState({ data: [], loading: false, totaldata: 0, pages: 0 })
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  handleToggle = (key) => {
    this.setState((prevState) => ({ [key]: !prevState[key] }))
    const { history } = this.props
    history.push(this.props.history.location.pathname)
  }

  handleColumn = () => {
    this.setState({ showRightbar: true, mode: "column" })
  }
  handleFilter = () => {
    this.setState({ showRightbar: true, mode: "filter" })
  }

  onChangeTab = (path) => {
    const { history, match } = this.props
    history.push(match.url + path)
  }
  onSentTextSearch = () => {
    if (this.state.textSearch !== "") {
      this.setState({ checktext: this.state.textSearch }, () => this.onFeactData())
    } else if (this.state.textSearch === "" && this.state.checktext !== "") {
      this.setState({ checktext: "clearSearch" }, () => this.onFeactData())
    } else {
      this.onFeactData()
    }
  }

  onExportData() {
    swal({
      className: "swal-modal",
      title: "Are you sure?",
      text: `Are you sure export data Monthly List?`,
      buttons: ["Cancel", "Confirm"],
      defeat: true,
    }).then((willDelete) => {
      if (willDelete) {
        let month = moment(this.state.datetime).format("MM")
        let year = moment(this.state.datetime).format("YYYY")

        // request.get(`maid/monthly/csv?month=${month}&year=${year}`).then((res) => {
        //   let url = window.URL.createObjectURL(new Blob([res.data]))
        //   let link = document.createElement("a")
        //   link.href = url
        //   link.setAttribute("download", `Monthly_List_${month}_${year}.csv`)
        //   document.body.appendChild(link)
        //   link.click()
        //   document.body.removeChild(link)

        //   swal("Export Success", {
        //     icon: "success",
        //   })
        // })

        let csv = []
        let rows = this.state.data
        const header = {
          App_ID: "App ID",
          Maid_ID: "Maid ID",
          name: "Maid Name",
          Work_completed: "Work Completed",
          Hrs_completed: "Hrs Completed",
          salary: "Salary(THB)",
          star_bonus: "5 Star Bonus",
          Monthly_bonus: "Monthly Bonus",
          Milestone_bonus: "Milestone Bonus",
        }
        csv.push(Array.from(Object.values(header)))
        rows.map((row) => {
          let temp = []
          for (let key in header) {
            temp.push(row[key])
          }
          csv.push(temp)
        })
        let csvContent = "data:text/csv;charset=utf-8,"
        csv.forEach(function (rowArray) {
          let row = rowArray.join(",")
          csvContent += row + "\r\n"
        })
        let encodedUri = encodeURI(csvContent)
        let link = document.createElement("a")
        link.setAttribute("href", encodedUri)
        link.setAttribute("download", `Monthly_List_${month}_${year}.csv`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        swal("Export Success", {
          icon: "success",
        })
      }
    })
  }

  compareBy(key, order) {
    return function (a, b) {
      if (order) {
        if (a[key] < b[key]) {
          return 1
        }
        if (a[key] > b[key]) {
          return -1
        }
        return 0
      } else {
        if (a[key] > b[key]) {
          return 1
        }
        if (a[key] < b[key]) {
          return -1
        }
        return 0
      }
    }
  }

  async togleSorting(newSorted, column, shiftKey) {
    let arrayCopy =
      (await this.state.tempDataTable.length) > 0
        ? [...this.state.tempDataTable]
        : [...this.state.data]
    arrayCopy.sort(this.compareBy(column.id, newSorted[0].desc))
    this.setState({ data: arrayCopy, tempDataTable: arrayCopy })
  }

  async filterdataList(data) {
    let arr = []
    if (this.props.averageListFilter.length === 0 || this.props.repeatRateListFilter.length === 0) {
      arr = ["noData"]
    } else {
      arr = await data.filter(
        (v) =>
          this.checkFilterAverRageList(v.Average_Ratings) &&
          this.checkFilterRepeateRateList(v.Repeat_Rate) &&
          this.checkFilterPublishedList(v.Is_Published)
      )
      if (arr.length === 0) {
        arr = ["noData"]
      }
    }
    this._on_data_filter(arr)
  }
  checkFilterAverRageList(averageRatings) {
    if (averageRatings >= 4.85) {
      return this.props.averageListFilter.findIndex((e) => e.id === 1) > -1
    } else if (averageRatings >= 4.7 && averageRatings <= 4.85) {
      return this.props.averageListFilter.findIndex((e) => e.id === 2) > -1
    } else if (averageRatings >= 4.55 && averageRatings <= 4.7) {
      return this.props.averageListFilter.findIndex((e) => e.id === 3) > -1
    } else if (averageRatings >= 4.1 && averageRatings <= 4.55) {
      return this.props.averageListFilter.findIndex((e) => e.id === 4) > -1
    } else if (averageRatings >= 3.95 && averageRatings <= 4.1) {
      return this.props.averageListFilter.findIndex((e) => e.id === 5) > -1
    } else {
      return this.props.averageListFilter.findIndex((e) => e.id === 6) > -1
    }
  }
  checkFilterRepeateRateList(repeatRate) {
    if (repeatRate >= 90) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 1) > -1
    } else if (repeatRate >= 80 && repeatRate <= 90) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 2) > -1
    } else if (repeatRate >= 70 && repeatRate <= 80) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 3) > -1
    } else if (repeatRate >= 60 && repeatRate <= 70) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 4) > -1
    } else if (repeatRate >= 50 && repeatRate <= 60) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 5) > -1
    } else if (repeatRate >= 40 && repeatRate <= 50) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 6) > -1
    } else if (repeatRate >= 30 && repeatRate <= 40) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 7) > -1
    } else if (repeatRate >= 20 && repeatRate <= 30) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 8) > -1
    } else if (repeatRate >= 10 && repeatRate <= 20) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 9) > -1
    } else {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 10) > -1
    }
  }
  checkFilterPublishedList(is_published) {
    if (is_published) {
      return true
    }
    return this.props.publishedListFilter.findIndex((e) => e.id === 1) > -1
  }

  _on_data_filter(data) {
    this.setState({ tempDataTable: data })
  }
  openProfileMaid(data) {
    const url = `#/admin/maid-management/maidProfile/${data.maidIdPro}`
    const state = data
    window.open(url, state)
  }

  handleChangeStartDate = (e) => {
    localStorage.setItem("datetime_monthly", e)
    this.setState({ datetime: e }, () => this.onFeactData())
  }
  render() {
    const allColumns = [
      {
        Header: () => <HeaderTable>App ID</HeaderTable>,
        accessor: "App_ID", // String-based value accessors!
        minWidth: 40,
        Cell: (row) => (
          <div className="maidAllTable">
            {row.original !== "noData"
              ? row.original.App_ID === -1
                ? "-"
                : row.original.App_ID
              : "-"}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable style={{ paddingLeft: 5 }}>Maid ID</HeaderTable>,
        accessor: "Maid_ID",
        Cell: (row) => (
          <div style={{ textAlign: "left", paddingLeft: 5 }}>{row.original.Maid_ID || "-"}</div>
        ),
        minWidth: 50,
      },
      {
        Header: () => <HeaderTable>Maid Name</HeaderTable>,
        accessor: "name", // String-based value accessors!
        minWidth: 100,
      },
      // {
      //   Header: () => <HeaderTable style={{ paddingLeft: "10px" }}>Month</HeaderTable>,
      //   accessor: "Mounth",
      //   minWidth: 80,
      // },
      {
        Header: () => <HeaderTable>Work Completed</HeaderTable>,
        accessor: "Work_completed",
        minWidth: 110,
        Cell: (row) => (
          <div>
            {this.formatNumber( row.original.Work_completed)}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable>Hrs Completed</HeaderTable>,
        accessor: "Hrs_completed",
        minWidth: 100,
        Cell: (row) => (
          <div>
            {this.formatNumber( row.original.Hrs_completed)}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable>Salary(THB)</HeaderTable>,
        accessor: "salary",
        minWidth: 90,
        Cell: (row) => (
          <div>
            {this.formatNumber( row.original.salary)}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable>5 Star Bonus</HeaderTable>,
        accessor: "star_bonus",
        minWidth: 90,
        Cell: (row) => (
          <div>
            {this.formatNumber( row.original.star_bonus)}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable>Monthly Bonus</HeaderTable>,
        accessor: "Monthly_bonus",
        minWidth: 100,
        Cell: (row) => (
          <div>
            {this.formatNumber( row.original.Monthly_bonus)}
          </div>
        ),
      },
      {
        Header: () => "Milestone Bonus",
        accessor: "Milestone_bonus",
        minWidth: 110,
        Cell: (row) => (
          <div>
            {this.formatNumber( row.original.Milestone_bonus)}
          </div>
        ),
      },
    ]

    let columnWillShow = allColumns
    const tabData = [
      {
        label: "Monthly List",
        total: this.state.tempDataTable.length > 0 ? this.state.tempDataTable.length : this.state.totaldata,
        key: "0",
        path: "/maid",
      },
    ]
    const currentLocation = this.props.history.location.pathname

    return (
      <div>
        {this.state.showRightbar && <Overlay onClick={() => this.handleToggle("showRightbar")} />}
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 btn-container" style={{ paddingTop: "5px" }}>
              {/* <Button
                style={{ float: "right", fontSize: "11px", width: "125px", height: "40px" }}
                className="btn"
                label="Column setting"
                onClick={() => this.handleColumn()}
              /> */}
              <Button
                style={{ float: "right", fontSize: "11px", width: "125px", height: "40px" }}
                className="btn"
                label="Filter by"
                onClick={() => this.handleFilter()}
              />
              <Button
                style={{ float: "right", fontSize: "11px", width: "125px", height: "40px" }}
                className="btn"
                label="Export"
                onClick={() => this.onExportData()}
              />
              <div className="" style={{ float: "right", marginRight: "10px" , marginTop: "5px" , marginLeft: "15px"}}>
                <label style={{ fontSize: "18px", fontWeight: "500px" }}>Month / Year :</label>
              </div>
              <div className="Datepicker_react_Popup">
                <DatePicker
                  className="Datepicker_react"
                  showMonthYearPicker
                  value={this.state.datetime}
                  selected={this.state.datetime}
                  onChange={(e) => this.handleChangeStartDate(e)}
                  dateFormat="MM/yyyy"
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"], // don't allow it to flip to be above
                    },
                    preventOverflow: {
                      enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                      enabled: false, // turn off since needs preventOverflow to be enabled
                    },
                  }}
                />
              </div>
            </div>
            {/* <div className="col-md-3 btn-container">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  id="searchText"
                  type="text"
                  className="inPut-search-textMaild"
                  placeholder="Search.."
                  name="textSearch"
                  value={this.state.textSearch}
                  onChange={(e) => this.setState({ textSearch: e.target.value })}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      this.onSentTextSearch()
                    }
                  }}
                />
                <button
                  id="searchTextmaid"
                  type="submit"
                  className="search-containe-button"
                  onClick={this.onSentTextSearch}>
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div> */}
          </div>
        </div>
        <TabComponent transparent style={{}} tabData={tabData} onChangeTab={this.onChangeTab} />
        <Card>
          <ReactTable
            data={this.state.tempDataTable.length > 0 ? this.state.tempDataTable : this.state.data}
            columns={columnWillShow} //column config object
            loading={this.state.loading}
            pageSize={25}
            page={this.state.page}
            showPagination={true}
            PaginationComponent={Pagination}
            pages={this.state.pages}
            manual
            onSortedChange={(newSorted, column, shiftKey) =>
              this.togleSorting(newSorted, column, shiftKey)
            }
            onPageChange={(page) =>
              this.setState({ page: page }, () => {
                this.onFeactData({ page: page + 1 })
              })
            }
            getTrGroupProps={(state, rowInfo) => {
              if (rowInfo !== undefined) {
                return {
                  onDoubleClick: () => {
                    this.openProfileMaid(rowInfo.original)
                  },
                }
              }
            }}
            getTrProps={(state, rowInfo) => {
              return {
                style: {
                  height: 25,
                  overflow: "hidden",
                },
              }
            }}
            className="-striped -highlight maidListetable"
          />
        </Card>
        <Rightbar
          data={this.state}
          currentLocation={currentLocation}
          dataFilter={this.state.data}
          data_filter={(data) => this._on_data_filter(data)}
        />
        {this.props.children}
      </div>
    )
  }
}

const mapState = (state) => ({
  maidColumn: state.maid.column,
  columnMaidProfile: state.maid.columnMaidProfile,
  totalPageList: state.maid.totalPageList,
  averageListFilter: state.maid.averageListFilter,
  repeatRateListFilter: state.maid.repeatRateListFilter,
  publishedListFilter: state.maid.publishedListFilter,
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    showBackIcon: dispatch.Title.showBackIcon,
    getMaidList: dispatch.maid.getMaidList,
  }
}

export default connect(mapState, mapDispatch)(MaidMonthlyPage)
