import React, { Component } from 'react'
import { connect } from 'react-redux'

class SettingPage extends Component {

  componentDidMount(){
    this.props.setPageTitle("Profile Setting")
  }

  render() {
    return (
      <div>
      settingPage 
      </div>
    )
  }
}

const mapState = () => ({})

const mapDispatch = (dispatch) => {
  return { setPageTitle: dispatch.Title.setPageTitle }

}


export default connect(
  mapState,
  mapDispatch
)(SettingPage)
