import React, { Component } from 'react'
import Modal from 'react-modal'
// import styled from 'styled-components'
import ErrorBoundary from '../errorBoundary'

const ModalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(38, 38, 38, 0.6)',
    zIndex: 400,
  },
  content: {
    // top: 0,
    // left: 0,
    // right: 0,
    // bottom: 0,
    // position: 'absolute',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    margin: 'auto',
    padding: 0,
  },
}

class CustomModal extends Component {
  static propTypes = {}

  render() {
    const { isOpen, customStyle = {}, onRequestClose, children } = this.props
    const overlay = {
      ...ModalStyle.overlay,
      ...customStyle.overlay,
    }
    const content = {
      ...ModalStyle.content,
      ...customStyle.content,
    }
    return (
      <ErrorBoundary>
        <Modal isOpen={isOpen}  style={{ overlay, content }} onRequestClose={onRequestClose} 
        ariaHideApp={false}  >
          {children}
        </Modal>
      </ErrorBoundary>
    )
  }
}

CustomModal.defaultProps = {
  customStyle: {},
}

export default CustomModal
