import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ChatLeft from "./chatLeft";
import ChatRight from "./chatRight";
import { USER_ID } from "../../store/models/sbConstants";
import request from "../../utils/request";
// import messageApi from '../../utils/sendbird/messageApi'

const ChatBoxPage = (props) => {
  const [curChannel, setChannel] = useState();
  const [channeList, setChanellList] = useState([]);
  const [role, setRole] = useState("CUSTOMER");
  const [loading, setLoading] = useState(true);

  if (Object.keys(props.sbUser).length === 0) {
    const fetchData = async () => {
      // fetchData();
      props.setPageTitle("Chat Box");
      console.log(" props", props);
      // await props.sbConnect()
      await props.sbGetGroupChannelList();
    };

    fetchData();
  }

  useEffect(() => {
    let findNotAdmin = {};
    let arr = [];
    if (
      props.match.params.id &&
      props.match.params.role &&
      props.match.params.chanel
    ) {
      arr = props.sbChannelList.filter((v) => {
        return v.url === props.match.params.chanel;
      });
      console.log("arr", arr);
      console.log("props.sbChannelList", props.sbChannelList);
      console.log("props.match.params.chanel", props.match.params.chanel);
      setRole(props.match.params.role);
      if (arr.length > 0) {
        setChanellList(arr);
      }
    } else {
      if (role === "MAID") {
        request.get(`/maid/list?search=&page=1&limit=5000`).then((res) => {
          let tempArr = [];
          let resp = res.data;
          if (resp.maids.length > 0) {
            for (let i = 0; i < resp.maids.length; i++) {
              if (resp.maids[i].real_maid_id) {
                tempArr.push(resp.maids[i].user.id);
              }
            }
            props.sbChannelList.forEach((v) => {
              findNotAdmin = v.members.find((u) => u.userId !== USER_ID);
              if (
                findNotAdmin &&
                findNotAdmin.metaData.role === role &&
                tempArr.includes(Number(findNotAdmin.userId))
              ) {
                arr.push(v);
              }
            });
          }
          setChanellList(arr);
          setLoading(false);
        });
      } else {
        props.sbChannelList.forEach((v) => {
          findNotAdmin = v.members.find((u) => u.userId !== USER_ID);
          if (findNotAdmin && findNotAdmin.metaData.role === role) {
            arr.push(v);
          }
        });
        setChanellList(arr);
        setLoading(false);
      }
    }
  }, [props.sbChannelList, role, props.match.params]);

  const onClickGroupChannel = (channel) => {
    if (!curChannel || curChannel.url !== channel.url) {
      setChannel(channel);
      props.sbGetMessageList(channel.url);
      // props.sbGetMessageList({ channelUrl: channel.url })
    }
  };

  props.sbGetMessageList(props.sbChannelList);

  const onInputFocus = () => {
    props.sbMarkRead(curChannel);
  };
  const onCloseChatRight = () => {
    let arr = [];
    props.sbSetMessageList(arr);
    setChannel(null);
  };

  const onSendMessage = (text) => {
    const data = {
      message: text,
      channelUrl: curChannel.url,
    };
    props.sbSendMessage(data);
  };

  const onSendFile = (file) => {
    file.channelUrl = curChannel.url;
    props.sbSendFile(file);
  };

  const filterChannel = (filter) => {
    props.sbGetGroupChannelList(filter);
  };

  const onFilterRole = (role) => {
    setLoading(true);
    setRole(role);
  };

  // const loadChannelList = (limit) => {
  //     props.sbGetGroupChannelList({ limit })
  // }

  // const loadMessageList = (limit) => {
  //     const payload = {
  //         limit,
  //         channelUrl: curChannel.url
  //     }
  //     props.sbGetMessageList(payload)
  // }

  return (
    <div>
      <ChatLeft
        channelList={channeList}
        onClickGroupChannel={onClickGroupChannel}
        channel={curChannel}
        filterChannel={filterChannel}
        role={role}
        loading={loading}
        onFilterRole={onFilterRole}
        onInputFocus={onInputFocus}
        messageList={props.checkNewMessage}
      />
      <ChatRight
        messageList={props.sbMessageList}
        userId={props.sbUser.userId}
        onInputFocus={onInputFocus}
        onSendMessage={onSendMessage}
        onSendFile={onSendFile}
        channel={curChannel}
        onCloseChatRight={onCloseChatRight}
      />
      ChatBoxPage
    </div>
  );
};

const mapState = (state) => {
  return {
    sbUser: state.sbUser.sbUser,
    sbChannelList: state.sbChannel.sbChannelList,
    sbMessageList: state.sbMessage.sbMessageList,
    checkNewMessage: state.sbMessage.checkNewMessage,
  };
};

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    // sbConnect: dispatch.sbUser.sbConnect,
    sbGetGroupChannelList: dispatch.sbChannel.sbGetGroupChannelList,
    //   getGroupChannel: dispatch.sbChannel.getGroupChannel,
    sbGetMessageList: dispatch.sbMessage.sbGetMessageList,
    sbSendMessage: dispatch.sbMessage.sbSendMessage,
    sbSendFile: dispatch.sbMessage.sbSendFile,
    sbMarkRead: dispatch.sbMessage.sbMarkRead,
    sbSetMessageList: dispatch.sbMessage.sbSetMessageList,
  };
};

export default connect(mapState, mapDispatch)(ChatBoxPage);
