import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import RightbarContent from './rightbarContent'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const HeaderText = styled.h3`
  padding: 10px 15px;
  color: white;
  font-weight: 200;
`

class RightbarDatabase extends Component {
  render() {
    // console.log(`this.props.data : ${JSON.stringify(this.props.data)}`)
    // console.log(`showRightbar : ${this.props.data.showRightbar}`)
    return (
      <div>
        <div
          id="rightbar"
          className={this.props.data.showRightbar ? 'rightbar show-service-panel' : 'rightbar'}>
          <div className="rightbar-body">
            <Container>
              <HeaderText>
                Column Setting
              </HeaderText>
                <RightbarContent mode={this.props.data.mode} />
            </Container>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  maidColumn: state.maid.column,
  customerColumn: state.customer.column,
})

const mapDispatch = (dispatch) => {
  return {
    setMaidColumnList: dispatch.maid.setMaidColumnList,
  }
}

export default connect(
  mapState,
  mapDispatch
)(RightbarDatabase)
