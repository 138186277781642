import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin-right: 1vw;
`

class Checkbox extends Component {
 
 
  render() {
   
    const { id, label, 
      // checked, 
      onChange ,
      // setchecked
    } = this.props
    // defaultChecked={checked}
    // onChange={onChange}
    // console.log(this.setValueSelect
    // console.log(`setchecked :: ${setchecked}`)
    return (
      <Container>
        <div className="custom-control custom-checkbox">
        <input
                type="checkbox"
                className="custom-control-input"
                // checked={this.state.checked}
                id={id}
                onChange={onChange}
              />
              <label className="custom-control-label" htmlFor={id} style={{cursor: 'pointer'}}>
                {label}
              </label>
        </div>
      </Container>
    )
  }
}

export default Checkbox
