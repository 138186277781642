import React, { Component } from 'react'
import { connect } from 'react-redux'

import Button from '../../components/button'
import ButtonIcon from '../../components/buttonIcon'
// import TabComponent from './../../components/tabComponent'
import SettingModal from './settingModal'
import ExportModal from './exportModal'

import styled from 'styled-components'

const Container = styled.div`
  display: flex;
`

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`



class DashboardLayout extends Component {

  state = {
    isSettingModalShow: false,
    index: 0,
  }

  componentDidMount() {
    this.props.setPageTitle('Dashboard')
    this.props.showBackIcon(false)
  }

  handleExport = () => {
    console.log('handleExport()')
    this.setState({ isExportModalShow: true })
  }

  handleSetting = () => {
    console.log('handleSetting()')
    this.setState({ isSettingModalShow: true })
  }

  closeModal = () => {
    this.setState({ isShow: false })
  }

  onChangeTab = (path) => {
    // console.log(`onChangeTab : ${match.url + path}`)
    const { history, match } = this.props
    history.push(match.url + path)
  }

  render() {

    return (
      <div>
        <div className="page-breadcrumb">
          <ItemContainer>
            <Container>
              <Button
                style={{ marginRight: '10px' }}
                focus
                className="btn"
                label="Overall"
                onClick={() => this.onChangeTab('/overall')}
              />
              <Button
                style={{ marginRight: '10px' }}
                focus
                className="btn"
                label="Customer"
                onClick={() => this.onChangeTab('/customer')}
              />

            </Container>
            <ButtonContainer>
              <ButtonIcon
                style={{ marginRight: '20px' }}
                icon='ti-export'
                onClick={() => this.handleExport()}
              />
              <ButtonIcon
                style={{ marginRight: '20px' }}
                icon='ti-settings'
                onClick={() => this.handleSetting()}
              />
            </ButtonContainer>
          </ItemContainer>
        </div>
        {this.props.children}

        <SettingModal
          isOpen={this.state.isSettingModalShow}
          onCallBack={this.onCallBack}
          closeModal={(data) => {
            this.setState({ isSettingModalShow: data }, () => console.log(data))
          }}

        />
        <ExportModal
          isOpen={this.state.isExportModalShow}
          onCallBack={this.onCallBack}
          closeModal={(data) => {
            this.setState({ isExportModalShow: data })
          }}

        />
      </div>
    )
  }
}

const mapState = () => ({})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    showBackIcon: dispatch.Title.showBackIcon,
  }
}
export default connect(
  mapState,
  mapDispatch
)(DashboardLayout)
