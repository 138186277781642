export const APP_ID = 'AC287AD4-E4DC-4CD2-B9F5-9E5AB205C294'
export const ACCESS_TOKEN = 'e4224011e0a78d002c63e296ae13dfd3c7e222f1'
export const USER_ID = 'Admin'
export const IMAGE_TYPE = ['image/jpeg', 'image/png']
export const VIDEOS_TYPE = ['video/mp4', 'video/mp3']

const dev = ['192.168.1.102', 'localhost']

const uat = ['bluuu-uat.firebaseapp.com', 'vulcan.houseofdev.tech','uat-admin.bluuu.co']

const config_dev = {
  APP_ID: 'AC287AD4-E4DC-4CD2-B9F5-9E5AB205C294',
  ACCESS_TOKEN: 'e4224011e0a78d002c63e296ae13dfd3c7e222f1'
}

const config_uat = {
  APP_ID: '5F5454AC-DFED-43FB-90C7-8B3B232F66D1',
  ACCESS_TOKEN: 'cea96cd81321d3b5d1513e8a3a043d219e5b890e'
}

const config_prod = {
  APP_ID: 'CCD403D5-D6B3-4F46-A33D-4FAF26C816E9',
  ACCESS_TOKEN: 'a1aa2929bc8107b84cdabf274f8e14d4f77ef08e'
}

export const findConfig = () => {
  const { hostname } = window.location

  if (dev.includes(hostname)) {
    return config_dev
  } else if (uat.includes(hostname)) {
    return config_uat
  }

  return config_prod
}