import React, { Component } from 'react'
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'

// No Dependency Trial
import styled from 'styled-components'

import DragIcon from '../../images/drag.svg'
import MinusIcon from '../../images/minus.svg'
import PlusIcon from '../../images/plus.svg'

const MainContainer = styled.div`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 80vh;
    overflow: auto;
  }
`

const CardContainer = styled.div`
  background: ${(props) => props.theme.rightCard};
  border: 1px solid ${(props) => props.theme.active};
  width: 100%;
  height: 44px;
  padding: 10px 20px;

  display: flex;
  justify-content: space-between;
  img {
    cursor: pointer;
  }
`

const Container = styled.div`
  display: flex;
  .hand {
    width: max-content;
    height: 100%;
    cursor: pointer;
  }
`
const Text = styled.div`
  margin-left: 15px;
  font-size: 12px;
  align-self:center;
`



class RightbarContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      column: this.props.databaseMaidColumn,
    }
    this.onDragEnd = this.onDragEnd.bind(this)
    this.handleToggleShow = this.handleToggleShow.bind(this)
  }

  // state = {
  //   column: this.props.customerColumn,
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.customerColumn !== this.props.customerColumn) {
      console.log('update')
      this.setState({ column: this.props.customerColumn })
    }
  }

  handleToggleShow(value) {
    let newColumn = this.state.column
    const result = this.state.column.map((obj, index) => {
      if (obj.accessor === value) {
        const editObj = { ...obj, isShow: !obj.isShow }

        newColumn[index] = editObj
        return newColumn
      }
      return newColumn
    })
    this.setState({ column: result[0] })
    this.props.setMaidColumnList(newColumn)
  }

  // No Dependency Trial
  onDragStart = (e, index) => {
    this.draggedItem = this.state.column[index]
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', e.target.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20)
  }

  onDragOver = (index) => {
    const draggedOverItem = this.state.column[index]

    // if the item is dragged over itself, ignore
    if (this.draggedItem === draggedOverItem) {
      return
    }

    // filter out the currently dragged item
    let items = this.state.column.filter((item) => item !== this.draggedItem)

    // add the dragged item after the dragged over item
    items.splice(index, 0, this.draggedItem)

    this.setState({ column: items })
    this.props.setMaidColumnList(items)
  }

  onDragEnd = () => {
    this.draggedIdx = null
  }

  render() {
    // console.log(`DragArea state: ${JSON.stringify(this.state)}`)
    // console.log(`this props : ${JSON.stringify(this.props.customerColumn)}`)
    console.log(`mode : ${this.props.mode}`)
    return (
      <MainContainer>
        <ul>
          {this.state.column.map((item, idx) => (
            <li key={item.accessor} onDragOver={() => this.onDragOver(idx)}>
                <CardContainer>
                  <Container>
                    <div
                      draggable
                      onDragStart={(e) => this.onDragStart(e, idx)}
                      onDragEnd={this.onDragEnd}>
                      <img alt="drag-icon" src={DragIcon} />
                    </div>
                    <Text>{item.label}</Text>
                  </Container>
                  <Container>
                    {item.isShow ? (
                      <div className="hand" onClick={() => this.handleToggleShow(item.accessor)}>
                        <img alt="minus-icon" src={MinusIcon} />
                      </div>
                    ) : (
                      <div className="hand" onClick={() => this.handleToggleShow(item.accessor)}>
                        <img alt="plus-icon" src={PlusIcon} />
                      </div>
                    )}
                  </Container>
                </CardContainer>
            </li>
          ))}
        </ul>
      </MainContainer>
    )
  }
}

const mapState = (state) => ({
  databaseMaidColumn: state.databaseMaid.column,
})

const mapDispatch = (dispatch) => {
  return {
    setMaidColumnList: dispatch.databaseMaid.setMaidColumnList,
    toggleShowMaidColumn: dispatch.databaseMaid.toggleShowMaidColumn,
  }
}

export default connect(
  mapState,
  mapDispatch
)(RightbarContent)
