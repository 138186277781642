import React, { Component } from 'react'
import { connect } from 'react-redux'
import TabComponent from '../../components/tabComponent'



class BroadcasManagementLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkTypePath: 'Announcement',
      checkActiveBtn: 'Announcement',
    }
    this.onDragEnd = this.onDragEnd.bind(this)
  }


  async componentDidMount() {
    await this.checkPathDetail()
    this.props.getTemplateList()
    this.props.setPageTitle('Announcement list')
    this.props.getAnnouncementSent()
    this.props.showBackIcon(false)
    // this.props.getInAppMessageSent()
  }

  checkPathDetail(checked) {
    if (this.props.showBtnAdd === true && (checked === 'Announcement' || checked === undefined)) {
      this.props.setPageTitle('Announcement list')
    } else if (this.props.showBtnAdd === true && checked === 'InAppMessage') {
      this.props.setPageTitle('In App Message list')
    } else if (this.props.showBtnAdd === true && checked === 'SMS') {
      this.props.setPageTitle('SMS list')
    } else if (this.props.showBtnAdd === true && checked === 'Email') {
      this.props.setPageTitle('Email list')
    } else {
      this.props.setPageTitle('Add new In app message template')
    }
  }
  onChangeTab = (path) => {
    const { history, match } = this.props
    history.push(match.url + path)
  }

  onDragEnd = () => {
    this.draggedIdx = null
  }

  async onAnnouncement(checked) {
    await this.setState({ checkTypePath: checked })
    await this.setState({ checkActiveBtn: checked })
    this.checkPathDetail(checked)
    this.props.setBroadcastMode(checked)
    this.props.checkRigthBarbroadcas(checked)
    this.props.getTemplateList()
    this.props.InAppList()
    this.props.getAnnouncementSent()
    this.props.getInAppMessageSent()
    this.props.getInAppMessageFailed()
  }
  onChangeTabTemplate = (path) => {
    const { history, match } = this.props
    history.push(match.url + path)
  }

  render() {

    const tabData = [
      {
        label: 'Sent',
        total: this.props.broadcastMode === 'Announcement' ? this.props.dataAnnouncementsentList : this.props.broadcastMode === 'InAppMessage' ? this.props.dataInAppMessagesentList : 0,
        key: '0',
        path: '/broadcastSent',
      },
      {
        label: 'Error',
        total: this.props.broadcastMode === 'InAppMessage' ? this.props.dataInAppMessageFailedList : 0,
        key: '1',
        path: '/broadcastError',
      },
    ]

    return (
      <div>
        <div>
          <div className="btn-container" id="ActiveBtn">
            <button
              className={
                'btn ' +
                (this.props.broadcastMode === 'Announcement'
                  ? 'active-button-broadcast'
                  : 'btn-button-broadcast')
              }
              onClick={() => this.onAnnouncement('Announcement')}>
              Announcement
            </button>
            <button
              className={
                'btn ' +
                (this.props.broadcastMode === 'InAppMessage'
                  ? 'active-button-broadcast'
                  : 'btn-button-broadcast')
              }
              onClick={() => this.onAnnouncement('InAppMessage')}>
              In app message
            </button>
            <button
              className={
                'btn ' +
                (this.props.broadcastMode === 'SMS'
                  ? 'active-button-broadcast'
                  : 'btn-button-broadcast')
              }
              onClick={() => this.onAnnouncement('SMS')}>
              SMS
            </button>
            <button
              className={
                'btn ' +
                (this.props.broadcastMode === 'Email'
                  ? 'active-button-broadcast'
                  : 'btn-button-broadcast')
              }
              onClick={() => this.onAnnouncement('Email')}>
              Email
            </button>
          </div>
          <TabComponent transparent tabData={tabData} onChangeTab={this.onChangeTab} />
        </div>
        {this.props.children}
      </div>
    )
  }
}
const mapState = (state) => ({
  showBtnAdd: state.promotion.showBtnAdd,
  broadcastMode: state.broadcasting.broadcastMode,
  dataInAppMessagesentList: state.broadcasting.dataInAppMessagesentList,
  dataAnnouncementsentList: state.broadcasting.dataAnnouncementsentList,
  dataInAppMessageFailedList: state.broadcasting.dataInAppMessageFailedList
})
const mapDispatch = (dispatch) => {
  return {
    setBroadcastMode: dispatch.broadcasting.setBroadcastMode,
    setPageTitle: dispatch.Title.setPageTitle,
    checkRigthBarbroadcas: dispatch.broadcasting.checkRigthBarbroadcas,
    getTemplateList: dispatch.broadcasting.getAnoucement,
    InAppList: dispatch.broadcasting.InAppList,
    getAnnouncementSent: dispatch.broadcasting.getAnnouncementSent,
    getInAppMessageSent: dispatch.broadcasting.getInAppMessageSent,
    getInAppMessageFailed: dispatch.broadcasting.getInAppMessageFailed,
    showBackIcon: dispatch.Title.showBackIcon,
  }
}

export default connect(
  mapState,
  mapDispatch
)(BroadcasManagementLayout)
