import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import iconclose from '../../images/iconclose.png'

import { IMAGE_TYPE, VIDEOS_TYPE, USER_ID } from '../../store/models/sbConstants'

const ChatRight = (props) => {
    const [text, setText] = useState([])
    const msgChat = useRef()
    const textMsg = useRef()
    const file = useRef()

    useEffect(() => {
        textMsg.current.focus()
    }, [props.channel])

    useEffect(() => {
        let scroll = msgChat.current
        // document.querySelector('.chat-box')
        scroll.scrollTop = 0
        setTimeout(() => {
            scroll.scrollTop = scroll.scrollHeight
        }, 10)
    }, [props.messageList])

    const onInputFocus = (e) => {
        if (props.channel.unreadMessageCount > 0) {
            props.onInputFocus()
        }
    }

    const onFileUpload = () => {
        const sbFile = file.current.files[0]
        if (sbFile) {
            props.onSendFile(sbFile)
        }
    }

    const onClickUpload = () => {
        file.current.click()
    }

    const onSendMessage = () => {
        if (text.length > 0) {
            props.onSendMessage(text)
            setText('')
        }
    }

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            onSendMessage()
        }
    }

    const oncloseSbRight = () => {
        props.onCloseChatRight()
    }
    const getContactWith = () => {
        if (props.channel) {
            return props.channel.members.find(v => v.userId !== USER_ID).nickname
        }

        return ''
    }

    // const onScrollMessage = (e) => {
    //     const { scrollTop } = msgChat.current
    //     if (scrollTop === 0) {
    //         const limit = Math.round(props.messageList.length / 30) + 1
    //         props.loadMessageList(limit)
    //     }
    // }

    const renderMessageList = props.messageList.map((message, index) => {
        return (
            <li className="chat-item"
                style={{ textAlign: props.userId === message._sender.userId ? 'right' : null }}
                key={index}>
                {
                    props.userId === message._sender.userId ? null :
                        <div className="chat-img">
                            <img src={message._sender.profileUrl} alt="user" />
                        </div>
                }
                <div className="chat-content">
                    <h6 className="font-medium">{message._sender.nickname}</h6>
                    <div>
                        {
                            message.messageType === 'file'
                                ?
                                IMAGE_TYPE.includes(message.type)
                                    ? <a href={message.url}><img src={message.url} alt={message.name} style={{ width: '400px' }} /></a>
                                    : VIDEOS_TYPE.includes(message.type)
                                        ? <video width="320" height="240" controls>
                                            <source src={message.url} type={message.type} />
                                        </video>
                                        : <div className="box bg-light-info">
                                            <a href={message.url}>{message.name}</a>
                                        </div>
                                : <div className="box bg-light-info">
                                    {message.message}
                                </div>
                        }
                    </div>
                </div>
                <div><small>{!message.createdAt ? null : moment.unix(message.createdAt / 1000).format('DD/MM/YYYY hh:mm a')}</small></div>
            </li>
        )
    })
    return (
        <div>
            <div className="right-part border-top p-20">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">{
                            props.channel && props.messageList !== null && props.messageList.length > 0 ? 
                            // <span className="nav-toggler waves-effect waves-light " style={{paddingRight:'15px'}} >
                            //     <i style={{ color: 'red', fontWeight: 'bold' }} className="fa fa-times" />
                            // </span>
                            <img style={{ width: "45px", paddingRight: "20px",cursor: 'pointer' }} src={iconclose} alt="iconclose"  onClick={oncloseSbRight} />
                             :
                              null}{getContactWith()}</h4>
                        <div className="chat-box scrollable" ref={msgChat} style={{ height: 'calc(100vh - 300px)' }}>
                            <ul className="chat-list" style={{ paddingRight: '20px' }}>
                                {props.channel && props.messageList !== null && props.messageList.length > 0 ? renderMessageList : null}

                            </ul>
                        </div>
                    </div>
                    <div className="card-body border-top">
                        <div className="row">
                            <div className="input-group">
                                <button className="btn btn-xs btn-cyan text-white" disabled={!props.channel} onClick={onClickUpload}>
                                    <input type="file" ref={file} style={{ display: 'none' }} onChange={onFileUpload} />
                                    <i className="fas fa-paperclip" />
                                </button>
                                <input type="text"
                                    ref={textMsg}
                                    className="form-control border-0"
                                    placeholder="Type and enter"
                                    disabled={!props.channel}
                                    value={text}
                                    onFocus={e => onInputFocus(e)}
                                    onChange={e => setText(e.target.value)}
                                    onKeyDown={e => onEnter(e)} />
                                <button className="btn btn-cyan float-right text-white" disabled={!props.channel} onClick={onSendMessage}>
                                    Send
                                    {/* <i className="fas fa-paper-plane" /> */}
                                </button>
                            </div>
                            {/* <div className="col-11">
                                <div className="input-field m-t-0 m-b-0">
                                <input
                                    ref={textMsg}
                                    value={text}
                                    id="textarea1"
                                    placeholder="Type and enter"
                                    className="form-control border-0"
                                    type="text"
                                    onChange={e => setText(e.target.value)}
                                    onKeyDown={e => onEnter(e)}
                                    disabled={!props.channel}
                                />
                                </div>
                            </div>
                            <div className="col-1">
                                <a className="btn btn-xs btn-cyan text-white" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={onClickUpload}>
                                    <input type="file" ref={file} style={{ display: 'none' }} onChange={onFileUpload} />
                                    <i className="fas fa-paperclip" />
                                </a>
                                <a className="btn-circle btn-lg btn-cyan float-right text-white" style={{cursor: 'pointer'}} onClick={onSendMessage}>
                                    <i className="fas fa-paper-plane" />
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatRight