import React, { Component } from 'react'
import styled, { css } from 'styled-components'

const StyledButton = styled.button`
  ${(props) => {
    if (props.width) {
      return css`
        width: ${props.width};
      `
    }
    return css`
      width: 140px;
    `

    // if (props.primary) {
    //       return `
    //         class="btn waves-effect waves-light btn-rounded btn-primary"
    //       `
    //     }
  }}

  :focus {
    ${(props) => {
      if (props.focus) {
        return css`
          background: ${(props) => props.theme.primary};
        `
      }
    }}
  }
`

class Button extends Component {
  static propTypes = {}

  render() {
    const { label, light } = this.props
    return (
      <StyledButton
        {...this.props}
        type="button"
        className={
          light
            ? 'btn waves-effect waves-light btn-rounded btn-light'
            : 'btn waves-effect waves-light btn-rounded btn-primary'
        }>
        {label}
      </StyledButton>
    )
  }
}

export default Button
