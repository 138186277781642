import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ErrorBoundary from "../../components/errorBoundary"
import CustomModal from "../../components/customModal"
import Button from "../../components/button"
import DatePicker from "react-datepicker"
import { customModalStyle } from "./modalViewstyle"
import "./swal.css"
import swal from "sweetalert"
import request from "../../utils/request"
import moment from "moment"

const Container = styled.div`
  padding: 20px;
`
// const Section = styled.div`
//   height: 250px;
//   overflow: auto;
// `

const Header = styled.div`
  text-align: justify;
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 50px;
  // margin-bottom: 40px;
`

class ExportModal extends Component {
  static propTypes = {}

  state = {
    startDate: new Date(),
    endDate: new Date(),
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.sendForDate.start_datetime !== this.props.sendForDate.start_datetime ||
      prevProps.sendForDate.end_datetime !== this.props.sendForDate.end_datetime
    ) {
      this.setState({
        startDate: new Date(this.props.sendForDate.start_datetime),
        endDate: new Date(this.props.sendForDate.end_datetime),
      })
    }
  }

  closeModal = () => {
    this.props.closeModal(false)
  }
  setStartMonth(e) {
    this.setState({ startDate: e })
  }
  setEndMonth(e) {
    this.setState({ endDate: e })
  }

  onCallExport = () => {
    swal({
      className: "swal-modal",
      title: "Are you sure?",
      text: `Are you sure export data Booking `,
      buttons: ["Cancel", "Confirm"],
      defeat: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = window.location.hostname === "admin.bluuu.co"
        ? process.env.REACT_APP_PRODUCTION
        : window.location.hostname === "uat-admin.bluuu.co"
          ? process.env.REACT_APP_UAT
          : process.env.REACT_APP_DEV
        let sDate = moment(this.state.startDate).format("YYYY-MM-DD")
        let eDate = moment(this.state.endDate).format("YYYY-MM-DD")
        const redir = url + "/" +  `booking/list/csv?start_date=${sDate}&end_date=${eDate}`
        console.log("redir",redir)
        // alert("asasdasd")
        // window.location.href =  redir
        window.open(redir,"blank")
        // console.log("sDate",sDate)

      //   request.get(`booking/list/csv?start_date=${sDate}&end_date=${eDate}`).then((res) => {

          // window.location.href = res.config.url
          swal("Export Success", {
            icon: "success",
          })
      //   })
      }
    })
  }

  onCallBack = () => {
    this.props.onCallBack()
  }

  render() {
    return (
      <ErrorBoundary>
        <CustomModal isOpen={this.props.isOpen} customStyle={customModalStyle}>
          <Container>
            <Header>Export Booking List </Header>
            {/* <Section> */}
            <div className="row col-12" style={{ paddingBottom: "10px" }}>
              <div className="col-6 Datepicker_react_Popup_invenca_export">
                <label style={{ fontSize: "18px", fontWeight: "500px" }}>Start Month</label>
                <DatePicker
                  className="Datepicker_react_invenca_export"
                  selected={this.state.startDate}
                  onChange={(e) => this.setStartMonth(e)}
                  maxDate={this.state.endDate}
                  dateFormat="yyyy/MM/dd"
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              </div>
              <div className="col-6 Datepicker_react_Popup_invenca_export">
                <label style={{ fontSize: "18px", fontWeight: "500px" }}>End Month</label>
                <DatePicker
                  className="Datepicker_react_invenca_export"
                  selected={this.state.endDate}
                  onChange={(e) => this.setEndMonth(e)}
                  minDate={this.state.startDate}
                  dateFormat="yyyy/MM/dd"
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              </div>
            </div>
            {/* </Section> */}
            <Button
              style={{ position: "absolute", bottom: "20px", right: "180px" }}
              onClick={() => this.props.closeModal()}
              label="Cancel"
              light
            />
            <Button
              style={{ position: "absolute", bottom: "20px", right: "20px" }}
              onClick={() => this.onCallExport()}
              type="submit"
              label="Export"
            />
          </Container>
        </CustomModal>
      </ErrorBoundary>
    )
  }
}

ExportModal.propstype = {
  closeModal: PropTypes.func.isRequired,
  onCallBack: PropTypes.func.isRequired,
}

export default ExportModal
