import React, { Component } from 'react'
import { connect } from 'react-redux'

class maidLayout extends Component {
  constructor() {
    super()
    this.state = {
      totalIN: [],
      totalAC: 0,
      totalEX: 0,
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <div className="page-breadcrumb">{this.props.children}</div>
      </div>
    )
  }
}

const mapState = () => ({})

const mapDispatch = () => {
  return {}
}
export default connect(mapState, mapDispatch)(maidLayout)
