import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'



import ReactTable from 'react-table'
import 'react-table/react-table.css'
import '../../style/react-table-custom.css'

import { data } from './data'

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.active};
  background: #fff;
  height: max-content;
  width: 100%;
  position: relative;
  top: -1px;
  padding: 10px;
  align-items: flex-start;
  overflow: auto;
`

class DatabaseMaidPage extends Component {

  componentDidMount() {
    this.props.setPageTitle('Maid Database List')
  }

  render() {
    const columns = [
      {
        Header: () => <h6>Name of Maid</h6>,
        accessor: 'name',
      },
      {
        Header: () => <h6>Training completed date</h6>,
        accessor: 'dateTrainComplete',
        width: 200,
      },
      {
        Header: () => <h6>First booking accepted date</h6>,
        accessor: 'dateFirstbookingAccept',
      },
      {
        Header: () => <h6>Last booking accepted date</h6>,
        accessor: 'dateLastbookingAccept',
      },
      {
        Header: () => <h6># of logins</h6>,
        accessor: 'noLogins',
      },

      {
        Header: () => <h6>Salary</h6>,
        accessor: 'salary',
      },
      {
        Header: () => <h6>Hrs worked</h6>,
        accessor: 'hrsWorked',
      },
      {
        Header: () => <h6># of users served</h6>,
        accessor: 'noUserServed',
      },
      {
        Header: () => <h6># of services completed</h6>,
        accessor: 'noServiceCompleted',
      },
      {
        Header: () => <h6># of ratings received</h6>,
        accessor: 'noRatingsReceived',
      },
      {
        Header: () => <h6># of users who rated</h6>,
        accessor: 'noUsersRated',
      },
      {
        Header: () => <h6>% of ratings received per service</h6>,
        accessor: 'pctRatingReceivedPerService',
      },
      {
        Header: () => <h6>% of ratings received per user</h6>,
        accessor: 'pctRatingReceivedPerUser',
      },
      {
        Header: () => <h6># of times Five Star Bonus achieved</h6>,
        accessor: 'noTimesFiveStarBonusAchieved',
      },
      {
        Header: () => <h6># of times Milestone Bonus achieved</h6>,
        accessor: 'noTimesMilestoneBonusAchieved',
      },
      {
        Header: () => <h6># of times Monthly Bonus achieved</h6>,
        accessor: 'noTimesMonthlyBonusAchieved',
      },
      {
        Header: () => <h6># of Referral Bonus achieved</h6>,
        accessor: 'noTimesReferralBonusAchieved',
      },
      {
        Header: () => <h6># of times maid rejected user's schedule offer in MP booking. </h6>,
        accessor: 'noTimesRejectedInMPbooking',
      },
      {
        Header: () => <h6># of total available Hrs marked in maid's calendar in the next 1 month</h6>,
        accessor: 'noTotalAvailableHrsNext1mth',
      },
      {
        Header: () => <h6># of total available Hrs marked in maid's calendar in the next 2 months</h6>,
        accessor: 'noTotalAvailableHrsNext2mth',
      },
      {
        Header: () => <h6># of total available Hrs marked in maid's calendar in the next 3 months</h6>,
        accessor: 'noTotalAvailableHrsNext3mth',
      },
    ]
    return (
      <div>
        <div className="container-fluid">
          <TableContainer>
            <ReactTable data={data} columns={columns} defaultPageSize={10} showPagination={false} />
          </TableContainer>
        </div>
      </div>
    )
  }
}

const mapState = () => ({})

const mapDispatch = (dispatch) => {
  return { setPageTitle: dispatch.Title.setPageTitle }
}

export default connect(
  mapState,
  mapDispatch
)(DatabaseMaidPage)
