import React, { Component } from "react";
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { connect } from "react-redux";

// No Dependency Trial
import styled from "styled-components";

import DragIcon from "../../images/drag.svg";
import MinusIcon from "../../images/minus.svg";
import PlusIcon from "../../images/plus.svg";
import BackIcon from "../../images/back.svg";

const MainContainer = styled.div`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

const CardContainer = styled.div`
  background: ${(props) => props.theme.rightCard};
  border: 1px solid ${(props) => props.theme.active};
  width: 100%;
  height: 44px;
  padding: 10px 20px;

  display: flex;
  justify-content: space-between;
  img {
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  .hand {
    width: max-content;
    height: 100%;
    cursor: pointer;
  }
`;
const Text = styled.div`
  margin-left: 15px;
`;

class CollumSettingBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: this.props.customerColumn,
      fillter: 0,
      index: null,
      itemPaymetDate: ["-", "Date"],
      paymentDateList: this.props.paymentdateListFillter,
      paymentCheckBox: this.props.paymentCheckBox,
      itemOrderType: ["OTA", "OTM", "MP"],
      orderTypeList: this.props.orderTypeListFillter,
      orderTypeCheckBox: this.props.orderTypeCheckBox,
      itemStatus: this.props.statusList,
      StatusListFilter: this.props.statusListFillter,
      StatusCheckBox: this.props.StatusChecBoxBooking,
      itemMpCredit: ["1", "4", "8", "12", "16", "20", "24", "28"],
      MpCreditList: this.props.mpCreditsFillter,
      MpCreditCheckBox: this.props.mpCreditCheckBox,
      itemDuration: ["2", "3", "4", "5", "6", "7", "8"],
      DurationList: this.props.durationFillter,
      DurationCheckBox: this.props.durationCheckBox,
      itemService: ["Cleaning", "Cleaning and Drapery", "Drapery"],
      ServiceList: this.props.serviceFillter,
      ServiceCheckBox: this.props.serviceCheckBox,
      itemLanguage: ["TH", "EN"],
      LanguageList: this.props.languageFillter,
      LanguageCheckBox: this.props.languageCheckBox,
      checked: false,
    };
    console.log("this.", this.props.StatusChecBoxBooking);
    console.log("aa", this.state.StatusListFilter);
    console.log("bb", this.props.statusList);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.handleToggleShow = this.handleToggleShow.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mode !== this.props.mode) {
      if (this.props.mode === "column") {
        this.setState({ column: this.props.customerColumn });
      } else {
        this.setState({
          column: this.props.columnFilterBookingList,
          fillter: 0,
          index: null,
        });
      }
    }
  }

  handleToggleShow(value) {
    let newColumn = this.state.column;
    const result = this.state.column.map((obj, index) => {
      if (obj.accessor === value) {
        const editObj = { ...obj, isShow: !obj.isShow };

        newColumn[index] = editObj;
        return newColumn;
      }
      return newColumn;
    });
    this.setState({ column: result[0] });
    this.props.setCustomerColumnList(newColumn);
  }

  // No Dependency Trial
  onDragStart = (e, index) => {
    this.draggedItem = this.state.column[index];
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  onDragOver = (index) => {
    const draggedOverItem = this.state.column[index];

    // if the item is dragged over itself, ignore
    if (this.draggedItem === draggedOverItem) {
      return;
    }

    // filter out the currently dragged item
    let items = this.state.column.filter((item) => item !== this.draggedItem);

    // add the dragged item after the dragged over item
    items.splice(index, 0, this.draggedItem);

    this.setState({ column: items });
    this.props.setCustomerColumnList(items);
  };

  onDragEnd = () => {
    this.draggedIdx = null;
  };
  onfillter = (index) => {
    var v = document.getElementById(`imgFillter${index}`);
    if (this.state.index !== index) {
      if (this.state.index !== null) {
        var temp = document.getElementById(`imgFillter${this.state.index}`);
        temp.classList.remove("transformIconRigbarFillter");
      }
      this.setState({ fillter: 1, index: index });
      v.classList.add("transformIconRigbarFillter");
    } else {
      this.setState({ fillter: 0, index: null });
      v.classList.remove("transformIconRigbarFillter");
    }
  };
  async PaymentFilter(e, i) {
    let arr = this.state.paymentCheckBox;
    this.state.paymentCheckBox[i].checked = !arr[i].checked;
    this.props.paymentCheckBoxDis(this.state.paymentCheckBox);
    let tempArr = this.state.paymentDateList;
    const index = await tempArr.findIndex((o) => o === e);
    if (index > -1) {
      tempArr.splice(index, 1);
      this.setState({ paymentDateList: tempArr }, () => this.filterdataList());
      this.props.setPaymentdateListFillter(tempArr);
    } else {
      await tempArr.push(e);
      this.setState({ paymentDateList: tempArr }, () => this.filterdataList());
      this.props.setPaymentdateListFillter(tempArr);
    }
  }
  async OrderTypeFilter(e, i) {
    let arr = this.state.orderTypeCheckBox;
    this.state.orderTypeCheckBox[i].checked = !arr[i].checked;
    this.props.orderTypeCheckBoxDis(this.state.orderTypeCheckBox);
    let tempArr = this.state.orderTypeList;
    const index = await tempArr.findIndex((o) => o === e);
    if (index > -1) {
      tempArr.splice(index, 1);
      this.setState({ orderTypeList: tempArr }, () => this.filterdataList());
      this.props.setOrderTypeListFillter(tempArr);
    } else {
      await tempArr.push(e);
      this.setState({ orderTypeList: tempArr }, () => this.filterdataList());
      this.props.setOrderTypeListFillter(tempArr);
    }
  }
  async statusFilter(e, i) {
    try {
      let arr = this.state.StatusCheckBox;
      console.log("xx", arr);
      // let arr1 = this.state.itemStatus
      // arr1[i].checked = !arr[i].checked
      // this.setState({ itemStatus: arr1 }, () => this.filterdataList())
      this.state.StatusCheckBox[i].checked = !arr[i].checked;
      this.props.StatusChecBoxBookingDis(this.state.StatusCheckBox);
      let tempArr = this.state.StatusListFilter;
      console.log("aa", this.state.StatusListFilter);
      const index = await tempArr.findIndex(
        (o) => o.toLowerCase() === e.toLowerCase()
      );
      if (index > -1) {
        tempArr.splice(index, 1);
        this.setState({ StatusListFilter: tempArr }, () =>
          this.filterdataList()
        );
        this.props.setStatusListFillter(tempArr);
      } else {
        await tempArr.push(e);
        this.setState({ StatusListFilter: tempArr }, () =>
          this.filterdataList()
        );
        this.props.setStatusListFillter(tempArr);
      }
    } catch (e) {
      console.log("e", e);
    }
  }
  async mpCreditFilter(e, i) {
    let arr = this.state.MpCreditCheckBox;
    this.state.MpCreditCheckBox[i].checked = !arr[i].checked;
    this.props.mpCreditCheckBoxDis(this.state.MpCreditCheckBox);
    let tempArr = this.state.MpCreditList;
    const index = await tempArr.findIndex((o) => o === e);
    if (index > -1) {
      tempArr.splice(index, 1);
      this.setState({ MpCreditList: tempArr }, () => this.filterdataList());
      this.props.setMpCreditsFillter(tempArr);
    } else {
      await tempArr.push(e);
      this.setState({ MpCreditList: tempArr }, () => this.filterdataList());
      this.props.setMpCreditsFillter(tempArr);
    }
  }
  async durationFilter(e, i) {
    let arr = this.state.DurationCheckBox;
    this.state.DurationCheckBox[i].checked = !arr[i].checked;
    this.props.durationCheckBoxDis(this.state.DurationCheckBox);
    let tempArr = this.state.DurationList;
    const index = await tempArr.findIndex((o) => o === e);
    if (index > -1) {
      tempArr.splice(index, 1);
      this.setState({ DurationList: tempArr }, () => this.filterdataList());
      this.props.setDurationFillter(tempArr);
    } else {
      await tempArr.push(e);
      this.setState({ DurationList: tempArr }, () => this.filterdataList());
      this.props.setDurationFillter(tempArr);
    }
  }
  async serviceFilter(e, i) {
    let arr = this.state.ServiceCheckBox;
    this.state.ServiceCheckBox[i].checked = !arr[i].checked;
    this.props.serviceCheckBoxDis(this.state.ServiceCheckBox);
    let tempArr = this.state.ServiceList;
    const index = await tempArr.findIndex(
      (o) => o.toLowerCase() === e.toLowerCase()
    );
    if (index > -1) {
      tempArr.splice(index, 1);
      this.setState({ ServiceList: tempArr }, () => this.filterdataList());
      this.props.setServiceFillter(tempArr);
    } else {
      await tempArr.push(e.toLowerCase());
      this.setState({ ServiceList: tempArr }, () => this.filterdataList());
      this.props.setServiceFillter(tempArr);
    }
  }
  async languageFilter(e, i) {
    let arr = this.state.LanguageCheckBox;
    this.state.LanguageCheckBox[i].checked = !arr[i].checked;
    this.props.languageCheckBoxDIs(this.state.LanguageCheckBox);
    let tempArr = this.state.LanguageList;
    const index = await tempArr.findIndex((o) => o === e);
    if (index > -1) {
      tempArr.splice(index, 1);
      this.setState({ LanguageList: tempArr }, () => this.filterdataList());
      this.props.setLanguageFillter(tempArr);
    } else {
      await tempArr.push(e);
      this.setState({ LanguageList: tempArr }, () => this.filterdataList());
      this.props.setLanguageFillter(tempArr);
    }
  }
  async filterdataList() {
    let arr = [];
    if (this.state.paymentDateList.length === 1) {
      if (this.state.paymentDateList[0] === "Date") {
        arr = await this.props.data.filter(
          (v) => v.Payment_date && this.checkFilterList(v)
        );
        if (arr.length === 0) {
          arr = ["noData"];
        }
      } else {
        arr = await this.props.data.filter(
          (v) => !v.Payment_date && this.checkFilterList(v)
        );
        if (arr.length === 0) {
          arr = ["noData"];
        }
      }
    } else if (this.state.paymentDateList.length === 0) {
      arr = ["noData"];
    } else {
      arr = this.props.data.filter((v) => this.checkFilterList(v));
      if (arr.length === 0) {
        arr = ["noData"];
      }
    }
    this.props.data_filter(arr);
  }
  checkFilterList(v) {
    return (
      this.state.orderTypeList.includes(v.Order_Type) &&
      this.state.StatusListFilter.includes(v.Status) &&
      this.state.MpCreditList.includes(`${v.MP_Credits}`) &&
      this.state.DurationList.includes(`${v.Duration}`) &&
      this.state.ServiceList.includes(v.Services.toLowerCase()) &&
      this.state.LanguageList.includes(v.Language)
    );
  }

  render() {
    return (
      <MainContainer>
        {this.props.mode === "column" ? (
          <ul className="rightBarOrdermangement">
            {this.state.column.map((item, idx) => (
              <CardContainer key={idx}>
                <Container>
                  <div
                    draggable
                    onDragStart={(e) => this.onDragStart(e, idx)}
                    onDragEnd={this.onDragEnd}
                  >
                    <img alt="drag-icon" src={DragIcon} />
                  </div>
                  <Text>{item.label}</Text>
                </Container>
                <Container>
                  {item.isShow ? (
                    <div
                      className="hand"
                      onClick={() => this.handleToggleShow(item.accessor)}
                    >
                      <img alt="minus-icon" src={MinusIcon} />
                    </div>
                  ) : (
                    <div
                      className="hand"
                      onClick={() => this.handleToggleShow(item.accessor)}
                    >
                      <img alt="plus-icon" src={PlusIcon} />
                    </div>
                  )}
                </Container>
              </CardContainer>
            ))}
          </ul>
        ) : (
          <ul style={{ paddingLeft: "10px", width: "290px" }}>
            {this.state.column.map((item, idx) => (
              <CardContainer
                className="row"
                style={{ height: "auto" }}
                key={idx}
              >
                <Container className="col-md-10">
                  <Text style={{ paddingBottom: "5px" }}>{item.label}</Text>
                </Container>
                <Container className="col-md-2">
                  {/* <div className="hand" onClick={() => this.onfillter(idx)}> */}
                  <img
                    id={"imgFillter" + idx}
                    alt="back-icon"
                    src={BackIcon}
                    onClick={() => this.onfillter(idx)}
                  />
                  {/* </div> */}
                </Container>
                {this.state.fillter === 1 && idx === this.state.index ? (
                  <div>
                    {this.state.index === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "30px",
                        }}
                      >
                        {this.state.itemPaymetDate.map((e, i) => {
                          return (
                            <div
                              className="custom-control custom-checkbox"
                              key={i}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={this.state.paymentCheckBox[i].checked}
                                id={i}
                                onChange={() => this.PaymentFilter(e, i)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={i}
                                style={{ cursor: "pointer" }}
                              >
                                {e}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    ) : this.state.index === 1 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "30px",
                        }}
                      >
                        {this.state.itemOrderType.map((e, i) => {
                          return (
                            <div
                              className="custom-control custom-checkbox"
                              key={i}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={
                                  this.state.orderTypeCheckBox[i].checked
                                }
                                id={i}
                                onChange={() => this.OrderTypeFilter(e, i)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={i}
                                style={{ cursor: "pointer" }}
                              >
                                {e}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    ) : this.state.index === 2 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "30px",
                        }}
                      >
                        {this.state.itemStatus.map((e, i) => {
                          return (
                            <div
                              className="custom-control custom-checkbox"
                              key={i}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={this.state.StatusCheckBox[i].checked}
                                id={i}
                                onChange={() => this.statusFilter(e, i)}
                              />

                              {/* <p>this.state.StatusCheckBox[i].checked</p> */}
                              <label
                                className="custom-control-label"
                                htmlFor={i}
                                style={{ cursor: "pointer" }}
                              >
                                {e}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    ) : this.state.index === 3 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "30px",
                        }}
                      >
                        {this.state.itemMpCredit.map((e, i) => {
                          return (
                            <div
                              className="custom-control custom-checkbox"
                              key={i}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={this.state.MpCreditCheckBox[i].checked}
                                id={i}
                                onChange={() => this.mpCreditFilter(e, i)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={i}
                                style={{ cursor: "pointer" }}
                              >
                                {e}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    ) : this.state.index === 4 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "30px",
                        }}
                      >
                        {this.state.itemDuration.map((e, i) => {
                          return (
                            <div
                              className="custom-control custom-checkbox"
                              key={i}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={this.state.DurationCheckBox[i].checked}
                                id={i}
                                onChange={() => this.durationFilter(e, i)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={i}
                                style={{ cursor: "pointer" }}
                              >
                                {e}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    ) : this.state.index === 5 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "30px",
                        }}
                      >
                        {this.state.itemService.map((e, i) => {
                          return (
                            <div
                              className="custom-control custom-checkbox"
                              key={i}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={this.state.ServiceCheckBox[i].checked}
                                id={i}
                                onChange={() => this.serviceFilter(e, i)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={i}
                                style={{ cursor: "pointer" }}
                              >
                                {e}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "30px",
                        }}
                      >
                        {this.state.itemLanguage.map((e, i) => {
                          return (
                            <div
                              className="custom-control custom-checkbox"
                              key={i}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={this.state.LanguageCheckBox[i].checked}
                                id={i}
                                onChange={() => this.languageFilter(e, i)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={i}
                                style={{ cursor: "pointer" }}
                              >
                                {e}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                ) : null}
              </CardContainer>
            ))}
          </ul>
        )}
      </MainContainer>
    );
  }
}

const mapState = (state) => ({
  customerColumn: state.orderManagement.columnOrderManagementBooking,
  columnFilterBookingList: state.orderManagement.columnFilterBookingList,
  paymentdateListFillter: state.orderManagement.paymentdateListFillter,
  paymentCheckBox: state.orderManagement.paymentCheckBox,
  orderTypeCheckBox: state.orderManagement.orderTypeCheckBox,
  StatusChecBoxBooking: state.orderManagement.StatusChecBoxBooking,
  mpCreditCheckBox: state.orderManagement.mpCreditCheckBox,
  durationCheckBox: state.orderManagement.durationCheckBox,
  serviceCheckBox: state.orderManagement.serviceCheckBox,
  languageCheckBox: state.orderManagement.languageCheckBox,
  statusListFillter: state.orderManagement.statusListFillter,
  statusList: state.orderManagement.statusList,
  orderTypeListFillter: state.orderManagement.orderTypeListFillter,
  mpCreditsFillter: state.orderManagement.mpCreditsFillter,
  durationFillter: state.orderManagement.durationFillter,
  serviceFillter: state.orderManagement.serviceFillter,
  languageFillter: state.orderManagement.languageFillter,
});

const mapDispatch = (dispatch) => {
  return {
    setCustomerColumnList: dispatch.orderManagement.setOrderBookingColumnList,
    setPaymentdateListFillter:
      dispatch.orderManagement.setPaymentdateListFillter,
    setOrderTypeListFillter: dispatch.orderManagement.setOrderTypeListFillter,
    setStatusListFillter: dispatch.orderManagement.setStatusListFillter,
    setMpCreditsFillter: dispatch.orderManagement.setMpCreditsFillter,
    setDurationFillter: dispatch.orderManagement.setDurationFillter,
    setServiceFillter: dispatch.orderManagement.setServiceFillter,
    setLanguageFillter: dispatch.orderManagement.setLanguageFillter,
    paymentCheckBoxDis: dispatch.orderManagement.paymentCheckBoxDis,
    orderTypeCheckBoxDis: dispatch.orderManagement.orderTypeCheckBoxDis,
    StatusChecBoxBookingDis: dispatch.orderManagement.StatusChecBoxBookingDis,
    mpCreditCheckBoxDis: dispatch.orderManagement.mpCreditCheckBoxDis,
    durationCheckBoxDis: dispatch.orderManagement.durationCheckBoxDis,
    serviceCheckBoxDis: dispatch.orderManagement.serviceCheckBoxDis,
    languageCheckBoxDIs: dispatch.orderManagement.languageCheckBoxDIs,
  };
};

export default connect(mapState, mapDispatch)(CollumSettingBooking);
