import { GetApiURL } from "../../utils/request"
import request from "../../utils/request"
import axios from "axios"
import qs from "qs"
import { connect } from "react-redux"
import React, { useState } from "react"
import { hashCode } from "../../utils/simpleHash"

const LoginPage = ({ history }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [err, setErr] = useState("")
  const [otp, setOtp] = useState("")
  const [showOtp, setShowOtp] = useState(false)

  const logOut = async () => {
    const logout = window.location.hash.includes("logout=true")
    let callback = false
    if (window.location.hash.includes("callback=")) {
      callback = window.location.hash.split("callback=")[1]
    }
    const accessToken = localStorage.getItem("accessToken")
    const refreshToken = localStorage.getItem("refreshToken")
    const timeOut = localStorage.getItem("timeOut")
    if (logout) {
      if (accessToken) {
        const res = await request
          .post("authentication/logout")
          .then()
          .catch((err) => err)
      }
      localStorage.removeItem("accessToken")
      localStorage.removeItem("refreshToken")
      localStorage.removeItem("timeOut")
    } else if (accessToken && refreshToken && new Date().getTime() < parseInt(timeOut)) {
      request.get(`customer/list?page=1&limit=1&search=`).then((res) => {
        console.log("res", res)
        if (callback) {
          window.location.href = callback
        } else {
          window.location.href = "/admin/customer-management"
        }
      })
    } else if (accessToken && refreshToken && new Date().getTime() > parseInt(timeOut)) {
      if (accessToken) {
        const res = await request
          .post("authentication/logout")
          .then()
          .catch((err) => err)
      }
      localStorage.removeItem("accessToken")
      localStorage.removeItem("refreshToken")
      localStorage.removeItem("timeOut")
    }
  }

  logOut()
  let hashed = 0
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic Ymx1dXU6dXV1Ymw=",
    },
  }

  const submitHandle = async (e) => {
    e.preventDefault()
    const userData = {
      email,
      password,
    }
    const res = await request
      .post("authentication/email/login", userData, axiosConfig)
      .then()
      .catch((err) => err)
    if (!res?.data) {
      setErr("Email or Password incorrect")
    } else {
      if (res?.data?.message === "send OTP") {
        setShowOtp(true)
        setErr("")
      }
    }
  }

  const submitOtp = async (e) => {
    e.preventDefault()
    const userData = {
      email,
      otp,
    }
    const res = await request
      .post("authentication/confirm/otp", userData, axiosConfig)
      .then()
      .catch((err) => err)
    if (!res?.data) {
      setErr("OTP incorrect or expired")
    } else {
      const { localId, idToken } = res.data
      if (localId && idToken) {
        const data = {
          username: localId,
          password: idToken,
          grant_type: "password",
          client_id: "bluuu-client-id",
          client_secret: "bluuu-client-secret",
        }
        const config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
        const res2 = await axios
          .post(GetApiURL().replace("/v1.0/admin", "") + "/oauth/token", qs.stringify(data), config)
          .then()
          .catch((err) => err)
        if (res2?.data?.access_token) {
          localStorage.setItem("accessToken", res2.data.access_token)
          localStorage.setItem("refreshToken", res2.data.refresh_token)
          localStorage.setItem("timeOut", new Date().getTime() + 1000 * 60 * 60 * 24 * 7)
          window.location.href = "/admin/customer-management"
        } else {
          setErr("Something went wrong please try again")
        }
      }
    }
  }
  return (
    <div className="wrapper">
      {showOtp ? (
        <form className="form-signin" onSubmit={(e) => submitOtp(e)}>
          <h2 className="headering">OTP</h2>
          <input
            id="otp"
            className="form-control"
            autoComplete="off"
            type="text"
            placeholder="otp"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <button
              className="login-button"
              onClick={() => {
                setShowOtp(false)
                setErr("")
              }}
              style={{ backgroundColor: "#3e5569" }}>
              Back
            </button>
            <button disabled={otp === ""} className="login-button" type="submit">
              Confirm
            </button>
          </div>
          {err && (
            <div
              style={{
                display: "flex",
                fontWeight: "bold",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
                color: "red",
              }}>
              <p>*{err}</p>
            </div>
          )}
        </form>
      ) : (
        <form className="form-signin" onSubmit={(e) => submitHandle(e)}>
          <h2 className="headering">Please Login</h2>
          <input
            id="email"
            className="form-control"
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            id="password"
            className="form-control"
            type="password"
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button disabled={email === "" || password === ""} className="login-button" type="submit">
            Login
          </button>
          {err && (
            <div
              style={{
                display: "flex",
                fontWeight: "bold",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
                color: "red",
              }}>
              <p>*{err}</p>
            </div>
          )}
        </form>
      )}
    </div>
  )
}
export default connect()(LoginPage)
