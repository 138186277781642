import request from "../../utils/request"
import moment from "moment"
export const customer = {
  state: {
    customers: [
      // {
      //       "id": 10,
      //       "first_name": "Attawat",
      //       "last_name": "Panich",
      //       "image_url": "https://graph.facebook.com/10156407181191048/picture?type=large",
      //       "email": "north.ap@live.com",
      //       "phone": null,
      //       "role": "CUSTOMER",
      //       "create_datetime": "2019-05-26T08:28:53.000Z"
      // }
    ],
    column: [
      // ANCHOR ตั้งค่าcolumnตรงนี้
      { label: "Picture", accessor: "picture", isShow: false },
      // { label: "Date/Time", accessor: "Date_Time", isShow: true },
      { label: "Joining Date", accessor: "joinDate", isShow: true },
      { label: "User ID", accessor: "User_ID", isShow: true },
      { label: "User Name", accessor: "User_Name", isShow: true },
      { label: "Chat", accessor: "chat", isShow: true },
      { label: "User Phone", accessor: "User_Phone", isShow: true },
      { label: "FB email", accessor: "FB_email", isShow: true },
      { label: "Secondary email", accessor: "Secondary_email", isShow: true },
      { label: "Login Type", accessor: "Login_Type", isShow: true },
      { label: "Notification before 24 hr", accessor: "Notification_before_24hr", isShow: false },
      {
        label: "Notification before last credit",
        accessor: "Noti_before_lastCredit",
        isShow: false,
      },
      { label: "Privacy setting", accessor: "Privacy_setting", isShow: false },
      { label: "Default Location 1", accessor: "Default_Location_1", isShow: true },
      { label: "Default Location 2", accessor: "Default_Location_2", isShow: true },
      { label: "Default Location 3", accessor: "Default_Location_3", isShow: true },
      // { label: 'Email', accessor: 'email', isShow: true },
      // { label: 'Role', accessor: 'role', isShow: true },
    ],
    column1: [
      { label: "Name", accessor: "name", isShow: true },
      { label: "Id", accessor: "id", isShow: true },
      { label: "Code", accessor: "code", isShow: true },
      { label: "Create BY", accessor: "createBy", isShow: true },
      { label: "Start Date", accessor: "startDate", isShow: true },
      { label: "End Date", accessor: "endDate", isShow: true },
    ],
    customerList: 0,
    totalPage: 0,
  },
  reducers: {
    setCustomerList(state, payload) {
      return { ...state, customers: payload }
    },
    setCustomerTotalList(state, payload) {
      return { ...state, totalPage: payload }
    },
    setCustomerColumnList(state, payload) {
      // console.log(`payload: ${JSON.stringify(payload)}`)
      return { ...state, column: payload }
    },
    toggleShowCustomerColumn(state, payload) {
      console.log(`payload: ${payload}`)
      // console.log(`target: ${JSON.stringify(state.column[6].accessor)}`)
      var newColumn = state.column
      const result = state.column.map((obj, index) => {
        if (obj.accessor === payload) {
          // console.log(`match: ${obj.accessor} === ${payload} `)
          const editObj = { ...obj, isShow: !obj.isShow }
          // console.log(`editObj: ${JSON.stringify(editObj)}`)
          newColumn[index] = editObj
          return newColumn
        }
        return newColumn
        // return {...state}
      })
      console.log(`result: ${JSON.stringify(result[0])}`)
      // return result from map method
      // which is array with 1 member
      return { ...state }
      // return result[0]
    },
  },
  effects: (dispatch) => ({
    async getCustomerList() {
      const res = await request.get("/customer/list?limit=5000")
      const cleanData = res.data.customers.map((customer) => {
        return {
          id: customer.id,
          first_name: customer.first_name,
          last_name: customer.last_name,
          name: `${customer.first_name} ${customer.last_name}`,
          image: customer.image_url,
          email: customer.email === null ? "-" : customer.email,
          phone: customer.phone === null ? "-" : customer.phone,
          role: customer.role,
          joinDate: moment(customer.create_datetime).format("YYYY-MM-DD"),
        }
      })
      const totalData = Math.ceil(res.data.item_count / 5000)
      dispatch.customer.setCustomerList(cleanData)
      dispatch.customer.setCustomerTotalList(totalData)
    },
  }),
}
