import { GetApiURL } from "./request";

export const ip = GetApiURL()
export const post = (object, path, token = true) => new Promise((resolve, reject) => {
   const access_token = localStorage.getItem("accessToken")
   fetch(ip + path, {
       method: 'POST',
       headers: {
           'Authorization': token ? `Bearer ${access_token}` : 'Basic Ymx1dXU6dXV1Ymw=',
           'Accept': 'application/json',
           'Content-Type': 'application/json'
       }, body: JSON.stringify(object)
   }).then(res => {
       setTimeout(() => null, 0);
       return res.json()
   }).then(json => {
       resolve(json);
   }).catch((err) => reject(err))
})
export const get = (path, token = true) => new Promise((resolve, reject) => {
   const access_token = localStorage.getItem("accessToken")
   fetch(ip + path, {
       method: 'GET',
       headers: {
           'Authorization': token ? `Bearer ${access_token}` : 'Basic Ymx1dXU6dXV1Ymw=',
           'Accept': 'application/json',
           'Content-Type': 'application/json'
       }
   }).then(res => {
       setTimeout(() => null, 0);
       return res.json()
   }).then(json => {
       resolve(json);
   }).catch((err) => reject(err))
})
export const get_other = (path, token) => new Promise((resolve, reject) => {    
   fetch(path, {
       method: 'GET',
       mode: 'no-cors'
   }).then(res => {
       setTimeout(() => null, 0);
       return res.blob()
   }).then(blob => {
       console.log(blob)
       resolve(blob);
   }).catch((err) => reject(err))
})

