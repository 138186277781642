import React, { Component } from 'react'
import styled, { css } from 'styled-components'

const StyledButton = styled.button`



  background: ${(props) => props.theme.secondary}
  :focus {
    border-color:transparent!important;
    outline:none;
  }
  
  ${(props) => {
    if (props.width) {
      return css`
        width: ${props.width}px;
        height: ${props.width}px;
        border-radius: ${props.width}px;
        border: none;
      `
    }
    return css`
      width: 40px;
      height: 40px;
      border-radius: 40px;
      border: none;
    `
  }}
    // Change color of the icon
    i {
    color: white;
  }
`

class ButtonIcon extends Component {
  static propTypes = {}

  render() {
    const { icon } = this.props
    return (
      <StyledButton {...this.props} type="button" className="waves-effect waves-light">
        <i className={icon} />
      </StyledButton>
    )
  }
}

export default ButtonIcon
