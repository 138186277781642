import axios from "axios"
import qs from "qs"

//  Easy types
const access_token = localStorage.getItem("accessToken")

let refresh_token_promise = null

const instance = axios.create({
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE',
    // 'Access-Control-Allow-Headers': 'Origin,Content-Type, Authorization, Content-Length, X-Requested-With',
    // 'Content-Type': 'application/json; charset=utf-8',
    // 'Content-Type': 'application/x-www-form-urlencoded',
    // Authorization: "Basic Ymx1dXU6dXV1Ymw=",
    Authorization: access_token ? `Bearer ${access_token}` : "Basic Ymx1dXU6dXV1Ymw=",
    "Content-Type": " application/json",
  },

  // baseURL: process.env.REACT_APP_UAT
  baseURL: GetApiURL(),
})

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return _handleError(error)
  }
)

export default instance

export function GetApiURL() {
  const url = window.location.hostname
  switch (url) {
    case "admin.bluuu.co":
      return process.env.REACT_APP_PRODUCTION
      break
    case "uat-admin.bluuu.co":
      return process.env.REACT_APP_UAT
      break
    default:
      return process.env.REACT_APP_DEV
      break
  }
}

const _handleError = async (error) => {
  let originalRequest = error.config
  let _retry = false
  if (error.response) {
    if (error.response.status === 500) {
      console.error("[Server Error]", error.response?.data.message || error.message)
      throw {
        title: "Server Error",
        content: `Cannot connect to server. Please try again later.`,
        message: error.response?.data.message || error.message,
        error: error,
      }
    }

    if (error.response.status === 403) {
      const callback = window.location.href
      window.location.replace("/admin/customer-management#/admin/login?callback=" + callback)
      throw {
        title: "Permission",
        content: error.response?.data.message || error.message,
      }
    }

    if (error.response.status === 401 && !_retry && !error.config.url?.includes("/oauth/token")) {
      _retry = true
      const refresh_token = localStorage.getItem("refreshToken")

      try {
        if (!refresh_token_promise) {
          refresh_token_promise = RefreshToken(refresh_token)
        }
        const access_token = await refresh_token_promise

        if (access_token) {
          originalRequest.headers.Authorization = `Bearer ${access_token}`
          return axios(originalRequest)
        }

        return Promise.reject('No Token')
      } catch (err) {
        console.error("[Refresh Token Error]", err)
        const callback = window.location.href
        window.location.replace("/admin/customer-management#/admin/login?callback=" + callback)
        throw {
          title: "Permission",
          content: err.response?.data.message || err.message,
        }
      }
    } else {
      return Promise.reject(error)
    }
  } else {
    return Promise.reject(error)
  }
}

async function RefreshToken (refresh_token) {
  const res = await axios.post(
    GetApiURL().replace("/v1.0/admin", "") + "/oauth/token",
    qs.stringify({
      grant_type: "refresh_token",
      refresh_token: refresh_token,
      client_id: "bluuu-client-id",
      client_secret: "bluuu-client-secret",
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  )
  if (res?.data?.access_token) {
    localStorage.setItem("accessToken", res.data.access_token)
    localStorage.setItem("refreshToken", res.data.refresh_token)
    return res.data.access_token
  }
}

// console.log(window.location)

// import { ACCESS_TOKEN } from '../constants'

// const baseURL = process.env.REACT_APP_API_URL

// axios.defaults.baseURL = baseURL
// function buildURLFromTemplate(data, options) {
//   if (data instanceof FormData) {
//     return {
//       data,
//       url: options.url,
//     }
//   }
//   let outputData = { ...options.defaultParams, ...data }
//   const outputURL = options.url.replace(/\{(.+?)\}/g, (m, label) => {
//     const value = outputData[label]
//     if (value !== undefined) {
//       delete outputData[label]
//     } else {
//       throw new Error(`Cannot find ${label} in ${options.url}`)
//     }
//     return value
//   })
//   if (Array.isArray(data)) {
//     outputData = data
//   }
//   return {
//     data: outputData,
//     url: outputURL,
//   }
// }
// // async function refreshToken(token) {
// //   const options = {
// //     method: 'post',
// //     url: '/users/token',
// //     headers: {
// //       Authorization: `Bearer ${token}`,
// //     },
// //   };
// //   try {
// //     const response = await axios.request(options);
// //     localStorage.setItem(ACCESS_TOKEN, JSON.stringify(response.data));
// //     return response;
// //   } catch (e) {
// //     throw e;
// //   }
// // }
// export default async (data, options, extraOptions) => {
//   const config = {}
//   const { data: outputData, url } = buildURLFromTemplate(data, options)
//   config.url = url
//   switch (options.method) {
//     case 'post':
//       config.method = 'post'
//       config.data = outputData
//       break
//     case 'get':
//       config.method = 'get'
//       config.params = outputData
//       break
//     case 'put':
//       config.method = 'put'
//       config.data = outputData
//       break
//     case 'delete':
//       config.method = 'delete'
//       config.params = outputData
//       break
//     case 'patch':
//       config.method = 'patch'
//       config.data = outputData
//       break
//     default:
//       throw new Error('Http method not support')
//   }
//   // return axios.request(config).then((res) => res.data);
//   try {
//     // set header
//     config.headers = {
//       ...options.headers,
//     }
//     const stringToken = localStorage.getItem(ACCESS_TOKEN)
//     if (stringToken) {
//       try {
//         const token = JSON.parse(stringToken)
//         // console.log('token', token)
//         if (token.createdAt && Date.now() - token.createdAt >= token.TTL - 120) {
//           // call refresh token
//           // await refreshToken(token.refreshToken);
//           // token = JSON.parse(localStorage.getItem(ACCESS_TOKEN));
//           localStorage.clear()
//           return Promise.reject(
//             new Error({ response: { statusCode: 401 }, message: 'token is expired' })
//           )
//         }
//         // check if require refresh token
//         // config.headers.Authorization = `Bearer ${token.id}`;
//         config.headers.Authorization = token.id
//       } catch (e) {
//         delete config.headers.Authorization
//       }
//     }
//     const result = await axios.request({ ...config, ...extraOptions })
//     return result.data
//   } catch (e) {
//     if (e.response && e.response.data && e.response.data.error) {
//       e.response = e.response.data.error
//     }
//     throw e
//   }
// }
