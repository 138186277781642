export const data = [
  {
    name: 'ฝน',
    dateTrainComplete:'25/10/2019',
    dateFirstbookingAccept:'1/11/2019',
    dateLastbookingAccept:'25/12/2019',
    noLogins:334,
    salary:8900,
    hrsWorked:102,
    noUserServed:28,
    noServiceCompleted:40,
    noRatingsReceived:10,
    noUsersRated:3,
    pctRatingReceivedPerService:'25.0%',
    pctRatingReceivedPerUser:'35.7%',
    noTimesFiveStarBonusAchieved:1,
    noTimesMilestoneBonusAchieved:0,
    noTimesMonthlyBonusAchieved:1,
    noTimesReferralBonusAchieved:0,
    noTimesRejectedInMPbooking:2,
    noTotalAvailableHrsNext1mth:158,
    noTotalAvailableHrsNext2mth:336,
    noTotalAvailableHrsNext3mth:560,
  },
  {
    name: 'ไผ่',
    dateTrainComplete:'25/10/2019',
    dateFirstbookingAccept:'5/11/2019',
    dateLastbookingAccept:'16/12/2019',
    noLogins:176,
    salary:7000,
    hrsWorked:47,
    noUserServed:10,
    noServiceCompleted:22,
    noRatingsReceived:3,
    noUsersRated:2,
    pctRatingReceivedPerService:'13.6%',
    pctRatingReceivedPerUser:'30.0%',
    noTimesFiveStarBonusAchieved:1,
    noTimesMilestoneBonusAchieved:0,
    noTimesMonthlyBonusAchieved:1,
    noTimesReferralBonusAchieved:0,
    noTimesRejectedInMPbooking:2,
    noTotalAvailableHrsNext1mth:180,
    noTotalAvailableHrsNext2mth:360,
    noTotalAvailableHrsNext3mth:0,
  },
  {
    name: 'พลอย',
    dateTrainComplete:'25/10/2019',
    dateFirstbookingAccept:'3/11/2019',
    dateLastbookingAccept:'23/12/2019',
    noLogins:98,
    salary:5000,
    hrsWorked:30,
    noUserServed:28,
    noServiceCompleted:14,
    noRatingsReceived:10,
    noUsersRated:3,
    pctRatingReceivedPerService:'21.4%',
    pctRatingReceivedPerUser:'50.0%',
    noTimesFiveStarBonusAchieved:0,
    noTimesMilestoneBonusAchieved:0,
    noTimesMonthlyBonusAchieved:0,
    noTimesReferralBonusAchieved:0,
    noTimesRejectedInMPbooking:0,
    noTotalAvailableHrsNext1mth:80,
    noTotalAvailableHrsNext2mth:120,
    noTotalAvailableHrsNext3mth:0,
  },
]
