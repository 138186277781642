import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import Toggle from "react-toggle"
import 'react-table/react-table.css'
import '../../style/react-table-custom.css'
import './invemcalendar.css'


const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: #fff;
  flex: 1;
  margin-bottom: 20px;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 10px;
  flex: 1;
`
const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const GraphContainer = styled.div`
  display: block;
  padding: 0px 10px;
  // width: max-content;
  // flex: 1;

  .highcharts-container {
    position: relative;
    overflow: auto;
    width: 100%;
    height: 100%;
  }
`


const Htable = styled.div`
text-align: right;
`
class Carlendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      isPercent: false
    }
  }


  render() {
    const {
      title,
    } = this.props

    const allColumns = [
      {
          Header: () => <h6>{''}</h6>,
          accessor: 'date',
          minWidth: 90,
          sort: false
      },
      ...Array.from({ length: 31 }, (_, index) => ({
          Header: () => <Htable>{`${Math.floor(index / 2) + 6}:${index % 2 === 0 ? '00' : '30'}`}</Htable>,
          accessor: this.state.isPercent ? `col${index + 1}percent` : `col${index + 1}`,
          minWidth: 45,
          Cell: row => {
              return (
                  <div>
                      {row.value ? parseFloat(row.value).toFixed(0) : ''}
                  </div>
              )
          }
      })),
      {
          Header: () => <Htable></Htable>,
          accessor: 'coltemp',
          minWidth: 20,
      },
  ];
  
    return (
      <Container>
        <HeaderContainer>
          <HeaderContent>
            <h5>{title}</h5>
          </HeaderContent>
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginRight: 20 }}>
          <Toggle
          onChange={() => this.setState({ isPercent: !this.state.isPercent })}
          checked={this.state.isPercent}
          value={this.state.isPercent}
          icons={{
            checked: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 12,
                  color: "#FFFFFF",
                  paddingRight: 2,
                }}>
                Percent
              </div>
            ),
            unchecked: (
              <div
                style={{
                  display: "flex",
                  float: "right",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 12,
                  color: "#324755",
                  paddingRight: 2,
                }}>
                Number
              </div>
            ),
          }}
        />
        </div>
        </HeaderContainer>
        <GraphContainer>
          <ReactTable
            sortable={false}
            data={this.props.inventoriesCalendar}
            columns={allColumns}
            defaultPageSize={32}
            loading={this.props.loadingPage}
            getTrProps={(state, rowInfo) => {
              return {
                style: {
                  height: 25,
                  overflow:'hidden'
                },
              }
            }}
            showPagination={false}
            style={{ textAlign: 'right'}}
            className="-striped -highlight invencalendartable"
          />
        </GraphContainer>
      </Container>
    )
  }
}

const mapState = (state) => {
  return {
    inventoriesCalendar: state.invenCalendar.inventoriesCalendar,
    loadingPage: state.invenCalendar.loadingPage
  }
}

const mapDispatch = (dispatch) => { return {} }

export default connect(
  mapState,
  mapDispatch
)(Carlendar)
