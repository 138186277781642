import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import ContentCard from '../../components/contentCard'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex:1;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.active};
  background: #fff;
  height: max-content;
  position:relative;
  top: -1px;
  padding: 20px 40px;
  align-items: flex-start;
`

class ContentPublishedPage extends Component {
  componentDidMount() {
    this.props.setPageTitle('Content List')
  }

  render() {
    return (
      <div>
        <Card>
          <ContentCard/>
          <ContentCard/>
          <ContentCard/>
          <ContentCard/>
          <ContentCard/>
          <ContentCard/>
          <ContentCard/>
          <ContentCard/>
          <ContentCard/>
          <ContentCard/>
          <ContentCard/>
          <ContentCard/>
        </Card>
      </div>
    )
  }
}

const mapState = () => ({})

const mapDispatch = (dispatch) => {
  return { setPageTitle: dispatch.Title.setPageTitle }
}

export default connect(
  mapState,
  mapDispatch
)(ContentPublishedPage)

