import * as SendBird from 'sendbird'
import { USER_ID, findConfig } from './sbConstants'

const { APP_ID, ACCESS_TOKEN } = findConfig()

export const sbUser = {
    state: {
        sbUser: {}
    },
    reducers: {
        setSbUser (state, payload) {
            return { ...state, sbUser: payload }
        }
    },
    effects: (dispatch) => ({
        async sbConnect () {
            const sb = new SendBird({appId: APP_ID})

            sb.connect(USER_ID, ACCESS_TOKEN, (user, error) => {
                if (error) {
                    return
                }

                dispatch.sbUser.setSbUser(user)
            })
        },
        sbUpdateUser (data) {
            const sb = SendBird.getInstance()

            sb.updateCurrentUserInfo(data.nickName, data.profileUrl, (resp, error) => {
                if (error) {
                    return
                }

                // *TODO: SOME
            })
        },
        sbUpdateUserFileImage (data) {
            const sb = SendBird.getInstance()

            sb.updateCurrentUserInfoWithProfileImage(data.nickName, data.profileUrl, (resp, error) => {
                if (error) {
                    return
                }

                // *TODO: SOME
            })
        },
        sbDisconnect () {
            const sb = SendBird.getInstance()
            sb.disconnect()
        }
    })
}