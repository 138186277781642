import request from '../../utils/request'

export const settingGroup = {
  state: {
    dataCustomerList: 0,
    dataMaidList: 0,
    dataGroupList: [],
    dataGroupCustomerList: [],
    dataGroupMaidList: [],
    updateData: '',
    columnSettingGroup: [
      { label: 'Checkbox', accessor: 'check', isShow: true },
      { label: 'Picture', accessor: 'picture', isShow: true },
      { label: 'Name', accessor: 'name', isShow: true },
      { label: 'Email', accessor: 'Email', isShow: true },
      { label: 'Role', accessor: 'Role', isShow: true },
    ],
    showBtnAdd: true,
    nameSettingGroup: '',
    modeSettingGroup: '',
    checkGroupEdit: false,
    dataForEditGroup: {},
    defaultDetailist: '',
    stateCheckbox: '',
    fillterRightBar: '',
    idDetailCustomer: '',
    dataReloadForEditGroup: {},
    reloadNameGroup: '',
    checkDisable: '',
    checkDisableMaid: '',
    keepcheckboxCus: [],
    keepcheckboxMaid: [],
    modeUPdate: '',
    IDForUPdate: '',

  },

  reducers: {
    setModeUPdate(state, payload) {
      return {
        ...state,
        modeUPdate: payload
      }
    },
    setIDForUPdate(state, payload) {
      return {
        ...state,
        IDForUPdate: payload
      }
    },
    SetkeepcheckboxCus(state, payload) {
      return {
        ...state,
        keepcheckboxCus: payload
      }
    },
    SetkeepcheckboxMaid(state, payload) {
      return {
        ...state,
        keepcheckboxMaid: payload
      }
    },
    setCheckDisable(state, payload) {
      return {
        ...state,
        checkDisable: payload
      }
    },
    setCheckDisableMaid(state, payload) {
      return {
        ...state,
        checkDisableMaid: payload
      }
    },
    setIdDetailCustomer(state, payload) {
      return {
        ...state,
        idDetailCustomer: payload
      }
    },
    checkFillterRightBar(state, payload) {
      return {
        ...state,
        fillterRightBar: payload
      }
    },
    checkStateCheckbox(state, payload) {
      return {
        ...state,
        stateCheckbox: payload
      }
    },
    setModeSettingGroup(state, payload) {
      return {
        ...state,
        modeSettingGroup: payload
      }
    },
    checkGroupForEdit(state, payload) {
      return {
        ...state,
        checkGroupEdit: payload
      }
    },
    checkdefaultDetail(state, payload) {
      return {
        ...state,
        defaultDetailist: payload
      }
    },
    setReloadDataForEditGroup(state, payload) {
      return {
        ...state,
        dataReloadForEditGroup: payload
      }
    },
    setDataForEditGroup(state, payload) {
      return {
        ...state,
        dataForEditGroup: payload
      }
    },
    setnameSettingGroup(state, payload) {
      return {
        ...state,
        nameSettingGroup: payload
      }
    },
    setreloadnameSettingGroup(state, payload) {
      return {
        ...state,
        reloadNameGroup: payload
      }
    },

    checkUpdateData(state, payload) {
      return {
        ...state,
        updateData: payload
      }
    },
    getSettingCustomerList(state, payload) {
      return {
        ...state,
        dataCustomerList: payload
      }
    },
    getGroupList(state, payload) {
      return {
        ...state,
        dataGroupList: payload
      }
    },
    getGroupCustomerList(state, payload) {
      return {
        ...state,
        dataGroupCustomerList: payload
      }
    },
    getGroupMaidList(state, payload) {
      return {
        ...state,
        dataGroupMaidList: payload
      }
    },

    getSettingMaidList(state, payload) {
      return {
        ...state,
        dataMaidList: payload
      }
    },
  },
  effects: (dispatch) => ({
    async getSettingMaid() {
      const res = await request.get('/maid/list')
      const cleanData = res.data.item_count
      dispatch.settingGroup.getSettingMaidList(cleanData)
    },
    async getSettingCustomer() {
      const res = await request.get('/customer/list')
      const cleanData = res.data.item_count
      dispatch.settingGroup.getSettingCustomerList(cleanData)
    },
    async getSettingGroupList() {
      const res = await request.get('/setting/group/list')
      const cloneCustomer = []
      const cloneMaid = []
      res.data.forEach(el => {
        if (el.type === 'CUSTOMER') {

          cloneCustomer.push({
            id: el.id,
            name: el.name,
            type: el.type
          })

          dispatch.settingGroup.getGroupCustomerList(cloneCustomer)
        } else if (el.type === 'MAID') {
          cloneMaid.push({
            id: el.id,
            name: el.name,
            type: el.type
          })
          dispatch.settingGroup.getGroupMaidList(cloneMaid)
        }
      });
      const cleanData = res.data.map((el) => {
        return {
          id: el.id,
          name: el.name,
          type: el.type
        }
      })
      dispatch.settingGroup.getGroupList(cleanData)
    },

  }),
}