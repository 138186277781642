import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import ReactTable from "react-table"
import Toggle from "react-toggle"
import "react-table/react-table.css"
import "../../style/react-table-custom.css"
import moment from "moment"
import request from "../../utils/request"
import Pagination from "../../components/paginationTable/pagination"
import Button from "../../components/button"
import TabComponent from "./../../components/tabComponent"
import Rightbar from "../../layouts/maidManagementLayout/rightbar"
import "./maidTableHeader.css"
import swal from "sweetalert"

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.active};
  background: #fff;
  height: max-content;
  width: 100%;
  position: relative;
  top: -1px;
  padding: 10px;
  align-items: flex-start;
  overflow: auto;
`
const HeaderTable = styled.div`
  text-align: left;
`
const Overlay = styled.div`
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
`
class MemberMaidPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      column: this.props.maidColumn,
      resAnnouncement: [],
      data: [],
      totaldata: 0,
      pages: 0,
      checktext: "",
      textSearch: "",
      tempDataTable: [],
    }
  }

  componentDidMount() {
    this.props.setPageTitle(
      window.location.href.includes("/radius/list") ? "Radius List" : "Maid List"
    )
    this.onFeactData()
    // this.props.showBackIcon(true)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.column !== this.props.maidColumn) {
      this.setState({ column: this.props.maidColumn })
      this.props.setPageTitle(
        window.location.href.includes("/radius/list") ? "Radius List" : "Maid List"
      )
    }
  }

  async onFeactData(page) {
    let txtSearch = this.state.checktext !== "clearSearch" ? this.state.textSearch : ""
    let pageNO = page ? page.page : 1
    let limit = 5000
    this.setState({ loading: true, tempDataTable: [] })
    // await this.props.getMaidList(txtSearch)
    try {
      request
        .get(`/maid/list?search=${txtSearch.trim()}&page=${pageNO}&limit=${limit}`)
        .then((res) => {
          let resp = res.data
          console.log(`: ------------------------------------------`)
          console.log(`MemberMaidPage -> onFeactData -> resp`, resp)
          console.log(`: ------------------------------------------`)

          let tempArr = []
          if (resp.maids.length > 0) {
            for (let i = 0; i < resp.maids.length; i++) {
              if (resp.maids[i].real_maid_id) {
                console.log("resp.maids[i].location_radius", resp.maids[i].location_radius)
                tempArr.push({
                  image:
                    resp.maids[i].user.image_url === null
                      ? "https://via.placeholder.com/300x300.png"
                      : resp.maids[i].user.image_url,
                  App_ID: resp.maids[i].id,
                  Maid_ID: resp.maids[i].real_maid_id,
                  maidIdPro: resp.maids[i].id ? resp.maids[i].id : "-",
                  chanel_url: resp.maids[i].user.admin_channel_url,
                  name: resp.maids[i].user.first_name + " " + resp.maids[i].user.last_name,
                  phone: resp.maids[i].user.phone === null ? "-" : resp.maids[i].user.phone,
                  Maid_Tier: resp.maids[i].maid_level ? resp.maids[i].maid_level : "-",
                  Average_Ratings: resp.maids[i].rating ? resp.maids[i].rating : -1,
                  of_ratings: resp.maids[i].no_of_reviews ? resp.maids[i].no_of_reviews : -1,
                  Repeat_Rate:
                    typeof resp.maids[i].repeat_rate === "number" ? resp.maids[i].repeat_rate : -1,
                  Total_OTA:
                    typeof resp.maids[i].repeat_rate === "number" ? resp.maids[i].total_OTA : -1,
                  Net_OTA:
                    typeof resp.maids[i].repeat_rate === "number" ? resp.maids[i].net_OTA : -999999,
                  Percent_OTA:
                    typeof resp.maids[i].repeat_rate === "number" ? resp.maids[i].percent_OTA : -1,
                  Start_Date: resp.maids[i].start_date || "-",
                  District_of_Pin: resp.maids[i].location_district
                    ? resp.maids[i].location_district
                    : "-",
                  Radius:
                    resp.maids[i].location_radius === null ||
                    resp.maids[i].location_radius === undefined
                      ? 9999
                      : parseFloat(resp.maids[i].location_radius),
                  Lock_Radius: resp.maids[i].lock_radius ? "Lock" : "Unlock",
                  Interviewer: "-",
                  Trainer: "-",
                  email: resp.maids[i].user.email === null ? "-" : resp.maids[i].user.email,
                  role: resp.maids[i].user.role,
                  status:
                    resp.maids[i].status === null || !resp.maids[i].status
                      ? "-"
                      : resp.maids[i].status,
                  joinDate: moment(resp.maids[i].create_datetime).format("YYYY-MM-DD"),
                  Is_Published: resp.maids[i].is_published,
                  Stop_OTA: resp.maids[i].stop_ota,
                })
              }
            }

            tempArr.sort((a, b) => b.Maid_ID - a.Maid_ID)

            console.log(`: ------------------------------------------------`)
            console.log(`MemberMaidPage -> onFeactData -> tempArr`, tempArr)
            console.log(`: ------------------------------------------------`)

            this.setState(
              {
                data: tempArr,
                loading: false,
                totaldata: resp.item_count,
                pages: Math.ceil(resp.item_count / limit),
              },
              () => this.filterdataList(tempArr)
            )
          } else {
            this.setState({ data: [], loading: false, totaldata: 0, pages: 0 })
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  handleToggle = (key) => {
    this.setState((prevState) => ({ [key]: !prevState[key] }))
    const { history } = this.props
    history.push(this.props.history.location.pathname)
  }

  handleColumn = () => {
    this.setState({ showRightbar: true, mode: "column" })
  }
  handleFilter = () => {
    this.setState({ showRightbar: true, mode: "filter" })
  }

  onChangeTab = (path) => {
    const { history, match } = this.props
    history.push(match.url + path)
  }
  onSentTextSearch = () => {
    if (this.state.textSearch !== "") {
      this.setState({ checktext: this.state.textSearch }, () => this.onFeactData())
    } else if (this.state.textSearch === "" && this.state.checktext !== "") {
      this.setState({ checktext: "clearSearch" }, () => this.onFeactData())
    } else {
      this.onFeactData()
    }
  }

  onExportData() {
    swal({
      className: "swal-modal",
      title: "Are you sure?",
      text: `Are you sure export data Maid `,
      buttons: ["Cancel", "Confirm"],
      defeat: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url =
          window.location.hostname === "admin.bluuu.co"
            ? process.env.REACT_APP_PRODUCTION
            : window.location.hostname === "uat-admin.bluuu.co"
            ? process.env.REACT_APP_UAT
            : process.env.REACT_APP_DEV
        const redir = url + "/" + `maid/list/csv`
        window.open(redir, "blank")
        swal("Export Success", {
          icon: "success",
        })
      }
    })
  }

  compareBy(key, order) {
    return function (a, b) {
      if (order) {
        if (a[key] < b[key]) {
          return 1
        }
        if (a[key] > b[key]) {
          return -1
        }
        return 0
      } else {
        if (a[key] > b[key]) {
          return 1
        }
        if (a[key] < b[key]) {
          return -1
        }
        return 0
      }
    }
  }

  async togleSorting(newSorted, column, shiftKey) {
    let arrayCopy =
      (await this.state.tempDataTable.length) > 0
        ? [...this.state.tempDataTable]
        : [...this.state.data]
    arrayCopy.sort(this.compareBy(column.id, newSorted[0].desc))
    this.setState({ data: arrayCopy, tempDataTable: arrayCopy })
  }

  async filterdataList(data) {
    let arr = []
    if (this.props.averageListFilter.length === 0 || this.props.repeatRateListFilter.length === 0) {
      arr = ["noData"]
    } else {
      arr = await data.filter(
        (v) =>
          this.checkFilterAverRageList(v.Average_Ratings) &&
          this.checkFilterRepeateRateList(v.Repeat_Rate) &&
          this.checkFilterPublishedList(v.Is_Published)
      )
      if (arr.length === 0) {
        arr = ["noData"]
      }
    }
    this._on_data_filter(arr)
  }
  checkFilterAverRageList(averageRatings) {
    if (averageRatings >= 4.85) {
      return this.props.averageListFilter.findIndex((e) => e.id === 1) > -1
    } else if (averageRatings >= 4.7 && averageRatings <= 4.85) {
      return this.props.averageListFilter.findIndex((e) => e.id === 2) > -1
    } else if (averageRatings >= 4.55 && averageRatings <= 4.7) {
      return this.props.averageListFilter.findIndex((e) => e.id === 3) > -1
    } else if (averageRatings >= 4.1 && averageRatings <= 4.55) {
      return this.props.averageListFilter.findIndex((e) => e.id === 4) > -1
    } else if (averageRatings >= 3.95 && averageRatings <= 4.1) {
      return this.props.averageListFilter.findIndex((e) => e.id === 5) > -1
    } else {
      return this.props.averageListFilter.findIndex((e) => e.id === 6) > -1
    }
  }
  checkFilterRepeateRateList(repeatRate) {
    if (repeatRate >= 90) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 1) > -1
    } else if (repeatRate >= 80 && repeatRate <= 90) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 2) > -1
    } else if (repeatRate >= 70 && repeatRate <= 80) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 3) > -1
    } else if (repeatRate >= 60 && repeatRate <= 70) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 4) > -1
    } else if (repeatRate >= 50 && repeatRate <= 60) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 5) > -1
    } else if (repeatRate >= 40 && repeatRate <= 50) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 6) > -1
    } else if (repeatRate >= 30 && repeatRate <= 40) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 7) > -1
    } else if (repeatRate >= 20 && repeatRate <= 30) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 8) > -1
    } else if (repeatRate >= 10 && repeatRate <= 20) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 9) > -1
    } else {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 10) > -1
    }
  }
  checkFilterPublishedList(is_published) {
    if (is_published) {
      return true
    }
    return this.props.publishedListFilter.findIndex((e) => e.id === 1) > -1
  }

  _on_data_filter(data) {
    this.setState({ tempDataTable: data })
  }
  openProfileMaid(data) {
    const url = `#/admin/maid-management/maidProfile/${data.maidIdPro}`
    const state = data
    window.open(url, state)
  }
  async toggleLockRadius(data) {
    const AppId = data.App_ID
    const toggleTo = data.Lock_Radius === "Lock" ? false : true
    try {
      await request.post(`maid/id/${AppId}/radius/lock/${toggleTo}`)
      this.onFeactData()
    } catch (err) {
      if (err.response) {
        alert(err.response.data.error)
      } else {
        alert(err.message)
      }
    }
  }

  async handleStopOTA(data) {
    if (!data.Stop_OTA) {
      swal({
        className: "swal-modal",
        title: "Are you sure?",
        text: `Are you sure to stop OTA for Maid ID ${data.Maid_ID} : ${data.name}`,
        buttons: ["Cancel", "Confirm"],
        defeat: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onStopOTA(data)
        }
      })
    } else {
      this.onStopOTA(data)
    }
  }

  async onStopOTA(data) {
    try {
      await request.post(`maid/id/${data.App_ID}/stopOTA/${!data.Stop_OTA}`)
      this.onFeactData()
    } catch (err) {
      if (err.response) {
        alert(err.response.data.error)
      } else {
        alert(err.message)
      }
    }
  }

  render() {
    const allColumns = [
      {
        Header: () => <h6>{""}</h6>,
        accessor: "picture", // String-based value accessors!
        minWidth: 30,
        Cell: (row) => (
          <div style={{ width: "100%", textAlign: "left" }}>
            <img
              src={`${row.original.image}`}
              alt="profile3"
              className="rounded-circle"
              width={25}
            />
          </div>
        ),
      },
      {
        Header: () => <HeaderTable style={{ paddingLeft: 5 }}>App ID</HeaderTable>,
        accessor: "App_ID",
        Cell: (row) => (
          <div style={{ textAlign: "left", paddingLeft: 5 }}>{row.original.App_ID || "-"}</div>
        ),
        minWidth: 50,
      },
      {
        Header: () => <HeaderTable style={{ paddingLeft: 5 }}>Maid ID</HeaderTable>,
        accessor: "Maid_ID",
        Cell: (row) => (
          <div style={{ textAlign: "left", paddingLeft: 5 }}>{row.original.Maid_ID || "-"}</div>
        ),
        minWidth: 50,
      },
      {
        Header: () => <HeaderTable>Maid Name</HeaderTable>,
        accessor: "name", // String-based value accessors!
        minWidth: 80,
      },
      {
        Header: () => <HeaderTable>Maid Phone</HeaderTable>,
        accessor: "phone",
        Cell: (row) => <div style={{ textAlign: "left" }}>{row.original.phone}</div>,
        minWidth: 65,
      },
      {
        Header: () => <HeaderTable>Maid Tier</HeaderTable>,
        accessor: "Maid_Tier",
        Cell: (row) => <div className="maidTierAver">{row.original.Maid_Tier}</div>,
        minWidth: 45,
      },

      {
        Header: () => <HeaderTable>Average Ratings</HeaderTable>,
        accessor: "Average_Ratings",
        Cell: (row) => (
          <div className="maidTierAver">
            {row.original.Average_Ratings !== "-" && row.original !== "noData"
              ? row.original.Average_Ratings === -1
                ? "-"
                : row.original.Average_Ratings.toFixed(2)
              : "-"}
          </div>
        ),
        minWidth: 75,
      },
      {
        Header: () => <HeaderTable># of ratings</HeaderTable>,
        accessor: "of_ratings",
        Cell: (row) => (
          <div className="maidAllTable">
            {row.original.of_ratings !== "-" && row.original !== "noData"
              ? row.original.of_ratings === -1
                ? "-"
                : row.original.of_ratings
              : "-"}
          </div>
        ),
        minWidth: 50,
      },
      {
        Header: () => <HeaderTable>Repeat Rate</HeaderTable>,
        accessor: "Repeat_Rate",
        Cell: (row) => (
          <div className="maidAllTable">
            {row.original.Repeat_Rate !== "-" && row.original !== "noData"
              ? row.original.Repeat_Rate === -1
                ? "-"
                : row.original.Repeat_Rate.toFixed(2)
              : "-"}
          </div>
        ),
        minWidth: 60,
      },
      {
        Header: () => <HeaderTable>Total OTA</HeaderTable>,
        accessor: "Total_OTA",
        Cell: (row) => (
          <div className="maidAllTable">
            {row.original.Total_OTA !== "-" && row.original !== "noData"
              ? row.original.Total_OTA === -1
                ? "-"
                : row.original.Total_OTA
              : "-"}
          </div>
        ),
        minWidth: 50,
      },
      {
        Header: () => <HeaderTable>Net OTA</HeaderTable>,
        accessor: "Net_OTA",
        Cell: (row) => (
          <div className="maidAllTable">
            {row.original.Net_OTA !== "-" && row.original !== "noData"
              ? row.original.Net_OTA === -999999
                ? "-"
                : row.original.Net_OTA || 0
              : "-"}
          </div>
        ),
        minWidth: 50,
      },
      {
        Header: () => <HeaderTable>% OTA</HeaderTable>,
        accessor: "Percent_OTA",
        Cell: (row) => (
          <div className="maidAllTable">
            {row.original.Percent_OTA !== "-" && row.original !== "noData"
              ? row.original.Percent_OTA === -1
                ? "-"
                : row.original.Percent_OTA.toFixed(2)
              : "-"}
          </div>
        ),
        minWidth: 50,
      },
      {
        Header: () => <HeaderTable>Stop OTA</HeaderTable>,
        accessor: "Stop_OTA",
        Cell: (row) => (
          <div className="paymentStatus">
            <label>
              <Toggle
                checked={row.original.Stop_OTA}
                onChange={() => this.handleStopOTA(row.original)}
                icons={{
                  checked: (
                    <div
                      style={{
                        display: "flex",
                        //  justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 12,
                        color: "#FFFFFF",
                        paddingRight: 2,
                      }}>
                      OTA Stop
                    </div>
                  ),
                  unchecked: (
                    <div
                      style={{
                        display: "flex",
                        float: "right",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 12,
                        color: "#324755",
                        paddingRight: 2,
                      }}>
                      Off
                    </div>
                  ),
                }}
              />
            </label>
          </div>
        ),
        minWidth: 100,
      },
      {
        Header: () => <HeaderTable>Start Date</HeaderTable>,
        accessor: "Start_Date",
        Cell: (row) => <div className="maidSdateTable">{row.original.Start_Date}</div>,
        minWidth: 70,
      },
      {
        Header: () => <HeaderTable>District of Pin</HeaderTable>,
        accessor: "District_of_Pin",
        minWidth: 60,
        Cell: (row) => <div className="maidAllTable">{row.original.District_of_Pin}</div>,
      },
      {
        Header: () => <HeaderTable>Radius</HeaderTable>,
        accessor: "Radius",
        minWidth: 60,
        Cell: (row) => (
          <div className="maidAllTable">
            {row.original.Radius === 9999 ? "Unlimited" : row.original.Radius}
          </div>
        ),
      },
      {
        Header: () => <HeaderTable>Lock radius</HeaderTable>,
        accessor: "Lock_Radius",
        width: 120,
        Cell: (row) => {
          return (
            <div style={{ cursor: "pointer" }} onClick={() => this.toggleLockRadius(row.original)}>
              <p
                style={{
                  textAlign: "center",
                  margin: 0,
                  borderRadius: "10px",
                  ...(row.original.Lock_Radius === "Lock"
                    ? {
                        backgroundColor: "#6e8ca0",
                        color: "white",
                      }
                    : {
                        backgroundColor: "#ccd1d3",
                        color: "#324755",
                      }),
                }}>
                {row.value}
              </p>
            </div>
          )
        },
      },
      {
        Header: () => <HeaderTable>Interviewer</HeaderTable>,
        accessor: "Interviewer",
        minWidth: 50,
        Cell: (row) => <div className="maidAllTable">{row.original.Interviewer}</div>,
      },
      {
        Header: () => <HeaderTable>Trainer</HeaderTable>,
        accessor: "Trainer",
        minWidth: 50,
        Cell: (row) => <div className="maidTrainerTable">{row.original.Trainer}</div>,
      },
    ]

    let columnWillShow = []
    let isRadiusList = window.location.href.includes("/radius/list")
    let arrRadiusList = ["Radius", "District_of_Pin", "Lock_Radius"]
    let arrBoth = ["App_ID", "Maid_ID", "name"]
    this.state.column.map((colName) => {
      for (var obj of allColumns) {
        if (
          (isRadiusList && arrRadiusList.includes(obj.accessor)) ||
          arrBoth.includes(obj.accessor)
        ) {
          obj.isShow =
            colName.isShow && obj.accessor === colName.accessor && columnWillShow.push(obj)
        } else if (
          (!isRadiusList && !arrRadiusList.includes(obj.accessor)) ||
          arrBoth.includes(obj.accessor)
        )
          obj.isShow =
            colName.isShow && obj.accessor === colName.accessor && columnWillShow.push(obj)
      }
      return {}
    })
    const tabData = [
      {
        label: window.location.href.includes("/radius/list") ? "Radius List" : "Maid List",
        total:
          this.state.tempDataTable.length > 0
            ? this.state.tempDataTable.length
            : this.state.totaldata,
        key: "0",
        path: "/maid",
      },
    ]
    const currentLocation = this.props.history.location.pathname

    return (
      <div>
        {this.state.showRightbar && <Overlay onClick={() => this.handleToggle("showRightbar")} />}
        <div className="col-md=12">
          <div className="row">
            <div className="col-md-3 btn-container" style={{ paddingTop: "5px" }}>
              <Button
                style={{ float: "right", fontSize: "11px", width: "125px", height: "40px" }}
                className="btn"
                label="Column setting"
                onClick={() => this.handleColumn()}
              />
              <Button
                style={{ float: "right", fontSize: "11px", width: "125px", height: "40px" }}
                className="btn"
                label="Filter by"
                onClick={() => this.handleFilter()}
              />
              <Button
                style={{ float: "right", fontSize: "11px", width: "125px", height: "40px" }}
                className="btn"
                label="Export"
                onClick={() => this.onExportData()}
              />
            </div>
            <div className="col-md-3 btn-container">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  id="searchText"
                  type="text"
                  className="inPut-search-textMaild"
                  placeholder="Search.."
                  name="textSearch"
                  value={this.state.textSearch}
                  onChange={(e) => this.setState({ textSearch: e.target.value })}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      this.onSentTextSearch()
                    }
                  }}
                />
                <button
                  id="searchTextmaid"
                  type="submit"
                  className="search-containe-button"
                  onClick={this.onSentTextSearch}>
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <TabComponent transparent style={{}} tabData={tabData} onChangeTab={this.onChangeTab} />
        <Card>
          <ReactTable
            data={this.state.tempDataTable.length > 0 ? this.state.tempDataTable : this.state.data} //data object
            columns={columnWillShow} //column config object
            loading={this.state.loading}
            pageSize={25}
            page={this.state.page}
            showPagination={true}
            PaginationComponent={Pagination}
            pages={this.state.pages}
            manual
            onSortedChange={(newSorted, column, shiftKey) =>
              this.togleSorting(newSorted, column, shiftKey)
            }
            onPageChange={(page) =>
              this.setState({ page: page }, () => {
                this.onFeactData({ page: page + 1 })
              })
            }
            getTrGroupProps={(state, rowInfo) => {
              if (rowInfo !== undefined) {
                return {
                  onDoubleClick: () => {
                    this.openProfileMaid(rowInfo.original)
                  },
                }
              }
            }}
            getTrProps={(state, rowInfo) => {
              return {
                style: {
                  height: 25,
                  overflow: "hidden",
                },
              }
            }}
            className="-striped -highlight maidListetable"
          />
        </Card>
        <Rightbar
          data={this.state}
          currentLocation={currentLocation}
          dataFilter={this.state.data}
          data_filter={(data) => this._on_data_filter(data)}
        />
        {this.props.children}
      </div>
    )
  }
}

const mapState = (state) => ({
  maidColumn: state.maid.column,
  totalPageList: state.maid.totalPageList,
  averageListFilter: state.maid.averageListFilter,
  repeatRateListFilter: state.maid.repeatRateListFilter,
  publishedListFilter: state.maid.publishedListFilter,
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    showBackIcon: dispatch.Title.showBackIcon,
    getMaidList: dispatch.maid.getMaidList,
  }
}

export default connect(mapState, mapDispatch)(MemberMaidPage)
