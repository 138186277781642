import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

const Container = styled.div`
  padding: 20px;
`

const Footer = styled.div`
  position: absolute;
  left: 10px;
  bottom: 50px;
`
const FooterPicContainer = styled.div`
  display: flex;
  padding: 0 0 0 30px;
  img {
    margin-right: 10px;
  }
`

class SettingProfilePage extends Component {
  componentDidMount() {
    this.props.setPageTitle('Profile Setting')
  }

  render() {
    return (
      <Container>
        <div className="container-fluid">
          {/* Row */}
          <div className="row">
            {/* Column */}
            <div className="col-lg-4 col-xl-3 col-md-4">
              <div className="card h-vh-80">
                <div className="card-body">
                  <center className="m-t-30">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/users/5.jpg`}
                      alt="profile"
                      className="rounded-circle"
                      width={150}
                    />
                    <h4 className="card-title m-t-10">Hanna Gover</h4>
                  </center>
                  <small className="text-muted">Role </small>
                  <h6>Administrator</h6> 
                  <small className="text-muted">Email address </small>
                  <h6>hannagover@gmail.com</h6> 
                  <small className="text-muted p-t-30 db">Phone</small>
                  <h6>+91 654 784 547</h6> 
                  <small className="text-muted p-t-30 db">Address</small>
                  <h6>71 Pilgrim Avenue Chevy Chase, MD 20815</h6>
                  <Footer>
                    <h6>Social Profile: </h6>
                    <FooterPicContainer>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/users/6.jpg`}
                        alt="profile2"
                        className="rounded-circle"
                        width={35}
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/users/7.jpg`}
                        alt="profile3"
                        className="rounded-circle"
                        width={35}
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/users/8.jpg`}
                        alt="profile4"
                        className="rounded-circle"
                        width={35}
                      />
                    </FooterPicContainer>
                  </Footer>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-xl-3 col-md-4">
              <div className="card h-vh-80"></div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

const mapState = () => ({})

const mapDispatch = (dispatch) => {
  return { setPageTitle: dispatch.Title.setPageTitle }
}

export default connect(
  mapState,
  mapDispatch
)(SettingProfilePage)
