import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import RightbarMaid from './rightbarMaid'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const HeaderText = styled.h3`
  padding: 10px 15px;
  color: white;
  font-weight: 200;
`

// const datas = [
//   'column1',
//   'column2',
//   'column3',
//   'column4',
//   'column5',
//   'column6',
//   'column7',
//   'column8',
//   'column9',
//   'column10',
// ]

class RightbarMemberManagement extends Component {
  render() {
    // console.log(`this.props.data : ${JSON.stringify(this.props.data)}`)
    // console.log(`showRightbar : ${this.props.data.showRightbar}`)
    // console.log(`maidColumn: ${JSON.stringify(this.props.maidColumn)}`)
    // console.log(`CustomerColumn: ${JSON.stringify(this.props.customerColumn)}`)
    // console.log(`data : ${this.props.data.mode}`)
    return (
      <div>
        <div
          id="rightbar"
          className={this.props.data.showRightbar ? 'rightbar show-service-panel' : 'rightbar'}>
          <div className="rightbar-body">
            <Container>
              <HeaderText>
                {this.props.data.mode === 'column' ? 'Column Setting' : 'Filter By'}
              </HeaderText>
              <RightbarMaid mode={this.props.data.mode} data={this.props.dataFilter} data_filter={this.props.data_filter} />
            </Container>
          </div>
        </div>
      </div>
    )
  }
}
// container={() => (useContainer ? this._container : document.body)}

const mapState = (state) => ({
  maidColumn: state.maid.column
})

const mapDispatch = (dispatch) => {
  return {
    setMaidColumnList: dispatch.maid.setMaidColumnList,
  }
}

export default connect(
  mapState,
  mapDispatch
)(RightbarMemberManagement)

