import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import CheckBox from '../../components/checkbox'

const Container = styled.div`
  padding: 20px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`
const CheckedboxContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15vw, 1fr));
  grid-auto-rows: 20px;
  flex: 1;
  width: 85%;

  padding-left: 30px;
`

class SettingRolesPage extends Component {
  componentDidMount() {
    this.props.setPageTitle('Roles Setting')
  }

  render() {
    return (
      <Container>
        
        <div className="container-fluid">
          {/* Row */}
          <div className="row">
            {/* Column */}
            <div className="col-lg-12 col-xl-3 col-md-6">
              <div className="card">
                <div className="card-body">
                  <Section>
                    <h5>Member</h5>
                    <CheckedboxContent>
                      <CheckBox id="check001" label="add_member" />
                      <CheckBox id="check002" label="edit_member" />
                      <CheckBox id="check003" label="delete_member" />
                      <CheckBox id="check004" label="list_member" />
                    </CheckedboxContent>
                  </Section>
                  <Section>
                    <h5>Contents</h5>
                    <CheckedboxContent>
                      <CheckBox id="check011" label="create_content" />
                      <CheckBox id="check012" label="edit_content" />
                      <CheckBox id="check013" label="delete_content" />
                      <CheckBox id="check014" label="publish_content" />
                    </CheckedboxContent>
                  </Section>
                  <Section>
                    <h5>Polls & Forms</h5>
                    <CheckedboxContent>
                      <CheckBox id="check021" label="create_poll&form" />
                      <CheckBox id="check022" label="edit_poll&form" />
                      <CheckBox id="check023" label="delete_poll&form" />
                      <CheckBox id="check024" label="publish_poll&form" />
                    </CheckedboxContent>
                  </Section>
                  <Section>
                    <h5>Boardcasting</h5>
                    <CheckedboxContent>
                      <CheckBox id="check031" label="create_boardcasting" />
                      <CheckBox id="check032" label="edit_boardcasting" />
                      <CheckBox id="check033" label="delete_boardcasting" />
                      <CheckBox id="check034" label="publish_boardcasting" />
                    </CheckedboxContent>
                  </Section>
                  <Section>
                    <h5>Promotions</h5>
                    <CheckedboxContent>
                      <CheckBox id="check041" label="create_promotion" />
                      <CheckBox id="check042" label="edit_promotion" />
                      <CheckBox id="check043" label="delete_promotion" />
                    </CheckedboxContent>
                  </Section>
                  <Section>
                    <h5>Chat</h5>
                    <CheckedboxContent>
                      <CheckBox id="check051" label="access_chatbox" />
                    </CheckedboxContent>
                  </Section>
                  <Section>
                    <h5>Dashboard</h5>
                    <CheckedboxContent>
                      <CheckBox id="check061" label="set_show_dashboard" />
                      <CheckBox id="check062" label="set_authen_dashboard" />
                      <CheckBox id="check063" label="read_dashboard" />
                      <CheckBox id="check064" label="export_dashboard" />
                    </CheckedboxContent>
                  </Section>
                  <Section>
                    <h5>Admin </h5>
                    <CheckedboxContent>
                      <CheckBox id="check071" label="add_admin" />
                      <CheckBox id="check072" label="edit_admin" />
                      <CheckBox id="check073" label="delete_admin" />
                      <CheckBox id="check074" label="set_list_admin" />
                      <CheckBox id="check075" label="read_list_admin" />
                    </CheckedboxContent>
                  </Section>
                  <Section>
                    <h5>User </h5>
                    <CheckedboxContent>
                      <CheckBox id="check081" label="add_user" />
                      <CheckBox id="check082" label="edit_user" />
                      <CheckBox id="check083" label="delete_user" />
                      <CheckBox id="check084" label="set_list_user" />
                      <CheckBox id="check085" label="read_list_user" />
                    </CheckedboxContent>
                  </Section>
                  <Section>
                    <h5>Roles </h5>
                    <CheckedboxContent>
                      <CheckBox id="check091" label="add_new_roles" />
                      <CheckBox id="check092" label="edit_roles" />
                      <CheckBox id="check093" label="delete_roles" />
                    </CheckedboxContent>
                  </Section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

const mapState = () => ({})

const mapDispatch = (dispatch) => {
  return { setPageTitle: dispatch.Title.setPageTitle }
}

export default connect(
  mapState,
  mapDispatch
)(SettingRolesPage)
