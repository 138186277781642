import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import moment from "moment"
import ReactTable from "react-table"
import "react-table/react-table.css"
import "../../style/react-table-custom.css"
import request from "../../utils/request"
import Button from "../../components/button"
import TabComponent from "./../../components/tabComponent"
import Pagination from "../../components/paginationTable/pagination"
import { withCookies } from "react-cookie"
import Rightbar from "../../layouts/memberManagementLayout/rightbar"
import "./customerList.css"
import LoginModal from "../../components/loginModal"

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.active};
  background: #fff;
  height: max-content;
  width: 100%;
  position: relative;
  top: -1px;
  padding: 10px;
  align-items: flex-start;
  overflow: auto;
`
const Overlay = styled.div`
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
`
const HeaderTable = styled.div`
  text-align: left;
`
class MemberCustomerPage extends Component {
  state = {
    column: this.props.customerColumn,
    resAnnouncement: [],
    data: [],
    loading: false,
    totaldata: 0,
    pages: 0,
    page: 0,
    checktext: "",
    textSearch: "",
    chanel_url: "",
    isLoginModalShow: false,
    user_id: undefined,
    searchOption: "",
    columnWillShow: [],
    showData: true,
  }

  componentDidMount() {
    this.props.setPageTitle(window.location.href.includes("/2/customer2") ? "User List 2" : "User List")
    this.handleSetCookies(this.props.customers)
    this.getAnnouncement()
    this.setColumWillShow()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.column !== this.props.customerColumn) {
      // console.log('update')
      this.setState({ column: this.props.customerColumn })
      this.setColumWillShow()
      this.props.setPageTitle(window.location.href.includes("/2/customer2") ? "User List 2" : "User List")
    }
  }

  setColumWillShow = () => {
    const allColumns = [
      {
        Header: () => <h6>{""}</h6>,
        accessor: "picture", // String-based value accessors!
        minWidth: 30,
        Cell: (row) => (
          <div style={{ width: "100%" }}>
            <img
              src={`${row.original.image}`}
              alt="profile3"
              className="rounded-circle"
              width={25}
            />
          </div>
        ),
      },
      {
        // ANCHOR  เอาข้อมูลเข้าตาราง
        Header: () => <HeaderTable>Date/Time</HeaderTable>,
        accessor: "joinDate",
        minWidth: 145,
        Cell: (row) =>
        row.original.joinDate ? (
          <Button
            id="test"
            style={{
              border: "none",
              padding: "1px 0px ",
              width: "auto",
              fontSize: "14px",
              background: "none",
              color: "#3e5569",
              userSelect: "text",
            }}
            label={row.original.joinDate}
            className="copyClipBoard"
            onClick={(e) => {
              return navigator.clipboard.writeText(row.original.joinDate)
            }}></Button>
        ) : (
          <p>-</p>
        ),
      },
      {
        Header: () => <HeaderTable style={{ paddingLeft: 10 }}>User ID</HeaderTable>,
        accessor: "User_ID",
        minWidth: 70,
        Cell: (row) =>
        row.original.User_ID ? (
          <Button
            id="test"
            style={{
              border: "none",
              padding: "1px 0px ",
              width: "auto",
              fontSize: "14px",
              background: "none",
              color: "#3e5569",
              userSelect: "text",
              paddingLeft: 10 ,
            }}
            label={row.original.User_ID}
            className="copyClipBoard"
            onClick={(e) => {
              return navigator.clipboard.writeText(row.original.User_ID)
            }}></Button>
        ) : (
          <p>-</p>
        ),
      },
      {
        Header: () => <HeaderTable>User Name</HeaderTable>,
        accessor: "User_Name",
        minWidth: 160,
        Cell: (row) =>
        row.original.User_Name ? (
          <Button
            id="test"
            style={{
              border: "none",
              padding: "1px 0px ",
              width: "auto",
              fontSize: "14px",
              background: "none",
              color: "#3e5569",
              userSelect: "text",
            }}
            label={row.original.User_Name}
            className="copyClipBoard"
            onClick={(e) => {
              return navigator.clipboard.writeText(row.original.User_Name)
            }}></Button>
        ) : (
          <p>-</p>
        ),
      },
      {
        Header: () => <HeaderTable>User Login</HeaderTable>,
        accessor: "chat",
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: "left" }}>
            <button
              className="btn"
              style={{
                borderRadius: "11px",
                backgroundColor: "#6e8ca0",
                height: "20px",
                marginTop: "-5px",
              }}
              onClick={() => this.openLoginModal(row.original.id)}>
              <h5 style={{ marginTop: "-5px", color: "white", fontSize: "12px" }}>Login</h5>{" "}
            </button>
          </div>
        ),
      },
      {
        Header: () => <HeaderTable>Chat</HeaderTable>,
        accessor: "chat",
        minWidth: 70,
        Cell: (row) => (
          <div style={{ textAlign: "left" }}>
            <button
              className="btn"
              style={{
                borderRadius: "11px",
                backgroundColor: "#6e8ca0",
                height: "20px",
                marginTop: "-5px",
              }}
              onClick={() => this.sendForChat(row.original.User_ID, row.original.chanel_url)}>
              <h5 style={{ marginTop: "-5px", color: "white", fontSize: "12px" }}>Chat</h5>{" "}
            </button>
          </div>
        ),
      },
      {
        Header: () => <HeaderTable>User Phone</HeaderTable>,
        accessor: "User_Phone",
        minWidth: 120,
        Cell: (row) =>
        row.original.User_Phone ? (
          <Button
            id="test"
            style={{
              border: "none",
              padding: "1px 0px ",
              width: "auto",
              fontSize: "14px",
              background: "none",
              color: "#3e5569",
              userSelect: "text",
            }}
            label={row.original.User_Phone}
            className="copyClipBoard"
            onClick={(e) => {
              return navigator.clipboard.writeText(row.original.User_Phone)
            }}></Button>
        ) : (
          <p>-</p>
        ),
      },
      {
        Header: () => <HeaderTable>FB email</HeaderTable>,
        accessor: "FB_email",
        minWidth: 240,
        Cell: (row) =>
          row.original.FB_email ? (
            <Button
              id="test"
              style={{
                border: "none",
                padding: "1px 0px ",
                width: "auto",
                fontSize: "14px",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.FB_email}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.FB_email)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <HeaderTable>Secondary email</HeaderTable>,
        accessor: "Secondary_email",
        minWidth: 240,
        Cell: (row) =>
          row.original.Secondary_email ? (
            <Button
              id="test"
              style={{
                border: "none",
                padding: "1px 0px ",
                width: "auto",
                fontSize: "14px",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.Secondary_email}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.Secondary_email)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <HeaderTable>Login Type</HeaderTable>,
        accessor: "Login_Type",
        minWidth: 90,
      },
      {
        Header: () => <HeaderTable>Notification before 24 hr</HeaderTable>,
        accessor: "Notification_before_24hr",
        minWidth: 170,
        Cell: (row) => (
          <div style={{ textAlign: "left" }}>{row.original.Notification_before_24hr}</div>
        ),
      },
      {
        Header: () => <HeaderTable>Notification before last credit</HeaderTable>,
        accessor: "Noti_before_lastCredit",
        minWidth: 200,
        Cell: (row) => (
          <div style={{ textAlign: "left" }}>{row.original.Noti_before_lastCredit}</div>
        ),
      },
      {
        Header: () => <HeaderTable>Privacy setting</HeaderTable>,
        accessor: "Privacy_setting",
        minWidth: 130,
        Cell: (row) => <div style={{ textAlign: "left" }}>{row.original.Privacy_setting}</div>,
      },
      {
        Header: () => <HeaderTable>Default Location 1</HeaderTable>,
        accessor: "Default_Location_1",
        minWidth: 125,
        Cell: (row) =>
        row.original.Default_Location_1 ? (
          <Button
            id="test"
            style={{
              border: "none",
              padding: "1px 0px ",
              width: "auto",
              fontSize: "14px",
              background: "none",
              color: "#3e5569",
              userSelect: "text",
            }}
            label={row.original.Default_Location_1}
            className="copyClipBoard"
            onClick={(e) => {
              return navigator.clipboard.writeText(row.original.Default_Location_1)
            }}></Button>
        ) : (
          <p>-</p>
        ),
      },
      {
        Header: () => <HeaderTable>Default Location 2</HeaderTable>,
        accessor: "Default_Location_2",
        minWidth: 125,
        Cell: (row) =>
        row.original.Default_Location_2 ? (
          <Button
            id="test"
            style={{
              border: "none",
              padding: "1px 0px ",
              width: "auto",
              fontSize: "14px",
              background: "none",
              color: "#3e5569",
              userSelect: "text",
            }}
            label={row.original.Default_Location_2}
            className="copyClipBoard"
            onClick={(e) => {
              return navigator.clipboard.writeText(row.original.Default_Location_2)
            }}></Button>
        ) : (
          <p>-</p>
        ),
      },
      {
        Header: () => <HeaderTable>Default Location 3</HeaderTable>,
        accessor: "Default_Location_3",
        minWidth: 125,
        Cell: (row) =>
        row.original.Default_Location_3 ? (
          <Button
            id="test"
            style={{
              border: "none",
              padding: "1px 0px ",
              width: "auto",
              fontSize: "14px",
              background: "none",
              color: "#3e5569",
              userSelect: "text",
            }}
            label={row.original.Default_Location_3}
            className="copyClipBoard"
            onClick={(e) => {
              return navigator.clipboard.writeText(row.original.Default_Location_3)
            }}></Button>
        ) : (
          <p>-</p>
        ),
      },
    ]

    const cWShow = allColumns.filter(obj =>
      this.state.column.some(colName => colName.isShow && obj.accessor === colName.accessor)
    );

    this.setState({ columnWillShow: cWShow })
  }

  async getAnnouncement(page, textSearch = "", checktext = true) {
    this.setState({ loading: true })
    try {
      let pageNo = page ? page.page : 1
      let limit = 5000
      let txtSearch = checktext ? this.state.checktext : textSearch
      let verified = window.location.href.includes("/2/customer2") ? "&verified=true" : ""
      // await this.props.getCustomerList()
      request.get(`customer/list?search=${txtSearch.trim()}&page=${pageNo}&limit=${limit}&by=${this.state.searchOption}${verified}`).then((resp) => {
        let data = resp.data
        let template = []
        if (data.customers.length > 0) {
          for (let i = 0; i < data.customers.length; i++) {
            // ANCHOR push data เข้าข้อมูลตาราง
            template.push({
              id: data.customers[i].id,
              User_ID: data.customers[i].customer_id,
              image:
                data.customers[i].image_url === null
                  ? "https://via.placeholder.com/300x300.png"
                  : data.customers[i].image_url,
              User_Name: data.customers[i].first_name + " " + data.customers[i].last_name,
              Secondary_email: data.customers[i].email === null ? "-" : data.customers[i].email,
              Login_Type: data.customers[i].provider
                ? data.customers[i].provider.charAt(0) +
                  data.customers[i].provider.slice(1).toLowerCase()
                : "-",
              User_Phone: data.customers[i].phone === null ? "-" : data.customers[i].phone,
              FB_email: data.customers[i].fb_email === null ? "-" : data.customers[i].fb_email,
              Default_Location_1:
                data.customers[i].user_locations.length > 0
                  ? data.customers[i].user_locations.length > 0
                    ? data.customers[i].user_locations[0].main_text +
                      " " +
                      data.customers[i].user_locations[0].secondary_text
                    : "-"
                  : "-",
              Default_Location_2:
                data.customers[i].user_locations.length > 0
                  ? data.customers[i].user_locations.length > 1
                    ? data.customers[i].user_locations[0].main_text +
                      " " +
                      data.customers[i].user_locations[1].secondary_text
                    : "-"
                  : "-",
              Default_Location_3:
                data.customers[i].user_locations.length > 0
                  ? data.customers[i].user_locations.length > 2
                    ? data.customers[i].user_locations[0].main_text +
                      " " +
                      data.customers[i].user_locations[2].secondary_text
                    : "-"
                  : "-",
              role: data.customers[i].role ? data.customers[i].role : "-",
              Notification_before_24hr: data.customers[i].send_email_before_booking
                ? "True"
                : "False",
              Noti_before_lastCredit: data.customers[i].send_email_when_remaining_credits
                ? "True"
                : "False",
              Privacy_setting: data.customers[i].dont_mind_let_fb_friends ? "True" : "False",
              joinDate: moment(data.customers[i].create_datetime).format("YYYY/MM/DD HH:mm"),
              chanel_url: data.customers[i].admin_channel_url,
            })
          }
          // const sortedTemplate = template.sort(
          //   (a, b) => moment(b.joinDate).toDate() - moment(a.joinDate).toDate()
          // )

          // console.log(sortedTemplate)
          this.setState({
            data: template,
            loading: false,
            totaldata: data.item_count,
            pages: Math.ceil(data.item_count / limit),
          })
        } else {
          this.setState({
            data: [],
            loading: false,
            totaldata: 0,
            pages: 0,
          })
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  handleSetCookies(list) {
    const { cookies } = this.props
    let result = []
    list.map((obj) => result.push(obj.image))
    cookies.set("picture", result, {
      path: "/",
      sameSite: false,
      secure: true,
    })
  }

  handleToggle = (key) => {
    this.setState((prevState) => ({ [key]: !prevState[key] }))
    const { history } = this.props
    history.push(this.props.history.location.pathname)
  }

  handleColumn = () => {
    this.setState({ showRightbar: true, mode: "column" })
  }
  handleFilter = () => {
    this.setState({ showRightbar: true, mode: "filter" })
  }

  onChangeTab = (path) => {
    const { history, match } = this.props
    history.push(match.url + path)
  }

  compareBy(key, order) {
    return function (a, b) {
      // a[key] = a[key] === null || a[key] === undefined ? -Infinity : a[key];
      // b[key] = b[key] === null || b[key] === undefined ? -Infinity : b[key];

      // a[key] = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
      // b[key] = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];

      if (order) {
        if (a[key] < b[key]) {
          return 1
        }
        if (a[key] > b[key]) {
          return -1
        }
        return 0
      } else {
        if (a[key] > b[key]) {
          return 1
        }
        if (a[key] < b[key]) {
          return -1
        }
        return 0
      }
    }
  }

  togleSorting(newSorted, column, shiftKey) {
    console.log("shit")
    let arrayCopy = [...this.state.data]
    arrayCopy.sort(this.compareBy(column.id, newSorted[0].desc))
    this.setState({ data: arrayCopy })
  }
  onSentTextSearch = (textSearch = "") => {
      this.getAnnouncement({ page: 1 }, textSearch, false);
      this.setState({ checktext: textSearch, page: 0});
  }
  sendForChat = (id, chanel_url) => {
    console.log("id", id, "chanel_url", chanel_url)
    this.props.history.push(`/admin/chatbox/${id}/CUSTOMER/${chanel_url}`)
    this.props.showBackIcon(false)
    this.props.setPageTitle("Chat Box")
  }
  openLoginModal = (user_id) => {
    console.log("user_id", user_id)
    this.setState({ user_id: user_id })
    this.setState({ isLoginModalShow: true })
  }
  render() {
    const tabData = [
      {
        label: "Customer list",
        total: this.state.totaldata,
        key: "0",
        path: "/customer",
      },
    ]
    const currentLocation = this.props.history.location.pathname

    return (
      <div>
        {this.state.showRightbar && <Overlay onClick={() => this.handleToggle("showRightbar")} />}
        <div className="col-md=12">
          <div className="row">
            <div className="col-md-2 btn-container" style={{ paddingTop: "5px" }}>
              <Button className="btn" label="Column setting" onClick={() => this.handleColumn()} />
              {false && (
                <Button className="btn" label="Filter by" onClick={() => this.handleFilter()} />
              )}
            </div>
            <div className="col-md-3 btn-container">
              <div style={{ display: "flex", flexDirection: "row" }}>
              <select
                  className="inPut-search-textAppove-option"
                  id="searchOption"
                  value={this.state.searchOption}
                  onChange={(e) => this.setState({ searchOption: e.target.value }, () => {
                    let text = document.getElementById("searchText").value
                    if (text !== "") {
                      this.onSentTextSearch(text)
                    }
                  })}
                  >
                  <option value="">All</option>
                  <option value="id">ID</option>
                  <option value="first_name">First Name</option>
                  <option value="last_name">Last Name</option>
                  <option value="phone">Phone</option>
                  <option value="email">Email</option>
                </select>    
                <input
                  id="searchText"
                  type="text"
                  className="inPut-search-textMaild"
                  placeholder="Search.."
                  name="textSearch"
                  // value={this.state.textSearch}
                  // onChange={(e) => this.setState({ textSearch: e.target.value })}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      this.onSentTextSearch(event.target.value )
                    }
                  }}
                />
                <button
                  id="searchTextmaid"
                  type="submit"
                  className="search-containe-button"
                  onClick={
                    () => {
                      this.onSentTextSearch(document.getElementById("searchText").value )
                    }
                  }>
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "none" }}>
          <input
            type="checkbox"
            id="showData"
            name="showData"
            value={!this.state.showData}
            onChange={(e) => this.setState({ showData: !e.target.checked })}
          />
          &nbsp;
          <label htmlFor="showData">Hide data</label>
        </div>
        <TabComponent transparent style={{}} tabData={tabData} onChangeTab={this.onChangeTab} />
        <Card>
          <ReactTable
            data={this.state.showData ? this.state.data : []}
            columns={this.state.columnWillShow} //column config object
            loading={this.state.loading}
            pageSize={25}
            page={this.state.page}
            showPagination={true}
            PaginationComponent={Pagination}
            pages={this.state.pages}
            manual
            onSortedChange={(newSorted, column, shiftKey) =>
              this.togleSorting(newSorted, column, shiftKey)
            }
            onPageChange={(page) =>
              this.setState({ page: page }, () => {
                this.getAnnouncement({ page: page + 1 })
              })
            }
            getTrProps={(state, rowInfo) => {
              return {
                style: {
                  height: 25,
                  overflow: "hidden",
                },
              }
            }}
            className="-striped -highlight customerLsittetable"
          />
        </Card>
        <Rightbar data={this.state} currentLocation={currentLocation} />
        {this.props.children}
        <LoginModal
          user_id={this.state.user_id}
          isOpen={this.state.isLoginModalShow}
          closeModal={(data) => {
            this.setState({ isLoginModalShow: data }, () => console.log(data))
          }}
        />
      </div>
    )
  }
}

const mapState = (state) => ({
  customers: state.customer.customers,
  customerColumn: state.customer.column,
  totalPage: state.customer.totalPage,
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    getCustomerList: dispatch.customer.getCustomerList,
    showBackIcon: dispatch.Title.showBackIcon,
  }
}

export default connect(mapState, mapDispatch)(withCookies(MemberCustomerPage))
