import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ErrorBoundary from '../../components/errorBoundary'
import CustomModal from '../../components/customModal'
import Button from '../../components/button'
import CheckBox from '../../components/checkbox'

// import img from '../../../images/popup-submit-288-px.svg'
// import CancelIcon from '../../images/ic-cancelbtn-gray-24-px.svg'
// import { Highlight } from '../../components/styledComponents'
import {
  customModalStyle,
  // mobileModalStyle
} from './customActualModal'
// import { Desktop, Mobile } from '../../utils/displayResponsive'

// const imgDesktop = {
//   width: '530px',
//   height: '230px',
//   objectFit: 'cover',
// }

const Container = styled.div`
  padding: 20px;
`
const Section = styled.div`
  height: 450px;
  overflow: auto;
`

const Header = styled.div`
  text-align: justify;
  font-size: 30px;
  font-weight: bold;

  // margin-bottom: 40px;
`

const SectionText = styled.h4`
  padding: 10px 20px 0px;
  // width: 100%;
`
// const Text = styled.div`
//   font-size: 14px;
// `

const Row = styled.div`
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  // width: 100%;
  flex: 1;
`

const graphContent = [
  {
    section: 'business',
    content: [
      {
        name: 'Sales and Maid’s wages, per locations',
        keyName: 'graph1',
      },
      {
        name: 'Number of services and avg hours per service',
        keyName: 'graph2',
      },
      {
        name: 'Number one-time and multi-package bookings',
        keyName: 'graph3',
      },
    ],
  },
  {
    section: 'userEngagement',
    content: [
      {
        name: 'Number of new login users and Number of new service users',
        keyName: 'graph4',
      },
      {
        name: 'Retention rate by user life cycle by booking appointment data, NOT payment',
        keyName: 'graph5',
      },
    ],
  },
]

// <Text>{text}</Text> <Text>[ ]</Text>

// const ExportOptions = (index, label, onChange, checked) => {
//   return (
//     <Row>
//       <CheckBox  id={index} label={label} onChange={onChange} checked={checked} />
//     </Row>
//   )
// }

class SettingModal extends Component {
  static propTypes = {}

  state = {}

  componentDidMount() {
    console.log(this.props)
    let initialState = {}
    // Set Initial State for toggle from array
    graphContent.map((obj) => {
      if (obj.content) {
        obj.content.map((objkey) => {
          initialState[objkey.keyName] = false
          return null
        })
      }
      return null
    })
    this.setState(initialState)
  }

  closeModal = () => {
    console.log('closeModal')
    this.props.closeModal(false)
  }

  onCallExport = () => {
    console.log('onCallExport')
    this.props.closeModal(false)
  }

  onCallBack = () => {
    console.log('onCallBack')
    this.props.onCallBack()
  }

  onSubmit = () => {
    console.log('onSubmit')
  }

  onToggle = (key, index) => {
    console.log(`index: ${JSON.stringify(index)}`)
    this.setState((prevState) => ({ [key]: !prevState[key] }))
  }

  setExportOptions = (index, label, key, keyState) => {
    return (
      <Row key={key}>
        <CheckBox
          id={index}
          label={label}
          onChange={() => this.onToggle(key, index)}
          checked={keyState}
        />
      </Row>
    )
  }

  // console.log(`${index},${objkey.name}, ${objkey.keyName}, ${this.state[objkey.keyName]}`)
  createOptions = (inputArray, section) => {
    let items = []
    inputArray.map((obj) => {
      if (obj.section === section) {
        obj.content.map((objkey) =>
          items.push(
            this.setExportOptions(
              objkey.keyName,
              objkey.name,
              objkey.keyName,
              this.state[objkey.keyName]
            )
          )
        )
      }
      return null
    })
    return items
  }

  render() {
    // const img = 'https://res.cloudinary.com/teepublic/image/private/s--F0FUtuXp--/t_Preview/b_rgb:191919,c_limit,f_jpg,h_630,q_90,w_630/v1527190543/production/designs/2720834_0.jpg'
    // const { img, name, school, point, description } = this.props.data
    // console.log(`exportModal state: ${JSON.stringify(this.state)}`)
    return (
      <ErrorBoundary>
        <CustomModal isOpen={this.props.isOpen} customStyle={customModalStyle}>
          <Container>
            <Header>Export</Header>
            <Section>
              <SectionText>Bussiness Section</SectionText>
              {this.createOptions(graphContent, 'business')}
              <SectionText>User Engagement Section</SectionText>
              {this.createOptions(graphContent, 'userEngagement')}
            </Section>
            <Button
              style={{ position: 'absolute', bottom: '20px', right: '180px' }}
              onClick={() => this.props.closeModal()}
              label="Cancel"
              light
            />
            <Button
              style={{ position: 'absolute', bottom: '20px', right: '20px' }}
              onClick={() => this.onCallExport()}
              type="submit"
              label="Export"
            />
          </Container>
        </CustomModal>
      </ErrorBoundary>
    )
  }
}

SettingModal.propstype = {
  closeModal: PropTypes.func.isRequired,
  onCallBack: PropTypes.func.isRequired,
}

export default SettingModal
