import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
// import Select from 'react-select'
import Divider from '../divider'
// import Button from '../buttonLight'

import ReactTable from 'react-table'
import 'react-table/react-table.css'
import '../../style/react-table-custom.css'

// import { yearOptions, weekOptions } from '../graphCard/options'

// import { cohortExample } from './example'

// const SelectContainer =styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-end;
// `

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: #fff;
  flex: 1;
  margin-bottom: 20px;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px;
  flex: 1;
`
const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const GraphContainer = styled.div`
  display: block;
  padding: 0px 10px;
  // width: max-content;
  // flex: 1;

  .highcharts-container {
    position: relative;
    overflow: auto;
    width: 100%;
    height: 100%;
  }
`

// React-Select Style Section 
// const customStylesYear = {
//   container: (styles) => ({
//     ...styles,
//     width: 250,
//   }),
// }

// const customStylesWeek = {
//   container: (styles) => ({
//     ...styles,
//     width: 250,
//     marginTop: "5px",
//   }),
// }

class GraphCohortCard extends Component {
  state = {
    year: null,
    week: null,
    data:this.props.dataRetentionList
    // selectedOption: null,
  }

  componentDidMount(){
    this.props.FaceserRetention()
  }

  handleChange = (selectedOption, key) => {
    this.setState({ [key] : selectedOption })
  }

  render() {
    const {
      title,
      // data,
      // type , width ,height, gWidth, gHeight
    } = this.props

    const allColumns = [
      {
        Header: () => <h6>{''}</h6>,
        accessor: 'date',
        minWidth: 50,
      },
      {
        Header: () => <h6>1</h6>,
        accessor: 'col1',
        minWidth: 50,
      },
      {
        Header: () => <h6>2</h6>,
        accessor: 'col2',
        minWidth: 50,
      },
      {
        Header: () => <h6>3</h6>,
        accessor: 'col3',
        minWidth: 50,
      },
      {
        Header: () => <h6>4</h6>,
        accessor: 'col4',
        minWidth: 50,
      },
      {
        Header: () => <h6>5</h6>,
        accessor: 'col5',
        minWidth: 50,
      },
      {
        Header: () => <h6>6</h6>,
        accessor: 'col6',
        minWidth: 50,
      },
      {
        Header: () => <h6>7</h6>,
        accessor: 'col7',
        minWidth: 50,
      },
      {
        Header: () => <h6>8</h6>,
        accessor: 'col8',
        minWidth: 50,
      },
      {
        Header: () => <h6>9</h6>,
        accessor: 'col9',
        minWidth: 50,
      },
      {
        Header: () => <h6>10</h6>,
        accessor: 'col10',
        minWidth: 50,
      },
      {
        Header: () => <h6>11</h6>,
        accessor: 'col11',
        minWidth: 50,
      },
      {
        Header: () => <h6>12</h6>,
        accessor: 'col12',
        minWidth: 50,
      },
    ]
    return (
      <Container>
        <HeaderContainer>
          <HeaderContent>
            <h5>{title}</h5>
          </HeaderContent>
          <Divider />
        </HeaderContainer>
        <GraphContainer>
          <ReactTable
            data={this.props.dataRetentionList}
            columns={allColumns}
            defaultPageSize={14}
            showPagination={false}
            className=""
          />
        </GraphContainer>
      </Container>
    )
  }
}

const mapState = (state) => {
  return {
    dataRetentionList:state.dashoard.dataRetentionList,
  }
}

const mapDispatch = (dispatch) => {
return { 
  FaceserRetention: dispatch.dashoard.FaceserRetention

  }
}

export default connect(
mapState,
mapDispatch
)(GraphCohortCard)
