import React, { Component } from 'react'
import styled from 'styled-components'
import ErrorBoundary from '../errorBoundary'
import {post} from '../../utils/service'
import { connect } from 'react-redux'

const Container = styled.div`
  width: 150px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;

  .link {
    cursor: pointer;
  }
`

const Item = styled.div`
  padding: 10px 0 10px 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  img {
    width: 20px;
    margin-right: 14px;
  }

  :hover {
    background-color: #f3f3f3;
  }
`
const RoleMaidClass = [
  'resend',
  'Remove'
]

class EditStatusBroadcasError extends Component {
    constructor(props){
        super(props);
        this.state={
            checkId : this.props.TypeStatus
        }
    }

 async handleClick(text) {
  const { props } = this.props
  // console.log(this.props)
    if(text === 'Remove'){
      if(this.state.checkId){ 
        await post({},`admin/broadcast/inappmessage/id/${this.state.checkId}/delete`).then(res=>{
            if(!res.error){
              props.history.replace({
                pathname: '/admin/broadcasting/list/broadcastError',
                state:'error'
              })
              this.props.checkModeModal({Type:'error'})
            }
          })
      }
    }else if(text === 'resend'){
      if(this.state.checkId){
        await post({},`admin/broadcast/inappmessage/id/${this.state.checkId}/resend`).then(res=>{
            if(!res.error){
            props.history.replace({pathname: '/admin/broadcasting/list/broadcastSent'})
            }
          })
      }
    }
  }

  render() {
    const {TypeStatus } = this.props
    const FilterMaidClassMenu = RoleMaidClass.filter((Allrole) => Allrole !== TypeStatus)
    return (
      <ErrorBoundary>
        <Container>
          {TypeStatus &&
            FilterMaidClassMenu.map((menu, index) => (
              <Item key={index} onClick={() => this.handleClick(menu)}>
                {menu}
              </Item>
            ))}
        </Container>
      </ErrorBoundary>
    )
  }
}
const mapState = () => ({})

const mapDispatch = (dispatch) => {
  return {
    checkModeModal: dispatch.broadcasting.checkModeModal,
    checkTypeImage:dispatch.broadcasting.checkTypeImage,
    setTextModal:dispatch.broadcasting.setTextModal
  }
}
export default connect(mapState, mapDispatch)(EditStatusBroadcasError)
