import * as SendBird from 'sendbird'
import { findConfig } from './sbConstants'
import store from '../index'

const { APP_ID } = findConfig()

export const sbMessage = {
    state: {
        sbMessageList: [],
        checkNewMessage:false
    },
    reducers: {
        sbSetMessageList (state, payload) {
            return {
                ...state, sbMessageList: payload
            }
        },
        sbSetMessage (state, payload) {
            return { ...state, sbMessageList: [...state.sbMessageList, ...payload] }
        },
        checkNewMsg (state, payload) {
            return { ...state, checkNewMessage: payload }
        },
    },
    effects: (dispatch) => ({
      
        async sbGetMessageList (channelUrl) {
            let sb = SendBird.getInstance()
            if (!sb) sb = new SendBird({ appId: APP_ID })
            const channelEvent = new sb.ChannelHandler()
            addEventHandler(channelEvent, channelUrl)
            sb.addChannelHandler(channelUrl, channelEvent)

            try {
                const channel = await store.dispatch.sbChannel.sbGetGroupChannel(channelUrl)

                const prevMessageListQuery = channel.createPreviousMessageListQuery()
                prevMessageListQuery.limit = 200
                // prevMessageListQuery.limit = 30 * (!payload || !payload.limit ? 1 : payload.limit)
                prevMessageListQuery.load((messages, error) => {
                    if (error) {
                        return
                    }

                    dispatch.sbMessage.sbSetMessageList(messages)
                })
            } catch (e) {
                return e
            }
            // sb.GroupChannel.getChannel(channelUrl, (channel, error) => {
            //     if (error) {
            //         return
            //     }

            //     addEventHandler(channelEvent, channelUrl)

            //     const prevMessageListQuery = channel.createPreviousMessageListQuery()
            //     prevMessageListQuery.load((messages, error) => {
            //         if (error) {
            //             return
            //         }

            //         dispatch.sbMessage.sbSetMessageList(messages)
            //     })
            // })
            // sb.addChannelHandler(channelUrl, channelEvent)
        },
        async sbSendMessage (data) {
            let sb = SendBird.getInstance()
            if (!sb) sb = new SendBird({ appId: APP_ID })
           
            const params = new sb.UserMessageParams()
            params.message = data.message
            try {
                const channel = await store.dispatch.sbChannel.sbGetGroupChannel(data.channelUrl)
                channel.sendUserMessage(params, (message, error) => {
                    if (error) {
                        return
                    }

                    dispatch.sbMessage.sbSetMessage([message])
                })
            } catch (e) {
                return e
            }
            // sb.GroupChannel.getChannel(data.channelUrl, (channel, error) => {
            //     if (error) {
            //         return
            //     }

            //     channel.sendUserMessage(params, (message, error) => {
            //         if (error) {
            //             return
            //         }

            //         store.dispatch.sbMessage.sbSetMessage([message])
            //     })

            // })
        },
        async sbSendFile (file) {
            let sb = SendBird.getInstance()
            if (!sb) sb = new SendBird({ appId: APP_ID })
            const params = new sb.FileMessageParams()
            params.file = file
            params.fileName = file.name
            params.fileSize = file.size
            params.thumbnailSizes = [{maxWidth: 100, maxHeight: 100}, {maxWidth: 200, maxHeight: 200}]

            try {
                const channel = await store.dispatch.sbChannel.sbGetGroupChannel(file.channelUrl)
                channel.sendFileMessage(params, (fileMessage, error) => {
                    if (error) {
                        return
                    }
                    
                    dispatch.sbMessage.sbSetMessage([fileMessage])
                    // console.log(fileMessage)
                })
            } catch (e) {
                return e
            }
        },
        async sbMarkRead (channel) {
            let sb = SendBird.getInstance()
            if (!sb) sb = new SendBird({ appId: APP_ID })
            // const newChannel = JSON.parse(JSON.stringify(channel))
            // console.log(newChannel)
            // console.log(channel)
            // const arr = await JSON.parse(JSON.stringify(store.getState().sbChannel.sbChannelList))
            // let arrTemp = [];
            // var key = Object.keys(newChannel);
            // let arr1 = Object.values(newChannel);
            // for (let i = 0; i < arr1.length; i++) {
            //   arr[key[i]] = arr1[i];
            //   arrTemp.push({ key: key[i], value: arr1[i] });
            // }
            //     console.log(arrTemp)
            // const temp = await arr.filter(v => v.unreadMessageCount !== newChannel.unreadMessageCount && v.url === newChannel.url)
            // console.log(temp)
            // console.log(arr)
            // const temp = arr.filter(v => v.unreadMessageCount > 0)
            // console.log(temp)
            // dispatch.sbMessage.checkNewMsg(temp.length > 0)

            channel.markAsRead()
            let check = false
            for(var operator in store.getState().sbChannel.sbChannelList) {
                if (store.getState().sbChannel.sbChannelList[operator].unreadMessageCount > 0 && store.getState().sbChannel.sbChannelList[operator].url !== channel.url) {
                    check = true
                }
            }
            dispatch.sbMessage.checkNewMsg(check)
            
            var handler = new sb.ChannelHandler();
            handler.onReadReceiptUpdated = (channell) => {
                channel = channell
            }
            sb.addChannelHandler(channel, handler)

            // const chs = store.getState().sbChannel.sbChannelList
            // store.dispatch.sbChannel.sbSetChannelList([...new Set([...chs], channel)])
        }
    })
}

const addEventHandler = (channelEvent, channelUrl) => {
    channelEvent.onMessageReceived = (channel, message) => {
        // const chs = store.getState().sbChannel.sbChannelList
        const msgs = store.getState().sbMessage.sbMessageList
        // store.dispatch.sbChannel.sbSetChannelList([...new Set([...chs], channel)])
        const temp = store.getState().sbChannel.sbChannelList.filter(v => ![channel].includes(v))
        store.dispatch.sbChannel.sbSetChannelList([channel, ...temp])
 
        if (msgs && msgs[0].channelUrl === message.channelUrl && channelUrl === message.channelUrl) {
            store.dispatch.sbMessage.sbSetMessage([message])
        }
    }
    // channelEvent.onMessageUpdated = (channel, message) => {
    //     if (channelUrl === channel.url) {
    //         console.log('onMessageUpdated', channel, message)
    //         // TODO:
    //     }
    // }
    // channelEvent.onMessageDeleted = (channel, messageId) => {
    //     if (channelUrl === channel.url) {
    //         console.log('onMessageDeleted', channel, messageId)
    //         // TODO:
    //     }
    // },

}