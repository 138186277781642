import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import './orderManage.css'



class orderManagementLayout extends Component {

  constructor() {
    super();
    this.state = {
      showRightbar: false,
      isExportModalShow: false,
      start_datetime: '',
      end_datetime: '',
      textSearchBooking: ''
    }
  }

   componentDidMount() {
    this.props.showBackIcon(false)
    this.props.setPageTitle(window.location.href.includes("/2/Booking2") ? "Booking Management 2" : "Booking Management")

  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.showBackIcon(false)
      this.props.setPageTitle(window.location.href.includes("/2/Booking2") ? "Booking Management 2" : "Booking Management")
    }
  }

  handleColumn = () => {
    this._isMounted = true;
    if (this._isMounted) {
      this.setState({ showRightbar: true, mode: 'column' })
    }

  }
  handleFilter = () => {
    this._isMounted = true;
    if (this._isMounted) {
      this.setState({ showRightbar: true, mode: 'filter' })
    }
  }

  onChangeTab = (path) => {
    const { history, match } = this.props
    const params = {
      start_datetime: this.state.start_datetime,
      end_datetime: this.state.end_datetime
    }
    history.push(match.url + path, params)
  }

  handleToggle = (key) => {
    this.setState((prevState) => ({ [key]: !prevState[key] }))
    const { history } = this.props
    history.push(this.props.history.location.pathname)
  }
    setDateLayout = () => {
    const d = new Date()
    const n = d.getDate();
    if (n <= 15) {
       this.setState({
        start_datetime: new Date(moment().set('date', 1).format()),
        end_datetime: new Date(moment().set('date', 15).format())
      })
      //  this.props.getOrderBookList({ start_date: moment().set('date', 1).format('YYYY-MM-DD'), end_date: moment().set('date', 15).format('YYYY-MM-DD') })
    } else if (n >= 16) {
       this.setState({
        start_datetime: new Date(moment().set('date', 16).format()),
        end_datetime: new Date(moment(d).endOf('month').format())
      })
      //  this.props.getOrderBookList({ start_date: moment().set('date', 16).format('YYYY-MM-DD'), end_date: moment(d).endOf('month').format('YYYY-MM-DD') })
    }

  }

  


  render() {
    // const tabData = [
    //   {
    //     label: 'Booking',
    //     total: this.props.dataOrderBooking,
    //     key: '0',
    //     path: '/Booking',
    //   },
    // ]

    return (
      <div>
        <div>

          {this.props.children}
         
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  orderMode: state.orderManagement.orderMode,
  dataOrderBooking: state.orderManagement.dataOrderBooking
})

const mapDispatch = (dispatch) => {
  return {
    setCheckTypePath: dispatch.promotion.setCheckTypePath,
    setPageTitle: dispatch.Title.setPageTitle,
    getOrderBookList: dispatch.orderManagement.getOrderBookList,
    setOrderMode: dispatch.orderManagement.setOrderMode,
    showBackIcon: dispatch.Title.showBackIcon,
  }
}
export default connect(mapState, mapDispatch)(orderManagementLayout)
