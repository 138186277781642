import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { get } from '../../utils/service'

const Container = styled.div`
  padding: 0px 0px 0 20px;;
`

// const Section = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 20px;
// `
// const CheckedboxContent = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fill, minmax(15vw, 1fr));
//   grid-auto-rows: 20px;
//   flex: 1;
//   width: 85%;

//   padding-left: 30px;
// `

class SettingRolesPage extends Component {

  constructor() {
    super();
    this.state = {
      nameOfGroup: '',
      dataDetailList: []
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.props.checkStateCheckbox()
    if (prevProps.dataForEditGroup.id !== this.props.dataForEditGroup.id && this.props.dataForEditGroup.id !== undefined) {
      if (this.props.dataForEditGroup.id !== '') {
        this.getGroupListDeatil(this.props.dataForEditGroup.id)
      }
    }
    if(prevProps.dataGroupList!==this.props.dataGroupList){
      if(this.props.dataGroupList.length === 0){
           this.setState({ dataDetailList:this.props.dataGroupList })
      }
       
    }
  }

  componentDidMount() {
    this.getGroupList()
    this.props.setPageTitle('Group Setting')
    this.props.setModeSettingGroup('setting-group')
  }
  async getGroupList() {
   try {
    await get(`admin/setting/group/list`).then(res => {
      this.props.checkdefaultDetail({ name: res[0].name, id: res[0].id })
      this.getGroupListDeatil(res[0].id)
    })
   } catch (error) {
     console.log(error)
   }
  }
  async getGroupListDeatil(id) {
    try {
      await get(`admin/setting/group/id/${id}`).then(res => {
        this.props.setnameSettingGroup(res.name)
        if (res) {
          this.setState({
            nameOfGroup: res.name,
            dataDetailList: res.setting_group_users
          })
  
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  async EditGroup() {
    const { history } = this.props
    console.log(this.props.dataForEditGroup.type)
    console.log(this.props.dataForEditGroup)
    if (this.props.dataForEditGroup.type === 'CUSTOMER') {
      history.replace({
        pathname: '/admin/group-setting/setting-group-Of-user/setting-group-customer',
        state: this.props.dataForEditGroup
      })

    } else if (this.props.dataForEditGroup.type === 'MAID') {
      history.replace({
        pathname: '/admin/group-setting/setting-group-Of-user/setting-group-maid',
        state: this.props.dataForEditGroup
      })
    }
    this.props.setIDForUPdate(this.props.dataForEditGroup.id)
    this.props.setModeUPdate('Edit')
    this.props.SetkeepcheckboxCus([])
    this.props.SetkeepcheckboxMaid([])
  }

  render() {

    const { nameOfGroup, dataDetailList } = this.state

    return (
      <Container className="col-md-9">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-xl-12 col-md-12">
              <div className="card" style={{ border: " solid 1px #324755", borderRadius: "6px" }}>
                <div className="card-body p-b-0" >
                  <input style={{ border: " solid 1px #324755", borderRadius: "2px", height: '51px', background: "#ffffff" }}
                    type="text"
                    className="form-control"
                    placeholder="Group A"
                    name="nameOfGroup"
                    defaultValue={nameOfGroup}
                    disabled
                  />
                  <div>
                    <div className="table-responsive">
                      <table className="table v-middle">
                        <thead>
                          <tr>
                            <th className="border-top-0"></th>
                            <th className="border-top-0">Name</th>
                            <th className="border-top-0">Email</th>
                            <th className="border-top-0">Roles</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            dataDetailList.length > 0 ?
                              dataDetailList.map((el, i) => {
                                return <tr key={i}>
                                  <td>
                                    {
                                      el.user !== null ?
                                        <div className="d-flex align-items-center">
                                          <div className="m-r-10">
                                            <img
                                              src={`${el.user.image_url}`}
                                              alt="user"
                                              className="rounded-circle"
                                              width={45}
                                            />
                                          </div>
                                        </div>
                                        : null
                                    }
                                  </td>
                                  <td>{el.user !== null ? el.user.first_name + ' ' + el.user.last_name : null}</td>
                                  <td>{el.user !== null ? el.user.email : null}</td>
                                  <td>{el.user !== null ? el.user.role : null}</td>
                                </tr>
                              })
                              :
                              null
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-12 col-md-12" style={{ paddingBottom: '25px', textAlign: 'end' }}>
                    {
                      this.props.checkGroupEdit === true ?
                        <button
                          className={
                            'btn ' +
                            ('btn-button-broadcast')
                          }
                          onClick={() => this.EditGroup()}
                        >
                          Edit this group
                </button>
                        :
                        null
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      // checkGroupForEdit

    )
  }
}

const mapState = (state) => ({
  modeSettingGroup: state.settingGroup.modeSettingGroup,
  checkGroupEdit: state.settingGroup.checkGroupEdit,
  dataForEditGroup: state.settingGroup.dataForEditGroup,
  dataGroupList: state.settingGroup.dataGroupList
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    setModeSettingGroup: dispatch.settingGroup.setModeSettingGroup,
    checkdefaultDetail: dispatch.settingGroup.checkdefaultDetail,
    setnameSettingGroup: dispatch.settingGroup.setnameSettingGroup,
    checkStateCheckbox: dispatch.settingGroup.checkStateCheckbox,
    setModeUPdate: dispatch.settingGroup.setModeUPdate,
    setIDForUPdate: dispatch.settingGroup.setIDForUPdate,
    SetkeepcheckboxCus: dispatch.settingGroup.SetkeepcheckboxCus,
    SetkeepcheckboxMaid: dispatch.settingGroup.SetkeepcheckboxMaid

  }
}

export default connect(
  mapState,
  mapDispatch
)(SettingRolesPage)
