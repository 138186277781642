import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import ErrorBoundary from '../errorBoundary'
// import { Route, Switch, Redirect } from 'react-router-dom'

const TabContainer = styled.div`
  width: 80vw;
  overflow: hidden;
`

const TabScroll = styled.div`
  box-sizing: content-box;
  overflow-x: auto;
`

const TabBar = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${(props) => props.theme.transparent};
  padding-left: 25px;
  position: relative;
  // top: 2px;
  z-index: 5;
  width: max-content;

  .active {
    color: ${(props) => props.theme.text};
    background-color: white;
    border-bottom: 1px solid white;
  }

  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const TabLabel = styled.div`
  font-size: 14px;
  text-align: center;
  background-color: ${(props) => props.theme.active};
  // color: ${(props) => props.theme.grey};
  color:white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid ${(props) => props.theme.active};
  border-style: solid solid non solid; 
  width: max-content;
  min-width: 130px;
  // width: 120px;
  padding: 2px 20px;
  margin-right: 0.5vw;
  border-bottom: 3px solid transparent;
  outline: none;
  transition: all 0.3s;

  span{
    font-size: 14px
  }

  @media (max-width: 768px) {
    flex: 1;
    padding: 2px 20px 0px;
    width: 8vw;
    height: 10vw;
    text-align: center;
    // font-size: 11px;
    font-size: 4vw;
  }

  :hover {
    color: ${(props) => props.theme.text};
    background-color: white;
    cursor: pointer;
  }
`

const TabContent = styled.div``

class TabComponent extends Component {
  static propTypes = {}

  genTab = (item, index, activeTab) => (
    <TabLabel
      key={item.key}
      className={activeTab === index ? 'active' : ''}
      role="link"
      tabIndex={item.key}
      onClick={() =>  this.onClickFunc(item,index)}
      onKeyDown={() => {}}>
      {item.label}
      {item.total &&
      <span>{` (${item.total})`}</span>
      }
    </TabLabel>
  )
  // item.tesdt==='Customer'? null:

  onClickFunc = (item,index) => {
      
    const { onChangeTab, tabData } = this.props
    onChangeTab(tabData[index].path)
  }

  render() {
    const { tabData, location, match, style } = this.props
    const tabIndex = tabData.findIndex((item) => location.pathname === `${match.url}${item.path}`)
    const activeTab = tabIndex > 0 ? tabIndex : 0

    return (
      <ErrorBoundary>
        <TabContainer>
          <TabScroll>
            <TabBar style={style} >
              {tabData.map((item, index) => {
                return this.genTab(item, index, activeTab)
              })}
            </TabBar>
          </TabScroll>
        </TabContainer>
        <TabContent>{activeTab !== null && tabData[activeTab].component}</TabContent>
      </ErrorBoundary>
    )
  }
}

TabComponent.propTypes = {
  tabData: PropTypes.array.isRequired,
  onChangeTab: PropTypes.func,
}

export default withRouter(TabComponent)
