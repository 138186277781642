import React, { Component } from 'react'
import { connect } from 'react-redux'
import PlusIcon from '../../images/plus.svg'
import styled, { css } from 'styled-components'
import Rightbar from './rightbar'
import request from '../../utils/request'
import swal from 'sweetalert';
import MoveIcon from '../../images/minus.svg'

const MainContainer = styled.div`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

const CardContainer = styled.div`

${(props) => {
    if (props.ChangeColor) {
      return css`
   background: ${(props) => props.theme.rightCard2};
   `
    } else {
      return css`
   background: ${(props) => props.theme.rightCard};
   `
    }
  }}
  border: 1px solid ${(props) => props.theme.active};
  width: 100%;
  height: 44px;
  padding: 10px 20px;

  display: flex;
  justify-content: space-between;
  img {
    cursor: pointer;
  }
`

const Container = styled.div`
  display: flex;
  .hand {
    width: max-content;
    height: 100%;
    cursor: pointer;
  }
`
const Overlay = styled.div`
  content: ' ';
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
`

class SettingGroupLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkClickTemp: '',
      checkClickTempIndex: '',
      showRightbar: false
    }
    this.onDragEnd = this.onDragEnd.bind(this)
    this.handleToggleShow = this.handleToggleShow.bind(this)
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.fillterRightBar.mode !== undefined && this.props.fillterRightBar.showRightbar !== undefined) {
      if (this.props.fillterRightBar.mode === 'filter' && this.props.fillterRightBar.showRightbar === true) {
        this.setState({ showRightbar: true })
        this.props.checkFillterRightBar('')

      }
    }

    if ((this.props.defaultDetailist.name !== '' && this.props.defaultDetailist.name !== undefined) && (this.props.defaultDetailist.id !== '' && this.props.defaultDetailist.id !== undefined)) {
      this.setState({
        checkClickTemp: this.props.defaultDetailist.name,
        checkClickTempIndex: this.props.defaultDetailist.id
      })
      if (this.props.dataGroupList !== undefined) {
        this.props.setDataForEditGroup({
          id: this.props.dataGroupList.length > 0 ? this.props.dataGroupList[0].id : null,
          name: this.props.dataGroupList.length > 0 ? this.props.dataGroupList[0].name : null,
          type: this.props.dataGroupList.length > 0 ? this.props.dataGroupList[0].type : null,
          mode: 'Edit'
        })
      }
      this.props.checkdefaultDetail('')
      this.props.checkGroupForEdit(true)
    }
    if (prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname === "/admin/group-setting/setting-group") {
      this.setState({
        checkClickTemp: '',
        checkClickTempIndex: ''
      })
      this.props.getSettingGroupList()
    }
    if (this.props.dataReloadForEditGroup !== undefined) {
      if (this.props.dataReloadForEditGroup !== {} && this.props.dataReloadForEditGroup !== '') {
        this.props.setDataForEditGroup({
          id: this.props.dataReloadForEditGroup.id,
          name: this.props.dataReloadForEditGroup.name,
          type: this.props.dataReloadForEditGroup.type,
          mode: 'Edit'
        })
        this.setState({
          checkClickTemp: this.props.dataReloadForEditGroup.name,
          checkClickTempIndex: this.props.dataReloadForEditGroup.id
        })

        this.props.setReloadDataForEditGroup()
      }

    }
  }
  componentDidMount() {
    this.props.setModeUPdate('Add')
    this.props.getSettingGroupList()
    this.props.showBackIcon(false)
  }

  onChangeTab = (path) => {
    const { history, match } = this.props
    history.push(match.url + path)
  }
  handleToggleShow(value) {
    let newColumn = this.state.column
    const result = this.state.column.map((obj, index) => {
      if (obj.accessor === value) {
        const editObj = { ...obj, isShow: !obj.isShow }
        newColumn[index] = editObj
        return newColumn
      }
      return newColumn
    })
    this.setState({ column: result[0] })
    this.props.setCustomerColumnList(newColumn)
  }

  onDragEnd = () => {
    this.draggedIdx = null
  }

  onChangeTabTemplate = (path) => {
    this.props.setCheckDisable('')
    this.props.setnameSettingGroup('AddGroup')
    this.props.setDataForEditGroup({
      id: '',
      name: '',
      type: '',
      mode: ''
    })
    this.setState({
      checkClickTemp: '',
      checkClickTempIndex: ''
    })
    const { history, match } = this.props
    history.push(match.url + path)
    this.props.setModeSettingGroup('Customer')
    this.props.setModeUPdate('Add')
    this.props.SetkeepcheckboxMaid([])
    this.props.SetkeepcheckboxCus([])

  }

  ChangeGropList = (el, i) => {
    this.props.setnameSettingGroup('')
    this.setState({
      checkClickTemp: el.name,
      checkClickTempIndex: el.id
    })
    this.props.setDataForEditGroup({
      id: el.id,
      name: el.name,
      type: el.type,
      mode: 'Edit'
    })
    this.props.setIDForUPdate(el.id)
    this.props.setModeUPdate('Edit')
    this.props.checkGroupForEdit(true)

  }
  ChangeGroupCustomer = (el, i) => {
    this.props.setnameSettingGroup('')
    this.setState({
      checkClickTemp: el.name,
      checkClickTempIndex: el.id
    })
    this.props.setDataForEditGroup({
      id: el.id,
      name: el.name,
      type: el.type,
      mode: 'Edit'
    })
    this.props.setIDForUPdate(el.id)
    this.props.setModeUPdate('Edit')
    this.props.checkGroupForEdit(true)

  }
  ChangeGroupMaid = (el, i) => {
    this.props.setnameSettingGroup('')
    this.setState({
      checkClickTemp: el.name,
      checkClickTempIndex: el.id
    })
    this.props.setDataForEditGroup({
      id: el.id,
      name: el.name,
      type: el.type,
      mode: 'Edit'
    })
    this.props.setIDForUPdate(el.id)
    this.props.setModeUPdate('Edit')
    this.props.checkGroupForEdit(true)

  }
  async DeleteGroupSetting(el, i) {
    swal({
      className: 'swal-modal',
      title: "Are you sure?",
      text: `You want delete Group`,
      icon: "warning",
      buttons: {
        confirm: `Delete`,
        cancel: `Cancel`,
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(el)

        swal("Deleted Success", {
          icon: "success",
        });
        try {
          request.post(`setting/group/id/${el.id}/delete`).then(res => {
            this.props.getSettingGroupList()
            this.props.getSettingCustomer()
            this.props.getSettingMaid()
            setTimeout(() => {
              if (this.props.dataGroupList.length > 0) {
                this.props.setDataForEditGroup({
                  id: this.props.dataGroupList.length > 0 ? this.props.dataGroupList[0].id : null,
                  name: this.props.dataGroupList.length > 0 ? this.props.dataGroupList[0].name : null,
                  type: this.props.dataGroupList.length > 0 ? this.props.dataGroupList[0].type : null,
                  mode: 'Edit'
                })
                this.props.checkdefaultDetail({ name: this.props.dataGroupList[0].name, id: this.props.dataGroupList[0].id })
              }
            }, 500);
          })

        } catch (error) {
          console.log(error)
        }
      }
    })
  }
  handleToggle = (key) => {
    this.setState((prevState) => ({ [key]: !prevState[key] }))
    const { history } = this.props
    history.push(this.props.history.location.pathname)
  }
  render() {

    const currentLocation = this.props.history.location.pathname
    return (
      <div>
        {this.state.showRightbar && <Overlay onClick={() => this.handleToggle('showRightbar')} />}
        <div className="page-breadcrumb" >

          {this.props.children}
        </div>
        <div id="RightBar-Broadcast">
          <MainContainer>
            <ul>
              <h3
                style={{
                  fontSize: '18px',
                  lineHeight: '1.22px',
                  color: '#f0f3f4',
                  padding: '15px',
                }}>
                Group
              </h3>
              <CardContainer>
                <Container>

                  <label
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.onChangeTabTemplate('/setting-group-Of-user')}>
                    Add new Group
                    </label>
                </Container>
                <Container>
                  <img alt="minus-icon" src={PlusIcon} />
                </Container>
              </CardContainer>
              {/* //////////// Template /////////////////*/}

              {this.props.modeSettingGroup === 'Customer' ?
                <div style={{ overflow: 'overlay', height: '85vh' }}>
                  {
                    this.props.dataGroupCustomerList.map((el, i) => (
                      <li key={el.id}>
                        {
                          this.state.checkClickTemp === el.name && this.state.checkClickTempIndex === el.id ?
                            <CardContainer ChangeColor={true}>
                              <Container >
                                <div style={{ cursor: 'pointer' }} onClick={() => this.ChangeGroupCustomer(el, i)}>{el.name}</div>
                              </Container>
                            </CardContainer>
                            :
                            <CardContainer>
                              <Container >
                                <div style={{ cursor: 'pointer' }} onClick={() => this.ChangeGroupCustomer(el, i)}>{el.name}</div>
                              </Container>
                            </CardContainer>
                        }
                      </li>
                    ))
                  }
                </div>
                : this.props.modeSettingGroup === 'Maid' ?
                  <div style={{ overflow: 'overlay', height: '85vh' }}>
                    {
                      this.props.dataGroupMaidList.map((el, i) => (
                        <li key={el.id}>
                          {
                            this.state.checkClickTemp === el.name && this.state.checkClickTempIndex === el.id ?
                              <CardContainer ChangeColor={true}>
                                <Container >
                                  <div style={{ cursor: 'pointer' }} onClick={() => this.ChangeGroupMaid(el, i)}>{el.name}</div>
                                </Container>
                              </CardContainer>
                              :
                              <CardContainer>
                                <Container >
                                  <div style={{ cursor: 'pointer' }} onClick={() => this.ChangeGroupMaid(el, i)}>{el.name}</div>
                                </Container>
                              </CardContainer>
                          }
                        </li>
                      ))
                    }
                  </div>
                  :
                  <div style={{ overflow: 'overlay', height: '85vh' }}>
                    {
                      this.props.dataGroupList.map((el, i) => (
                        <li key={el.id}>
                          {

                            this.state.checkClickTemp === el.name && this.state.checkClickTempIndex === el.id ?
                              <CardContainer ChangeColor={true}>
                                <Container >
                                  <div style={{ cursor: 'pointer' }} onClick={() => this.ChangeGropList(el, i)}>{el.name}</div>
                                </Container>
                                <Container>
                                  <img alt="minus-icon" src={MoveIcon} onClick={() => this.DeleteGroupSetting(el, i)} />
                                </Container>
                              </CardContainer>
                              :
                              <CardContainer>
                                <Container >
                                  <div style={{ cursor: 'pointer' }} onClick={() => this.ChangeGropList(el, i)}>{el.name}</div>
                                </Container>
                                <Container>
                                  <img alt="minus-icon" src={MoveIcon} onClick={() => this.DeleteGroupSetting(el, i)} />
                                </Container>
                              </CardContainer>
                          }
                        </li>
                      ))
                    }
                  </div>
              }
            </ul>
          </MainContainer>
        </div>
        <Rightbar data={this.state} currentLocation={currentLocation} />
      </div>
    )
  }
}
const mapState = (state) => ({
  showBtnAdd: state.promotion.showBtnAdd,
  pageTitle: state.Title.pageTitle,
  dataGroupList: state.settingGroup.dataGroupList,
  modeSettingGroup: state.settingGroup.modeSettingGroup,
  dataGroupCustomerList: state.settingGroup.dataGroupCustomerList,
  dataGroupMaidList: state.settingGroup.dataGroupMaidList,
  defaultDetailist: state.settingGroup.defaultDetailist,
  fillterRightBar: state.settingGroup.fillterRightBar,
  dataReloadForEditGroup: state.settingGroup.dataReloadForEditGroup,
})
const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    setCustomerColumnList: dispatch.customer.setCustomerColumnList,
    getSettingGroupList: dispatch.settingGroup.getSettingGroupList,
    setModeSettingGroup: dispatch.settingGroup.setModeSettingGroup,
    checkGroupForEdit: dispatch.settingGroup.checkGroupForEdit,
    setDataForEditGroup: dispatch.settingGroup.setDataForEditGroup,
    checkdefaultDetail: dispatch.settingGroup.checkdefaultDetail,
    checkFillterRightBar: dispatch.settingGroup.checkFillterRightBar,
    setReloadDataForEditGroup: dispatch.settingGroup.setReloadDataForEditGroup,
    setnameSettingGroup: dispatch.settingGroup.setnameSettingGroup,
    setCheckDisable: dispatch.settingGroup.setCheckDisable,
    setModeUPdate: dispatch.settingGroup.setModeUPdate,
    setIDForUPdate: dispatch.settingGroup.setIDForUPdate,
    SetkeepcheckboxMaid: dispatch.settingGroup.SetkeepcheckboxMaid,
    SetkeepcheckboxCus: dispatch.settingGroup.SetkeepcheckboxCus,
    getSettingCustomer: dispatch.settingGroup.getSettingCustomer,
    getSettingMaid: dispatch.settingGroup.getSettingMaid,
    showBackIcon: dispatch.Title.showBackIcon,

  }
}

export default connect(
  mapState,
  mapDispatch
)(SettingGroupLayout)
