import React, { Component } from 'react'
import styled from 'styled-components'


const Container = styled.div`
  border-top: 1.5px solid #d1ceda;
  // padding: 10px 0px;
  margin: 10px 0px 0px;
  width: 100%;
`

class Divider extends Component {
  render() {
    return (
      <Container />
    )
  }
}

export default Divider