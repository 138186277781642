import React, { Component } from 'react'
import styled from 'styled-components'

import MoreIcon from '../../images/more.svg'
import ContentPic from '../../images/contentPic1.png'

const FrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  padding: 10px 0px;
`

const Divider = styled.div`
  width: 97.5%
  align-self: flex-end;
  border-bottom: 1.5px solid ${(props) => props.theme.active};
`

const Container = styled.div`
  display: flex;
`

const Icon = styled.div`
  align-self: center;
  img {
  }
`

const Picture = styled.img`
  width: 100px;
  align-self: center;
  margin: 0 20px 0;
  img {
  }
`
const FrontContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;
`

const Title = styled.div``

const Text = styled.div``
const BackContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`

export default class index extends Component {
  render() {
    return (
      <FrameContainer>
        <CardContainer>
          <Container>
            <Icon>
              <img alt="moreIcon" src={MoreIcon} />
            </Icon>
            <Picture alt="contentPicture" src={ContentPic} />
            <FrontContainer>
              <Title>{`The Journey Begins`}</Title>
              <Container>
                <Text> {`Date : 06/09/2007`}</Text>
                <Text> {`Time : 06: 15 PM`}</Text>
              </Container>
            </FrontContainer>
          </Container>
          <BackContainer>
            <Text> {`Created by : Sudarat`}</Text>
            <Text> {`Show on : Homepage`}</Text>
          </BackContainer>
        </CardContainer>
        <Divider />
      </FrameContainer>
    )
  }
}
